/**
 * @author Vyacheslav Skripin <vs@ieksr.ru>
 * @created 25.04.2022
 * @description Dts chart alarms tab
 */

import { DtsChartModel } from '../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/DtsChartModel';
import { ChartAlarmLinesControl, IChartAlarmLinesControlActionProps } from '../ChartAlarmLinesControl';

interface IDtsChartAlarmsTabProps extends IChartAlarmLinesControlActionProps {
    chart: DtsChartModel;
}

export const DtsChartAlarmsTab = ({
    chart,
    onAlarmLineDeleteClickHandler,
    onChangeAlarmLines,
    onNewAlarmLinkClickHandler
}: IDtsChartAlarmsTabProps) => {

    const { AlarmLines } = chart;

    return (
        <div className="geovisChartTab" style={{ height: '100%', overflow: 'hidden' }}>
            <div className="flexRowContainerLine">
                <ChartAlarmLinesControl
                    AlarmLines={AlarmLines}
                    chartType={chart.Type}
                    onAlarmLineDeleteClickHandler={onAlarmLineDeleteClickHandler}
                    onChangeAlarmLines={onChangeAlarmLines}
                    onNewAlarmLinkClickHandler={onNewAlarmLinkClickHandler}
                    yUnit={chart.LeftYAxisSettings.Unit}
                    xUnit={undefined}
                />
            </div>
        </div>
    )
}