import { MediaClient } from '@atlaskit/media-client';
import { Auth, AuthContext } from '@atlaskit/media-core';
import { Dropzone } from '@atlaskit/media-picker';
import {
    BrowserConfig,
    DropzoneConfig,
    UploadEndEventPayload,
    UploadErrorEventPayload,
    UploadsStartEventPayload
} from '@atlaskit/media-picker/types';
import { CSSProperties, useEffect, useState } from 'react';
import { Subscription } from 'rxjs/Subscription';
import { t } from 'i18next';
import { ButtonProps } from '@atlaskit/button';
import AuthService from '../../services/AuthService';
import { LoadingContainerSkeleton } from '../LoadingContainerSkeleton';
import FileBrowser, { IErrorUploadFileInfo, IProcessingUploadFileInfo, IProgressUploadFileInfo, IUploadProcessedFileInfo } from '../FileBrowser';
import Logger from '../../services/Logger';
import { GeovisUploadFileState } from '../../store/uploading.types';
import { UploadedFilesTable } from './UploadedFilesTable';
import FlagService from '../../services/FlagService';

const LoggerSource = "GeovisDragAdnDrop";

interface IComponentProps {
    collection: string;
    baseUrl: string;
    fileExtensions: string[];
    multiple?: boolean;
    files: GeovisUploadFileState[];
    buttonProps?: ButtonProps;
    isDisabled?: boolean;
    uploadText?: string;

    onFileDelete: (fileId: string) => void;
    onUploadsStart?: (payload: UploadsStartEventPayload) => void;
    onUploadEnd?: (payload: UploadEndEventPayload) => void;
    onUploadStatusUpdate?: (statusUpdateEvent: IProgressUploadFileInfo) => void;
    onUploadProcessing?: (uploadProcessing: IProcessingUploadFileInfo) => void;
    onError?: (errorEvent: IErrorUploadFileInfo) => void;
    onUploadProcessed?: (status: IUploadProcessedFileInfo) => void;
}

interface IMediaComponentState {
    mediaClient?: MediaClient;
    dropzoneConfig?: DropzoneConfig;
    browserConfig?: BrowserConfig;
    cancelFn?: (uniqueIdentifier: string) => void
}

interface IComponentState {
    isDropped: boolean;
    isUploadEnd: boolean;
    isDraggingFileUnderContainer: boolean;
    disableReason: string;
}

export const GeovisDragAndDropFilesComponent = ({
    collection,
    baseUrl,
    onError,
    onUploadEnd,
    onUploadProcessed,
    onUploadProcessing,
    onUploadStatusUpdate,
    onUploadsStart,
    fileExtensions,
    multiple,
    files,
    onFileDelete,
    buttonProps,
    isDisabled,
    uploadText = "files"
}: IComponentProps) => {

    let subscription: Subscription | undefined;

    const unsubscribe = () => {
        if (subscription) {
            subscription.unsubscribe();
        }
    };

    const [mediaState, setMediaState] = useState<IMediaComponentState>({
        mediaClient: undefined,
        dropzoneConfig: undefined,
        browserConfig: undefined
    });

    const [componentState, setComponentState] = useState<IComponentState>({
        isDropped: false,
        isUploadEnd: false,
        isDraggingFileUnderContainer: false,
        disableReason: ""
    });

    useEffect(() => {
        createClient();
    }, [1]);

    useEffect(() => {
        if (!multiple && files.length >= 1) {
            setComponentState({
                ...componentState,
                disableReason: "Uploading only one file allowed"
            })
        }
    }, [files]);

    const uploadingDisabled = (): boolean => isDisabled || !multiple && files.length === 1;

    const createClient = () => {

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const authMediaPickerProvider = ({ collectionName }: AuthContext) => new Promise<Auth>((resolve) => {
            resolve({
                token: AuthService.getTokenSafety(),
                clientId: 'clientId',
                baseUrl
            });
        });

        const dropzoneConfig: DropzoneConfig = {
            uploadParams: {
                collection
            },
            container: document.getElementById('dropzone')!
        };

        const browserConfig: BrowserConfig = {
            fileExtensions: (fileExtensions.length > 0 && fileExtensions[0] !== '*.*') ? [...fileExtensions] : undefined,
            multiple,
            uploadParams: {
                collection
            }
        };

        setMediaState({
            mediaClient: new MediaClient({ authProvider: authMediaPickerProvider }),
            dropzoneConfig,
            browserConfig
        });
    };

    const onUploadsStartHandler = (payload: UploadsStartEventPayload) => {
        if (uploadingDisabled()) {
            payload.files.forEach(f => {
                if (mediaState.cancelFn) {
                    mediaState.cancelFn(f.id);
                }
            });
            return;
        }

        const invalidFiles = payload.files.filter(file => {
            const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
            return fileExtensions.length > 0 && !fileExtensions.includes(fileExtension) && fileExtensions[0] !== '*.*';
        });

        if (invalidFiles.length > 0) {
            FlagService.addError("Invalid file format. Please upload a valid file.", `Invalid file format: ${invalidFiles.map(file => file.name).join(', ')}`);

            invalidFiles.forEach(file => {
                if (mediaState.cancelFn) {
                    mediaState.cancelFn(file.id);
                }
            });

            setComponentState({
                ...componentState,
                isDropped: false,
                isUploadEnd: true,
                disableReason: "Invalid file format"
            });
            return;
        }

        if (payload.files.length > 1 && !multiple) {
            FlagService.addError(t(`Can not upload multiple ${uploadText}`), t(`Uploading multiple ${uploadText} is forbidden.`));

            payload.files.forEach(f => {
                if (mediaState.cancelFn) {
                    mediaState.cancelFn(f.id);
                }
            });

            return;
        }

        const { mediaClient } = mediaState;

        if (mediaClient) {
            unsubscribe();

            payload.files.map((file) => {
                subscription = mediaClient.file.getFileState(file.id)
                    .subscribe({
                        next(state) {
                            if (state.status === 'uploading') {
                                if (onUploadStatusUpdate) {
                                    onUploadStatusUpdate({ fileId: state.id, fileName: state.name, progress: state.progress });
                                }
                            }
                            else if (state.status === 'processing') {
                                if (onUploadProcessing) {
                                    onUploadProcessing({ fileId: state.id, fileName: state.name, artifacts: state.artifacts });
                                }
                            }
                            else if (state.status === 'failed-processing') {
                                setComponentState({
                                    ...componentState,
                                    isDropped: false,
                                    isUploadEnd: true
                                });
                                Logger.trace(`Dropzone: subscription failed-processing ${state}`, LoggerSource);
                            }
                            else if (state.status === 'processed') {
                                setComponentState({
                                    ...componentState,
                                    isDropped: false,
                                    isUploadEnd: true
                                });
                                if (onUploadProcessed) {
                                    onUploadProcessed({ fileId: state.id, fileName: state.name, size: state.size });
                                }
                            }
                        },

                        error(err) {
                            setComponentState({
                                ...componentState,
                                isDropped: false,
                                isUploadEnd: true
                            });
                            if (err === 'canceled') {
                                Logger.trace(`Dropzone: subscription canceled error`, LoggerSource);
                            }
                            else {
                                Logger.warning(`Dropzone: subscription error ${err}`, LoggerSource);
                            }
                        }
                    });
            });

            if (onUploadsStart) {
                onUploadsStart(payload);
            }
        }
    };

    const onUploadEndHandler = (payload: UploadEndEventPayload) => {
        if (uploadingDisabled()) {
            return;
        }
        setComponentState({
            ...componentState,
            isDraggingFileUnderContainer: false,
            isDropped: false,
            isUploadEnd: true,
        });
        if (onUploadEnd) {
            onUploadEnd(payload);
        }
    }

    const onErrorHandler = (payload: UploadErrorEventPayload) => {
        setComponentState({
            ...componentState,
            isDraggingFileUnderContainer: false,
            isDropped: false,
            isUploadEnd: true,
        });
        onAnyError(payload.fileId, payload.error.description);
    }

    const onAnyError = (fileId: string, error: any) => {
        if (onError) {
            const errorDescription = typeof error === "string" ? error
                : (error.error && error.error instanceof Error ? error.error.message : "unexpected error");
            onError({ fileId, fileName: "", errorDescription });
        }
    }

    const onBrowserErrorHandler = (errorEvent: IErrorUploadFileInfo) => {
        setComponentState({
            ...componentState,
            isDraggingFileUnderContainer: false,
            isDropped: false,
            isUploadEnd: true
        });
        if (onError) {
            onError(errorEvent);
        }
    }

    const onDrop = () => {
        if (uploadingDisabled()) {
            return;
        }
        setComponentState({
            ...componentState,
            isDraggingFileUnderContainer: false,
            isDropped: true,
            isUploadEnd: false,
        })
    };

    const onCancelFnCallback = (cancel: (uniqueIdentifier: string) => void) => {
        setMediaState(st => ({
            ...st,
            cancelFn: cancel
        }));
    }

    const onDragEnterHandler = () => {
        setComponentState({
            ...componentState,
            isDraggingFileUnderContainer: true
        })
    }

    const onDragLeaveHandler = () => {
        setComponentState({
            ...componentState,
            isDraggingFileUnderContainer: false
        })
    }

    const dropzoneContainerStyle: CSSProperties = componentState.isDraggingFileUnderContainer
        ? { width: '100%', height: '100%', background: '#7a869a', display: 'flex', padding: '10px' }
        : { width: '100%', height: '100%', background: '#ebecf0', display: 'flex', padding: '4px' };

    const dropzoneContentStyle: CSSProperties = componentState.isDraggingFileUnderContainer
        ? { width: '100%', background: 'transparent', border: '3px dashed #ebecf0', display: 'flex', justifyContent: 'center' }
        : { width: '100%', background: 'transparent', border: '3px dashed #7a869a', display: 'flex', justifyContent: 'center' };

    return (
        <div className='flexColumnContainer'>
            {files.length !== 0 &&
                <div style={{ width: '100%' }}>
                    <UploadedFilesTable
                        files={files}
                        onFileDelete={onFileDelete}
                    />
                </div>
            }
            <div style={dropzoneContainerStyle} id="dropzone">
                <div style={dropzoneContentStyle}>
                    {componentState.isDropped && !componentState.isUploadEnd && !componentState.isDraggingFileUnderContainer &&
                        <div style={{ height: '100%', width: '100%' }}>
                            <LoadingContainerSkeleton loadingText={t("Upload is in progress")} />
                        </div>
                    }
                    {!componentState.isDropped &&
                        <div style={{ height: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', gap: '5px' }}>
                            {!componentState.isDraggingFileUnderContainer && <h1 style={{ color: '#7a869a' }}>{uploadingDisabled() ? t(`Uploading ${uploadText} is disabled`) : t(`Drag here ${uploadText} for uploading`)}</h1>}
                            {!componentState.isDraggingFileUnderContainer && !uploadingDisabled() && <span style={{ color: '#7a869a', fontWeight: 'bold' }}>{t("or")}</span>}
                            {!componentState.isDraggingFileUnderContainer && uploadingDisabled() && componentState.disableReason !== '' && <h3 style={{ color: '#7a869a' }}>{t(componentState.disableReason)}</h3>}
                            {componentState.isDraggingFileUnderContainer && <h1 style={{ color: '#ebecf0' }}>{t(`Drop your ${uploadText} here`)}</h1>}
                            {mediaState.mediaClient && mediaState.browserConfig && collection.length && !componentState.isDraggingFileUnderContainer && !uploadingDisabled() &&
                                <FileBrowser
                                    baseUrl={baseUrl}
                                    buttonText={t(`Choose ${uploadText} manually`)}
                                    buttonProps={{ ...buttonProps, isDisabled: uploadingDisabled() }}
                                    collection={collection}
                                    fileExtensions={fileExtensions}
                                    multiple={multiple}
                                    onUploadsStart={onUploadsStartHandler}
                                    onUploadEnd={onUploadEndHandler}
                                    onUploadStatusUpdate={onUploadStatusUpdate}
                                    onError={onBrowserErrorHandler}
                                />
                            }
                        </div>
                    }
                    {(!mediaState.mediaClient || !mediaState.dropzoneConfig) &&
                        <LoadingContainerSkeleton />
                    }
                    {!uploadingDisabled() && mediaState.mediaClient && mediaState.dropzoneConfig && collection.length &&
                        <div>
                            <Dropzone
                                mediaClientConfig={mediaState.mediaClient.config}
                                config={mediaState.dropzoneConfig}
                                onUploadsStart={onUploadsStartHandler}
                                onError={onErrorHandler}
                                onDragEnter={onDragEnterHandler}
                                onDragLeave={onDragLeaveHandler}
                                onDrop={onDrop}
                                onCancelFn={onCancelFnCallback}
                                onEnd={onUploadEndHandler} />
                        </div>
                    }
                </div>
            </div>
        </div>

    )
}