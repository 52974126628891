/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 28.10.2022
 * @description Geovis4Table render tools for HotTable
 */


import Handsontable from 'handsontable/base';
import type { CellMeta, CellSettings } from 'handsontable/settings';
import { IGeovis4TableCellProperties, IGeovis4TableCellSettings } from '../../../../../components/projectOverview/geovis4table/types';
import { Geovis4TableData } from '../../../../../server/AVTService/TypeLibrary/Computation/Geovis4TableData';
import { Geovis4Table } from '../../../../../server/AVTService/TypeLibrary/Model/Geovis4Tables/Geovis4Table';
import { Geovis4TableCellMeta } from '../../../../../server/AVTService/TypeLibrary/Model/Geovis4Tables/Geovis4TableCellMeta';
import { Geovis4TableColumnsWidthMode } from '../../../../../server/AVTService/TypeLibrary/Model/Geovis4Tables/Geovis4TableColumnsWidthMode';
import { IGeovis4TableReportInfo, IGeovisReportSettings } from '../../../../../store/projectReport.types';
import { geovis4TableDataInitialState } from '../../../../../store/reducers/projectReportReducer';
import { ISomethingStorageBaseEx } from '../../../../../store/types';
import { Geovis4TableHotCellRenderFactory } from './Geovis4TableHotCellRender';
import { Geovis4TableRowsToNextTable } from './Geovis4TableReport.Render.Page';
import { geovis4TableHandsonTableCommonSettings, HotTableRenderMode } from './renderTypes';

const getColumnWidth = (columnWidths: number[], columnWidthMode: Geovis4TableColumnsWidthMode) => (index: number) => {
    if (columnWidthMode === Geovis4TableColumnsWidthMode.StretchAll) {
        return undefined;
    }

    return columnWidths[index];
}

/**
 * Generate Geovis4Table 
 * @param isConstructorMode
 * @param table 
 * @param containerRef
 */
export const getGeovis4TableHotViewerSettings = (table: Geovis4Table, renderMode: HotTableRenderMode): Handsontable.GridSettings => {

    const settings: Handsontable.GridSettings = {
        ...geovis4TableHandsonTableCommonSettings,
        data: table.Data,
        cell: convertGeovis4TableCellsMetaToHotTableCellsMeta(table.CellsMeta),
        preventOverflow: true,
        stretchH: getStretchHMode(table),
        colWidths: getColumnWidth(table.ColumnWidths, table.ColumnWidthMode),
        rowHeights: table.RowHeights,
        manualRowResize: false,
        manualColumnResize: false,
        autoColumnSize: false,
        // when this settings set to true we have problems with merged cells height (see https://ai7d.atlassian.net/browse/AGMS-4695?focusedCommentId=18899)
        autoRowSize: false,
        cells: getCellFunction(table),
        mergeCells: table.CellMerge,
        // height: 'auto',
        // width: 'auto',
        height: renderMode === HotTableRenderMode.CONSTRUCTOR ? '100%' : 'auto',
        width: '100%', // renderMode === HotTableRenderMode.CONSTRUCTOR ? '100%' : 'auto',
        disableVisualSelection: true
    };

    return settings;
}

/**
 * Get function, which helps to setup the Cell parameters
 * Use to style the cell
 * @param table 
 * @returns 
 */
const getCellFunction = (table: Geovis4Table) => {

    const renderer = Geovis4TableHotCellRenderFactory(table, true)

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return function cellsFunction(this: IGeovis4TableCellProperties, row: number, column: number, prop: string | number): CellMeta {

        return { ...this, renderer };
    }
}

/**
 * Get table column stretch mode
 * @param table 
 * @returns 
 */
const getStretchHMode = (table: Geovis4Table): 'none' | 'all' | 'last' => {
    switch (table.ColumnWidthMode) {
        case Geovis4TableColumnsWidthMode.StretchAll: return 'all';
        case Geovis4TableColumnsWidthMode.StretchLast: return 'last';
        case Geovis4TableColumnsWidthMode.Manually: return 'none';
    }
}

/**
 * Convert Geovis4TableCellMeta to HotTable cell settings
 * @param geovisCellsMeta 
 */
const convertGeovis4TableCellsMetaToHotTableCellsMeta = (geovisCellsMeta: Geovis4TableCellMeta[]): CellSettings[] | undefined => {

    if (geovisCellsMeta.length === 0) {
        return undefined;
    }

    return geovisCellsMeta.map<IGeovis4TableCellSettings>(m => ({
        fontSize: m.FontSize,
        fontColor: m.FontColor,
        backgroundColor: m.BackgroundColor,
        borders: m.Borders,
        textDirection: m.TextDirection,
        className: m.ClassNames ? m.ClassNames.join(' ') : '',
        col: m.Col,
        row: m.Row,
        decimalDigits: m.DecimalsDigits,
        useCustomDecimalDigits: m.UseCustomDecimalDigits,
        typeValue: m.ValueType
    }));
}

/**
 * Get geovis4 table report info (to get data from server to draw on report)
 * @param reportSettings 
 * @param pageNum 
 * @param tableId 
 * @returns 
 */
export const getGeovis4TableReportInfo = (reportSettings: IGeovisReportSettings, pageNum: number, tableId: number): IGeovis4TableReportInfo | undefined => {

    const page = reportSettings.geovisPages.get(pageNum);
    if (!page) {
        return undefined;
    }

    return page.tables.get(tableId);
}

/**
 * Get geovis4table report data to render
 * @param reportSettings 
 * @param pageNum 
 * @param tableId 
 */
export const getGeovis4TableReportData = (reportSettings: IGeovisReportSettings, pageNum: number, tableId: number): ISomethingStorageBaseEx<Geovis4TableData> => {

    const page = reportSettings.geovisPagesData.get(pageNum);
    if (!page) {
        return geovis4TableDataInitialState;
    }

    const data = page.geovis4TablesData.get(tableId);
    if (!data) {
        return geovis4TableDataInitialState;
    }

    return data;
}

/**
 * Merge Geovis4Table with it's data to draw on the report page
 * @param table 
 * @param data 
 */
export const mergeGeovis4TableData = (table: Geovis4Table, data: Geovis4TableData): Geovis4Table => {


    const result: Geovis4Table = {
        ...table,
        Timeslot: { customerChangeable: table.Timeslot.customerChangeable, Value: data.Timeslot },
        Data: data.Data,
        CellsMeta: table.CellsMeta.map<Geovis4TableCellMeta>(tm => {

            const row = data.CellsMeta[tm.Row];
            if (!row) {
                return tm;
            }

            const dataCell = row[tm.Col];
            if (!dataCell) {
                return tm;
            }

            return {
                ...tm,
                BackgroundColor: dataCell.BackgroundColor || tm.BackgroundColor,
                ClassNames: dataCell.ClassNames || tm.ClassNames,
                FontColor: dataCell.FontColor || tm.FontColor,
                FontSize: dataCell.FontSize || tm.FontSize,
                Borders: dataCell.Borders || tm.Borders
            };
        })
    };

    return result;
}

export const getInitialGeovis4Table = (table: Geovis4Table): Geovis4Table => ({
    ...table,
    ColumnWidths: [...table.ColumnWidths],
    Data: [],
    CellsMeta: [],
    RowHeights: [],
    CellMerge: []
});

const getRowIndexesOrdered = (rowsToNextTable: Geovis4TableRowsToNextTable): number[] => {

    if (!rowsToNextTable) {
        return [];
    }

    const rowsMap: { [key: number]: boolean } = {};

    for (const meta of rowsToNextTable.CellsMeta) {
        if (rowsMap[meta.Row] === undefined) {
            rowsMap[meta.Row] = true;
        }
    }

    return Object.keys(rowsMap).map(r => +r).sort();

}

/**
 * Generate new table to draw on new page
 * @param baseTable 
 * @param rowsToNextTable 
 */
export const generateGeovis4TableFromRows = (baseTable: Geovis4Table, firstPageTable: Geovis4Table | false, rowsToNextTable: Geovis4TableRowsToNextTable): Geovis4Table => {

    if (!rowsToNextTable) {
        return baseTable;
    }

    const destTable = getInitialGeovis4Table(baseTable);

    if (firstPageTable && firstPageTable.HeaderFirstRows.value > 0) {
        for (let rowIndex = 0; rowIndex < firstPageTable.HeaderFirstRows.value; ++rowIndex) {
            if (rowIndex < firstPageTable.Data.length) {
                const data = firstPageTable.Data[rowIndex];
                destTable.Data.push(data);
            }

            if (rowIndex < firstPageTable.RowHeights.length) {
                destTable.RowHeights.push(firstPageTable.RowHeights[rowIndex]);
            }

            if (rowIndex < firstPageTable.CellsMeta.length) {
                const metaRecords = firstPageTable.CellsMeta.filter(r => r.Row === rowIndex);
                destTable.CellsMeta.push(...metaRecords);
            }

            if (rowIndex < firstPageTable.CellMerge.length) {
                const mergeRecords = firstPageTable.CellMerge.filter(r => r.row === rowIndex);
                destTable.CellMerge.push(...mergeRecords);
            }
        }
    }

    {
        const rowOffset = destTable.Data.length;
        const rowIndexesOrdered = getRowIndexesOrdered(rowsToNextTable);

        for (let rowIndex = 0; rowIndex < rowIndexesOrdered.length; rowIndex++) {
            const row = rowIndexesOrdered[rowIndex];

            const data = rowsToNextTable.Data[rowIndex];
            // const rowHeight = rowsToNextTable.RowHeights[rowIndex];
            const metaRecords = rowsToNextTable.CellsMeta.filter(r => r.Row === row);
            const mergeRecords = rowsToNextTable.CellMerge.filter(r => r.row === row);

            destTable.Data.push(data);
            destTable.CellsMeta.push(...metaRecords.map(m => ({ ...m, Row: rowIndex + rowOffset })));
            destTable.CellMerge.push(...mergeRecords.map(m => ({ ...m, row: rowIndex + rowOffset })));
        }

        destTable.RowHeights.push(...rowsToNextTable.RowHeights);
    }

    return destTable;
}

/**
 * Join table rows to exist table
 * Insert rows before exist rows
 * @param baseTable
 * @param rowsToNextTable 
 */
export const joinGeovis4TableRows = (baseTable: Geovis4Table, rowsToNextTable: Geovis4TableRowsToNextTable): Geovis4Table => {

    if (!rowsToNextTable) {
        return getInitialGeovis4Table(baseTable);
    }

    const destTable = generateGeovis4TableFromRows(baseTable, false, rowsToNextTable);
    const rowOffset = destTable.Data.length;

    destTable.Data.push(...baseTable.Data);
    destTable.CellsMeta.push(...baseTable.CellsMeta.map(m => ({ ...m, Row: m.Row + rowOffset })));
    destTable.CellMerge.push(...baseTable.CellMerge.map(m => ({ ...m, row: m.row + rowOffset })));
    destTable.RowHeights.push(...baseTable.RowHeights);

    return destTable;
}