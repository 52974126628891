import { UploadEndEventPayload, UploadsStartEventPayload } from '@atlaskit/media-picker/dist/cjs/types';
import { useRef, useState } from 'react';
import FileBrowser, { IErrorUploadFileInfo, IProgressUploadFileInfo } from "../../../../components/FileBrowser";
import { IWithGeovisServerProps, withGeovisServer } from '../../../../helpers/GeovisHooks';
import { t } from '../../../../i18n';
import FlagService from '../../../../services/FlagService';
import { GeovisUploadFileState } from '../../../../store/uploading.types';
import { FeedbackAttachmentInfo } from "../../../../server/AVTService/TypeLibrary/Feedback/FeedbackAttachmentInfo";
import { DataActionResponse } from "../../../../server/DataActionResponse";
import Logger from "../../../../services/Logger";
import ServerRoutesGen from "../../../../server/Routes/ServerRoutesGen";
import { GeovisDragAndDropFilesComponent } from '../../../../components/dragAndDropFiles/GeovisDragAndDropFilesComponent';
import { getFeedbackAttachmentsCollectionString } from '../../../../helpers/UploadHelper';

const LoggerName = "FeedbackAttachmentsUploadControl";

const MAX_UPLOAD_FILES_LENGTH = 3;
const MAX_SIZE_IN_MB = 10;
const MAX_ATTACHMENT_FILE_SIZE = MAX_SIZE_IN_MB * 1024 * 1024; // max size of an attachment for upload

interface IOwnProps{
    alreadyUploadedAttachments: number;
    onAddAttachment: (attachment: FeedbackAttachmentInfo) => void;
}

interface IComponentProps extends IOwnProps, IWithGeovisServerProps {
    
}

const Component = ({
    Server,
    onAddAttachment
}: IComponentProps) => {
    const fileBrowserRef = useRef<FileBrowser>(null);

    const [uploadFileStates, setUploadFileStates] = useState<GeovisUploadFileState[]>([]);

    const onAttachmentUploadStart = ({ files }: UploadsStartEventPayload) => {
        if(files.length > MAX_UPLOAD_FILES_LENGTH){
            files.forEach( f => {
                fileBrowserRef.current?.triggerCancelFn(f.id);
            })
            FlagService.addError(t('Error upload attachments'), 'Possible to upload max 3 files');
            return;
        }

        const errorList: string[] = [];
        const tooBigFiles = files.filter(f => f.size > MAX_ATTACHMENT_FILE_SIZE);
        if (tooBigFiles.length > 0) {
            tooBigFiles.forEach(f => {
                fileBrowserRef.current?.triggerCancelFn(f.id);
                errorList.push(t("tooBigAttachmentsUploadErrorMessage").replace("%1", f.name).replace("%2", MAX_SIZE_IN_MB.toString()));
            });

            FlagService.addErrors("Error uploading images", errorList);
            return;
        }

        const fileStates = files.map<Partial<GeovisUploadFileState>>(f => ({
            id: '',
            linkId: f.id,
            name: f.name,
            progress: 0,
            isCompleted: false,
        }));

        setUploadFileStates(fileStates.map<GeovisUploadFileState>(f => ({ ...new GeovisUploadFileState(), ...f })))
    }

    const onAttachmentUploadEnd = async ({ file }: UploadEndEventPayload) => {
        try {
            const url = ServerRoutesGen.GeovisFeedback.FeedbackAttachmentInfoByLinkId.patch(file.id).path;
            const response = await Server.get<DataActionResponse<FeedbackAttachmentInfo>>(url);

            if (!response.Success) {
                FlagService.addError(t('Error upload attachments'), response.Messages.join(', '));
                Logger.error(`Error to fetch the feedback attachment info of ${file.name} error. ${response.Messages.join(', ')}`, LoggerName);
                return;
            }

            setUploadFileStates(uploadFileStates.filter(f => f.id !== file.id))
            onAddAttachment(response.Data);
        }
        catch (error) {
            FlagService.addError(t('Error upload attachments'), t("Error to fetch the feedback attachment info"));
        }
    }

    const onAttachmentUploadProgress = ({ fileId, fileName, progress }: IProgressUploadFileInfo) => {
        const fileInfo: Array<Partial<GeovisUploadFileState>> = [{
            id: fileId,
            name: fileName,
            progress,
            isProcessing: true
        }];

        setUploadFileStates(fileInfo.map<GeovisUploadFileState>(f => ({ ...new GeovisUploadFileState(), ...f })))
    }

    const onAttachmentUploadError = ({ errorDescription }: IErrorUploadFileInfo) => {
        FlagService.addError(t('Error upload attachment'), errorDescription);
    }

    return (
        <div className='flexColumnContainer' style={{ width: '590px'}}>
            <div style={{ display: 'flex', height: '500px' }}>
                    <GeovisDragAndDropFilesComponent
                        baseUrl={Server.getApiBaseUrl()}
                        collection={getFeedbackAttachmentsCollectionString()}
                        fileExtensions={['*.*']}
                        multiple={false}
                        files={uploadFileStates}
                        onUploadsStart={onAttachmentUploadStart}
                        onUploadEnd={onAttachmentUploadEnd}
                        onUploadStatusUpdate={onAttachmentUploadProgress}
                        onError={onAttachmentUploadError}
                        onFileDelete={(fileId) => {
                            // eslint-disable-next-line no-console
                            console.log("Deleting file with ID:", fileId);
                        }}
                    />
            </div>
        </div>
    )
}

export default withGeovisServer(Component);