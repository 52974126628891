import { useState } from "react";
import { ChartType, getChartTypeToDescription } from "../../../server/AVTService/TypeLibrary/Common/ChartType";
import { GeovisChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartModel";
import { HeatmapChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/HeatmapChartModel";
import { InclinometerChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/InclinometerChartModel";
import { TimeValueChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/TimeValueChartModel";
import { XyChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/XyChartModel";
import { SensorChangesModel } from "../../../server/GEOvis3/Model/GeovisChart/SensorChangesModel";
import ElementReportTitle from "../reportOverlay/reportRender/ElementReportTitle";
import { HeatmapPreviewChartRender } from "../reportOverlay/reportRender/charts/previewRenders/HeatmapPreviewChartRender";
import { InclinometerPreviewChartRender } from "../reportOverlay/reportRender/charts/previewRenders/InclinometerPreviewChartRender";
import { SemiCirclePreviewChartRender } from "../reportOverlay/reportRender/charts/previewRenders/SemiCirclePreviewChartRenderHost";
import { TimeValuePreviewChartRender } from "../reportOverlay/reportRender/charts/previewRenders/TimeValuePreviewChartRender";
import { VibrationPreviewChartRenderHost } from "../reportOverlay/reportRender/charts/previewRenders/VibrationPreviewChartRenderHost";
import { XYPreviewChartRender } from "../reportOverlay/reportRender/charts/previewRenders/XYPreviewChartRender";
import { getReportChartTitle } from "../reportOverlay/reportRender/charts/tools";
import { IGeovisChartPropertyChangedProps } from "./types";

interface IComponentProps extends IGeovisChartPropertyChangedProps<GeovisChartModel> {
    sensorsChanges: SensorChangesModel[];
    projectId: number;
}

interface IComponentState {
    timeslot: string
}

export const ChartPreviewTab = ({
    chart,
    sensorsChanges,
    projectId
}: IComponentProps) => {

    const [state, setState] = useState<IComponentState>({
        timeslot: ""
    })

    const onTimeslotReceived = (timeslot: string) => {
        setState({
            timeslot
        })
    }

    const getRender = () => {
        switch (chart.Type) {
            case ChartType.TimeValue:
                return (
                    <TimeValuePreviewChartRender
                        chart={chart as TimeValueChartModel}
                        sensorsChanges={sensorsChanges}
                        projectId={projectId}
                    />
                )

            case ChartType.XYDiagram:
                return (
                    <XYPreviewChartRender
                        chart={chart as XyChartModel}
                        projectId={projectId}
                    />
                )

            case ChartType.Inclinometer:
                return (
                    <InclinometerPreviewChartRender
                        chart={chart as InclinometerChartModel}
                        projectId={projectId}
                    />
                )

            case ChartType.HeatMap:
                return (
                    <HeatmapPreviewChartRender
                        chart={chart as HeatmapChartModel}
                        projectId={projectId}
                    />
                )

            case ChartType.VibrationDiagramm:
                return (
                    <VibrationPreviewChartRenderHost
                        chart={chart}
                        projectId={projectId}
                    />
                )

            case ChartType.XYVibrationEventDiagram:
                return (
                    <VibrationPreviewChartRenderHost
                        chart={chart}
                        projectId={projectId}
                    />
                )

            case ChartType.SemiCircle:
                return (
                    <SemiCirclePreviewChartRender
                        chart={chart}
                        projectId={projectId}
                        onChartDataLoaded={onTimeslotReceived}
                    />
                )

            default:
                return (
                    <div>
                        <h2>{`Nothing to preview for ${getChartTypeToDescription(chart.Type)}`}</h2>
                    </div>
                )
        }
    }

    const onHideEventLocker = () => {
        //
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
            <ElementReportTitle
                elementTitle={getReportChartTitle(chart, state.timeslot)}
                elementIndexOnPage={0}
                isInGroup={false}
                //lastInGroup={false}
                isVibrationEventChart={false}
                onHideVibrationEvent={onHideEventLocker}
                vibrationEventInfo={undefined}
                projectId={chart.ProjectId}
                chartId={chart.Id}
                isPrinting={false}
                reportId={0}
                drawBorder={false}
                firstInGroup={true}
            />
            {getRender()}
        </div>
    )
}