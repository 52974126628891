/* eslint-disable eqeqeq */
import { ChartRegressionSettings } from "../../../../server/AVTService/TypeLibrary/Common/ChartRegressionSettings";
import { ChartRegressionType, getChartRegressionTypeToName } from "../../../../server/AVTService/TypeLibrary/Common/ChartRegressionType";
import { round } from "./tools";

export const getRegressionSettings = (seriesSettings: any, options: ChartRegressionSettings): Highcharts.SeriesOptionsType | null => {
    const name = `${seriesSettings.name} (${getChartRegressionTypeToName(options.FunctionType)})`;

    const result: Highcharts.SeriesOptionsType = {
        type: 'spline',
        turboThreshold: 0,
        id: name + "_id",
        name,
        color: seriesSettings.color,
        dashStyle: "Dot",
        lineWidth: 2,
        yAxis: seriesSettings.yAxis,
        marker: {
            enabled: false,
            symbol: seriesSettings.marker.symbol,
            lineColor: seriesSettings.marker.lineColor,
            lineWidth: seriesSettings.marker.lineWidth
        },
        custom: {
            ...seriesSettings.custom,
            // sensorId: seriesSettings.custom.sensorId,
            // countData: seriesSettings.custom.countData,
            // numberOfDigits: seriesSettings.custom.numberOfDigits,
            regression: true,
            regressionName: getChartRegressionTypeToName(options.FunctionType)
        }
    };

    let calculatedData: IRegressionResult;
    // const precision = 4;
    const extrapolate = 0;
    const decimalPlaces = 4;
    // const decimalPlaces = undefined;

    switch (options.FunctionType) {
        case ChartRegressionType.Exponential:
            calculatedData = getExponential(seriesSettings.data, decimalPlaces, extrapolate);
            break;
        case ChartRegressionType.Linear:
            calculatedData = getLinear(seriesSettings.data, decimalPlaces, extrapolate);
            // result.type = 'line';
            break;
        case ChartRegressionType.Logarithmic:
            calculatedData = getLogarithmic(seriesSettings.data, decimalPlaces, extrapolate);
            break;
        case ChartRegressionType.Power:
            calculatedData = getPower(seriesSettings.data, decimalPlaces, extrapolate);
            break;
        case ChartRegressionType.Polynomial:
            calculatedData = getPolynomial(seriesSettings.data, decimalPlaces, options.NumberPolinomMembers, extrapolate);
            break;
        // case ChartRegressionType.Loess:
        //     const loessSmooth = 25;
        //     calculatedData = getLoess(seriesSettings.data, loessSmooth / 100);
        //     break;
        default:
            return null;
    }

    result.data = calculatedData.points;

    // const rSquared = getCoefficientOfDetermination(seriesSettings.data, calculatedData.points);
    // const placeholders: IRegressionPlaceholders = {
    //     rValue: round(Math.sqrt(rSquared), decimalPlaces),
    //     rSquared: round(rSquared, decimalPlaces),
    //     standardError: round(getStandardError(seriesSettings.data, calculatedData.points), decimalPlaces),
    //     label: calculatedData.label
    // };
    // result.name = replaceRegressionPlaceholders(result.name, placeholders);

    return result;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getCoefficientOfDetermination = (data: number[][], pred: number[][]): number => {
    // Sort the initial data { pred array (model's predictions) is sorted  }
    // The initial data must be sorted in the same way in order to calculate the coefficients
    data.sort((a, b) => {
        if (a[0] > b[0]) {
            return 1;
        }
        if (a[0] < b[0]) {
            return -1;
        }
        return 0;
    });

    // Calc the mean
    let mean = 0;
    let N = data.length;
    for (let i = 0, len = data.length; i < len; i++) {
        if (data[i][1] != null) {
            mean += data[i][1];
        } else {
            N--;
        }
    }
    mean /= N;

    // Calc the coefficient of determination
    let SSE = 0;
    let SSYY = 0;
    for (let i = 0; i < data.length; i++) {
        if (data[i][1] != null) {
            SSYY += Math.pow(data[i][1] - pred[i][1], 2);
            SSE += Math.pow(data[i][1] - mean, 2);
        }
    }

    return 1 - (SSYY / SSE);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getStandardError = (data: number[][], pred: number[][]) => {
    let SE = 0;
    let N = data.length;

    for (let i = 0; i < data.length; i++) {
        if (data[i][1] != null) {
            SE += Math.pow(data[i][1] - pred[i][1], 2);
        } else {
            N--;
        }
    }
    SE = Math.sqrt(SE / (N - 2));

    return SE;
}

interface IRegressionPlaceholders {
    rSquared: number;
    rValue: number;
    label: string;
    standardError: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const replaceRegressionPlaceholders = (text: string, regressionPlaceholders: IRegressionPlaceholders): string => {
    return text
        .replace("%r2", regressionPlaceholders.rSquared.toString())
        .replace("%r", regressionPlaceholders.rValue.toString())
        .replace("%eq", regressionPlaceholders.label)
        .replace("%se", regressionPlaceholders.standardError.toString());
}

interface IRegressionPoint {
    x: number,
    y: number,
    custom: any
}

interface IRegressionResult {
    equation: number[],
    points: IRegressionPoint[],
    label: string
}

/**
 * Code extracted from https://github.com/Tom-Alexander/regression-js/
 *
 * https://simenergy.ru/math-analysis/digital-processing/85-ordinary-least-squares
 * Human readable formulas:
 * 
 * y = A * e^(B*x)
 *
 *        Σ(ln(Y)) * Σ(X^2) - Σ(ln(Y)*X) * Σ(X)
 * ln A = -------------------------------------
 *               N * Σ(X^2) - Σ(X)^2
 *
 *        N * Σ(ln(Y)*X) - Σ(ln(Y)) * Σ(X)
 * B    = --------------------------------
 *              N * Σ(X^2) - Σ(X)^2
 *
 */
const getExponential = (data: any[], decimalPlaces: number, extrapolate: number): IRegressionResult => {

    if (data.length < 2) {
        return { equation: [], label: '', points: [] };
    }

    // const sum = [0, 0, 0, 0, 0, 0];
    let N = 0;
    const sumN = [0, 0, 0, 0];
    const results: any[] = [];
    let Xmin: number | null = null;
    let Ymin: number | null = null;

    const xIsDateTime = data.find(v => v.x < 1000000000000) === undefined;
    const getX = (x: number) =>
        Xmin != null && xIsDateTime ? (x - Xmin) / 1000000 :
            Xmin != null ? x - Xmin :
                x;
    const getY = (y: number) => Ymin == null ? y : y - Ymin;
    const getResultY = (y: number) => Ymin == null ? y : y + Ymin;

    for (const dataN of data) {
        if (dataN.x != null) {
            dataN[0] = dataN.x;
            dataN[1] = dataN.y;
        }

        if (dataN[0] == null || dataN[1] == null) {
            continue;
        }

        if (Xmin === null || Xmin > dataN[0]) {
            Xmin = dataN[0];
        }

        if (Ymin === null || Ymin > dataN[1]) {
            Ymin = dataN[1];
        }
    }

    if (!xIsDateTime && Xmin !== null) {
        Xmin -= 0.1;
    }

    if (Ymin !== null) {
        Ymin -= 0.1;
    }

    for (const dataN of data) {
        if (dataN[1] == null) {
            continue;
        }

        // sum[0] += dataN[0]; // X
        // sum[1] += dataN[1]; // Y
        // sum[2] += dataN[0] * dataN[0] * dataN[1]; // XXY
        // sum[3] += dataN[1] * Math.log(dataN[1]); // Y Log Y
        // sum[4] += dataN[0] * dataN[1] * Math.log(dataN[1]); // YY Log Y
        // sum[5] += dataN[0] * dataN[1]; // XY

        // if (Xmin === null) {
        //     Xmin = dataN[0] - 1;
        // }

        const X = getX(dataN[0]);
        const Y = getY(dataN[1]);

        sumN[0] += X; // Sum(X)
        sumN[1] += X * X; // Sum(X^2)
        sumN[2] += Math.log(Y); // Sum(ln(Y))
        sumN[3] += X * Math.log(Y); // Sum(ln(Y)*X)

        N++;
    }

    // const denominator = (sum[1] * sum[2] - sum[5] * sum[5]);
    // const A = Math.exp((sum[2] * sum[3] - sum[5] * sum[4]) / denominator);
    // const B = (sum[1] * sum[4] - sum[5] * sum[3]) / denominator;

    const denominator = (N * sumN[1] - sumN[0] * sumN[0]);
    // const lnA = denominator === 0 ? 0 : denominator;
    const A = denominator === 0 ? 0 : Math.exp((sumN[2] * sumN[1] - sumN[3] * sumN[0]) / denominator);
    const B = denominator === 0 ? 0 : (N * sumN[3] - sumN[2] * sumN[0]) / denominator;

    const resultLength = N + extrapolate;
    const step = data[N - 1][0] - data[N - 2][0];

    for (let i = 0; i < resultLength; i++) {
        let x;
        let y;
        if (typeof data[i] !== 'undefined') {
            x = data[i][0];
            y = data[i][1];
        } else {
            x = data[N - 1][0] + (i - N) * step;
        }

        const X = getX(x);
        const Y = A * Math.exp(B * X);
        const coordinate = {
            x,
            y: getResultY(Y),
            custom: {
                x,
                y
            }
        };
        results.push(coordinate);
    }

    results.sort((a, b) => {
        if (a.x > b.x) {
            return 1;
        }
        if (a.x < b.x) {
            return -1;
        }
        return 0;
    });

    const label = `y = ${round(A, decimalPlaces)} * e ^ (${round(B, decimalPlaces)} * x)`;

    return {
        equation: [A, B],
        points: results,
        label
    };
}

/**
 * Code extracted from https://github.com/Tom-Alexander/regression-js/
 * 
 * Human readable formulas:
 *
 *              N * Σ(XY) - Σ(X)
 * intercept = ---------------------
 *              N * Σ(X^2) - Σ(X)^2
 *
 * correlation = N * Σ(XY) - Σ(X) * Σ (Y) / √ (  N * Σ(X^2) - Σ(X) ) * ( N * Σ(Y^2) - Σ(Y)^2 ) ) )
 *
 */
function getLinear(data: any[], decimalPlaces: number, extrapolate: number): IRegressionResult {

    if (data.length < 2) {
        return { equation: [], label: '', points: [] };
    }

    const sum = [0, 0, 0, 0, 0];
    const results: any[] = [];
    let N = 0;
    let X0: any = null;

    const xIsDateTime = data.find(v => v.x < 1000000000000) === undefined;
    const getX = (x: number) => xIsDateTime ? (x - X0) / 1000000 : x;

    for (const dataN of data) {
        if (dataN.x != null) {
            dataN[0] = dataN.x;
            dataN[1] = dataN.y;
        }
        if (dataN[1] != null) {
            if (X0 === null) {
                X0 = dataN[0] - 1;
            }

            const X = getX(dataN[0]);
            const Y = dataN[1];

            sum[0] += X; // Σ(X)
            sum[1] += Y; // Σ(Y)
            sum[2] += X * X; // Σ(X^2)
            sum[3] += X * Y; // Σ(XY)
            sum[4] += Y * Y; // Σ(Y^2)
            N++;
        }
    }

    const rise = ((N * sum[3]) - (sum[0] * sum[1]));
    const run = ((N * sum[2]) - (sum[0] * sum[0]));
    const gradient = run === 0 ? 0 : rise / run;
    const intercept = (sum[1] / N) - ((gradient * sum[0]) / N);

    // const gradient = (len * sum[3] - sum[0] * sum[1]) / (len * sum[2] - sum[0] * sum[0]);
    // const intercept = (sum[1] / len) - (gradient * sum[0]) / len;
    // var correlation = (N * sum[3] - sum[0] * sum[1]) / Math.sqrt((N * sum[2] - sum[0] * sum[0]) * (N * sum[4] - sum[1] * sum[1]));

    const resultLength = data.length + extrapolate;
    const step = data[data.length - 1][0] - data[data.length - 2][0];

    for (let i = 0; i < resultLength; i++) {
        let x;
        let y;
        if (typeof data[i] !== 'undefined') {
            x = data[i][0];
            y = data[i][1];
        } else {
            x = data[data.length - 1][0] + (i - data.length) * step;
        }

        const X = getX(x);
        const Y = X * gradient + intercept;

        const coordinate = {
            x,
            y: Y,
            custom: {
                x,
                y
            }
        };

        results.push(coordinate);
    }

    results.sort((a, b) => {
        if (a.x > b.x) {
            return 1;
        }
        if (a.x < b.x) {
            return -1;
        }
        return 0;
    });

    const label = `y = ${round(gradient, decimalPlaces)} * x + ${round(intercept, decimalPlaces)}`;

    return {
        equation: [gradient, intercept],
        points: results,
        label
    };
}

/**
 *  Code extracted from https://github.com/Tom-Alexander/regression-js/
 *
 * https://simenergy.ru/math-analysis/digital-processing/85-ordinary-least-squares
 * Human readable formulas:
 * 
 * y = A + B * ln(x)
 *
 *     Σ(Y) * Σ(ln(X)^2) - Σ(Y*ln(X)) * Σ(ln(X))
 * A = -----------------------------------------
 *            N * Σ(ln(X)^2) - Σ(ln(X))^2
 *
 *     N * Σ(Y*ln(X)) - Σ(Y) * Σ(ln(X))
 * B = --------------------------------
 *       N * Σ(ln(X)^2) - Σ(ln(X))^2
 *
 */
function getLogarithmic(data: any[], decimalPlaces: number, extrapolate: number): IRegressionResult {

    if (data.length < 2) {
        return { equation: [], label: '', points: [] };
    }

    const sum = [0, 0, 0, 0];
    const results: any[] = [];
    let N = 0;
    let Xmin: number | null = null;
    let Ymin: number | null = null;

    const xIsDateTime = data.find(v => v.x < 1000000000000) === undefined;
    const getX = (x: number) =>
        Xmin != null && xIsDateTime ? (x - Xmin) / 1000000 :
            Xmin != null ? x - Xmin :
                x;
    const getY = (y: number) => Ymin == null ? y : y - Ymin;
    const getResultY = (y: number) => Ymin == null ? y : y + Ymin;

    for (const dataN of data) {
        if (dataN.x != null) {
            dataN[0] = dataN.x;
            dataN[1] = dataN.y;
        }

        if (dataN[0] == null || dataN[1] == null) {
            continue;
        }

        if (Xmin === null || Xmin > dataN[0]) {
            Xmin = dataN[0];
        }

        if (Ymin === null || Ymin > dataN[1]) {
            Ymin = dataN[1];
        }
    }

    if (!xIsDateTime && Xmin !== null) {
        Xmin -= 0.1;
    }

    if (Ymin !== null) {
        Ymin -= 0.1;
    }

    for (const dataN of data) {
        if (dataN.x != null) {
            dataN[0] = dataN.x;
            dataN[1] = dataN.y;
        }
        if (dataN[1] != null) {
            const X = getX(dataN[0]);
            const Y = getY(dataN[1]);

            sum[0] += Math.log(X);
            sum[1] += Y * Math.log(X);
            sum[2] += Y;
            sum[3] += Math.pow(Math.log(X), 2);
            N++;
        }
    }

    const denominator = (N * sum[3] - sum[0] * sum[0]);
    const A = (sum[2] * sum[3] - sum[1] * sum[0]) / denominator;
    const B = (N * sum[1] - sum[2] * sum[0]) / denominator;

    // const B = (N * sum[1] - sum[2] * sum[0]) / (N * sum[3] - sum[0] * sum[0]);
    // const A = (sum[2] - B * sum[0]) / N;

    const resultLength = data.length + extrapolate;
    const step = data[data.length - 1][0] - data[data.length - 2][0];

    for (let i = 0; i < resultLength; i++) {
        let x;
        let y;
        if (typeof data[i] !== 'undefined') {
            x = data[i][0];
            y = data[i][1];
        } else {
            x = data[data.length - 1][0] + (i - data.length) * step;
        }

        const X = getX(x);
        const Y = A + B * Math.log(X);

        const coordinate = {
            x,
            y: getResultY(Y),
            custom: {
                x,
                y
            }
        };

        results.push(coordinate);
    }

    results.sort((a, b) => {
        if (a.x > b.x) {
            return 1;
        }
        if (a.x < b.x) {
            return -1;
        }
        return 0;
    });

    const label = `y = ${round(A, decimalPlaces)} + ${round(B, decimalPlaces)} * ln(x)`;

    return {
        equation: [A, B],
        points: results,
        label
    };
}

/**
 * Code extracted from https://github.com/Tom-Alexander/regression-js/
 *
 * https://simenergy.ru/math-analysis/digital-processing/85-ordinary-least-squares
 * Human readable formulas:
 * 
 * y = A * x^B
 *
 *        Σ(ln(Y)) * Σ(ln(X)^2) - Σ(ln(Y)*ln(X)) * Σ(ln(X))
 * ln A = -------------------------------------------------
 *                 N * Σ(ln(X)^2) - Σ(ln(X))^2
 *
 *        N * Σ(ln(Y)*ln(X)) - Σ(ln(Y)) * Σ(ln(X))
 * B    = ----------------------------------------
 *              N * Σ(ln(X)^2) - Σ(ln(X))^2
 *
 */
function getPower(data: any[], decimalPlaces: number, extrapolate: number): IRegressionResult {

    if (data.length < 2) {
        return { equation: [], label: '', points: [] };
    }

    const sum = [0, 0, 0, 0];
    const results: any[] = [];
    let N = 0;
    let Xmin: number | null = null;
    let Ymin: number | null = null;

    const xIsDateTime = data.find(v => v.x < 1000000000000) === undefined;
    const getX = (x: number) =>
        Xmin != null && xIsDateTime ? (x - Xmin) / 1000000 :
            Xmin != null ? x - Xmin :
                x;
    const getY = (y: number) => Ymin == null ? y : y - Ymin;
    const getResultY = (y: number) => Ymin == null ? y : y + Ymin;

    for (const dataN of data) {
        if (dataN.x != null) {
            dataN[0] = dataN.x;
            dataN[1] = dataN.y;
        }

        if (dataN[0] == null || dataN[1] == null) {
            continue;
        }

        if (Xmin === null || Xmin > dataN[0]) {
            Xmin = dataN[0];
        }

        if (Ymin === null || Ymin > dataN[1]) {
            Ymin = dataN[1];
        }
    }

    if (Ymin !== null) {
        Ymin -= 0.1;
    }

    if (Xmin !== null) {
        Xmin -= 0.1;
    }

    for (const dataN of data) {
        if (dataN[1] == null) {
            continue;
        }

        const X = getX(dataN[0]);
        const Y = getY(dataN[1]);

        sum[0] += Math.log(X); // ln(X)
        sum[1] += Math.log(X) * Math.log(Y); // ln(X) * ln(Y)
        sum[2] += Math.log(Y); // ln(Y)
        sum[3] += Math.pow(Math.log(X), 2); // ln(X) ^ 2
        N++;
    }

    const denominator = (N * sum[3] - sum[0] * sum[0]);
    const A = Math.exp((sum[2] * sum[3] - sum[1] * sum[0]) / denominator);
    const B = (N * sum[1] - sum[2] * sum[0]) / denominator;

    // const B = (N * sum[1] - sum[2] * sum[0]) / (N * sum[3] - sum[0] * sum[0]);
    // const A = Math.exp((sum[2] - B * sum[0]) / N);

    const resultLength = data.length + extrapolate;
    const step = data[data.length - 1][0] - data[data.length - 2][0];

    for (let i = 0; i < resultLength; i++) {
        let x;
        let y;
        if (typeof data[i] !== 'undefined') {
            x = data[i][0];
            y = data[i][1];
        } else {
            x = data[data.length - 1][0] + (i - data.length) * step;
        }

        const X = getX(x);
        const Y = A * Math.pow(X, B); // Math.pow(X, B) = Math.exp(Math.log(X) * B)
        const coordinate = {
            x,
            y: getResultY(Y),
            custom: {
                x,
                y
            }
        };
        results.push(coordinate);
    }

    results.sort((a, b) => {
        if (a.x > b.x) {
            return 1;
        }
        if (a.x < b.x) {
            return -1;
        }
        return 0;
    });

    const label = `y = ${round(A, decimalPlaces)} * x ^ ${round(B, decimalPlaces)}`;

    return {
        equation: [A, B],
        points: results,
        label
    };
}

//#region polinomial

/**
 * Code extracted from https://github.com/Tom-Alexander/regression-js/
 */
function gaussianElimination(a: number[][], o: number): number[] {
    let maxrow = 0;
    let tmp = 0;
    const n = a.length - 1;
    const x = new Array(o);

    for (let i = 0; i < n; i++) {
        maxrow = i;
        for (let j = i + 1; j < n; j++) {
            if (Math.abs(a[i][j]) > Math.abs(a[i][maxrow])) {
                maxrow = j;
            }
        }
        for (let k = i; k < n + 1; k++) {
            tmp = a[k][i];
            a[k][i] = a[k][maxrow];
            a[k][maxrow] = tmp;
        }
        for (let j = i + 1; j < n; j++) {
            for (let k = n; k >= i; k--) {
                a[k][j] -= a[k][i] * a[i][j] / a[i][i];
            }
        }
    }

    for (let j = n - 1; j >= 0; j--) {
        tmp = 0;
        for (let k = j + 1; k < n; k++) {
            tmp += a[k][j] * x[k];
        }
        x[j] = (a[n][j] - tmp) / a[j][j];
    }

    return (x);
}

function getPolynomial(data: any[], decimalPlaces: number, numberMembers: number, extrapolate: number): IRegressionResult {

    if (data.length < 2) {
        return { equation: [], label: '', points: [] };
    }

    if (typeof numberMembers === 'undefined') {
        numberMembers = 3;
    }

    let Xmin: number | null = null;
    let Ymin: number | null = null;

    const xIsDateTime = data.find(v => v.x < 1000000000000) === undefined;
    const getX = (x: number) =>
        Xmin != null && xIsDateTime ? (x - Xmin) / 1000000 :
            Xmin != null ? x - Xmin :
                x;
    const getY = (y: number) => Ymin == null ? y : y - Ymin;
    const getResultY = (y: number) => Ymin == null ? y : y + Ymin;

    for (const dataN of data) {
        if (dataN.x != null) {
            dataN[0] = dataN.x;
            dataN[1] = dataN.y;
        }

        if (dataN[0] == null || dataN[1] == null) {
            continue;
        }

        if (Xmin === null || Xmin > dataN[0]) {
            Xmin = dataN[0];
        }

        if (Ymin === null || Ymin > dataN[1]) {
            Ymin = dataN[1];
        }
    }

    if (Ymin !== null) {
        Ymin -= 0.1;
    }

    if (Xmin !== null) {
        Xmin -= 0.1;
    }

    const lhs: any[] = [];
    const rhs: any[] = [];
    const results: any[] = [];
    let a = 0;
    let b = 0;
    const k = numberMembers + 1;

    for (let i = 0; i < k; i++) {
        for (let l = 0, len = data.length; l < len; l++) {
            const dataN = data[l];

            if (dataN[1] == null) {
                continue;
            }

            const X = getX(dataN[0]);
            const Y = getY(dataN[1]);

            a += Math.pow(X, i) * Y;
        }
        lhs.push(a);
        a = 0;
        const c: any[] = [];
        for (let j = 0; j < k; j++) {
            for (let l = 0, len = data.length; l < len; l++) {
                const dataN = data[l];
                const X = getX(dataN[0]);
                const Y = getY(dataN[1]);

                if (Y) {
                    b += Math.pow(X, i + j);
                }
            }
            c.push(b);
            b = 0;
        }
        rhs.push(c);
    }
    rhs.push(lhs);

    const equation = gaussianElimination(rhs, k);

    const resultLength = data.length + extrapolate;
    const step = data[data.length - 1][0] - data[data.length - 2][0];
    for (let i = 0; i < resultLength; i++) {
        let answer = 0;
        let x = 0;
        let y;
        if (typeof data[i] !== 'undefined') {
            x = data[i][0];
            y = data[i][1];
        } else {
            x = data[data.length - 1][0] + (i - data.length) * step;
        }

        const X = getX(x);

        for (let w = 0; w < equation.length; w++) {
            answer += equation[w] * Math.pow(X, w);
        }

        const coordinate = {
            x,
            y: getResultY(answer),
            custom: {
                x,
                y
            }
        };

        results.push(coordinate);
    }

    results.sort((a1, a2) => {
        if (a1.x > a2.x) {
            return 1;
        }
        if (a1.x < a2.x) {
            return -1;
        }
        return 0;
    });

    let label = 'y = ';

    for (let i = equation.length - 1; i >= 0; i--) {
        label += round(equation[i], decimalPlaces);

        if (i > 1) {
            label += ` * x ^ ${i} + `;
        } else if (i === 1) {
            label += ' * x + ';
        }
    }

    return {
        equation,
        points: results,
        label
    };
}

//#endregion

//#region loess

// function array_unique(values: any[]): any[] {
//     const o = {};
//     for (const value of values) {
//         o[value] = value;
//     }

//     const r: any[] = Object.values(o);

//     return r;
// }

// eslint-disable-next-line camelcase
function array_unique_length(values: any[]): number {
    const o = {};
    for (const value of values) {
        o[value] = value;
    }

    return Object.keys(o).length;
}

function tricube(x: number): number {
    const tmp = 1 - x * x * x;
    return tmp * tmp * tmp;
}

/**
 * @author: Ignacio Vazquez
 * Based on
 * - http://commons.apache.org/proper/commons-math/download_math.cgi LoesInterpolator.java
 * - https://gist.github.com/avibryant/1151823
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getLoess(data: any[], bandwidth: number): IRegressionResult {
    bandwidth = bandwidth || 0.25;

    const xval: number[] = data.map((pair) => {
        return pair.x || pair[0];
    });
    const lengthDistinctX = array_unique_length(xval);
    if (2 / lengthDistinctX > bandwidth) {
        bandwidth = Math.min(2 / lengthDistinctX, 1);
        // eslint-disable-next-line no-console
        console.warn("updated bandwith to " + bandwidth);
    }

    const yval = data.map((pair) => {
        return pair.y || pair[1];
    });

    const res: number[] = [];

    let left = 0;
    let right = Math.floor(bandwidth * xval.length) - 1;

    for (let i = 0; i < xval.length; i++) {
        const x = xval[i];

        if (i > 0) {
            if (right < xval.length - 1 &&
                xval[right + 1] - xval[i] < xval[i] - xval[left]) {
                left++;
                right++;
            }
        }
        // console.debug("left: "+left  + " right: " + right );

        let edge;
        if (xval[i] - xval[left] > xval[right] - xval[i]) {
            edge = left;
        } else {
            edge = right;
        }

        const denom = Math.abs(1.0 / (xval[edge] - x));
        let sumWeights = 0;
        let sumX = 0;
        let sumXSquared = 0;
        let sumY = 0;
        let sumXY = 0;

        let k = left;
        while (k <= right) {
            const xk = xval[k];
            const yk = yval[k];
            let dist;
            if (k < i) {
                dist = (x - xk);
            } else {
                dist = (xk - x);
            }
            const w = tricube(dist * denom);
            const xkw = xk * w;
            sumWeights += w;
            sumX += xkw;
            sumXSquared += xk * xkw;
            sumY += yk * w;
            sumXY += yk * xkw;
            k++;
        }

        const meanX = sumX / sumWeights;
        // console.debug(meanX);
        const meanY = sumY / sumWeights;
        const meanXY = sumXY / sumWeights;
        const meanXSquared = sumXSquared / sumWeights;

        let beta;
        if (meanXSquared === meanX * meanX) {
            beta = 0;
        } else {
            beta = (meanXY - meanX * meanY) / (meanXSquared - meanX * meanX);
        }

        const alpha = meanY - beta * meanX;
        res[i] = beta * x + alpha;
    }

    // console.debug(res);

    return {
        equation: [],
        points: xval.map((x, i) => {
            return {
                x,
                y: res[i],
                custom: {
                    x,
                    y: undefined
                }
            }
        }),
        label: ""
    };
}

//#endregion