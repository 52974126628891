import Button from '@atlaskit/button';
import Tooltip from '@atlaskit/tooltip';
import { useState } from "react";
import { t } from '../../../i18n';
import { ChartType } from '../../../server/AVTService/TypeLibrary/Common/ChartType';
import { XYSensorPair } from '../../../server/AVTService/TypeLibrary/Common/XYSensorPair';
import { SensorCategory } from '../../../server/AVTService/TypeLibrary/Sensors/SensorCategory';
import { ChainInfo } from '../../../server/ChainInfo';
import { SensorChangesModel } from '../../../server/GEOvis3/Model/GeovisChart/SensorChangesModel';
import { SensorInfo } from '../../../server/GEOvis3/Model/SensorInfo';
import { EditIcon } from '../../Icons';
import { ChartSensorsSelectDialog, SensorDialogType } from './ChartSensorsSelectDialog';
import { compareChartTypes } from './tools';
import { ElementSettingAllowCheckbox } from '../ElementSettingAllowCheckbox';

interface IComponentProps {
    chartType: ChartType;
    projectId: number;
    dtsSectionIds: string[];
    ySensorType: SensorCategory;
    sensorIds: string[];
    customerChangeable: boolean;
    sensorChanges: SensorChangesModel[];
    xSensorType?: SensorCategory;
    sensorsPairs?: XYSensorPair[];
    showPairsDialog: boolean;
    isDisabled?: boolean;
    needXAxisCheck?: boolean;
    isChain: boolean;
    hideCustomerChangeable?: boolean
    selectedChainsIds: string[];
    isSingle: boolean;
    isMultiType?: boolean;

    onChange: (sensorIds: string[], value: boolean, sensorChanges: SensorChangesModel[]) => void;
    onChangeXyChartSensorsPairs?: (pairs: XYSensorPair[], value: boolean, sensorChanges: SensorChangesModel[]) => void;
}

export const ChartAxisSensorsControl = ({
    chartType,
    dtsSectionIds,
    projectId,
    ySensorType,
    xSensorType,
    sensorIds,
    customerChangeable,
    onChange,
    sensorChanges,
    onChangeXyChartSensorsPairs,
    showPairsDialog,
    sensorsPairs,
    isDisabled,
    needXAxisCheck,
    isChain,
    hideCustomerChangeable,
    selectedChainsIds,
    isSingle,
    isMultiType
}: IComponentProps) => {

    const [showSelectDialog, setShowSelectDialog] = useState<boolean>(false);

    const onSelectSensorsDialogShowHandler = () => setShowSelectDialog(true);
    const onSelectSensorsDialogCloseHandler = () => setShowSelectDialog(false);

    const onChangeAllowCheckboxHandler = (value: boolean) => {
        onChange(sensorIds, value, sensorChanges);
    }

    const onSensorsSelected = (items: SensorInfo[], sensorsChanges: SensorChangesModel[]) => {
        onChange(items.map(i => i.Id), customerChangeable, sensorsChanges);
        onSelectSensorsDialogCloseHandler();
    }

    const onSingleSensorSelected = (sensorId: string) => {
        if (sensorId === '') {
            onChange([], customerChangeable, []);
        }
        else {
            onChange([sensorId], customerChangeable, []);
        }

        onSelectSensorsDialogCloseHandler();
    }

    const onChainsSelected = (items: ChainInfo[]) => {
        onChange(items.map(i => i.Id), customerChangeable, []);
        onSelectSensorsDialogCloseHandler();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onSensorsPairsSelected = (pairs: XYSensorPair[], sensorsChanges: SensorChangesModel[]) => {
        if (onChangeXyChartSensorsPairs) {
            onChangeXyChartSensorsPairs(pairs, customerChangeable, sensorChanges);
        }

        onSelectSensorsDialogCloseHandler();
    }


    const getCountOfItems = (): number => {
        if (showPairsDialog) {
            return sensorsPairs ? sensorsPairs.length : 0;
        }
        else if (isChain) {
            return selectedChainsIds ? selectedChainsIds.length : 0;
        }
        else {
            return sensorIds ? sensorIds.length : 0;
        }
    }

    const getSelectedSensorId = (): string => {
        if (isSingle && sensorIds.length > 0) {
            return sensorIds[0];
        }
        return "";
    }

    const getLabelText = (): string => {
        if (isChain) {
            return getCountOfItems() === 1 ? "Chain" : "Chains";
        }
        if (showPairsDialog) {
            return getCountOfItems() === 1 ? "Pair" : "Pairs";
        }

        return getCountOfItems() === 1 ? "Sensor" : "Sensors";
    }

    const isSelectingSensorsDisabled = (): boolean => {

        if (showPairsDialog) {
            return ySensorType === SensorCategory.Unknown || xSensorType === SensorCategory.Unknown;
        }
        else if (isMultiType) {
            return false;
        }
        else {
            return ySensorType === SensorCategory.Unknown
        }
    }

    const getSensorsTooltipText = (): string => {
        if (compareChartTypes(chartType, ChartType.DtsChart)) {
            return t("For DTS-charts individual sensor selection is not possible");
        }
        else if (compareChartTypes(chartType, ChartType.XYDiagram) && needXAxisCheck) {
            return t("Please select first sensor type for Y-axis and X-axis")
        }
        else if (compareChartTypes(chartType, ChartType.SemiCircle)) {
            return t("Please select first a sensor type")
        }
        else {
            return t("Please select first sensor type for Y-axis")
        }
    }

    const checkDialogType = (): SensorDialogType => {
        if (isSingle) {
            return 'single';
        }
        if (isChain) {
            return 'chains';
        }
        if (showPairsDialog) {
            return 'pairs';
        }
        return isMultiType ? 'base-multiType' : 'base';
    }

    return (
        <div className="flexRowContainerLine">
            <div style={{ width: '30%' }}>
                <label>{isChain ? t("Chains") : t("Sensors")}</label>
            </div>
            <div>
                <label>{`${getCountOfItems()} ${t(getLabelText())}`}</label>
            </div>
            <div>
                {(isDisabled || isSelectingSensorsDisabled()) &&
                    <Tooltip content={getSensorsTooltipText()}>
                        <Button
                            appearance="subtle-link"
                            spacing="compact"
                            iconBefore={<EditIcon label="" primaryColor='#f4f5f7' />}
                        />
                    </Tooltip>
                }
                {!isDisabled && !isSelectingSensorsDisabled() &&
                    <Button
                        appearance="subtle"
                        spacing="compact"
                        onClick={onSelectSensorsDialogShowHandler}
                        iconBefore={<EditIcon label="" />}
                    />
                }
            </div>
            {!hideCustomerChangeable &&
                <ElementSettingAllowCheckbox
                    isChecked={customerChangeable}
                    onChange={onChangeAllowCheckboxHandler}
                    isDisabled={false}
                />
            }

            {showSelectDialog && (
                <ChartSensorsSelectDialog
                    chartType={chartType}
                    dtsSectionIds={dtsSectionIds}
                    projectId={projectId}
                    dialogType={checkDialogType()}
                    yAxisSensorType={ySensorType}
                    xAxisSensorType={xSensorType}
                    selectedSensorIds={sensorIds}
                    onSelectedAxisSensors={onSensorsSelected}
                    onSelectSensorsPairs={onSensorsPairsSelected}
                    onCanceled={onSelectSensorsDialogCloseHandler}
                    selectedSensorsPairs={sensorsPairs}
                    onSelectedChains={onChainsSelected}
                    selectedChainsIds={selectedChainsIds}
                    onSelectSingleSensor={onSingleSensorSelected}
                    selectedSensorId={getSelectedSensorId()}
                    sensorChanges={sensorChanges}
                />
            )}
        </div>
    )
}