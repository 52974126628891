import { MapProviders } from "../../server/AVTService/TypeLibrary/Map/MapProviders";
import { DataActionResponse } from "../../server/DataActionResponse";
import { GeovisFeedbackStatistic } from "../../server/GEOvis3/Model/GeovisFeedback/GeovisFeedbackStatistic";
import { ProjectOverviewModel } from "../../server/GEOvis3/Model/ProjectsOverview/ProjectOverviewModel";
import {
    CHANGE_LANGUAGE,
    CHANGE_MAP_BASE_LAYER,
    HIDE_PROJECT_NO_PERMISSIONS_DIALOG,
    LOGIN_FAILED,
    LOGIN_IN_PROGRESS,
    LOGIN_MESSAGE_LANGUAGE,
    LOGIN_OUT,
    LOGIN_SUCCESS,
    NAVIGATION_FEEDBACK_STATISTIC_DATA,
    NAVIGATION_UPDATE_USER_VIEW_MODE,
    PROJECTS_OVERVIEW,
    PROJECTS_OVERVIEW_DATA,
    PROJECTS_OVERVIEW_SEARCH_QUERY,
    PROJECTS_OVERVIEW_UPDATE_PROJECT,
    REFRESH_NAVIGATION_STATE,
    RESET_PASSWORD_FAILED,
    RESET_PASSWORD_IN_PROGRESS,
    RESET_PASSWORD_SUCCESS,
    SHOW_PROJECT_NO_PERMISSIONS_DIALOG,
    SWITCH_PROJECTS_OVERVIEW
} from "../actions/navigationActions";
import {
    IGeovisLoginAction,
    IGeovisNavigationAction,
    IGeovisProjectsOverviewAction
} from "../types";

/**
 * Login actions creators
 */

/**
 * Dispatch when login is success
 */
export function loginSuccess(): IGeovisLoginAction {
    return { type: LOGIN_SUCCESS };
}

/**
 * Dispatch when button "Sign in" is pressed
 */
export function loginInProgress(): IGeovisLoginAction {
    return { type: LOGIN_IN_PROGRESS };
}

/**
 * Dispatch when login failed
 */
export function loginFailed(errorMessage: string): IGeovisLoginAction {
    return { type: LOGIN_FAILED, errorMessage };
}

/**
 * Dispatch when user is signed out, to cleanup storage
 */
export function loginOut(): IGeovisLoginAction {
    return { type: LOGIN_OUT };
}

/**
 * Dispatch when an user change the message language
 */
export function loginMessageLanguage(messageLanguage: string): IGeovisLoginAction {
    return { type: LOGIN_MESSAGE_LANGUAGE, messageLanguage };
}

export const resetPasswordFailed = (errorMessage: string): IGeovisLoginAction => {
    return { type: RESET_PASSWORD_FAILED, errorMessage };
}

export const resetPasswordInProgress = (): IGeovisLoginAction => {
    return { type: RESET_PASSWORD_IN_PROGRESS };
}

export const resetPasswordSuccess = (): IGeovisLoginAction => {
    return { type: RESET_PASSWORD_SUCCESS };
}

// Global actions creators 

/**
 * Dispatch when Projects overview page will mounted.
 * Set projects(navigation) store to Loading state
 */
export function projectsOverview(): IGeovisNavigationAction {
    return { type: PROJECTS_OVERVIEW };
}

/**
 * Dispatch when Projects data is loaded and need to store in the storage
 * Set store to "Ready" state to draw data
 * @param projects List of projects
 */
export const projectsOverviewData = (projects: DataActionResponse<ProjectOverviewModel[]>): IGeovisProjectsOverviewAction => ({
    type: PROJECTS_OVERVIEW_DATA, projects
})

/**
 * Switch UI language creator
 * @param language destination language code
 */
export function changeLanguage(language: string): IGeovisNavigationAction {
    return { type: CHANGE_LANGUAGE, language };
}

export const changeMapBaseLayer = (mapProvider: MapProviders): IGeovisNavigationAction => {
    return { type: CHANGE_MAP_BASE_LAYER, mapProvider };
}

/**
 * Switch projects view between map or table
 * @param view is view id
 */
export const switchProjectsOverviewView = (view: string): IGeovisProjectsOverviewAction => {
    return { type: SWITCH_PROJECTS_OVERVIEW, view };
}

/**
 * Switch visible projects between my and all projects
 * @param filter the filter value
 */
export const switchProjectsOverviewFilter = (filter: string): IGeovisProjectsOverviewAction => {
    return { type: SWITCH_PROJECTS_OVERVIEW, filter };
}

/**
 * Switch visibility of project names
 * @param showProjectName true if visible
 */
export const switchProjectsOverviewProjectNames = (showProjectName: boolean): IGeovisProjectsOverviewAction => {
    return { type: SWITCH_PROJECTS_OVERVIEW, showProjectName };
}

/**
 * Change count of visible projects on one page
 * @param rowsPerPage count lines per row
 */
export const switchProjectsOverviewRowsPerPage = (rowsPerPage: number): IGeovisProjectsOverviewAction => {
    return { type: SWITCH_PROJECTS_OVERVIEW, rowsPerPage };
}

export const setSearchProjectsQuery = (searchQuery: string): IGeovisProjectsOverviewAction => {
    return { type: PROJECTS_OVERVIEW_SEARCH_QUERY, searchQuery };
}

/**
 * set user to project view mode in the application
 * @param role to project role
 */
export const navigationUpdateUserViewMode = (): IGeovisNavigationAction => ({
    type: NAVIGATION_UPDATE_USER_VIEW_MODE
});

/**
 * Just make a new instance of navigation state to refresh depended components
 */
export const refreshNavigationState = (): IGeovisNavigationAction => ({
    type: REFRESH_NAVIGATION_STATE
});

export const projectsOverviewUpdateProject = (projectToUpdate: ProjectOverviewModel): IGeovisProjectsOverviewAction => {
    return { type: PROJECTS_OVERVIEW_UPDATE_PROJECT, projectToUpdate };
}

export const showProjectNoPermissionsDialog = (projectId: number): IGeovisProjectsOverviewAction => ({
    type: SHOW_PROJECT_NO_PERMISSIONS_DIALOG, projectId
})

export const hideProjectNoPermissionsDialog = (): IGeovisProjectsOverviewAction => ({
    type: HIDE_PROJECT_NO_PERMISSIONS_DIALOG
})

/**
 * Set geovis feedback statistic data
 * @param statistic 
 * @returns 
 */
export const navigationFeedbackStatisticData = (statistic: DataActionResponse<GeovisFeedbackStatistic>): IGeovisNavigationAction => ({
    type: NAVIGATION_FEEDBACK_STATISTIC_DATA, statistic
})