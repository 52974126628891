/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 31.10.2019
 * @description Functions to help filter data
 */

import { GEOvisDXFLayerType } from "../server/AVTService/TypeLibrary/Common/GEOvisDXFLayerType";
import { MapProviders } from "../server/AVTService/TypeLibrary/Map/MapProviders";
import { ProjectViewConfig } from "../server/AVTService/TypeLibrary/ProjectViews/ProjectViewConfig";
import { ProjectViewInfo } from "../server/GEOvis3/Model/ProjectViews/ProjectViewInfo";
import {
    IProjectInfoStorage,
    IProjectViewsStorage
} from "../store/data.types";
import { IProjectViewsEditConfigStorage } from "../store/edit.types";
import { ProjectViewOverviewId } from "../store/projectOverview.types";
import { mapToListOfElements } from './StorageHelper';

/**
 * Returns true is value contains a filter
 * @param value
 * @param filter
 */
export const isMatchToSearchString = (value: string, filter: string): boolean => {
    if (!filter || filter.length === 0) {
        return true;
    }
    try {
        return value.toLowerCase().search(filter.toLowerCase()) > -1;
    } catch {
        return false;
    }
}

/**
 * Get project view from project view storage
 * If view not found, then returns default view object
 * @param projectInfoStorage
 * @param projectViews - list of loaded project views
 */
export const getProjectViewId = (projectInfoStorage: IProjectInfoStorage, projectViews: Map<string, ProjectViewInfo>): string => {
    if (projectInfoStorage.isLoading || projectViews.size === 0) {
        return '';
    }

    if (projectInfoStorage.isError) {
        return '';
    }

    const { project } = projectInfoStorage;

    if (project.DefaultProjectViewId) {
        const defaultView = projectViews.get(project.DefaultProjectViewId);

        if (defaultView) {
            return defaultView.Id;
        }
    }

    const customView = getFirstCustomView(projectViews);
    if (customView) {
        return customView.Id;
    }

    return ProjectViewOverviewId;
}

/**
 * Search view config in the edit storage
 * @param viewId 
 * @param projectViewsEditConfig 
 */
export const getProjectViewConfig = (viewId: string, { isError, isLoading, projectViews }: IProjectViewsEditConfigStorage): ProjectViewConfig | undefined => {
    if (isError || isLoading) {
        return undefined;
    }

    return projectViews.get(viewId);
}

/**
 * Get project view, impossible to use when some data still not loaded
 * @param projectInfoStorage
 * @param projectViewsStorage
 */
export const getProjectView = (projectInfoStorage: IProjectInfoStorage, projectViewsStorage: IProjectViewsStorage): ProjectViewInfo => {

    const { isLoaded, projectViewsInfo, viewId } = projectViewsStorage;

    if (!isLoaded) {
        // throw Error('getProjectView: Impossible to use when view storage is not loaded yet');
        return getAbstractView();
    }

    const view = projectViewsInfo.get(viewId);
    if (view) {
        return view;
    }
    // throw Error(`getProjectView: Expected project view=${viewId} not found`);
    const expectedViewId = getProjectViewId(projectInfoStorage, projectViewsInfo);
    const expectedView = projectViewsInfo.get(expectedViewId);
    if (expectedView) {
        return expectedView;
    }

    return getFirstCustomView(projectViewsInfo);
}

const getFirstCustomView = (projectViews: Map<string, ProjectViewInfo>): ProjectViewInfo => {
    const views = mapToListOfElements(projectViews, v => v.IsPublic);

    if (views.length === 0) {
        // throw Error('No any public custom views are found');
        return new ProjectViewInfo()
    }

    return views[0];
}

const getAbstractView = (): ProjectViewInfo => ({
    AlarmsInfo: [],
    DefaultMapData: { Latitude: 0, Longitude: 0, ZoomLevel: 0 },
    Id: '',
    IsPublic: false,
    Name: 'View',
    Order: 0,
    ProjectId: 0,
    DocumentsIds: [],
    LogbookIds: [],
    LeftsideDisplayType: GEOvisDXFLayerType.Map,
    RightsideDisplayType: GEOvisDXFLayerType.ProfileView,
    IsDefaultExpanded: true,
    ParentViewId: '',
    FontSize: 10,
    ChainIds: [],
    SensorsIds: [],
    Rasters: [],
    AdditionalAttributes: [],
    ShowNameOfSensors: false,
    UseBordersForSensorText: true,
    UseSensorColorFoName: false,
    LeftMapProvider: MapProviders.CartoDBPositron,
    RightMapProvider: MapProviders.CartoDBPositron,
    UseSensorColor: false,
    LeftMovementSettings: {
        ShowVectorDeviationValue: false,
        ShowHeightDeviationValue: false,
        ShowMovementHeightBars: false,
        ShowMovementVector: false,
        ShowScaleLegend: false,
        UseVectorSettingsForBars: false,
        HeightBarsMovementSettings: {
            MaxAge: 0,
            ScalingRate: 0,
            Weight: 1
        },
        VectorMovementSettings: {
            MaxAge: 0,
            ScalingRate: 0,
            Weight: 1
        }
    },
    RightMovementSettings: {
        ShowVectorDeviationValue: false,
        ShowHeightDeviationValue: false,
        ShowMovementHeightBars: false,
        ShowMovementVector: false,
        ShowScaleLegend: false,
        UseVectorSettingsForBars: false,
        HeightBarsMovementSettings: {
            MaxAge: 0,
            ScalingRate: 0,
            Weight: 1
        },
        VectorMovementSettings: {
            MaxAge: 0,
            ScalingRate: 0,
            Weight: 1
        }
    }
})