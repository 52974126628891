//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum SemiCirclePlotValuesMode {
	StartAndEnd = 0,
	All = 1
}

const SemiCirclePlotValuesModeToName = new Map<number, string>([
	[ 0, 'StartAndEnd' ],
	[ 1, 'All' ],
])

export function getSemiCirclePlotValuesModeToName(element: SemiCirclePlotValuesMode) : string {
    return SemiCirclePlotValuesModeToName.get(element) || '';
}

const SemiCirclePlotValuesModeByName = new Map<string, number>([
	[ 'StartAndEnd', 0 ],
	[ 'All', 1 ],
]);

export function getSemiCirclePlotValuesModeByName(name: string): SemiCirclePlotValuesMode {
    return SemiCirclePlotValuesModeByName.get(name)!;
}


export const SemiCirclePlotValuesModeList : Readonly<SemiCirclePlotValuesMode[]> = [
	SemiCirclePlotValuesMode.StartAndEnd,
	SemiCirclePlotValuesMode.All,
];



const SemiCirclePlotValuesModeToDescription = new Map<number, string>([
	[ 0, 'Start and End' ],
	[ 1, 'All Sensors' ],
])

export function getSemiCirclePlotValuesModeToDescription(element: SemiCirclePlotValuesMode) : string {
    return SemiCirclePlotValuesModeToDescription.get(element) || '';
}

