/**
 * Author: Vyacheslav Skripin <vs@ieskr.ru>
 * Task: AGMS-1190
 * Created: 20.09.2019
 */

import axios, {
    AxiosError,
    AxiosRequestConfig,
    CancelToken,
    CancelTokenSource
} from "axios";
import { lookup } from "mime-types";
import IGeovisComponent from '../components/abstract/IGeovisComponent';
import AxiosCancelError from "../errors/AxiosCancelError";
import { fetchServerElements } from "../helpers/ProjectDataHelper";
import Route from "../helpers/Route";
import { ActionResponse } from "../server/ActionResponse";
import { AuthorizationCode } from '../server/AuthorizationCode';
import { GeovisServerInfo } from "../server/GEOvis3/Model/Server/GeovisServerInfo";
import ServerRoutesGen from "../server/Routes/ServerRoutesGen";
import { ApplicationId, ITokenStorage } from "./AuthService";
import { cancelTokenById, checkTokenById, removeTokenById, setTokenToId } from "./cancelTokens";
import Logger from './Logger';

export interface IHttpRequestOptions {
    headers?: {
        // eslint-disable-next-line camelcase
        access_token_mt: string | null;
    },
    asBlob?: boolean
    signal?: AbortSignal;
}

// export interface IGetOptions extends IHttpRequestOptions {
//     asBlob?: boolean;
// }

export interface IRequestHelper {
    get<T extends ActionResponse>(route: string, options?: IHttpRequestOptions): Promise<T>;
    post<T extends ActionResponse>(route: string, data: any, options?: IHttpRequestOptions): Promise<T>;
    put<T extends ActionResponse>(route: string, data: any): Promise<T>;
    delete<T extends ActionResponse>(route: string): Promise<T>;
    getCancellable<T extends ActionResponse>(route: string, requestId: string, options?: IHttpRequestOptions): Promise<T>;
    postCancellable<T extends ActionResponse>(route: string, requestId: string, data: any, options?: IHttpRequestOptions): Promise<T>;

    downloadFile(route: string, filename: string): Promise<void>;
    downloadFilePost(route: string, data: any, filename: string): Promise<void>;
    downloadFileData(data: any, filename: string): void;
    uploadFile<T>(route: string, file: File): Promise<T>;

    cancelAllRequests(): void;

    loadServerInfo(): Promise<GeovisServerInfo>;
    loadServerInfoByUrl(url: Route): Promise<GeovisServerInfo>;

    getApiBaseUrl(): string;
    getApiBaseUrlWithSlash(): string;
    getEndpointServerUrl(subUrl: string): string;

    getFileObjectUrl(route: string, filename: string): Promise<string>;
}

/**
 * @description Request helper to manage requests to a Server
 */
export default class RequestHelper implements IRequestHelper {

    // members
    private readonly cancellationTokenSources: Set<CancelTokenSource>;

    /**
     * Create request helper
     * @param apiRoot the root of remove endpoint
     * @param owner the owner of requests
     * @param tokenStorage storage with authentication token
     */
    constructor(
        private apiRoot: string,
        private owner: IGeovisComponent,
        private tokenStorage: ITokenStorage,
        private applicationId: ApplicationId,
        private useApiPrefix: boolean = true) {

        if (!owner) {
            throw Error('RequestHelper: Owner component cannot be null');
        }

        if (!tokenStorage) {
            throw Error('RequestHelper: Token cannot be empty. Application must be authorized');
        }

        this.cancellationTokenSources = new Set<CancelTokenSource>();

    }

    /**
     * Get api base url for the endpoint
     */
    public getApiBaseUrl = (): string => {
        if (!this.useApiPrefix) {
            return this.apiRoot;
        }

        return `${this.apiRoot}/api`;
    }

    public getApiBaseUrlWithSlash = (): string => {
        return `${this.getApiBaseUrl()}/`;
    }

    public getEndpointServerUrl = (subUrl: string): string => {
        return `${this.getApiBaseUrl()}/${subUrl}`;
    }

    /**
     * Get something
     * @param route - route path
     * @param options - get request options
     */
    public get = async <T>(route: string, options?: IHttpRequestOptions): Promise<T> => {

        if (!this.owner.isComponentMounted()) {
            throw new AxiosCancelError(`${this.LoggerName()}: component has unmounted already`);
        }

        const url = this.getEndpointServerUrl(route);
        const source = axios.CancelToken.source();
        this.cancellationTokenSources.add(source);

        try {
            const asBlob = options && options.asBlob;
            const customHeaders = options && options.headers;

            Logger.trace(`Request started: GET ${url}`, this.LoggerName());

            const response = await axios.get<T>(url, this.getRequestConfig(source.token, { ...customHeaders }, asBlob));

            Logger.trace(`Request finished: GET ${url}`, this.LoggerName());
            return response.data;
        } catch (error) {
            if (axios.isCancel(error)) {
                const message = `Request was canceled: GET ${url}`;
                Logger.info(message, this.LoggerName());
                throw new AxiosCancelError(message);
            } else {
                Logger.error(`Request failed: GET ${url}`, this.LoggerName());
            }

            throw error;
        } finally {
            this.cancellationTokenSources.delete(source);
        }
    };
    /**
     * Get something. 
     * If previous request to this URL was not finished it will be cancelled
     * @param route 
     * @param cToken 
     * @param options 
     * @returns 
     */
    public getCancellable = async <T extends ActionResponse>(route: string, requestId: string, options?: IHttpRequestOptions): Promise<T> => {
        if (!this.owner.isComponentMounted()) {
            throw new AxiosCancelError(`${this.LoggerName()}: component has unmounted already`);
        }
        const cToken = axios.CancelToken.source();
        const url = this.getEndpointServerUrl(route);
        this.cancellationTokenSources.add(cToken);
        // previous request is still running
        if (checkTokenById(requestId)) {
            cancelTokenById(requestId);
        }
        setTokenToId(requestId, cToken);
        try {
            const asBlob = options && options.asBlob;
            const customHeaders = options && options.headers;

            Logger.trace(`Request started: GET ${url}`, this.LoggerName());

            const response = await axios.get<T>(url, this.getRequestConfig(cToken.token, { ...customHeaders }, asBlob));

            Logger.trace(`Request finished: GET ${url}`, this.LoggerName());
            return response ? response.data : this.emptyResponse<T>();
        } catch (error) {
            const loggedError = this.logRequestError(error, url, "GET");
            throw loggedError;
        } finally {
            this.cancellationTokenSources.delete(cToken);
            removeTokenById(requestId);
        }
    };

    /**
     * Post data to the server
     * @param route - route on the server
     * @param data - data to the server
     * @param options - the request options
     */
    public post = async <T extends ActionResponse>(route: string, data: any, options?: IHttpRequestOptions): Promise<T> => {

        if (!this.owner.isComponentMounted()) {
            throw new AxiosCancelError(`${this.LoggerName()}: component has unmounted already`);
        }

        const url = this.getEndpointServerUrl(route);
        const source = axios.CancelToken.source();
        this.cancellationTokenSources.add(source);

        try {
            Logger.trace(`Request started: POST ${url}`, this.LoggerName());

            const headers = options && options.headers;
            const asBlob = options && options.asBlob;
            const response = await axios.post<T>(url, data, this.getRequestConfig(source.token, headers, asBlob));

            Logger.trace(`Request finished: POST ${url}`, this.LoggerName());
            return response ? response.data : this.emptyResponse<T>();
        } catch (error) {
            const loggedError = this.logRequestError(error, url, "POST");
            throw loggedError;
        } finally {
            this.cancellationTokenSources.delete(source);
        }
    };

    /**
     * Post data to the server
     * @param route - route on the server
     * @param data - data to the server
     * @param options - the request options
     */
    public postCancellable = async <T extends ActionResponse>(route: string, requestId: string, data: any, options?: IHttpRequestOptions): Promise<T> => {

        if (!this.owner.isComponentMounted()) {
            throw new AxiosCancelError(`${this.LoggerName()}: component has unmounted already`);
        }
        const cToken = axios.CancelToken.source();
        const url = this.getEndpointServerUrl(route);
        this.cancellationTokenSources.add(cToken);
        // previous request is still running
        if (checkTokenById(requestId)) {
            cancelTokenById(requestId);
        }
        setTokenToId(requestId, cToken);
        try {
            Logger.trace(`Request started: POST ${url}`, this.LoggerName());

            const headers = options && options.headers;
            const response = await axios.post<T>(url, data, this.getRequestConfig(cToken.token, headers));

            Logger.trace(`Request finished: POST ${url}`, this.LoggerName());
            return response ? response.data : this.emptyResponse<T>();
        } catch (error) {
            const loggedError = this.logRequestError(error, url, "POST");
            throw loggedError;
        } finally {
            this.cancellationTokenSources.delete(cToken);
            removeTokenById(requestId);
        }
    };

    /**
     * Put something to the server
     * @param route - route on the server
     * @param data - data to the server
     */
    public put = async <T>(route: string, data: any): Promise<T> => {

        if (!this.owner.isComponentMounted()) {
            throw new AxiosCancelError(`${this.LoggerName()}: component has unmounted already`);
        }

        const url = this.getEndpointServerUrl(route);
        const source = axios.CancelToken.source();
        this.cancellationTokenSources.add(source);

        try {
            Logger.trace(`Request started: PUT ${url}`, this.LoggerName());

            const response = await axios.put<T>(url, data, this.getRequestConfig(source.token));

            Logger.trace(`Request finished: PUT ${url}`, this.LoggerName());

            return response.data;
        } catch (error) {
            const loggedError = this.logRequestError(error, url, "PUT");
            throw loggedError;
        } finally {
            this.cancellationTokenSources.delete(source);
        }
    };

    /**
     * Delete something on the server
     * @param - route with parameter
     */
    public delete = async <T extends ActionResponse>(route: string): Promise<T> => {

        if (!this.owner.isComponentMounted()) {
            throw new AxiosCancelError(`${this.LoggerName()}: component has unmounted already`);
        }

        const url = this.getEndpointServerUrl(route);
        const source = axios.CancelToken.source();
        this.cancellationTokenSources.add(source);

        try {
            Logger.trace(`Request started: DELETE ${url}`, this.LoggerName());

            const response = await axios.delete(url, this.getRequestConfig(source.token));

            Logger.trace(`Request finished: DELETE ${url}`, this.LoggerName());

            return response.data;

        } catch (error) {
            const loggedError = this.logRequestError(error, url, "DELETE");
            throw loggedError;
        } finally {
            this.cancellationTokenSources.delete(source);
        }
    };

    public getFileObjectUrl = async (route: string, filename: string): Promise<string> => {
        const data = await this.get<any>(route, { asBlob: true });

        const extension = filename.split('.').pop();
        const mimeType = extension === "csv" ? "text/plain" : lookup(filename); // a trick to avoid downloading a csv-file
        const file = new File([data], filename, { type: mimeType ? mimeType : undefined });
        return window.URL.createObjectURL(file);
    }

    public downloadFile = async (route: string, filename: string): Promise<void> => {
        const data = await this.get<any>(route, { asBlob: true });
        this.downloadFileData(data, filename);
    }

    public downloadFilePost = async (route: string, payload: any, filename: string): Promise<void> => {
        const data = await this.post<any>(route, payload, { asBlob: true });
        this.downloadFileData(data, filename);
    }

    public downloadFileData = (data: any, filename: string) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", filename);

        document.body.appendChild(link);

        link.click();
    };

    /**
     * Upload file to the endpoint
     */
    public uploadFile = async <T>(route: string, file: File): Promise<T> => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("filename", file.name);

        const endpointUrl = this.getEndpointServerUrl(route);
        const source = axios.CancelToken.source();

        this.cancellationTokenSources.add(source);

        try {
            Logger.trace(`Request started: POST ${endpointUrl}`, this.LoggerName());

            const requestConfig = this.getRequestConfig(source.token);
            requestConfig["Content-Type"] = "multipart/form-data";

            const response = await axios.post<T>(endpointUrl, formData, requestConfig);

            Logger.trace(`Request finished: POST ${endpointUrl}`, this.LoggerName());

            return response.data;
        } catch (error) {
            const loggedError = this.logRequestError(error, endpointUrl, "POST");
            throw loggedError;
        } finally {
            this.cancellationTokenSources.delete(source);
        }
    };

    /**
     * Cancel all requests
     */
    public cancelAllRequests = () => {
        if (this.cancellationTokenSources.size) {
            Logger.info(`Cancelling ${this.cancellationTokenSources.size} requests`, this.LoggerName());
            this.cancellationTokenSources.forEach(source => source.cancel());
        }
    };

    public loadServerInfoByUrl = async (url: Route): Promise<GeovisServerInfo> => {
        const result = await fetchServerElements<GeovisServerInfo>(this, url);

        if (result.AuthorizationCode === AuthorizationCode.AuthorizationFailed) {
            throw Error("Authorization failed");
        }

        if (result.AuthorizationCode === AuthorizationCode.NoReadPermissions) {
            throw Error("You don't have permissions to get current user info");
        }

        return result.Data;
    }

    /**
     * Load logged user info
     */
    public loadServerInfo = async (): Promise<GeovisServerInfo> => {
        return await this.loadServerInfoByUrl(ServerRoutesGen.Account.CurrentServerInfo);
    }

    /**
     * Get request auth configuration
     */
    private getRequestAuthConfig = (headers?: any) => {
        let resultHeaders = {
            // Authorization: "Bearer " + this.tokenStorage.getToken(),
            access_token: "Bearer " + this.tokenStorage.getToken(),
            applicationId: this.applicationId
        };

        if (headers) {
            resultHeaders = Object.assign({}, resultHeaders, headers);
        }

        return resultHeaders;
    }

    /**
     * Get request configuration
     */
    private getRequestConfig = (cancelToken: CancelToken, headers?: any, asBlob: boolean = false): AxiosRequestConfig => {
        if (!this.tokenStorage.getToken()) {
            return { headers, cancelToken };
        }

        let conf: AxiosRequestConfig = {
            cancelToken,
            headers: this.getRequestAuthConfig(headers)
        };

        if (asBlob) {
            conf = { ...conf, responseType: 'arraybuffer' };
        }

        return conf;
    };

    private logRequestError = (error: any, url: string, action: string) => {
        if (axios.isCancel(error)) {
            const message = `Request was canceled: ${action} ${url}`;
            Logger.info(message, this.LoggerName());
            return new AxiosCancelError(message);
        }
        
        if (axios.isAxiosError(error)) {
            const axiosError = error as AxiosError;
            const responseStatus = axiosError.response ? `${axiosError.response.status}` : "unknown";
            Logger.error(`Request failed with code ${responseStatus}: ${action} ${url}`, this.LoggerName());
        }
        else {
            Logger.error(`Request failed with error ${error instanceof Error ? error.message : `${error}`}: ${action} ${url}`, this.LoggerName());
        }

        return error;
    }

    private emptyResponse = <T extends ActionResponse>() => {
        return { ...({} as T), Success: false, Messages: ['Empty response'] }
    }

    private LoggerName = () => this.owner.constructor.name;
}