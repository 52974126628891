//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { ArchivingTargetType } from '../Model/ArchivingTargetType';
import { TaskProgressState } from './TaskProgressState';

export class ArchiveTaskProgressInfo
{
	public UserId: string;
	public TaskId: string;
	public TargetType: ArchivingTargetType;
	public IsRestoring: boolean;
	public ProgressState: TaskProgressState;
	public ArchiveFileName: string;
	public FileSize: number;
	public Finished: string;
	public ErrorDescription: string;
	public DeleteAfterArchiving: boolean;
	public ProjectId: number;
}
