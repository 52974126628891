import React, { useState } from "react";
import { isEmptyLocation2D, latLngToLocation } from "../../helpers/MapHelper";
import { GEOvisDXFLayerType } from "../../server/AVTService/TypeLibrary/Common/GEOvisDXFLayerType";
import { SensorValueAttribute } from "../../server/AVTService/TypeLibrary/Common/SensorValueAttribute";
import { ImageBackgroundInfo } from "../../server/AVTService/TypeLibrary/ImageAsMap/ImageBackgroundInfo";
import { SensorsMovementSettings } from "../../server/AVTService/TypeLibrary/Map/SensorsMovementSettings";
import { SensorCategory } from "../../server/AVTService/TypeLibrary/Sensors/SensorCategory";
import { GeoLocation } from "../../server/GeoLocation";
import { SensorInfo } from "../../server/GEOvis3/Model/SensorInfo";
import { ImageSensorModel } from "../../server/ImageSensorModel";
import Logger from "../../services/Logger";
import { IChainsInfoStorage, IChainsLastDataStorage, ISensorsInfoStorage, ISensorsLastDataStorage, ISensorsSymbolsStorage } from "../../store/data.types";
import { IMapViewSensorsLayerState } from "../../store/types";
import { SensorsOverviewLayer } from "../projectOverview/sensors/SensorsOverviewLayer";
import { ICloseToBorderSensorsIds } from "../projectOverview/sensors/types";
import { ImageMapConnected } from "./ImageMapConnected";
import { getDeltaCoefficient } from "./tools";
import { ImageMapContentType } from "./types";
import { ImageMapSelectSensorTable } from "./ImageMapSelectSensorTable";
import { getMapAreaOfGeovisImageViewPort } from "../projectOverview/reportOverlay/reportRender/geovisImage/tools";
import { TimePeriod } from "../../server/AVTService/TypeLibrary/Common/TimePeriod";
import { ImageBackgroundFileType } from "../../server/ImageBackgroundFileType";

const LoggerSource = 'ImageMapView';

interface IImageMapViewProps {

    projectId: number;
    elementId: string;

    // can't pass the GeovisImage there, because this component is used for LMO too
    sensors: ImageSensorModel[];
    chains: ImageSensorModel[];
    imageInfo: ImageBackgroundInfo;

    contentType: ImageMapContentType;
    userId?: string;
    userHash?: string;

    isPdfPrintingMode: boolean;

    sensorsInfoStorage: ISensorsInfoStorage;
    sensorsLastDataStorage: ISensorsLastDataStorage;

    chainsInfoStorage: IChainsInfoStorage;
    chainsLastDataStorage: IChainsLastDataStorage;
    onChangeSensorPosition?: (fillId: string, position: GeoLocation) => void;

    sensorsSymbolsSettings: ISensorsSymbolsStorage;

    sensorLayerState?: IMapViewSensorsLayerState;
    movementSettings?: SensorsMovementSettings;
    additionalProps: SensorValueAttribute[]
    fontSize?: number;
    pictureXCoordinate?: SensorValueAttribute;
    pictureYCoordinate?: SensorValueAttribute;

    rootContainer?: HTMLDivElement;
    setCoordinatesMode?: boolean;
    disableManipulation?: boolean;

    children?: React.ReactNode;

    onSetChainPosition?: (fullId: string, position: GeoLocation) => void;
    onSetSensorPosition?: (fullId: string, position: GeoLocation) => void;

    onReadyToDrawLegend?: (leafletElementRef: L.Map) => void;
    onRendered?: () => void;
    onZoomingFinished?: (leafletElementRef: L.Map) => void;
    showBorderAroundIconText?: boolean;
}

interface IComponentState {
    map?: L.Map;

    selectedSensorId: string;
    selectedChainId: string;
}

const getComponentInitialState = ({ setCoordinatesMode, ...restProps }: IImageMapViewProps): IComponentState => {

    if (!setCoordinatesMode) {
        return {
            map: undefined,
            selectedChainId: '',
            selectedSensorId: ''
        }
    }

    const { sensors, sensorsInfoStorage } = restProps;

    const nextSensorId = getNextSensorIdWithoutCoordinated(sensors, sensorsInfoStorage, '');
    if (nextSensorId !== '') {
        return {
            map: undefined,
            selectedSensorId: nextSensorId,
            selectedChainId: ''
        }
    }

    const { chains, chainsInfoStorage } = restProps;

    return {
        map: undefined,
        selectedSensorId: '',
        selectedChainId: getNextChainIdWithoutCoordinates(chains, chainsInfoStorage, '')
    }
}

export const ImageMapView = (props: IImageMapViewProps) => {

    const {
        setCoordinatesMode,
        sensorsSymbolsSettings,
        sensors,
        chains,
        projectId,
        elementId,
        imageInfo,
        contentType,
        onReadyToDrawLegend,
        rootContainer,
        sensorLayerState,
        movementSettings,
        // isPdfPrintingMode,
        sensorsInfoStorage,
        sensorsLastDataStorage,
        chainsInfoStorage,
        chainsLastDataStorage,
        userHash,
        userId,
        fontSize,
        disableManipulation,
        onZoomingFinished,
        pictureXCoordinate,
        pictureYCoordinate,
        children,
        onRendered,
        showBorderAroundIconText,
        additionalProps
    } = props;

    const [state, setState] = useState<IComponentState>(getComponentInitialState(props));


    const onSelectSensor = (sensorId: string) => {
        setState({ ...state, selectedChainId: '', selectedSensorId: sensorId });

    }

    const onSelectChain = (chainId: string) => {
        setState({ ...state, selectedChainId: chainId, selectedSensorId: '' });
    }

    const [zoomFinishedState, setZoomFinishedState] = useState<boolean>(false);


    const onSetPositionClickFn = (position: L.LatLng) => {
        const deltaCoefficient = getDeltaCoefficient(rootContainer || null, getMapAreaOfGeovisImageViewPort(imageInfo.ViewPort), imageInfo.FileType, contentType);

        if (state.selectedSensorId) {
            onSetSensorPositionClickHandler(position, props, deltaCoefficient, state, setState);
        } else if (state.selectedChainId) {
            onSetChainPositionClickHandler(position, props, deltaCoefficient, state, setState);
        }
    }

    const currentSensorLayerState: IMapViewSensorsLayerState = sensorLayerState ?? {
        visibleSensorTypes: new Map<SensorCategory, boolean>(),
        showSensorNames: false
    }

    const onReadyToDrawLegendHandler = (leafletElementRef: L.Map) => {
        if (onReadyToDrawLegend) {
            onReadyToDrawLegend(leafletElementRef)
        }
        setState({
            ...state,
            map: leafletElementRef
        })
    }

    const closeToBorderSensorsIds: ICloseToBorderSensorsIds = {
        bottom: [],
        left: [],
        right: [],
        top: []
    }

    const checkIfSensorIsCloseToBorder = (s: SensorInfo) => {
        const IMG_APPEND_VALUE = 25;
        const DXF_APPEND_VALUE = 50;
        const { ViewPort } = imageInfo;
        const FRAME_SIZE = 256;
        const FRAME_WHITE_SPACE_SIZE = 21.3;

        if (imageInfo.FileType === ImageBackgroundFileType.DXF) {
            if (s.Coordinates.Longitude < DXF_APPEND_VALUE + FRAME_WHITE_SPACE_SIZE) {
                closeToBorderSensorsIds.left.push(s.Id);
            }
            if (Math.abs(FRAME_SIZE - s.Coordinates.Longitude - 2 * FRAME_WHITE_SPACE_SIZE) < DXF_APPEND_VALUE) {
                closeToBorderSensorsIds.right.push(s.Id);
            }
            if (FRAME_SIZE - 2 * FRAME_WHITE_SPACE_SIZE - Math.abs(s.Coordinates.Latitude) < DXF_APPEND_VALUE) {
                closeToBorderSensorsIds.bottom.push(s.Id);
            }
            if (Math.abs(s.Coordinates.Latitude) < DXF_APPEND_VALUE + FRAME_WHITE_SPACE_SIZE) {
                closeToBorderSensorsIds.top.push(s.Id);
            }
        }
        else {
            const width = Math.abs(ViewPort.Left - ViewPort.Right);
            const height = Math.abs(ViewPort.Top - ViewPort.Bottom);

            if (s.Coordinates.Longitude < IMG_APPEND_VALUE) {
                closeToBorderSensorsIds.left.push(s.Id);
            }
            if (Math.abs(width - s.Coordinates.Longitude) < IMG_APPEND_VALUE) {
                closeToBorderSensorsIds.right.push(s.Id);
            }
            if (s.Coordinates.Latitude < IMG_APPEND_VALUE) {
                closeToBorderSensorsIds.bottom.push(s.Id);
            }
            if (Math.abs(height - s.Coordinates.Latitude) < IMG_APPEND_VALUE) {
                closeToBorderSensorsIds.top.push(s.Id);
            }
        }
    }

    const actualSensorsInfo = new Map<string, SensorInfo>();
    for (const sensor of sensors) {
        const sensorInfo = sensorsInfoStorage.sensorsInfo.get(sensor.FullId);
        if (sensorInfo) {
            checkIfSensorIsCloseToBorder(sensorInfo);
            actualSensorsInfo.set(sensor.FullId, sensorInfo);
        }
    }

    const actualSensorsInfoStorage: ISensorsInfoStorage = {
        ...sensorsInfoStorage,
        sensorsInfo: actualSensorsInfo
    };

    const onZoomFinishedHandler = (map: L.Map) => {
        if (onZoomingFinished) {
            onZoomingFinished(map);
        }
        setZoomFinishedState(true);
    }

    return (
        <React.Fragment>
            <div style={{ height: '100%', flexGrow: 1 }}>
                <ImageMapConnected
                    onClick={onSetPositionClickFn}
                    elementId={elementId}
                    imageInfo={imageInfo}
                    contentType={contentType}
                    projectId={projectId}
                    onReadyToDrawLegend={onReadyToDrawLegendHandler}
                    onRendered={onRendered}
                    onZoomingFinished={onZoomFinishedHandler}
                    rootContainer={rootContainer}
                    disableManipulation={disableManipulation}
                //disableManipulation={false}
                >

                    <SensorsOverviewLayer
                        additionalProps={additionalProps}
                        projectId={projectId}
                        sensorsInfoStorage={actualSensorsInfoStorage}
                        customSensorsLastDataStorage={sensorsLastDataStorage}
                        chainsInfoStorage={chainsInfoStorage}
                        customChainsLastDataStorage={chainsLastDataStorage}
                        chainsLastDataStorage={chainsLastDataStorage}
                        sensorsLastDataStorage={sensorsLastDataStorage}
                        layersVisibility={{
                            showGeoJSONLayers: false,
                            showInclinometerChains: true,
                            showSensorsLayer: true
                        }}
                        sensorsLayerState={currentSensorLayerState}
                        sensorsSymbolsStorage={sensorsSymbolsSettings}
                        sensorFilter={{ angleFilter: {}, manufacturerFilter: [], radiusFilter: {}, sensorTypeFilter: [], tunnelMeterFilter: {}, unitFilter: [], databasesFilter: [] }}
                        viewType={GEOvisDXFLayerType.Map}
                        invertXAxis={false}
                        movementOptions={movementSettings ? {
                            layerType: GEOvisDXFLayerType.Map,
                            movementSettings,
                            fontSize: fontSize ?? 10,
                            pictureXCoordinate: pictureXCoordinate ?? SensorValueAttribute.Empty,
                            pictureYCoordinate: pictureYCoordinate ?? SensorValueAttribute.Empty,
                            imageSensors: sensors
                        } : undefined}
                        deltaCoefficient={getDeltaCoefficient(rootContainer || null, getMapAreaOfGeovisImageViewPort(imageInfo.ViewPort), imageInfo.FileType, contentType)}
                        leafletElement={state.map ? state.map : undefined}
                        geovisImageId={contentType === "GeovisImage" ? +elementId : undefined}
                        userId={userId}
                        userToken={userHash}
                        useMapTextOrientation={true}
                        forceSingleMarkerMode={true}
                        createCustomizedClusterIcon={true}
                        useTooltipSmartPosition={true}
                        closeToBorderSensorsIds={closeToBorderSensorsIds}
                        popupEnabled={false}
                        isMapReady={zoomFinishedState}
                        timeSearchDistanceElement={{
                            ChainFullId: [],
                            SensorFullIds: [],
                            TimeSearchDistance: TimePeriod.Custom,
                            Timeslot: '',
                            UseCurrentTime: false,
                            UseLastMeasurementTime: false,
                            ReportId: 0,
                            RequestedSensorAttributes: []
                        }}
                        showIconTextBorder={showBorderAroundIconText}
                        forceHideAllSensorInfoText={setCoordinatesMode}
                    />

                    {/* here possible rendering the map legend */}
                    {children}
                </ImageMapConnected>
            </div>
            {setCoordinatesMode && (
                <ImageMapSelectSensorTable
                    sensors={sensors}
                    chains={chains}
                    sensorsInfoStorage={sensorsInfoStorage}
                    chainsInfoStorage={chainsInfoStorage}
                    onSelectChain={onSelectChain}
                    onSelectSensor={onSelectSensor}
                    selectedChainId={state.selectedChainId}
                    selectedSensorId={state.selectedSensorId}
                />
            )}
        </React.Fragment >
    )
}

/**
 * 
 * @param sensors 
 * @param sensorsInfoStorage 
 * @param skipSensorId - ебучий костыль
 */
const getNextSensorIdWithoutCoordinated = (sensors: ImageSensorModel[], sensorsInfoStorage: ISensorsInfoStorage, skipSensorId: string): string => {

    for (const sensor of sensors) {
        if (!isEmptyLocation2D(sensor.RelativeLocation) || sensor.FullId === skipSensorId) {
            continue;
        }

        const sensorInfo = sensorsInfoStorage.sensorsInfo.get(sensor.FullId);
        if (sensorInfo) {
            return sensorInfo.Id;
        }
    }

    return '';
}

/**
 * 
 * @param chains 
 * @param chainsInfoStorage 
 * @param skipChainId - и тут костыль, а что поделать, я заебался рефакторить
 */
const getNextChainIdWithoutCoordinates = (chains: ImageSensorModel[], chainsInfoStorage: IChainsInfoStorage, skipChainId: string): string => {

    for (const chain of chains) {
        if (!isEmptyLocation2D(chain.RelativeLocation) || chain.FullId === skipChainId) {
            continue;
        }

        const chainInfo = chainsInfoStorage.chainsInfo.get(chain.FullId);
        if (chainInfo) {
            return chainInfo.Id;
        }
    }

    return '';
}

/**
 * Manage set position for selected chain
 * @param position 
 * @param props 
 * @param deltaCoefficient 
 * @param state 
 * @param setState 
 * @returns 
 */
const onSetChainPositionClickHandler = (
    position: L.LatLng,
    props: IImageMapViewProps,
    deltaCoefficient: number | undefined,
    state: IComponentState,
    setState: (state: IComponentState) => void
) => {

    const { onSetChainPosition, setCoordinatesMode, chainsInfoStorage } = props;

    if (!setCoordinatesMode) {
        return;
    }

    const { selectedChainId } = state;

    const selected = chainsInfoStorage.chainsInfo.get(selectedChainId);

    if (!selected) {
        Logger.error(`Cannot set position of chain, selected chain not found`, LoggerSource);
        return;
    }

    const coordinates = latLngToLocation(position);
    const coefficient = deltaCoefficient ?? 1;

    const modifiedCoordinates: GeoLocation = { Height: 0, Latitude: coordinates.Latitude / coefficient, Longitude: coordinates.Longitude / coefficient };

    // set coordinates to sensor
    if (onSetChainPosition) {
        onSetChainPosition(selectedChainId, modifiedCoordinates);
    }


    const nextChainId = getNextChainIdWithoutCoordinates(props.chains, props.chainsInfoStorage, selectedChainId);
    if (nextChainId !== '') {
        setState({ ...state, selectedChainId: nextChainId });
        return;
    }

    const nextSensorId = getNextSensorIdWithoutCoordinated(props.sensors, props.sensorsInfoStorage, state.selectedSensorId);
    setState({ ...state, selectedSensorId: nextSensorId, selectedChainId: nextChainId });
}

/**
 * Manage set position for selected sensor
 * @param position 
 * @param props 
 * @param deltaCoefficient 
 * @param state 
 * @param setState 
 * @returns 
 */
const onSetSensorPositionClickHandler = (
    position: L.LatLng,
    props: IImageMapViewProps,
    deltaCoefficient: number | undefined,
    state: IComponentState,
    setState: (state: IComponentState) => void
) => {
    const { onSetSensorPosition, setCoordinatesMode, sensorsInfoStorage, onChangeSensorPosition } = props;
    if (!setCoordinatesMode) {
        return;
    }

    const { selectedSensorId } = state;

    const selected = sensorsInfoStorage.sensorsInfo.get(selectedSensorId);

    if (!selected) {
        Logger.error(`Cannot set position of sensor, selected sensor not found`, LoggerSource);
        return;
    }

    const coordinates = latLngToLocation(position);
    const coefficient = deltaCoefficient ?? 1;
    const modifiedCoordinates: GeoLocation = { Height: 0, Latitude: coordinates.Latitude / coefficient, Longitude: coordinates.Longitude / coefficient };

    // set coordinates to sensor
    if (onSetSensorPosition) {
        onSetSensorPosition(selectedSensorId, modifiedCoordinates);
        if (onChangeSensorPosition) {
            onChangeSensorPosition(selectedSensorId, modifiedCoordinates);
        }
    }

    const nextSensorId = getNextSensorIdWithoutCoordinated(props.sensors, sensorsInfoStorage, selectedSensorId);
    if (nextSensorId !== '') {
        setState({ ...state, selectedSensorId: nextSensorId });
        return;
    }

    const nextChainId = getNextChainIdWithoutCoordinates(props.chains, props.chainsInfoStorage, state.selectedChainId);
    setState({ ...state, selectedSensorId: nextSensorId, selectedChainId: nextChainId });
}