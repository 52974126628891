/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 31.07.2020
 * @description Sensor map item marker implementation
 */

import { Direction } from 'leaflet';
import { getViewSensorLocation } from '../../../helpers/MapHelper';
import { SensorMapTextOrientation } from '../../../server/AVTService/TypeLibrary/Common/SensorMapTextOrientation';
import { GeoJSONMapTilesLayerBounds } from '../../../server/AVTService/TypeLibrary/Model/GeoJSONMapTilesLayerBounds';
import { SensorInfo } from '../../../server/GEOvis3/Model/SensorInfo';
import Dispatcher, { IGeovisEventDispatcherUnsubscribe } from '../../../services/GeovisEventDispatcher';
import { ACTION_SHOW_POPUP_OF_SENSOR } from '../../../services/GeovisEventDispatcherActions';
import { createSingleSensorMarkerIcon } from '../../map/IconFactory';
import { GeovisMarker } from './GeovisMarker';
import MapItemMarker, { IMapItemMarkerProps } from './MapItemMarker';
import { SingleSensorPopup } from './PopupControls';
import { getIconTextInfo, isAnyActiveAlarm } from './tools';
import { SingleSensorTooltip } from './TooltipControls';
import { ICustomSensorTooltipContentProps } from './SensorLastMeasurement';
import { SensorCategory } from '../../../server/AVTService/TypeLibrary/Sensors/SensorCategory';
import { SensorValueAttribute } from '../../../server/AVTService/TypeLibrary/Common/SensorValueAttribute';
import { ISensorsLastDataStorage } from '../../../store/data.types';


interface ISensorMapItemMarkerProps extends IMapItemMarkerProps, ICustomSensorTooltipContentProps {
    sensor: SensorInfo;
    showSensorNames: boolean;
    fontSize?: number;
    useSensorColor?: boolean;
    useSensorColorForText?: boolean;
    offsetsBounds?: GeoJSONMapTilesLayerBounds;
    tooltipEnabled?: boolean;
    popupEnabled?: boolean;
    useGeovis4SensorTypeSymbol?: boolean;
    useMapTextOrientation?: boolean;
    tooltipDirection?: Direction;
    leafletElement?: L.Map;
    useTooltipSmartPosition?: boolean;
    onShowChartConfig: (chartId: number) => void;
    onShowAlarmConfig: (alarmId: string) => void;
    deltaCoefficient?: number;
    sensorTextOrientation?: SensorMapTextOrientation;
    showBold?: boolean;
    backgroundColor?: string;
    showTooltipContentEvenFaAlarm?: boolean;
    markerPaneName: string;
    showLastMeasurementTime?: boolean;
    showBorder?: boolean;
    additionalProps: SensorValueAttribute[];
    useSensorMarkerText: boolean;
    sensorsLastDataStorage: ISensorsLastDataStorage;
}

/**
 * Sensor map item marker
 */
export default class SensorMapItemMarker extends MapItemMarker<ISensorMapItemMarkerProps> {

    private unsubscribe: IGeovisEventDispatcherUnsubscribe;

    constructor(props: ISensorMapItemMarkerProps) {
        super(props);

        this.unsubscribe = () => { /** */ }
    }

    public componentDidMount = () => {
        this.unsubscribe = Dispatcher.subscribe(this.geovisDispatchedEvent, [ACTION_SHOW_POPUP_OF_SENSOR]);
    }

    public componentWillUnmount = () => {
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    }

    public render = (): JSX.Element => {

        const {
            projectId,
            sensor,
            showSensorNames,
            fontSize,
            sensorsSymbolsSettings,
            viewType,
            invertXAxis,
            offsetsBounds,
            tooltipEnabled,
            popupEnabled,
            useSensorColor,
            useGeovis4SensorTypeSymbol,
            useMapTextOrientation,
            tooltipDirection,
            leafletElement,
            useTooltipSmartPosition,
            onShowChartConfig,
            useSensorColorForText,
            deltaCoefficient,
            sensorTextOrientation,
            backgroundColor,
            showBold,
            onShowAlarmConfig,
            showTooltipContentEvenFaAlarm,
            customSensorsLastDataStorage,
            markerPaneName,
            showLastMeasurementTime,
            showBorder,
            additionalProps,
            useSensorMarkerText,
            sensorsLastDataStorage
        } = this.props;

        const { iconSettings } = sensorsSymbolsSettings;
        const drawPopup = popupEnabled === undefined || popupEnabled === true;
        const drawTooltip = tooltipEnabled === undefined || tooltipEnabled === true;

        const hasAnyActiveAlarm = isAnyActiveAlarm(sensor.CausedAlarms);
        const dataStorage = customSensorsLastDataStorage ?? sensorsLastDataStorage;

        const getMarkerChildren = (): JSX.Element[] => {
            const result: JSX.Element[] = [];

            if (drawTooltip) {
                result.push(
                    <SingleSensorTooltip
                        key={`sensor_${sensor.Id}_marker_tooltip`}
                        sensor={sensor}
                        direction={tooltipDirection}
                        useSmartPosition={useTooltipSmartPosition}
                        useGeovis4SensorTypeSymbol={useGeovis4SensorTypeSymbol}
                        showEvenNoDataAlarm={showTooltipContentEvenFaAlarm}
                        showLastMeasurementTime={showLastMeasurementTime}
                        dataStorage={dataStorage}
                        useSensorColor={useSensorColor}
                    />
                )
            }

            if (drawPopup) {
                result.push(
                    <SingleSensorPopup
                        key={`sensor_${sensor.Id}_map_popup`}
                        projectId={projectId}
                        sensor={sensor}
                        onShowChartConfig={onShowChartConfig}
                        onShowAlarmConfig={onShowAlarmConfig}
                        dataStorage={dataStorage}
                    />
                );
            }

            return result;
        }

        const sensorMarkerPosition = getViewSensorLocation(viewType, sensor, invertXAxis, offsetsBounds, leafletElement, deltaCoefficient);

        const getTopAddition = (): number => {
            if (useGeovis4SensorTypeSymbol === false) {
                return 0;
            }
            switch (sensor.PhysicalType) {
                case SensorCategory.VibrationSensor:
                    return 5;
                default:
                    return 0;
            }
        }

        const getLeftAddition = (): number => {
            if (useGeovis4SensorTypeSymbol === false) {
                return 0;
            }
            switch (sensor.PhysicalType) {
                default:
                    return 0;
            }
        }

        const [countLinesUnder, sensorIconText] = getIconTextInfo(sensor, showSensorNames, false, false, "", "", additionalProps, dataStorage.sensorsLastMeasurements);

        const markerIcon = createSingleSensorMarkerIcon({ ...sensor, Name: sensorIconText }, {
            showName: useSensorMarkerText,
            fontSize,
            replaceIconMap: iconSettings,
            useSensorColor,
            useGeovis4SensorTypeSymbol,
            useMapTextOrientation,
            noScale: useSensorColor ?? true,
            useSensorColorForText,
            countLinesUnder,
            mandatoryOrientation: sensorTextOrientation,
            fontWeight: showBold ? "bold" : undefined,
            backgroundColor,
            showBorder,
            topAddition: getTopAddition(),
            leftAddition: getLeftAddition()
        });

        return (
            <GeovisMarker
                key={`sensors_${sensor.Id}_marker`}
                makerPaneName={markerPaneName}
                markerProps={{
                    position: sensorMarkerPosition,
                    zIndexOffset: hasAnyActiveAlarm ? 1000 : 50,
                    icon: markerIcon
                }}
                setRef={this.setMarkerRefFn(sensor.Id)}
                additionalProps={{ sensor }}>

                {getMarkerChildren()}

            </GeovisMarker>
        )
    }
}