import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighChartAnnotations from 'highcharts/modules/annotations';
import HighchartsBoost from 'highcharts/modules/boost';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import React, { useLayoutEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { cloneDeep } from "lodash";
// eslint-disable-next-line @atlaskit/design-system/no-deprecated-imports
import { withNavigationUIController } from "@atlaskit/navigation-next";
import { t } from "../../../../../../i18n";
import { XYVibrationChartData } from "../../../../../../server/AVTService/TypeLibrary/Computation/XYVibrationChartData";
import { GeovisChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartModel";
import { XYVibrationChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/XYVibrationChartModel";
import Logger from "../../../../../../services/Logger";
import { projectGeovisReportVibrationEventShow, setDirtyGeovisChartSettings } from "../../../../../../store/creators/projectReportCreators";
import { IGeovisChartReportData, IGeovisReportPageData } from "../../../../../../store/projectReport.types";
import { IGeovisStoreState } from "../../../../../../store/store.types";
import { IGeovisAction } from "../../../../../../store/types";
import { LoadingContainerSkeleton } from "../../../../../LoadingContainerSkeleton";
import { LoadingPageErrorSkeleton } from "../../../../../LoadingPageErrorSkeleton";
import { IReportElementRenderOwnProps } from "../../types";
import { getGeovisChartConfigFromStorage } from "../options/tools";
import { getXYVibrationChartRenderOptions } from "../options/xyVibrationRenderOptions";
import { IChartRenderStateToProps } from "../types";
import { ChartLoadingSkeleton } from "./ChartLoadingComponent";
import { XYVibrationChartLegend } from "./extraComponents/XYVibrationChartLegend";
import { XYVibrationChartTimeline } from "./extraComponents/XYVibrationChartTimeline";
import IUINavigationControllerProps from "../../../../../atlaskit/UIControllerInterface";


type IStateToProps = IChartRenderStateToProps<XYVibrationChartData>

interface IDispatchToProps {
    setDirtyChartConfig: (pageNum: number, config: GeovisChartModel) => void;
    showVibrationEventChart: (pageNum: number, elementId: number, eventId: string, fullId: string, eventDate: string) => void;
}

interface IOwnProps extends IReportElementRenderOwnProps {
    chartId: number;
}


interface IComponentProps extends IStateToProps, IDispatchToProps, IOwnProps, IUINavigationControllerProps {

}

const XYVibrationChartRender = ({
    chartData,
    chartId,
    isPrinting,
    pageNum,
    showVibrationEventChartOnPage,
    showVibrationEventChart,
    navigationUIController
}: IComponentProps) => {
    if (!chartData) {
        return (<ChartLoadingSkeleton text={t("Initializing vibration chart data")} />)
    }

    const { data, errorDescription, isError, isLoading, isLoaded } = chartData;

    if (isError) {
        return (<LoadingPageErrorSkeleton errorText={t("Error load vibration chart data")} errorDescription={errorDescription} />);
    }

    if (!isLoaded) {
        return (<ChartLoadingSkeleton text={t("Loading vibration chart data...")} />)
    }

    Logger.render('XYVibrationChartRender');

    // getting changed chart config directly from storage, do not subscribe on UPDATE event
    const chart = getGeovisChartConfigFromStorage<XYVibrationChartModel>(pageNum, chartId);
    if (!chart) {
        return null;
    }

    const onShowEventData = (eventId: string, fullId: string, eventDate: string) => {
        Logger.trace(`Asked to show event ${eventId} for chart ${chartId} on page ${pageNum}`);
        // showVibrationEventChart(pageNum, chartId, eventId, fullId);
        if (showVibrationEventChartOnPage) {
            showVibrationEventChartOnPage(chartId, eventId, fullId, eventDate);
        }
        showVibrationEventChart(pageNum, chartId, eventId, fullId, eventDate);
    }

    const containerRef = useRef<HTMLDivElement>(null);

    const [state, setState] = useState<boolean>(false);

    useLayoutEffect(() => {
        if (navigationUIController && state !== navigationUIController.state.isCollapsed) {
            setTimeout(() => {
                setState(navigationUIController.state.isCollapsed);
            }, 200);
        }
    });

    const renderOptions = getXYVibrationChartRenderOptions(pageNum, cloneDeep(chart), data, onShowEventData);

    NoDataToDisplay(Highcharts);
    HighChartAnnotations(Highcharts);

    // disable boost if chart is in Printing mode
    if (!isPrinting) {
        HighchartsBoost(Highcharts);
    }


    return (
        <React.Fragment>
            <div className="report-chart-render" ref={containerRef} style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', height: '100%' }}>

                {isLoading && (
                    <LoadingContainerSkeleton loadingText={t("Loading vibration chart data...")} />
                )}

                {isLoaded &&
                    <XYVibrationChartTimeline
                        chart={chart}
                        IntensivityToDates={data.IntensivityToDates}
                        timeslotFrom={data.TimeSlotFrom}
                        timeslotTo={data.TimeSlotTo}
                    />
                }
                {isLoaded &&
                    <HighchartsReact
                        key={`highcharts-${chart.Id}-0-${chartData.Timestamp}-${containerRef.current?.clientWidth}-${state}`}
                        highcharts={Highcharts}
                        options={renderOptions}
                        constructorType={'chart'}
                        containerProps={{ style: { flexGrow: 1, height: `100%` } }}
                    />
                }
                {isLoaded && (
                    <XYVibrationChartLegend
                        descriptions={data.Descriptions}
                    />
                )}

            </div>
        </React.Fragment>
    )
}

const getChartData = (chartId: number, pageNum: number, pagesData: Map<number, IGeovisReportPageData>): IGeovisChartReportData<XYVibrationChartData> => {
    const resultChartData: IGeovisChartReportData<XYVibrationChartData> = {
        data: {
            ... new XYVibrationChartData(),
            Descriptions: [],
            LinkedAlarmLines: [],
            Lines: [],
            WarningSensors: []
        },
        dtsSectionInfos: [],
        errorDescription: '',
        isError: false,
        isInProgress: false,
        isLoaded: false,
        isLoading: true,
        Timestamp: 0,
    }
    const chartsOnPageData = pagesData.get(pageNum);
    if (chartsOnPageData) {
        const chartData = chartsOnPageData.geovisChartsData.get(chartId);
        if (chartData) {
            return chartData as IGeovisChartReportData<XYVibrationChartData>;
        }
    }
    return resultChartData;
}

const mapStateToProps = ({ projectReport }: IGeovisStoreState, { chartId, pageNum }: IOwnProps): IStateToProps => ({
    chartData: getChartData(chartId, pageNum, projectReport.geovisReportSettings.geovisPagesData)
});

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>): IDispatchToProps => ({
    setDirtyChartConfig: (pageNumber, config) => dispatch(setDirtyGeovisChartSettings(pageNumber, config)),
    showVibrationEventChart: (pageNum, elementId, eventId, fullId, eventDate) => dispatch(projectGeovisReportVibrationEventShow(pageNum, elementId, eventId, fullId, eventDate))
});

export default connect<IStateToProps, IDispatchToProps, IOwnProps>(mapStateToProps, mapDispatchToProps)(withNavigationUIController(XYVibrationChartRender));