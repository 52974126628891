/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 13.07.2023
 * @description The component to render project elements group
 */

import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { GeovisReportPageRenderProjectElement } from "./GeovisReportPageRender.ProjectElement";
import { IReportElementRenderHostOwnProp } from "./types";
import { IGeovisReportPageConfig } from "../../../../store/projectReport.types";
import { IGeovisStoreState } from "../../../../store/store.types";
import { getProjectElementsGroupElementsInfo } from "./GeovisReportPageRender.ProjectElementsGroup.tools";
import { LoadingPageSkeleton } from "../../../LoadingPageSkeleton";

interface IStateToProps {
    page: IGeovisReportPageConfig | false;
}

interface IOwnProps {
    pageNum: number;
}

interface IComponentProps extends IStateToProps, IOwnProps, Omit<IReportElementRenderHostOwnProp, 'isInGroup'> {
    rootContainerWidth: number;
}

const Component = ({ rootContainerWidth, page, elementInfo, ...restOwnProps }: IComponentProps) => {

    if (page === false || !elementInfo.Children || elementInfo.Children.length === 0) {
        return null;
    }

    if (elementInfo.Children.length === 1) {
        return (
            <GeovisReportPageRenderProjectElement
                {...restOwnProps}
                allContainerWidth={rootContainerWidth}
                rootContainerWidth={rootContainerWidth}
                elementInfo={{ ...elementInfo, ...elementInfo.Children[0] }}
            />
        )
    }

    const [rootRef, setRootRef] = useState<HTMLDivElement | null>(null);

    return (
        <div
            key={`report-elements-group-div-${elementInfo.Id}`}
            ref={setRootRef}
            style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', width: '100%' }}>

            {rootRef && (
                <Fragment>
                    {getProjectElementsGroupElementsInfo(rootRef, elementInfo, page).map((element, elementIndex, collection) => {

                        return (
                            <div
                                id={`group-item-${elementIndex}`}
                                key={`group-item-${elementIndex}`}
                                style={{ height: '100%', width: `${element.width}%` }}>
                                {/* if element not found or still loading, then draw the loading skeleton */}
                                {element.isLoading && (
                                    <LoadingPageSkeleton />
                                )}
                                {/* if element found, then draw it */}
                                {!element.isLoading && (
                                    <GeovisReportPageRenderProjectElement
                                        {...restOwnProps}
                                        key={`report-element-of-group-${elementInfo.Id}`}
                                        elementInfo={element}
                                        isInGroup={true}
                                        firstInGroup={elementIndex === 0}
                                        lastInGroup={elementIndex === collection.length - 1}
                                        rootContainerWidth={rootRef.clientWidth * element.width / 100}
                                        allContainerWidth={rootRef.clientWidth}
                                        groupedElements={collection}
                                    />
                                )}
                            </div>
                        )
                    })}
                </Fragment>
            )}
        </div>
    )
}

const mapStateToProps = ({ projectReport: { geovisReportSettings } }: IGeovisStoreState, { pageNum }: IOwnProps): IStateToProps => ({
    page: geovisReportSettings.geovisPages.get(pageNum) || false
});

const ComponentConnected = connect<IStateToProps, never, IOwnProps>(mapStateToProps)(Component);

export default ComponentConnected;