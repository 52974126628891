import { LatLng } from "leaflet";
import { Fragment, useEffect, useState } from "react";
import { IWithGeovisServerProps, withGeovisServer } from "../../../helpers/GeovisHooks";
import { geoLocationToLatLng } from "../../../helpers/MapHelper";
import { fetchServerElementsByPost } from "../../../helpers/ProjectDataHelper";
import { elementsToMapWithConverted } from "../../../helpers/StorageHelper";
import { GEOvisDXFLayerType } from "../../../server/AVTService/TypeLibrary/Common/GEOvisDXFLayerType";
import { SensorAttribyteEntry } from "../../../server/AVTService/TypeLibrary/Common/SensorAttribyteEntry";
import { SensorValueAttribute } from "../../../server/AVTService/TypeLibrary/Common/SensorValueAttribute";
import { SensorsMovementSettings } from "../../../server/AVTService/TypeLibrary/Map/SensorsMovementSettings";
import { GeoJSONMapTilesLayerBounds } from "../../../server/AVTService/TypeLibrary/Model/GeoJSONMapTilesLayerBounds";
import { getSensorSymbolToName, SensorSymbol } from "../../../server/AVTService/TypeLibrary/Sensors/SensorSymbol";
import { SensorInfo } from "../../../server/GEOvis3/Model/SensorInfo";
import { ImageSensorsMovementDataRequest } from "../../../server/GEOvis3/Model/SensorsData/ImageSensorsMovementDataRequest";
import { SensorMovementVectorInfo } from "../../../server/GEOvis3/Model/SensorsData/SensorMovementVectorInfo";
import { SensorsMovementDataRequest } from "../../../server/GEOvis3/Model/SensorsData/SensorsMovementDataRequest";
import { ImageSensorModel } from "../../../server/ImageSensorModel";
import ServerRoutesGen from "../../../server/Routes/ServerRoutesGen";
import { SensorMeasurementInfo } from "../../../server/SensorMeasurementInfo";
import AuthService from "../../../services/AuthService";
import { getSensorIconProps } from "../../map/IconFactory";
import { ICreateSensorStateIconOptions } from "../../types";
import SensorsHeightMovementVectorsControl from "./SensorsHeightMovementVectorsControl";
import SensorsMovementVectorsControl from "./SensorsMovementVectorsControl";
import {
    getLocXYLocation,
    getProfileCoordsProps,
    getProfileViewGeoLocation,
    getSideViewCoordinates,
    getSideViewCoordsProps,
    getSideViewGeoLocation,
    ILocalCoordsProps
} from "./tools";
import { ICloseToBorderSensorsIds, IHeightMovementVectorInfo, IMovementVectorInfo } from "./types";
import { SensorsMovementDataOnTimeslotRequest } from "../../../server/GEOvis3/Model/SensorsData/SensorsMovementDataOnTimeslotRequest";
import { TimeSearchDistanceDataRequest } from "../../../server/AVTService/Computation/TimeSearchDistance/TimeSearchDistanceDataRequest";
import { ISomethingStorageBaseEx, defaultSomethingStorageState } from "../../../store/types";
import { processFetchedData } from "../../../store/helpers/DataHelper";
import Route from "../../../helpers/Route";
import { DataActionResponse } from "../../../server/DataActionResponse";
import { SensorMovementIconsTextRender, prepareSensorIconMovementData } from "./SensorMovementIconsTextRender";

interface IComponentOwnProps {
    projectId: number;
    sensors: SensorInfo[];
    layerType: GEOvisDXFLayerType;
    movementSettings: SensorsMovementSettings;
    timeSearchDistanceElement: TimeSearchDistanceDataRequest;
    reportElementTimestamp?: number;
    fontSize: number;
    leafletElement: L.Map;
    offsetsBounds?: GeoJSONMapTilesLayerBounds;
    invertXAxis: boolean;
    userId?: string;
    userToken?: string;
    deltaCoefficient?: number;
    geovisImageId?: number
    sensorsData: Map<string, SensorMeasurementInfo>;
    pictureXCoordinate: SensorValueAttribute
    pictureYCoordinate: SensorValueAttribute
    imageSensors: ImageSensorModel[];
    closeToBorderSensorsIds?: ICloseToBorderSensorsIds;
    createSensorStateIconOptions: ICreateSensorStateIconOptions;
    showSensorsNames: boolean;
    useSensorColor: boolean;
    isMapReady?: boolean;
    useGeovis4Symbol?: boolean;
    showBorder: boolean;
    noIconScale: boolean;
    additionalProps: SensorValueAttribute[]
}

interface IComponentProps extends IComponentOwnProps, IWithGeovisServerProps {

}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IMovementDataStorage extends ISomethingStorageBaseEx<SensorMovementVectorInfo[]> {

}

const movementDataStorageInitialState: IMovementDataStorage = {
    ...defaultSomethingStorageState,
    data: []
}

const isStorageLoaded = ({ isLoading, isError }: IMovementDataStorage): boolean => {
    return !isLoading && !isError;
}

const MovementComponent = ({
    Server,
    imageSensors,
    invertXAxis,
    layerType,
    movementSettings,
    timeSearchDistanceElement,
    reportElementTimestamp,
    pictureXCoordinate,
    pictureYCoordinate,
    projectId,
    sensors,
    closeToBorderSensorsIds,
    deltaCoefficient,
    geovisImageId,
    leafletElement,
    offsetsBounds,
    userId,
    userToken,
    createSensorStateIconOptions,
    showSensorsNames,
    fontSize,
    useSensorColor,
    isMapReady,
    useGeovis4Symbol,
    showBorder,
    noIconScale,
    additionalProps,
    sensorsData
}: IComponentProps) => {

    const isPdfPrintingMode = userToken !== undefined && userId !== undefined;
    const sensorIdMap = elementsToMapWithConverted(sensors, s => [s.Id, s]);
    const isGeovisImage = geovisImageId !== undefined;

    const [movementVectorsDataStorage, setMovementVectorsDataStorage] = useState<IMovementDataStorage>(movementDataStorageInitialState);
    const [movementBarsDataStorage, setMovementBarsDataStorage] = useState<IMovementDataStorage>(movementDataStorageInitialState);
    const [justRefreshValue, setJustRefreshValue] = useState<boolean>(false);

    const getArrowEndPosition = (vectorEndPosition: L.Point, vectorAngle: number, isFirst: boolean) => {
        const arrowLength = 10;
        const multiplier = isFirst ? 1 : -1;
        const angleInRadian = (vectorAngle + 135 * multiplier) * Math.PI / 180;

        const endPoint = layerType === GEOvisDXFLayerType.Map
            ? L.point(vectorEndPosition.x + arrowLength * Math.sin(angleInRadian), vectorEndPosition.y - arrowLength * Math.cos(angleInRadian))
            : L.point(vectorEndPosition.x + arrowLength * Math.cos(angleInRadian), vectorEndPosition.y - arrowLength * Math.sin(angleInRadian));

        return leafletElement.layerPointToLatLng(endPoint);
    }

    const getLatLngStartPosition = (sensorInfo: SensorInfo | undefined): L.LatLng => {
        if (!sensorInfo) {
            return new L.LatLng(0, 0);
        }

        switch (layerType) {
            case GEOvisDXFLayerType.Map:
                return geoLocationToLatLng(sensorInfo.Coordinates, deltaCoefficient);

            case GEOvisDXFLayerType.ProfileView:
            case GEOvisDXFLayerType.SideView: {
                const startLocation = layerType === GEOvisDXFLayerType.ProfileView
                    ? getLocXYLocation(sensorInfo, offsetsBounds, leafletElement)
                    : getSideViewCoordinates(sensorInfo, invertXAxis, offsetsBounds);

                return geoLocationToLatLng(startLocation);
            }

            default:
                return new L.LatLng(0, 0);
        }
    }

    const getLatLngEndPosition = (vectorInfo: SensorMovementVectorInfo, sensorInfo?: SensorInfo) => {
        if (!sensorInfo) {
            return new L.LatLng(0, 0);
        }

        if (layerType === GEOvisDXFLayerType.Map && !isGeovisImage) {
            return geoLocationToLatLng(vectorInfo.EndPosition);
        }

        if (layerType === GEOvisDXFLayerType.Map && isGeovisImage) {
            const deviationInMillimeters = vectorInfo.Deviation * 1000;
            const deviationWidthInPx = deviationInMillimeters * movementSettings.VectorMovementSettings.ScalingRate;
            const vectorAngleInRadian = vectorInfo.MovementAngle2D * Math.PI / 180;
            const endX = sensorInfo.Coordinates.Longitude + deviationWidthInPx * Math.sin(vectorAngleInRadian);
            const endY = sensorInfo.Coordinates.Latitude + deviationWidthInPx * Math.cos(vectorAngleInRadian);

            return geoLocationToLatLng({ Latitude: endY, Longitude: endX, Height: 0 }, deltaCoefficient);
        }

        if (!offsetsBounds) {
            return new L.LatLng(0, 0);
        }

        const localCoordsProps = layerType === GEOvisDXFLayerType.ProfileView ? getProfileCoordsProps(offsetsBounds) : getSideViewCoordsProps(offsetsBounds);

        const deviation = vectorInfo.Deviation * 1000;
        const deviationLength = movementSettings.VectorMovementSettings.ScalingRate * deviation; // length of vector in meters of map
        const angleInRadian = vectorInfo.MovementAngle2D * Math.PI / 180;

        if (AuthService.isNagraDistribution()) {
            const endLocX = sensorInfo.LocX + deviationLength * Math.sin(angleInRadian);
            const endLocY = layerType === GEOvisDXFLayerType.ProfileView
                ? sensorInfo.LocY + deviationLength * Math.cos(angleInRadian)
                : sensorInfo.TunnelMeter + deviationLength * Math.cos(angleInRadian);

            return geoLocationToLatLng(layerType === GEOvisDXFLayerType.ProfileView
                ? getProfileViewGeoLocation(localCoordsProps, endLocX, endLocY, sensorInfo.ActualAxisReference)
                : getSideViewGeoLocation(localCoordsProps as ILocalCoordsProps, endLocX, endLocY, sensorInfo.ActualAxisReference, invertXAxis, offsetsBounds));
        }

        if (!sensorInfo.ActualAxisReference || sensorInfo.ActualAxisReference.values.length < 3) {
            return new L.LatLng(0, 0);
        }

        const endAxisReference: SensorAttribyteEntry = layerType === GEOvisDXFLayerType.ProfileView
            ? {
                ...sensorInfo.ActualAxisReference,
                values: [
                    sensorInfo.ActualAxisReference.values[0],
                    sensorInfo.ActualAxisReference.values[1] + deviationLength * Math.cos(angleInRadian),
                    sensorInfo.ActualAxisReference.values[2] + deviationLength * Math.sin(angleInRadian),
                ]
            }
            : {
                ...sensorInfo.ActualAxisReference,
                values: [
                    sensorInfo.ActualAxisReference.values[0] + deviationLength * Math.cos(angleInRadian),
                    sensorInfo.ActualAxisReference.values[1],
                    sensorInfo.ActualAxisReference.values[2] + deviationLength * Math.sin(angleInRadian),
                ]
            }

        return geoLocationToLatLng(layerType === GEOvisDXFLayerType.ProfileView
            ? getProfileViewGeoLocation(localCoordsProps, 0.0, 0.0, endAxisReference)
            : getSideViewGeoLocation(localCoordsProps as ILocalCoordsProps, 0.0, 0.0, endAxisReference, invertXAxis, offsetsBounds));
    }

    const getMovementTextValue = (movement: number, isImage: boolean): string => {
        const modifier = movement < 0 ? -1 : 1;
        return `${isImage ? "" : "2D: "}${(Math.round(Math.abs(movement) * 10000) * modifier / 10).toFixed(1)}mm`
    }

    /**
     * Prepare vector movements data
     * @returns 
     */
    const prepareVectorsMovementData = (): IMovementVectorInfo[] => {
        const { isLoading, isError, data } = movementVectorsDataStorage;

        if (isLoading || isError) {
            return [];
        }

        // const vectorsWithRealMovement = data.filter(v => Math.abs(v.Deviation) > 0.0 && sensorIdMap.has(v.SensorFullId));

        return data.map(vectorInfo => {
            const sensorInfo = sensorIdMap.get(vectorInfo.SensorFullId);

            const vectorEndPositionLatLng = getLatLngEndPosition(vectorInfo, sensorInfo);
            const endPositionLayerPoint = leafletElement.latLngToLayerPoint(vectorEndPositionLatLng);

            return {
                sensorFullId: vectorInfo.SensorFullId,
                startPosition: getLatLngStartPosition(sensorInfo),
                endPosition: vectorEndPositionLatLng,
                angle: vectorInfo.MovementAngle2D,
                arrowEndPosition1: getArrowEndPosition(endPositionLayerPoint, vectorInfo.MovementAngle2D, true),
                arrowEndPosition2: getArrowEndPosition(endPositionLayerPoint, vectorInfo.MovementAngle2D, false),
                value: getMovementTextValue(vectorInfo.Deviation, isGeovisImage),
                justForText: false,
                color: vectorInfo.Color
            }
        })
    }

    /**
     * Prepare height bars movement data
     * @returns 
     */
    const prepareBarsMovementData = (): IHeightMovementVectorInfo[] => {

        const { isLoading, isError, data } = movementBarsDataStorage;

        if (isLoading || isError) {
            return [];
        }

        // const vectorsWithRealMovement = data.filter(v => Math.abs(v.Deviation) > 0.0);

        return data.map<IHeightMovementVectorInfo>(vectorInfo => {
            const startPositionLatLng = geoLocationToLatLng(vectorInfo.StartPosition, deltaCoefficient);
            const startPositionLayerPoint = leafletElement.latLngToLayerPoint(startPositionLatLng);

            const endPositionLatLng = geoLocationToLatLng(vectorInfo.EndPosition, deltaCoefficient);
            const endPositionLayerPoint = leafletElement.latLngToLayerPoint(endPositionLatLng);

            const sensorInfo = sensorIdMap.get(vectorInfo.SensorFullId);
            const iconOffset = getIconOffset(vectorInfo.Deviation, sensorInfo);

            const rectangleBounds = geovisImageId
                ? getImageRectangleBounds(vectorInfo.Deviation, startPositionLatLng, endPositionLatLng, iconOffset)
                : getRectangleBounds(vectorInfo.Deviation, startPositionLayerPoint, endPositionLayerPoint, iconOffset);

            return {
                sensorFullId: vectorInfo.SensorFullId,
                deviation: vectorInfo.Deviation,
                startPosition: startPositionLatLng,
                endPosition: endPositionLatLng,
                bounds: rectangleBounds,
                color: vectorInfo.Color,
                showValue: movementSettings.ShowHeightDeviationValue,
                deviationText: getDeviationText(vectorInfo.Deviation),
                justForText: false
            }
        })
    }

    const getIconOffset = (deviation: number, sensorInfo?: SensorInfo): L.Point => {
        if (!sensorInfo) {
            return L.point(0, 0);
        }
        const iconProps = getSensorIconProps(sensorInfo, createSensorStateIconOptions);
        if (iconProps) {
            const yHalfSize = iconProps.iconSize.y / 2.0;
            if (iconProps.key === getSensorSymbolToName(SensorSymbol.TriangleRight) ||
                iconProps.key === getSensorSymbolToName(SensorSymbol.TriangleLeft)) {
                return L.point(-0.5, yHalfSize);
            }

            if (iconProps.key === getSensorSymbolToName(SensorSymbol.Plus)) {
                return L.point(-1.0, 12.0);
            }

            if (iconProps.key === getSensorSymbolToName(SensorSymbol.Romb)) {
                return L.point(-0.5, 12);
            }

            if (iconProps.key === getSensorSymbolToName(SensorSymbol.TriangleUp)) {
                const yOffset = deviation < 0 ? yHalfSize : iconProps.iconSize.y;
                return L.point(0.0, yOffset);
            }

            if (iconProps.key === getSensorSymbolToName(SensorSymbol.TriangleDown)) {
                const yOffset = deviation < 0 ? 14 : 11;
                return L.point(0.0, yOffset);
            }

            if (iconProps.key === getSensorSymbolToName(SensorSymbol.Circle)) {
                return L.point(0.0, yHalfSize);
            }

            return L.point(0.5, yHalfSize);
        }
        return L.point(0, 0);
    }

    const getRectangleBounds = (deviation: number, startPositionLayerPoint: L.Point, endPositionLayerPoint: L.Point, iconOffset: L.Point): L.LatLngBounds => {
        const heightBarHalfWidth = movementSettings.HeightBarsMovementSettings.Weight / 2.0;

        if (deviation < 0) {
            const southWestPoint = new L.Point(startPositionLayerPoint.x + iconOffset.x - heightBarHalfWidth, endPositionLayerPoint.y + iconOffset.y);
            const northEastPoint = new L.Point(startPositionLayerPoint.x + iconOffset.x + heightBarHalfWidth, startPositionLayerPoint.y + iconOffset.y);

            return new L.LatLngBounds(leafletElement.layerPointToLatLng(southWestPoint), leafletElement.layerPointToLatLng(northEastPoint));
        }
        else {
            const southWestPoint = new L.Point(startPositionLayerPoint.x + iconOffset.x - heightBarHalfWidth, startPositionLayerPoint.y - iconOffset.y);
            const northEastPoint = new L.Point(startPositionLayerPoint.x + iconOffset.x + heightBarHalfWidth, endPositionLayerPoint.y - iconOffset.y);

            return new L.LatLngBounds(leafletElement.layerPointToLatLng(southWestPoint), leafletElement.layerPointToLatLng(northEastPoint));
        }
    }

    const getImageRectangleBounds = (deviation: number, startPosition: LatLng, endPosition: LatLng, iconOffset: L.Point): L.LatLngBounds => {
        const heightBarHalfWidth = movementSettings.HeightBarsMovementSettings.Weight * (deltaCoefficient ?? 1) / 2.0;

        if (deviation < 0) {
            const southWest: LatLng = new LatLng(startPosition.lat + iconOffset.y, startPosition.lng + iconOffset.x - heightBarHalfWidth);
            const northEast: LatLng = new LatLng(endPosition.lat + iconOffset.y, startPosition.lng + iconOffset.x + heightBarHalfWidth);

            return new L.LatLngBounds(southWest, northEast);
        }
        else {
            const southWest: LatLng = new LatLng(startPosition.lat - iconOffset.y, startPosition.lng + iconOffset.x - heightBarHalfWidth);
            const northEast: LatLng = new LatLng(endPosition.lat - iconOffset.y, startPosition.lng + iconOffset.x + heightBarHalfWidth);

            return new L.LatLngBounds(southWest, northEast);
        }
    }

    const getDeviationText = (deviation: number): string => {
        const modifier = deviation < 0 ? -1 : 1;
        return `H: ${(Math.round(Math.abs(deviation) * 10000) * modifier / 10).toFixed(1)}mm`
    }

    const getHeightMovementUrl = () => {
        if (geovisImageId) {
            return isPdfPrintingMode
                ? ServerRoutesGen.ReportPdfRenderData.GetImageSensorsHeightMovementVectors.patch(projectId, userToken, userId, geovisImageId)
                : ServerRoutesGen.ProjectSensorsData.GetImageSensorsHeightMovementVectors.patch(projectId, geovisImageId);
        }

        return isPdfPrintingMode
            ? ServerRoutesGen.ReportPdfRenderData.GetSensorsHeightMovementVectors.patch(projectId, userToken, userId)
            : ServerRoutesGen.ProjectSensorsData.GetSensorsHeightMovementVectors.patch(projectId);
    }




    const getMovementUrl = () => {
        if (layerType === GEOvisDXFLayerType.Map) {
            if (isGeovisImage) {
                return isPdfPrintingMode
                    ? ServerRoutesGen.ReportPdfRenderData.GetImageSensorsHorizontalMovementVectors.patch(projectId, userToken, userId, geovisImageId)
                    : ServerRoutesGen.ProjectSensorsData.GetImageSensorsHorizontalMovementVectors.patch(projectId);
            }

            return isPdfPrintingMode
                ? ServerRoutesGen.ReportPdfRenderData.GetSensorsHorizontalMovementVectors.patch(projectId, userToken, userId)
                : ServerRoutesGen.ProjectSensorsData.GetSensorsHorizontalMovementVectors.patch(projectId);
        }

        return isPdfPrintingMode
            ? ServerRoutesGen.ReportPdfRenderData.GetSensorsVerticalMovementVectors.patch(projectId, userToken, userId)
            : ServerRoutesGen.ProjectSensorsData.GetSensorsVerticalMovementVectors.patch(projectId);
    }

    const loadMovementData = async (url: Route): Promise<DataActionResponse<SensorMovementVectorInfo[]>> => {

        const movementDataRequest: SensorsMovementDataRequest = {
            MovementSettings: movementSettings,
            LayerType: layerType
        }

        const response = isGeovisImage
            ? await fetchServerElementsByPost<SensorMovementVectorInfo[], ImageSensorsMovementDataRequest>(Server, url, {
                ...movementDataRequest,
                PictureXCoordinate: pictureXCoordinate,
                PictureYCoordinate: pictureYCoordinate,
                Sensors: imageSensors,
                FullIds: sensors.map(s => s.Id)
            })
            : await fetchServerElementsByPost<SensorMovementVectorInfo[], SensorsMovementDataOnTimeslotRequest>(Server, url, {
                ...movementDataRequest,
                Request: timeSearchDistanceElement
            });

        return response;
    }

    useEffect(() => {

        if (sensors.length === 0) {
            // if no sensors, then reset to initial state
            setMovementVectorsDataStorage(movementDataStorageInitialState);
            setMovementBarsDataStorage(movementDataStorageInitialState);

            return;
        }

        if (movementSettings.ShowMovementVector) {
            (async function loadMovementVectorsData() {

                setMovementVectorsDataStorage(movementDataStorageInitialState);

                const url = getMovementUrl();
                const response = await loadMovementData(url);

                setMovementVectorsDataStorage(processFetchedData(response, movementVectorsDataStorage, movementDataStorageInitialState, st => ({
                    data: st
                })));

            })();
        }

        if (movementSettings.ShowMovementHeightBars) {
            (async function loadMovementBarsData() {

                setMovementBarsDataStorage(movementDataStorageInitialState);

                const url = getHeightMovementUrl();
                const response = await loadMovementData(url);

                setMovementBarsDataStorage(processFetchedData(response, movementBarsDataStorage, movementDataStorageInitialState, st => ({
                    data: st
                })));

            })();
        }

    }, [reportElementTimestamp, sensors.length]);

    useEffect(() => {
        setJustRefreshValue(!justRefreshValue);
    }, [isMapReady])

    if (layerType === GEOvisDXFLayerType.Nothing || sensors.length === 0 || isMapReady === false) {
        return null;
    }

    const showHeightBars = movementSettings.ShowMovementHeightBars && leafletElement && layerType === GEOvisDXFLayerType.Map && isStorageLoaded(movementBarsDataStorage);
    const showVectors = movementSettings.ShowMovementVector && leafletElement && isStorageLoaded(movementVectorsDataStorage)

    const vectorsMovementData = showVectors ? prepareVectorsMovementData() : false;
    const barsMovementData = showHeightBars ? prepareBarsMovementData() : false;

    return (
        <Fragment>
            <SensorMovementIconsTextRender
                fontSize={fontSize}
                showBarDeviation={movementSettings.ShowHeightDeviationValue}
                showSensorName={showSensorsNames}
                showVectorDeviation={movementSettings.ShowVectorDeviationValue}
                useGeovis4Symbol={useGeovis4Symbol}
                iconMovementData={prepareSensorIconMovementData(sensors, vectorsMovementData, barsMovementData, useSensorColor, closeToBorderSensorsIds, getLatLngStartPosition)}
                showBorder={showBorder}
                noScale={noIconScale}
                replaceIconMap={createSensorStateIconOptions.replaceIconMap}
                additionalProps={additionalProps}
                sensorsData={sensorsData}
            />

            {vectorsMovementData &&
                <SensorsMovementVectorsControl
                    layerType={layerType}
                    movementSettings={movementSettings}
                    leafletElement={leafletElement}
                    movementVectors={vectorsMovementData}
                />
            }

            {barsMovementData &&
                <SensorsHeightMovementVectorsControl
                    sensorIdMap={sensorIdMap}
                    layerType={layerType}
                    movementSettings={movementSettings}
                    leafletElement={leafletElement}
                    heightMovementVectors={barsMovementData}
                    reportElementTimestamp={reportElementTimestamp}
                />
            }
        </Fragment>
    )
}

const ComponentWithServer = withGeovisServer(MovementComponent);

export default ComponentWithServer;