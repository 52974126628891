import Button from "@atlaskit/button";
import Checkbox from "@atlaskit/checkbox";
import EditorInfoIcon from '@atlaskit/icon/glyph/editor/info';
import { CreatableSelect, OptionType } from "@atlaskit/select";
import Textfield from "@atlaskit/textfield";
import Tooltip from '@atlaskit/tooltip';
import React, { Fragment, SyntheticEvent } from "react";
import { getGeovisCurrentDateTime, getGeovisDateTimeShift } from "../../../../helpers/DateHelper";
import { t } from "../../../../i18n";
import { ChartMeasureTimeCalcType, ChartMeasureTimeCalcTypeList, getChartMeasureTimeCalcTypeToDescription } from "../../../../server/AVTService/TypeLibrary/Common/ChartMeasureTimeCalcType";
import { getTimePeriodToDescription, TimePeriod, TimePeriodList } from "../../../../server/AVTService/TypeLibrary/Common/TimePeriod";
import { XYVibrationChartModel } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/XYVibrationChartModel";
import GeovisReactDateTimePicker from "../../../dateTimePickers/GeovisReactDateTimePicker";
import { GeovisSelect } from "../../../select/GeovisSelect";
import { getTimeOfPeriodInHours, getTimes } from "../tools";
import { defaultChartLabelColumnStyle, defaultChartRowSettingsStyle, IGeovisChartPropertyChangedProps } from "../types";
import { ElementSettingAllowCheckbox } from "../../ElementSettingAllowCheckbox";

type ChartProperty = keyof XYVibrationChartModel;

type IXYVibrationMainSettingsProps = IGeovisChartPropertyChangedProps<XYVibrationChartModel>

export const XYVibrationChartMainSettings = ({ chart, onChartPropertyChanged, onChartPropertiesChanged }: IXYVibrationMainSettingsProps) => {

    const {
        StartDateSetting,
        EndDateSetting,
        ShowAllMeasurementsSetting,
        Period,
        IsPeriodCustomerChangeable,
        UseLastMeasurementTime,
        MeasureTimeCalcType,
        MeasureTimeCalcCustomerChangeable,
        NumberOfMeasurementsToShow,
        Times,
        DivideIntervalAlarmIfNoData
    } = chart;

    const onBooleanPropertyChangedFunc = (propertyName: ChartProperty) => (event: SyntheticEvent<HTMLInputElement>) => {
        const value = event.currentTarget.checked;

        switch (propertyName) {
            case "ShowAllMeasurementsSetting":
                onChartPropertyChanged(propertyName, { ...ShowAllMeasurementsSetting, value });
                break;

            case "IsPeriodCustomerChangeable":
            case "UseLastMeasurementTime":
            case "DivideIntervalAlarmIfNoData":
                onChartPropertyChanged(propertyName, value);
                break;
        }
    }

    const onCustomerChangeablePropertyChangedFunc = (propertyName: ChartProperty) => (value: boolean) => {

        switch (propertyName) {
            case "ShowAllMeasurementsSetting":
                onChartPropertyChanged(propertyName, { ...ShowAllMeasurementsSetting, customerChangeable: value })
                break;
            case "StartDateSetting":
                onChartPropertyChanged(propertyName, { ...StartDateSetting, customerChangeable: value });
                break;
            case "EndDateSetting":
                onChartPropertyChanged(propertyName, { ...EndDateSetting, customerChangeable: value });
                break;
            case "NumberOfMeasurementsToShow":
                onChartPropertyChanged(propertyName, { ...NumberOfMeasurementsToShow, customerChangeable: value });
                break;
            case "IsPeriodCustomerChangeable":
            case "MeasureTimeCalcCustomerChangeable":
                onChartPropertyChanged(propertyName, value);
                break;
        }
    }

    const onStringPropertyChangedFunc = (propertyName: ChartProperty) => (value: string) => {
        switch (propertyName) {
            case "StartDateSetting":
                onChartPropertyChanged(propertyName, { ...StartDateSetting, Value: value });
                break;
            case "EndDateSetting":
                onChartPropertyChanged(propertyName, { ...EndDateSetting, Value: value });
                break;
        }
    }

    const onNumberPropertyChangedFunc = (propertyName: ChartProperty) => (event: React.SyntheticEvent<HTMLInputElement, Event>) => {
        switch (propertyName) {
            case "NumberOfMeasurementsToShow":
                onChartPropertyChanged(propertyName, { ...NumberOfMeasurementsToShow, value: +event.currentTarget.value });
                break;
        }
    }

    const onSelectablePropertyChangedFunc = (propertyName: ChartProperty) => (selected: OptionType) => {
        switch (propertyName) {
            case "Period":
                if (+selected.value === TimePeriod.Custom) {
                    onChartPropertyChanged(propertyName, +selected.value);
                }
                else {
                    const delta = getTimeOfPeriodInHours(+selected.value);
                    const endDate = getGeovisCurrentDateTime();
                    const startDate = getGeovisDateTimeShift(new Date(), -delta, 'hours');

                    onChartPropertiesChanged({
                        StartDateSetting: { Value: startDate, customerChangeable: StartDateSetting.customerChangeable },
                        EndDateSetting: { Value: endDate, customerChangeable: EndDateSetting.customerChangeable },
                        Period: +selected.value
                    })
                }
                break;
            case "BucketReportValueKind":
            case "MeasureTimeCalcType":
                onChartPropertyChanged(propertyName, +selected.value);
                break;
        }
    }

    const getPeriodOptions = (): OptionType[] => {
        return TimePeriodList.map(val => ({ value: val, label: getTimePeriodToDescription(val) }))
    }

    const getSelectedPeriod = (): OptionType => {
        return { value: Period, label: getTimePeriodToDescription(Period) };
    }

    const getChartCalcMeasurementTypeOptions = (): OptionType[] => {
        const filteredList = ChartMeasureTimeCalcTypeList.filter(f => f !== ChartMeasureTimeCalcType.AtExactTimeslots);
        return filteredList.map(val => ({ value: val, label: getChartMeasureTimeCalcTypeToDescription(val) }))
    }

    const getSelectedChartCalcMeasureType = (): OptionType => {
        return { value: MeasureTimeCalcType, label: getChartMeasureTimeCalcTypeToDescription(MeasureTimeCalcType) }
    }

    const getInitialCreatedTimes = (): string[] => {
        const result: string[] = [];
        Times.forEach(time => {
            if (!getTimes().includes(time)) {
                result.push(time);
            }
        })
        return result;
    }

    const getTimesOptions = (): OptionType[] => {
        const result: OptionType[] = [];
        result.push(...getTimes().map<OptionType>(time => ({ value: time, label: time })));
        result.push(...getInitialCreatedTimes().map<OptionType>(time => ({ value: time, label: time })));
        return result.sort();
    }

    const getSelectedTimesOptions = (): OptionType[] => {
        return Times.map(time => ({ value: time, label: time }));
    }

    const onTimesSelectionChanged = (selected: OptionType[] | undefined) => {

        const nTimes = selected ? selected.map(op => op.value.toString()) : [];
        onChartPropertyChanged("Times", nTimes);
    }

    const onCreateNewTimesOptions = (newValue: any) => {
        // const newOption: OptionType = { value: newValue.toString(), label: newValue.toString() };
        Times.push(newValue.toString());
        onChartPropertyChanged("Times", Times);
    }

    const getTimesPlaceholder = (): string | undefined => {
        if (Times.length === 0) {
            return "Select times";
        }
        if (Times.length < 6) {
            return undefined;
        }
        else {
            return `${Times.length} selected`
        }
    }

    const labelColumnStyle = defaultChartLabelColumnStyle;
    const settingRowStyle = defaultChartRowSettingsStyle;

    return (
        <Fragment>
            {/* Show all measurements */}
            <div className="flexRowContainerLine">
                <div style={labelColumnStyle}>
                    {/*  */}
                </div>
                <div className="flexCellContainer_g1_m0">
                    <Checkbox
                        label={t("Show all measurements")}
                        isChecked={ShowAllMeasurementsSetting.value}
                        onChange={onBooleanPropertyChangedFunc("ShowAllMeasurementsSetting")}
                    />
                </div>
                <ElementSettingAllowCheckbox
                    isChecked={ShowAllMeasurementsSetting.customerChangeable}
                    onChange={onCustomerChangeablePropertyChangedFunc("ShowAllMeasurementsSetting")}
                    isDisabled={false}
                />
            </div>

            {/* Period */}
            <div className="flexRowContainerLine" style={settingRowStyle}>
                <div style={labelColumnStyle}>
                    <label htmlFor="cmbPeriod">{t("Period")}:</label>
                </div>
                <div className="flexCellContainer_g1_m0">
                    <GeovisSelect
                        id="cmbPeriod"
                        options={getPeriodOptions()}
                        value={getSelectedPeriod()}
                        onChange={onSelectablePropertyChangedFunc("Period")}
                        isCompact={true}
                        spacing='compact'
                        isDisabled={ShowAllMeasurementsSetting.value}
                    />
                </div>
                {ElementSettingAllowCheckbox({
                    isChecked: IsPeriodCustomerChangeable,
                    onChange: onCustomerChangeablePropertyChangedFunc("IsPeriodCustomerChangeable"),
                    isDisabled: ShowAllMeasurementsSetting.value
                })}
            </div>

            {/* Use last measurements */}
            <div className="flexRowContainerLine">
                <div style={labelColumnStyle}>
                    {/*  */}
                </div>
                <div className="flexCellContainer_g1_m0">
                    <Checkbox
                        label={t("Using the last measurement time for the report end time")}
                        isChecked={UseLastMeasurementTime}
                        isDisabled={ShowAllMeasurementsSetting.value || Period === TimePeriod.Custom}
                        onChange={onBooleanPropertyChangedFunc("UseLastMeasurementTime")}
                    />
                </div>
            </div>

            {/* Start date */}
            <div className="flexRowContainerLine" style={settingRowStyle}>
                <div style={labelColumnStyle}>
                    <label htmlFor="dtStartDateTime">{t("Start date")}:</label>
                </div>
                <div className="flexCellContainer_g1_m0">
                    <GeovisReactDateTimePicker
                        locale="en-GB"
                        key="dtStartDateTime"
                        value={StartDateSetting.Value}
                        isPairedPicker={true}
                        isThisPickerOfStartDate={true}
                        pairPickerDate={EndDateSetting.Value}
                        disabled={ShowAllMeasurementsSetting.value || Period !== TimePeriod.Custom}
                        onChange={onStringPropertyChangedFunc("StartDateSetting")} />
                </div>
                {ElementSettingAllowCheckbox({
                    isChecked: StartDateSetting.customerChangeable,
                    onChange: onCustomerChangeablePropertyChangedFunc("StartDateSetting"),
                    isDisabled: ShowAllMeasurementsSetting.value || Period !== TimePeriod.Custom
                })}
            </div>

            {/* End date */}
            <div className="flexRowContainerLine" style={settingRowStyle}>
                <div style={labelColumnStyle}>
                    <label htmlFor="dtEndDateTime">{t("End date")}:</label>
                </div>
                <div className="flexCellContainer_g1_m0">
                    <GeovisReactDateTimePicker
                        locale="en-GB"
                        key="dtEndDateTime"
                        value={EndDateSetting.Value}
                        isPairedPicker={true}
                        pairPickerDate={StartDateSetting.Value}
                        onChange={onStringPropertyChangedFunc("EndDateSetting")}
                        disabled={ShowAllMeasurementsSetting.value || Period !== TimePeriod.Custom}
                    />
                </div>
                {ElementSettingAllowCheckbox({
                    isChecked: EndDateSetting.customerChangeable,
                    onChange: onCustomerChangeablePropertyChangedFunc("EndDateSetting"),
                    isDisabled: ShowAllMeasurementsSetting.value || Period !== TimePeriod.Custom
                })}
            </div>

            {/* MEasure calc type */}
            <div className="flexRowContainerLine" style={settingRowStyle}>
                <div style={labelColumnStyle}>
                    <label htmlFor="cmbMCT">{t("Measure calculation type")}:</label>
                </div>
                <div className="flexCellContainer_g1_m0" style={{ width: '300px' }}>
                    <GeovisSelect
                        id="cmbMCT"
                        options={getChartCalcMeasurementTypeOptions()}
                        value={getSelectedChartCalcMeasureType()}
                        onChange={onSelectablePropertyChangedFunc("MeasureTimeCalcType")}
                        isCompact={true}
                        spacing='compact'
                        isDisabled={false}
                    />
                </div>
                {ElementSettingAllowCheckbox({
                    isChecked: MeasureTimeCalcCustomerChangeable,
                    onChange: onCustomerChangeablePropertyChangedFunc("MeasureTimeCalcCustomerChangeable"),
                    isDisabled: false
                })}
            </div>

            {MeasureTimeCalcType === ChartMeasureTimeCalcType.Reduce &&
                <div className="flexRowContainerLine" style={settingRowStyle}>
                    <div style={{ ...labelColumnStyle, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <label htmlFor="tfMeasurementsToShow">{t("Number of measurements to show")}:</label>
                        <Tooltip content={"Defines the number of measurements which are displayed within the above defined period "}>
                            <Button appearance={'subtle-link'} iconBefore={<EditorInfoIcon label={''} size='small' />} />
                        </Tooltip>
                    </div>
                    <div className="flexCellContainer_g1_m0" style={{ width: '300px' }}>
                        <Textfield
                            name="tfMeasurementsToShow"
                            isCompact={true}
                            value={NumberOfMeasurementsToShow.value}
                            type="number"
                            min={0}
                            onChange={onNumberPropertyChangedFunc("NumberOfMeasurementsToShow")} />
                    </div>
                </div>
            }
            {MeasureTimeCalcType === ChartMeasureTimeCalcType.DailyAt &&
                <div className="flexRowContainerLine">
                    <div style={labelColumnStyle}>
                        <label htmlFor="cmbTimes">{t("Times of measurements")}:</label>
                    </div>
                    <div className="flexCellContainer_g1_m0">
                        <CreatableSelect
                            id="cmbTimes"
                            options={getTimesOptions()}
                            value={getSelectedTimesOptions()}
                            isMulti={true}
                            onChange={onTimesSelectionChanged}
                            onCreateOption={onCreateNewTimesOptions}
                            placeholder={getTimesPlaceholder()}
                            controlShouldRenderValue={Times.length < 6}
                        />
                    </div>
                </div>
            }
            <div className="flexRowContainerLine">
                <div style={labelColumnStyle}>
                    {/*  */}
                </div>
                <div className="flexCellContainer_g1_m0">
                    <Checkbox
                        label={t("Show alarm info if no data for expected part of period")}
                        isChecked={DivideIntervalAlarmIfNoData}
                        onChange={onBooleanPropertyChangedFunc("DivideIntervalAlarmIfNoData")}
                    />
                </div>
            </div>
        </Fragment>
    );
}
