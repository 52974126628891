/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 07.02.2023
 * @description The user feedback modal dialog
 */


import { ActionProps } from "@atlaskit/modal-dialog";
import { isAllowSubmitUserFeedback } from "./tools";
import UserFeedbackDataLayer from "./UserFeedbackDataLayer";
import UserFeedbackDialogView from "./UserFeedbackDialogView";
import FeedbackAttachmentsUploadControl from "./FeedbackAttachmentsUploadControl";
import { GeovisModalDialog } from "../../../../components/editDialogs/GeovisModalDialog";

export type GeovisDialogSize = "normal" | "slim" | "wide";

interface IComponentProps {
    feedbackTitle: string;
    onCancel: () => void;
}

export const UserFeedbackModalDialog = ({
    feedbackTitle,
    onCancel,
}: IComponentProps) => (
    <UserFeedbackDataLayer initialFeedbackTitle={feedbackTitle} onCancel={onCancel}>
        {({ onSubmit, onCancel: onCancelInline, isInProgress, onAddAttachment, ...viewProps }) => {
            const actions: ActionProps[] = [
                {
                    text: 'Submit',
                    onClick: onSubmit,
                    appearance: 'primary',
                    isDisabled: !isAllowSubmitUserFeedback(viewProps.model),
                    isLoading: isInProgress
                },
                {
                    text: 'Cancel',
                    onClick: onCancelInline,
                    appearance: 'default'
                }
            ];

            return (
                <GeovisModalDialog
                    heading="Submit user feedback"
                    width="large"
                    actions={actions} 
                >
                    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <UserFeedbackDialogView {...viewProps} />
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '10px', marginTop: '10px' }}>
                                <FeedbackAttachmentsUploadControl 
                                    onAddAttachment={onAddAttachment} 
                                    alreadyUploadedAttachments={viewProps.model.AttachmentIds.length} 
                                />
                            </div>
                        </div>
                    </div>
                </GeovisModalDialog>
            );
        }}
    </UserFeedbackDataLayer>
);