import { t } from "i18next";
import { OptionType } from "@atlaskit/select";
import { Fragment } from "react";
import { getSensorValueAttributeToDescription, SensorValueAttribute } from "../../../../server/AVTService/TypeLibrary/Common/SensorValueAttribute";
import { SemiCircleChartModel } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/SemiCircleChartModel";
import { getSemiCircleParametersOrderToDescription, SemiCircleParametersOrderList } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/SemiCircleParametersOrder";
import { getSemiCirclePlotValuesModeToDescription, SemiCirclePlotValuesModeList } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/SemiCirclePlotValuesMode";
import { getIGvOptionTypeList, IGvOptionType } from "../../../select/GeovisSelect_tools";
import { defaultChartLabelColumnStyle, defaultChartRowSettingsStyle, IGeovisChartPropertyChangedProps } from "../types";
import { GeovisSelect } from "../../../select/GeovisSelect";
import { SemiCircleVisualSettings } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/SemiCircleVisualSettings";
import { ChartBooleanSettingProps, ChartTextSetting } from "../ChartTab_tools";
import { onChangeTextPropertyDebounced } from "../tools";
import { SemiCircleType } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/SemiCircleType";
import { getSemiCircleGroupsOrderModeToDescription, SemiCircleGroupsOrderModeList } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/SemiCircleGroupsOrderMode";

type ISemiCircleVisualSettingsProps = IGeovisChartPropertyChangedProps<SemiCircleChartModel>

export const SemiCircleChartVisualSettings = ({ chart, onChartPropertyChanged }: ISemiCircleVisualSettingsProps) => {
    const { VisualSettings } = chart;

    const labelColumnStyle = defaultChartLabelColumnStyle;
    const settingsRowStyle = defaultChartRowSettingsStyle;

    const parametersList = [
        SensorValueAttribute.ValuesXYZ,
        SensorValueAttribute.AxisReference1,
        SensorValueAttribute.AxisReference2,
        SensorValueAttribute.AxisReference3,
        SensorValueAttribute.Km,
        SensorValueAttribute.LocationNorth,
        SensorValueAttribute.LocationEast,
        SensorValueAttribute.LocationHeight,
        SensorValueAttribute.Param1,
        SensorValueAttribute.Param2,
        SensorValueAttribute.Param3,
        SensorValueAttribute.Param4,
        SensorValueAttribute.Param5,
    ]

    const sensorValueToString = (attribute: SensorValueAttribute): string => {
        switch (attribute) {
            case SensorValueAttribute.ValuesXYZ:
                return t("Value");
            default:
                return getSensorValueAttributeToDescription(attribute)
        }
    }

    const parameterOptions = getIGvOptionTypeList(parametersList, sensorValueToString);
    const selectedSensorParameterOption = parameterOptions.find(o => o.value === VisualSettings.SensorParameter);

    const parameterOrderOptions = getIGvOptionTypeList(SemiCircleParametersOrderList, getSemiCircleParametersOrderToDescription);
    const selectedSensorParameterOrderOption = parameterOrderOptions.find(o => o.value === VisualSettings.ParametersOrder);

    const plotValuesOptions = getIGvOptionTypeList(SemiCirclePlotValuesModeList, getSemiCirclePlotValuesModeToDescription);
    const selectedPlotValuesOption = plotValuesOptions.find(o => o.value === VisualSettings.PlotValuesMode);

    const groupOrderOptions = getIGvOptionTypeList(SemiCircleGroupsOrderModeList, getSemiCircleGroupsOrderModeToDescription);
    const selectedGroupOrderOption = groupOrderOptions.find(o => o.value === VisualSettings.OrderGroupsMode);

    const onValuePropertyChangedFunc = (propertyName: keyof SemiCircleVisualSettings) => (value: any) => {

        const changes: Partial<SemiCircleVisualSettings> = {};
        changes[propertyName] = value;

        onChartPropertyChanged<SemiCircleVisualSettings>("VisualSettings", { ...VisualSettings, ...changes });
    }

    const onSelectedPropertyChanged = (propertyName: keyof SemiCircleVisualSettings) => (option: IGvOptionType<number>) => {
        onValuePropertyChangedFunc(propertyName)(option.value);
    }

    const onChangeStringProperty = (propertyName: keyof SemiCircleVisualSettings) => (v1: string) => {
        onChangeTextPropertyDebounced(v1, (v2) => {
            onValuePropertyChangedFunc(propertyName)(v2);
        });
    }

    const getLabelPlacementOptions = (): OptionType[] => {
        return [{
            label: t("Draw labels inside the circle"),
            value: 0
        }, {
            label: t("Draw labels outside of the circle"),
            value: 1
        }]
    }

    const getSelectedLabelPlacementOption = (): OptionType => (
        chart.DrawLabelsOutside ? getLabelPlacementOptions()[1] : getLabelPlacementOptions()[0]
    )

    const onSelectedLabelPlacementOptionChanged = (selected: OptionType) => {
        onChartPropertyChanged("DrawLabelsOutside", +selected.value !== 0)
    }

    const customTooltipInfo = () => (
        <div>
            <div>{t("Title which be written in the center of the chart. With support of")}:</div>
            <div>{"<Km>"}</div>
            <div>{"<Param{1-5}>"}</div>
            <div>{"<Reference>"}</div>
            <div>{"<AxisReference>"}</div>
            <div>{"<Offset>"}</div>
            <div>{"<AxisOffset>"}</div>
            <div>{"<SensorName>"}</div>
            <div>{"<CustomerName>"}</div>
        </div>
    )

    const isSensorValues = chart.SemiCircleType === SemiCircleType.SensorValues;
    const isGrouping = chart.SemiCircleType === SemiCircleType.Grouping;
    const isSuccessMeas = chart.SemiCircleType === SemiCircleType.SuccessfulMeasurementRate;

    return (
        <div className='geovisChartTab'>
            {isSensorValues &&
                <div className="flexRowContainerLine" style={settingsRowStyle}>
                    <div style={labelColumnStyle}>
                        <label htmlFor="cmbParameter" >{t("Sensor position parameter")}:</label>
                    </div>
                    <div className="flexCellContainer_g1_m0" style={{ display: 'flex', gap: "5px" }}>
                        <div style={{ width: '100%' }}>
                            <GeovisSelect
                                id="cmbParameter"
                                options={parameterOptions}
                                value={selectedSensorParameterOption}
                                spacing='compact'
                                onChange={onSelectedPropertyChanged("SensorParameter")}
                            />
                        </div>
                        <div style={{ width: '100%' }}>
                            <GeovisSelect
                                options={parameterOrderOptions}
                                value={selectedSensorParameterOrderOption}
                                spacing='compact'
                                onChange={onSelectedPropertyChanged("ParametersOrder")}
                            />
                        </div>
                    </div>
                </div>
            }

            {isSensorValues &&
                <ChartBooleanSettingProps
                    label={t("Calculate sensor segment length out of sensor parameter")}
                    isChecked={VisualSettings.CalculateDistanceByParameter}
                    onChange={onValuePropertyChangedFunc("CalculateDistanceByParameter")}
                    isDisabled={false}
                />
            }
            {isGrouping &&
                <div className="flexRowContainerLine" style={settingsRowStyle}>
                    <div style={labelColumnStyle}>
                        <label htmlFor="cmbParameter" >{t("Sensor position parameter")}:</label>
                    </div>
                    <div className="flexCellContainer_g1_m0" style={{ display: 'flex', gap: "5px" }}>
                        <div style={{ width: '100%' }}>
                            <GeovisSelect
                                id="cmbParameter"
                                options={groupOrderOptions}
                                value={selectedGroupOrderOption}
                                spacing='compact'
                                onChange={onSelectedPropertyChanged("OrderGroupsMode")}
                            />
                        </div>
                    </div>
                </div>
            }

            <ChartTextSetting
                labelColumnStyle={labelColumnStyle}
                labelText={t("Start angle")}
                settingsStyle={settingsRowStyle}
                value={VisualSettings.StartAngle}
                type="number"
                htmlName="tbStartAngle"
                onChangeTextField={onChangeStringProperty("StartAngle")}
                tooltipContent={t("Start angle of circle")} />

            <ChartTextSetting
                labelColumnStyle={labelColumnStyle}
                labelText={t("End angle")}
                settingsStyle={settingsRowStyle}
                value={VisualSettings.EndAngle}
                type="number"
                htmlName="tbEndAngle"
                onChangeTextField={onChangeStringProperty("EndAngle")}
                tooltipContent={t("End angle of circle")} />

            <ChartTextSetting
                labelColumnStyle={labelColumnStyle}
                labelText={t("Center title")}
                settingsStyle={settingsRowStyle}
                value={VisualSettings.CenterTitle}
                htmlName="tbTitle"
                onChangeTextField={onChangeStringProperty("CenterTitle")}
                tooltipContent={isSuccessMeas ? customTooltipInfo() : t("Title which be written in the center of the chart")} />

            <ChartTextSetting
                labelColumnStyle={labelColumnStyle}
                labelText={t("Filling of circle [%]")}
                settingsStyle={settingsRowStyle}
                value={VisualSettings.InnerCircleSize}
                type="number"
                htmlName="tbCircleSize"
                onChangeTextField={onChangeStringProperty("InnerCircleSize")}
                tooltipContent={t("Thickness of the circle in percents")} />

            <fieldset style={{ border: '1px #ccc solid', display: 'flex', flexDirection: 'column', gap: '7px', padding: '5px' }}>
                <legend style={{ fontSize: '16px' }}>{t("Label settings")}</legend>
                <Fragment>
                    {isSensorValues && <ChartBooleanSettingProps
                        label={t("Show sensor names")}
                        isChecked={VisualSettings.ShowSensorsNames}
                        onChange={onValuePropertyChangedFunc("ShowSensorsNames")}
                        isDisabled={false}
                    />
                    }
                    {isSensorValues &&
                        <ChartBooleanSettingProps
                            label={t("Show values")}
                            isChecked={VisualSettings.PlotValuesInCircle}
                            onChange={onValuePropertyChangedFunc("PlotValuesInCircle")}
                            isDisabled={false}
                        />
                    }
                    {isSensorValues &&
                        <div className="flexRowContainerLine">
                            <div style={{ width: '30%' }}>
                                <ChartBooleanSettingProps
                                    label={t("Show position parameters")}
                                    isChecked={VisualSettings.PlotParametersInCircle}
                                    onChange={onValuePropertyChangedFunc("PlotParametersInCircle")}
                                    isDisabled={false}
                                />
                            </div>
                            <div style={{ width: '70%' }}>
                                <GeovisSelect
                                    options={plotValuesOptions}
                                    value={selectedPlotValuesOption}
                                    spacing='compact'
                                    onChange={onSelectedPropertyChanged("PlotValuesMode")}
                                />
                            </div>
                        </div>
                    }
                    {(isGrouping || isSuccessMeas) &&
                        <ChartBooleanSettingProps
                            label={t("Show group name")}
                            isChecked={VisualSettings.PlotGroupNameInCircle}
                            onChange={onValuePropertyChangedFunc("PlotGroupNameInCircle")}
                            isDisabled={false}
                        />
                    }
                    {(isGrouping || isSuccessMeas) &&
                        <ChartBooleanSettingProps
                            label={t("Show amount per group")}
                            isChecked={VisualSettings.PlotAmountPerGroup}
                            onChange={onValuePropertyChangedFunc("PlotAmountPerGroup")}
                            isDisabled={false}
                        />
                    }
                    <div className="flexRowContainerLine" style={settingsRowStyle}>
                        <div style={labelColumnStyle}>
                            <label htmlFor="cmbParameter" >{t("Label position")}:</label>
                        </div>
                        <div className="flexCellContainer_g1_m0" style={{ width: '70%' }}>
                            <GeovisSelect
                                id="cmbParameter"
                                options={getLabelPlacementOptions()}
                                value={getSelectedLabelPlacementOption()}
                                spacing='compact'
                                onChange={onSelectedLabelPlacementOptionChanged}
                            />
                        </div>
                    </div>
                </Fragment>

            </fieldset>



        </div>
    )
}