import Button from '@atlaskit/button';
import Checkbox from '@atlaskit/checkbox';
import InfoIcon from '@atlaskit/icon/glyph/info';
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close'
import { OptionType } from '@atlaskit/select';
import Popup from '@atlaskit/popup';
import TextArea from '@atlaskit/textarea';
import Textfield from "@atlaskit/textfield";
import Tooltip from '@atlaskit/tooltip';
import { debounce } from "lodash";
import { CSSProperties, RefObject, SyntheticEvent, useState } from "react";
import { Placement } from '@atlaskit/popper';
import { GeovisFieldFailInfo } from "../../server/Geovis/ClientServerIntermediate/GeovisFieldFailInfo";
import { GeovisCheckboxSelect, GeovisSelect, GeovisSelectMulti } from '../select/GeovisSelect';
import { GeovisFieldValidationError } from "../validationErrors/GeovisFieldValidationError";
import { GeovisUserCommonInfo } from '../../server/GEOvis3/Model/User/GeovisUserCommonInfo';
import { CompanyInfo } from '../../server/GEOvis3/Model/Company/CompanyInfo';
import { GeovisUsersSelect } from '../select/GeovisUserSelect';
import GeovisColorPicker from '../GeovisColorPicker';

const defaultEditFieldLabelColumnStyle: CSSProperties = { width: '30%' };
const defaultEditFieldRowSettingsStyle: CSSProperties = { alignItems: 'center', gap: '5px', gridGap: '5px' };

interface ITextEditFieldSettingProps {
    labelColumnStyle?: CSSProperties;
    settingsStyle?: CSSProperties;
    popupContainerStyle?: CSSProperties;
    labelText?: string;
    value: string | number;
    type?: string,
    htmlName: string;
    tooltipContent?: React.ReactNode;
    popupContent?: React.ReactNode;
    underControlContent?: React.ReactNode;
    isDisabled?: boolean;
    min?: number;
    validationError?: GeovisFieldFailInfo;
    hideLabel?: boolean;
    isMultiLine?: boolean;
    lineCount?: number;
    max?: number;
    popupHeaderText?: string;
    popupPlacement?: Placement
    isReadonly?: boolean
    reference?: RefObject<HTMLInputElement>
    key?: string;

    onChangeTextField?: (text: string) => void;
    onInfoButtonClick?: () => void;
}

export const TextEditField = ({
    labelColumnStyle,
    labelText,
    value,
    type,
    htmlName,
    isDisabled,
    settingsStyle,
    tooltipContent,
    onChangeTextField,
    validationError,
    min,
    hideLabel,
    isMultiLine,
    max,
    popupContent,
    popupContainerStyle,
    popupHeaderText,
    popupPlacement,
    isReadonly,
    lineCount,
    underControlContent,
    reference,
    key,
    onInfoButtonClick
}: ITextEditFieldSettingProps) => {

    const onTextChangedHandlerDebounced = debounce((textValue: string) => {
        if (onChangeTextField) {
            onChangeTextField(textValue)
        }
    }, 500);

    const onTextChangedHandlerCallback = ({ currentTarget }: SyntheticEvent<HTMLInputElement>) => onTextChangedHandlerDebounced(currentTarget.value);

    const onTextAreaChangedHandlerCallback = ({ currentTarget }: React.ChangeEvent<HTMLTextAreaElement>) => onTextChangedHandlerDebounced(currentTarget.value);

    return (
        <div className="flexRowContainerLine" style={{ ...defaultEditFieldRowSettingsStyle, ...settingsStyle }}>
            {!hideLabel &&
                <div className="flexCellContainer" style={labelColumnStyle ?? defaultEditFieldLabelColumnStyle}>
                    <label htmlFor={htmlName}>{labelText ?? ""}:</label>
                </div>
            }
            <div className="flexCellContainer_g1">
                {!isMultiLine &&
                    <Textfield
                        name={htmlName}
                        key={key ?? htmlName}
                        isCompact={true}
                        defaultValue={value}
                        isDisabled={isDisabled}
                        type={type}
                        isReadOnly={isReadonly}
                        max={type === 'number' ? max ?? 6 : undefined}
                        ref={reference}
                        min={min !== undefined ? min : type === 'number' ? 0 : undefined}
                        onChange={onTextChangedHandlerCallback} />
                }
                {isMultiLine &&
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <TextArea
                            name={htmlName}
                            key={htmlName}
                            isReadOnly={isReadonly}
                            defaultValue={value.toString()}
                            minimumRows={lineCount ?? 3}
                            onChange={onTextAreaChangedHandlerCallback}
                            isDisabled={isDisabled} />
                        <div style={{ width: "100%" }}>
                            {underControlContent}
                        </div>
                    </div>
                }
                {validationError && (
                    <GeovisFieldValidationError validationError={validationError} />
                )}
            </div>
            {tooltipContent && (
                <div className="flexCellContainer">
                    <EditFieldTooltip content={tooltipContent} />
                </div>
            )}
            {popupContent &&
                <div className="flexCellContainer">
                    <EditFieldPopup content={popupContent} popupContainerStyle={popupContainerStyle} popupHeaderText={popupHeaderText} popupPlacement={popupPlacement} />
                </div>
            }
            {onInfoButtonClick &&
                <div className="flexCellContainer">
                    <EditFieldButton onClick={onInfoButtonClick} />
                </div>
            }
        </div>
    )
}

interface IEditFieldTooltip {
    content?: React.ReactNode;
}

export const EditFieldTooltip = ({
    content
}: IEditFieldTooltip) => (
    <Tooltip
        content={content}>
        <Button
            appearance='subtle-link'
            iconBefore={<InfoIcon label="Information" size='small' primaryColor="#42526E" />}
            tabIndex={-1}
        />
    </Tooltip>
)

interface IEditFieldButton {
    onClick: () => void;
}

export const EditFieldButton = ({ onClick }: IEditFieldButton) => (
    <Button
        appearance='subtle-link'
        iconBefore={<InfoIcon label="Information" size='small' primaryColor="#42526E" />}
        onClick={onClick}
    />
)

interface IEditFieldPopup {
    content?: React.ReactNode;
    popupContainerStyle?: CSSProperties;
    popupHeaderText?: string;
    popupPlacement?: Placement
}

export const EditFieldPopup = ({
    content,
    popupContainerStyle,
    popupHeaderText,
    popupPlacement
}: IEditFieldPopup) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const containerStyle: CSSProperties = { padding: '10px', display: 'flex', flexDirection: 'column', ...popupContainerStyle }

    const placement = popupPlacement ?? 'right'

    return (
        <Popup
            isOpen={isOpen}
            //onClose={() => setIsOpen(false)}
            placement={placement}
            zIndex={999}
            content={() =>
                <div style={containerStyle}>
                    <div style={{ display: 'flex', flexDirection: 'row', borderBottom: 'solid 1px', marginBottom: '10px' }}>
                        <div style={{ width: '100%', flexGrow: 2 }}>
                            <span style={{ fontWeight: 'bold' }}>{popupHeaderText ?? "Info"}</span>
                        </div>
                        <div>
                            <Button
                                onClick={() => setIsOpen(false)}
                                iconBefore={<EditorCloseIcon label='close' />}
                                appearance='subtle-link'
                                spacing='compact'
                            />
                        </div>
                    </div>
                    {content}
                </div>
            }
            trigger={(triggerProps) => (
                <Button
                    {...triggerProps}
                    isSelected={isOpen}
                    appearance='subtle-link'
                    iconBefore={<InfoIcon label="Information" size='small' primaryColor="#42526E" />}
                    onClick={() => setIsOpen(!isOpen)}
                />
            )}
        />
    );
}

interface IBoolEditFieldProps {
    label: string;
    isChecked: boolean;
    isDisabled?: boolean;
    tooltipContent?: React.ReactNode;

    onChange: (value: boolean) => void;
}

export const BoolEditField = ({
    label,
    isChecked,
    isDisabled,
    tooltipContent,
    onChange
}: IBoolEditFieldProps) => {
    const onChangeHandler = (event: SyntheticEvent<HTMLInputElement>) => {
        onChange(event.currentTarget.checked);
    }

    return (
        <div className="flexCellContainer" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '0px' }}>
            <Checkbox
                label={label}
                isChecked={isChecked}
                isDisabled={isDisabled}
                onChange={onChangeHandler} />
            {tooltipContent && (
                <div className="flexCellContainer">
                    <EditFieldTooltip content={tooltipContent} />
                </div>
            )}
        </div>
    )
}

interface ISingleSelectEditFieldProps {
    label: string;
    options: OptionType[];
    value?: OptionType;
    labelColumnStyle?: CSSProperties;
    settingsStyle?: CSSProperties;
    tooltipContent?: React.ReactNode;
    isDisabled?: boolean;

    onValueChanged: (value: OptionType) => void;
}

export const SingleSelectEditField = ({
    label,
    onValueChanged,
    options,
    value,
    labelColumnStyle,
    settingsStyle,
    tooltipContent,
    isDisabled
}: ISingleSelectEditFieldProps) => {

    const onSelectPropertyChanged = (option: OptionType) => {
        if (option) {
            onValueChanged(option);
        }
    }


    return (
        <div className="flexRowContainerLine" style={{ ...defaultEditFieldRowSettingsStyle, ...settingsStyle }}>
            <div className="flexCellContainer" style={labelColumnStyle ?? defaultEditFieldLabelColumnStyle}>
                <label htmlFor="cmbValuesSingleEditor" >{label}:</label>
            </div>
            <div className="flexCellContainer_g1">
                <GeovisSelect
                    id="cmbValuesSingleEditor"
                    options={options}
                    value={value}
                    spacing='compact'
                    isDisabled={isDisabled}
                    onChange={onSelectPropertyChanged}
                />
            </div>
            {tooltipContent && (
                <div className="flexCellContainer">
                    <EditFieldTooltip content={tooltipContent} />
                </div>
            )}
        </div>
    )
}

interface IMultiSelectEditFieldProps {
    label: string;
    options: OptionType[];
    value: OptionType[];
    labelColumnStyle?: CSSProperties;
    settingsStyle?: CSSProperties;
    tooltipContent?: React.ReactNode;
    isCheckbox?: boolean
    isDisabled?: boolean

    onValueChanged: (value: OptionType[] | undefined) => void;
}

export const MultiSelectEditField = ({
    label,
    onValueChanged,
    options,
    value,
    labelColumnStyle,
    settingsStyle,
    tooltipContent,
    isCheckbox,
    isDisabled
}: IMultiSelectEditFieldProps) => {

    const onSelectPropertyChanged = (option: OptionType[] | undefined) => {
        onValueChanged(option);
    }


    return (
        <div className="flexRowContainerLine" style={{ ...defaultEditFieldRowSettingsStyle, ...settingsStyle }}>
            <div className="flexCellContainer" style={labelColumnStyle ?? defaultEditFieldLabelColumnStyle}>
                <label htmlFor="cmbValuesMultiEditor" >{label}:</label>
            </div>
            {!isCheckbox &&
                <div className="flexCellContainer_g1">
                    <GeovisSelectMulti
                        id="cmbValuesMultiEditor"
                        options={options}
                        value={value}
                        spacing='compact'
                        onChange={onSelectPropertyChanged}
                    />
                </div>
            }
            {isCheckbox &&
                <div className="flexCellContainer_g1">
                    <GeovisCheckboxSelect
                        id="cmbValuesMultiEditor"
                        options={options}
                        value={value}
                        spacing='compact'
                        isDisabled={isDisabled}
                        onChange={onSelectPropertyChanged}
                    />
                </div>
            }
            {tooltipContent && (
                <div className="flexCellContainer">
                    <EditFieldTooltip content={tooltipContent} />
                </div>
            )}
        </div>
    )
}

interface IEditColorFieldProps {
    color: string;
    label: string;
    isDisabled?: boolean;
    labelColumnStyle?: CSSProperties;
    settingsStyle?: CSSProperties;
    tooltipContent?: React.ReactNode;
    width?: string;
    height?: string
    onChange: (color: string) => void;
}

export const EditColorField = ({
    color,
    isDisabled,
    label,
    labelColumnStyle,
    settingsStyle,
    tooltipContent,
    height,
    width,
    onChange
}: IEditColorFieldProps) => {

    const onColorChanged = (result: string) => {
        onChange(result)
    }

    return (
        <div className="flexRowContainerLine" style={{ ...defaultEditFieldRowSettingsStyle, ...settingsStyle }}>
            <div className="flexCellContainer" style={labelColumnStyle ?? defaultEditFieldLabelColumnStyle}>
                <label htmlFor="colorPikerEditor" >{label}:</label>
            </div>
            <div className="flexCellContainer_g1">
                <GeovisColorPicker
                    key="colorPikerEditor"
                    width={width ?? "100%"}
                    height={height ?? '100%'}
                    hexColor={color}
                    onColorChange={onColorChanged}
                    isDisabled={isDisabled}
                />
            </div>
            {tooltipContent && (
                <div className="flexCellContainer">
                    <EditFieldTooltip content={tooltipContent} />
                </div>
            )}
        </div>
    )

}

interface IUsersSelectFieldProps {
    users: GeovisUserCommonInfo[];
    companies: CompanyInfo[];
    isMulti?: boolean;
    onChange: (selectedUsers: string[]) => void;
    placeholder?: string;
    renderContent?: boolean;
    value: string[];
    isDisabled?: boolean;
    label: string;
    labelColumnStyle?: CSSProperties;
    settingsStyle?: CSSProperties;
    tooltipContent?: React.ReactNode;
}

export const UsersSelectEditField = ({
    companies,
    label,
    onChange,
    users,
    value,
    isDisabled,
    isMulti,
    labelColumnStyle,
    placeholder,
    renderContent,
    settingsStyle,
    tooltipContent
}: IUsersSelectFieldProps) => {

    return (
        <div className="flexRowContainerLine" style={{ ...defaultEditFieldRowSettingsStyle, ...settingsStyle }}>
            <div className="flexCellContainer" style={labelColumnStyle ?? defaultEditFieldLabelColumnStyle}>
                <label htmlFor="cmbValuesSingleEditor" >{label}:</label>
            </div>
            <div className="flexCellContainer_g1">
                <GeovisUsersSelect
                    companies={companies}
                    onChange={onChange}
                    users={users}
                    value={value}
                    isDisabled={isDisabled}
                    isMulti={isMulti}
                    placeholder={placeholder}
                    renderContent={renderContent}
                />
            </div>
            {tooltipContent && (
                <div className="flexCellContainer">
                    <EditFieldTooltip content={tooltipContent} />
                </div>
            )}
        </div>
    )
}