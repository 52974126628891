/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 25.08.2022
 * @description Tool methods for chart renders
 */

import chroma from "chroma-js";
import { formattedDateTime } from "../../../../../helpers/DateHelper";
import { ChartType } from "../../../../../server/AVTService/TypeLibrary/Common/ChartType";
import { GeovisChartData } from "../../../../../server/AVTService/TypeLibrary/Computation/GeovisChartData";
import { GeovisChartModel } from "../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartModel";
import { HeatmapTemperatureColorEntry } from "../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/HeatmapTemperatureColorEntry";
import { SemiCircleValuesSettings } from "../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/SemiCircleValuesSettings";
import { SemiCircleChartDataModel } from "../../../../../server/AVTService/TypeLibrary/Model/SemiCircleChartDataModel";
import { IGeovisChartReportData, IGeovisChartReportInfo, IGeovisReportSettings } from "../../../../../store/projectReport.types";

/**
 * 
 * @param reportSettings 
 * @param pageNum 
 * @param chartId 
 * @returns 
 */
export const getGeovisChartInfo = (reportSettings: IGeovisReportSettings, pageNum: number, chartId: number): IGeovisChartReportInfo<GeovisChartModel> | undefined => {

    const page = reportSettings.geovisPages.get(pageNum);
    if (!page) {
        return undefined;
    }

    return page.geovisCharts.get(chartId);
}

export const getChartDataIsLoading = (reportSettings: IGeovisReportSettings, pageNum: number, chartId: number, isVibrationEvent: boolean): boolean | undefined => {
    const pageData = reportSettings.geovisPagesData.get(pageNum);
    if (!pageData) {
        return undefined;
    }
    const chartData = isVibrationEvent ? pageData.geovisEventChartsData.get(chartId) : pageData.geovisChartsData.get(chartId);
    if (!chartData) {
        return undefined;
    }

    return chartData.isLoading;
}

/**
 * Get chart type
 * @param pageNum
 * @param chartId 
 * @param reportSettings
 * @returns 
 */
export const getChartType = (pageNum: number, chartId: number, reportSettings: IGeovisReportSettings): ChartType | undefined => {
    const chartInfo = getGeovisChartInfo(reportSettings, pageNum, chartId);
    if (!chartInfo) {
        return undefined;
    }

    return chartInfo.Chart.Type;
}

/**
 * Get geovis chart data
 * @param reportSettings 
 * @param pageNum 
 * @param chartId 
 * @returns 
 */
export const getGeovisChartData = <TData extends GeovisChartData>(reportSettings: IGeovisReportSettings, pageNum: number, chartId: number): IGeovisChartReportData<TData> | undefined => {
    const page = reportSettings.geovisPagesData.get(pageNum);
    if (!page) {
        return undefined;
    }

    return page.geovisChartsData.get(chartId) as IGeovisChartReportData<TData>;
}

const TimeslotTemplate = "{timeslot}";

export const processTitleTimeslotTemplate = (title: string, timeslotValue: string) => {
    const formattedTimeslot = formattedDateTime(timeslotValue);
    return title.replace(TimeslotTemplate, formattedTimeslot);
}

/**
 * Update the chart title in the report if needed
 * @param chart 
 * @returns 
 */
export const getReportChartTitle = (chart: GeovisChartModel, globalTimeslot: string | undefined) => {
    const { Title, Type } = chart;
    return (Type === ChartType.HeatMap || Type === ChartType.SemiCircle) && globalTimeslot ? processTitleTimeslotTemplate(Title, globalTimeslot) : Title;
}

export const getDefaultChromaGradient = () => {
    return chroma.scale(defaultPaletteStops().map(p => p[1])).domain(defaultPaletteStops().map(p => p[0]));
}

export const defaultPaletteStops = (): Array<[number, string]> => [
    [0, '#3060cf'],
    [0.5, '#fffbbc'],
    [1, '#c4463a']
]

const sortColorTempEntries = (a: HeatmapTemperatureColorEntry, b: HeatmapTemperatureColorEntry): number => {
    if (a.Temperature === b.Temperature) return 0;
    return a.Temperature > b.Temperature ? 1 : -1;
}

export interface IColorPalette {
    min: number;
    max: number;
    palette: any;
    colors: Array<[number, string]>;
}

export const getSemiCirclePaletteData = (valueSettings: SemiCircleValuesSettings, data: SemiCircleChartDataModel[]): IColorPalette => {
    const {
        TemperatureColorSettings
    } = valueSettings;

    const {
        ColorEntries,
        EnableCustomColorScheme
    } = TemperatureColorSettings;

    if (EnableCustomColorScheme && ColorEntries.length > 0) {
        const colorsSortByValue = ColorEntries.sort((a, b) => sortColorTempEntries(a, b));
        const minValue = +colorsSortByValue[0].Temperature;
        const maxValue = +colorsSortByValue[colorsSortByValue.length - 1].Temperature;
        const range = maxValue - minValue;
        const palette = chroma.scale(colorsSortByValue.map(c => c.ColorHex)).domain(colorsSortByValue.map(c => (c.Temperature - minValue) / range));
        const colors: Array<[number, string]> = colorsSortByValue.map(c => ([c.Temperature, c.ColorHex]));

        return ({ min: minValue, max: maxValue, palette, colors });
    }
    else {
        const noEmptyData = data.filter(d => d.Data !== undefined && d.Data !== null);
        const min = valueSettings.MinValue.auto ? Math.min(...noEmptyData.map(d => d.Data!)) : valueSettings.MinValue.value;
        const max = valueSettings.MaxValue.auto ? Math.max(...noEmptyData.map(d => d.Data!)) : valueSettings.MaxValue.value;
        const palette = getDefaultChromaGradient();

        return ({ min, max, palette, colors: defaultPaletteStops() });
    }
}