/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 09.11.2022
 * @description Geovis4Table cell renderer
 */

import Core from 'handsontable/core';
import { IGeovis4TableCellProperties } from '../../../../../components/projectOverview/geovis4table/types';
import { Geovis4Table } from '../../../../../server/AVTService/TypeLibrary/Model/Geovis4Tables/Geovis4Table';
import { Geovis4TableTextDirectionMode } from '../../../../../server/AVTService/TypeLibrary/Model/Geovis4Tables/Geovis4TableTextDirectionMode';


export const Geovis4TableHotCellRenderAlias = "Geovis4TableHotCellRender";
// const paddingTD = 4;

export const Geovis4TableHotCellRenderFactory = (table: Geovis4Table, isPrinting: boolean) => (instance: Core, TD: HTMLTableCellElement,
    row: number, col: number, prop: string | number, value: any, cellProperties: IGeovis4TableCellProperties): void => {

    const classNames: string[] = [];

    if (cellProperties.className) {
        if (typeof cellProperties.className === 'string') {
            classNames.push(...cellProperties.className.split(' '));
        }
        else {
            classNames.push(...(cellProperties.className as []));
        }
    }

    if (cellProperties.readOnly && cellProperties.readOnlyCellClassName) {
        classNames.push(cellProperties.readOnlyCellClassName);
    }

    TD.className = classNames.join(' ');

    if (cellProperties.fontSize) {
        TD.style.fontSize = cellProperties.fontSize;
    }
    if (cellProperties.fontColor) {
        TD.style.color = cellProperties.fontColor;
    }
    if (cellProperties.backgroundColor) {
        TD.style.backgroundColor = cellProperties.backgroundColor;
    }

    if (isPrinting) {
        TD.style.border = '0';
    }

    if (cellProperties.borders) {
        const isLeftBorder = cellProperties.borders.left.visible;
        const isTopBorder = cellProperties.borders.top.visible;
        const isRightBorder = cellProperties.borders.right.visible;
        const isBottomBorder = cellProperties.borders.bottom.visible;

        if (isLeftBorder) {
            TD.style.borderLeft = `${cellProperties.borders.left.width}px solid #000000`;

        }
        if (isTopBorder) {
            TD.style.borderTop = `${cellProperties.borders.top.width}px solid #000000`;
        }
        if (isRightBorder) {
            TD.style.borderRight = `${cellProperties.borders.right.width}px solid #000000`;
        }
        if (isBottomBorder) {
            TD.style.borderBottom = `${cellProperties.borders.bottom.width}px solid #000000`;
        }
    }

    if (!value) {
        value = '';
    } else {
        value = value.toString().replace('\n', "</br>"); // support line break
    }

    if (cellProperties.textDirection === Geovis4TableTextDirectionMode.ToBottom ||
        cellProperties.textDirection === Geovis4TableTextDirectionMode.ToTop) {

        //const ttt = table;

        const height = Math.max(...[instance.getRowHeight(row), TD.scrollHeight]);
        // const height = TD.scrollHeight;
        // if table width is stretched then cell width not equal column width
        // const width = instance.getColWidth(col);
        const width = TD.scrollWidth;

        TD.textContent = "";
        TD.style.verticalAlign = "top";
        // TD.style.overflow = "hidden";
        // TD.style.height = `${height}px`;
        // TD.style.width = `${width}px`;
        TD.innerHTML = getChildNodesForDirection(value, cellProperties.textDirection, height, width, classNames);

        // const span: any = TD.children[0];
        // const marginTop = (span.scrollWidth - span.scrollHeight) / 2;
        // span.style.marginTop = `${marginTop}px`;
    }
    else {
        TD.innerHTML = `<div>${value}</div>`;
    }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getChildNodesForDirection = (value: any, textDirection: Geovis4TableTextDirectionMode, height: number, width: number, classNames: string[]) => {
    const className =
        textDirection === Geovis4TableTextDirectionMode.ToTop ? 'htDirectionTop' :
            textDirection === Geovis4TableTextDirectionMode.ToBottom ? 'htDirectionBottom' :
                "";

    const spanHeight = width;
    const spanWidth = height;

    const marginLeft = (width - height) / 2 - 4;
    const marginTop = (height - width) / 2 - 4;

    const valueStyle = classNames.includes("htUnderline") ? ' style="text-decoration: underline;"' : ""

    const divHtml = `
        <div 
            class="${className}" 
            style="margin-left: ${marginLeft}px; margin-top: ${marginTop}px; height: ${spanHeight}px; width: ${spanWidth}px;"
        >
            <div${valueStyle}>${value}</div>
        </div>`;

    return divHtml;
}