//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { ProjectViewBase } from '../../../AVTService/TypeLibrary/ProjectViews/ProjectViewBase';
import { MapViewport } from '../../../MapViewport';
import { ProjectViewAlarmInfo } from './ProjectViewAlarmInfo';
import { SensorsMovementSettings } from '../../../AVTService/TypeLibrary/Map/SensorsMovementSettings';
import { MapProviders } from '../../../AVTService/TypeLibrary/Map/MapProviders';
import { SensorValueAttribute } from '../../../AVTService/TypeLibrary/Common/SensorValueAttribute';

export class ProjectViewInfo extends ProjectViewBase
{
	public DefaultMapData: MapViewport;
	public AlarmsInfo: ProjectViewAlarmInfo[];
	public DocumentsIds: string[];
	public LogbookIds: number[];
	public LeftMovementSettings: SensorsMovementSettings;
	public RightMovementSettings: SensorsMovementSettings;
	public ShowNameOfSensors: boolean;
	public UseSensorColorFoName: boolean;
	public FontSize: number;
	public UseBordersForSensorText: boolean;
	public LeftMapProvider: MapProviders;
	public RightMapProvider: MapProviders;
	public SensorsIds: string[];
	public ChainIds: string[];
	public UseSensorColor: boolean;
	public Rasters: string[];
	public AdditionalAttributes: SensorValueAttribute[];
}
