/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 09.06.2023
 * @description Map section local map objects data layer
 * It loads only LMOs' of the MapSection
 */

import { useEffect, Fragment, useState } from "react";
import { MapSectionModel } from "../../../../server/AVTService/TypeLibrary/Model/MapSectionModel"
import { IWithGeovisServerProps, withGeovisServer } from "../../../../helpers/GeovisHooks";
import { ILocalMapObjectsStorage } from "../../../../store/data.types";
import { localMapObjectsDataStorageInitialState } from "../../../../store/reducers/dataReducer";
import ServerRoutesGen from "../../../../server/Routes/ServerRoutesGen";
import { fetchServerElementsByPost } from "../../../../helpers/ProjectDataHelper";
import { LocalMapObject } from "../../../../server/AVTService/TypeLibrary/LocalMapObjects/LocalMapObject";
import { processFetchedData } from "../../../../store/helpers/DataHelper";
import { elementsToMap, mapToListOfElements } from "../../../../helpers/StorageHelper";
import { LocalMapObjectSensorsInfo } from "../../../../server/GEOvis3/Model/LocalMapObjects/LocalMapObjectSensorsInfo";
import { MapSectionLocalMapObjectsMapRender } from "./MapSectionLocalMapObjectsMapRender";

interface IComponentProps extends IWithGeovisServerProps {
    mapSection: MapSectionModel;
    userId?: string;
    userToken?: string;
}

/**
 * Map Section report data layer and render
 * @param param0 
 * @returns 
 */
export const GeovisMapSectionLocalMapObjectsLayer = withGeovisServer(({ Server, mapSection, userId, userToken }: IComponentProps) => {

    const { LocalMapObjects, ProjectId } = mapSection;
    const [localMapObjectsStorage, setLocalMapObjectsStorage] = useState<ILocalMapObjectsStorage>(localMapObjectsDataStorageInitialState);

    useEffect(() => {

        if (LocalMapObjects.length === 0) {
            return;
        }

        (async function loadLocalMapObjects() {

            const url = userId && userToken
                ? ServerRoutesGen.ReportPdfRenderData.GetLocalMapObjectsByIds.patch(ProjectId, userToken, userId)
                : ServerRoutesGen.LocalMapObjects.GetLocalMapObjectsByIds.patch(ProjectId);

            const response = await fetchServerElementsByPost<LocalMapObject[], string[]>(Server, url, LocalMapObjects);

            setLocalMapObjectsStorage(processFetchedData(response, localMapObjectsStorage, localMapObjectsDataStorageInitialState, st => ({
                localMapObjects: elementsToMap(st),
                localMapObjectSensorsInfo: new Map<string, LocalMapObjectSensorsInfo>()
            })));

        })();

    }, [LocalMapObjects]);

    if (!LocalMapObjects || LocalMapObjects.length === 0) {
        return null;
    }

    const { isLoaded, isError, localMapObjects } = localMapObjectsStorage;

    return (
        <Fragment>
            {isLoaded && !isError && (
                <MapSectionLocalMapObjectsMapRender
                    localMapObjects={mapToListOfElements(localMapObjects)}
                />
            )}
        </Fragment>
    )
});