/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 20.05.2022
 * @description The geovis chart title render
 */

import { connect } from 'react-redux';
import { GeovisChartModel } from '../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartModel';
import { IGeovisChartReportInfo, IGeovisReportSettings, IGeovisVibrationEventSlimInfo } from '../../../../../store/projectReport.types';
import { IGeovisStoreState } from '../../../../../store/store.types';
import ElementReportTitle from '../ElementReportTitle';
import { getReportChartTitle, getGeovisChartData, getGeovisChartInfo } from './tools';
import { IReportHeaderState } from '../../../../../store/reportRender.types';
import { ChartType } from '../../../../../server/AVTService/TypeLibrary/Common/ChartType';
import { HeatmapChartData } from '../../../../../server/AVTService/TypeLibrary/Computation/HeatmapChartData';
import { SemiCircleChartResponseModel } from '../../../../../server/AVTService/Computation/ChartComputation/ResponseModel/SemiCircleChartResponseModel';

interface IStateToProps {
    chart?: IGeovisChartReportInfo<GeovisChartModel>;
    projectId: number;
    headerState: IReportHeaderState; // Required for correct parsing the chart header template
    globalTimeslot: string | undefined;
}

interface IOwnProps {
    pageNum: number;
    chartId: number;
    chartIndexOnPage: number;
    isInGroup?: boolean;
    //lastInGroup?: boolean;
    firstInGroup: boolean;
    reportId: number;
    drawBorder: boolean;

    vibrationEventInfo?: IGeovisVibrationEventSlimInfo
    onHideVibrationEvent?: () => void;
    isVibrationEventChart?: boolean;
    isPrinting: boolean;
}

interface IComponentProps extends IStateToProps, IOwnProps {

}

const GeovisChartHeader = ({ chart, chartIndexOnPage, isInGroup, isVibrationEventChart, onHideVibrationEvent, vibrationEventInfo, projectId, globalTimeslot, isPrinting, reportId, drawBorder, firstInGroup }: IComponentProps) => {

    if (!chart) {
        return null;
    }

    return (
        <ElementReportTitle
            elementTitle={getReportChartTitle(chart.Chart, globalTimeslot)}
            elementIndexOnPage={chartIndexOnPage}
            isInGroup={isInGroup}
            //lastInGroup={lastInGroup}
            firstInGroup={firstInGroup}
            isVibrationEventChart={isVibrationEventChart}
            onHideVibrationEvent={onHideVibrationEvent}
            vibrationEventInfo={vibrationEventInfo}
            projectId={projectId}
            chartId={chart.Chart.Id}
            isPrinting={isPrinting}
            reportId={reportId}
            drawBorder={drawBorder}
        />
    )
}

const getHeatmapGlobalTimeslot = (geovisReportSettings: IGeovisReportSettings, chart: GeovisChartModel, pageNum: number) => {
    const chartData = getGeovisChartData<HeatmapChartData>(geovisReportSettings, pageNum, chart.Id);
    return chartData && chartData.data ? chartData.data.GlobalTimeslot : undefined;
}

const getSemiCircleGlobalTimeslot = (geovisReportSettings: IGeovisReportSettings, chart: GeovisChartModel, pageNum: number) => {
    const chartData = getGeovisChartData<SemiCircleChartResponseModel>(geovisReportSettings, pageNum, chart.Id);

    return chartData && chartData.data ? chartData.data.Timeslot : undefined;
}

const mapStateToProps = ({ projectReport: { geovisReportSettings }, data, reportRenderState }: IGeovisStoreState, { chartId, pageNum }: IOwnProps): IStateToProps => {
    const chart = getGeovisChartInfo(geovisReportSettings, pageNum, chartId);

    let globalTimeslot: string | undefined = undefined;
    if (chart && chart.Chart.Type === ChartType.HeatMap) {
        globalTimeslot = getHeatmapGlobalTimeslot(geovisReportSettings, chart.Chart, pageNum);
    }
    else if (chart && chart.Chart.Type === ChartType.SemiCircle) {
        globalTimeslot = getSemiCircleGlobalTimeslot(geovisReportSettings, chart.Chart, pageNum);
    }

    return {
        chart,
        projectId: data.projectInfo.project.Id,
        headerState: reportRenderState.headerState,
        globalTimeslot
    }
};

export default connect<IStateToProps, never, IOwnProps>(mapStateToProps)(GeovisChartHeader);