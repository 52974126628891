/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 07.03.2024
 * @description Geovis time picker, based on atlaskit time picker
 */

import { TimePicker, TimePickerProps } from "@atlaskit/datetime-picker";

/**
 * Generate array of times for the time picker
 * @param nonZero - whether to exclude "00:00" and include "24:00"
 * @returns array of time strings
 */
const getTimes = (nonZero: boolean, restrictInterval: boolean): string[] => {
    const result: string[] = [];
    for (let index = 0; index < 24; index++) {
        if (nonZero && index === 0) {
            // Skip 00:00 if nonZero is true
            result.push("00:30");
        } else {
            result.push(`${index < 10 ? "0" : ""}${index.toString()}:00`);
            result.push(`${index < 10 ? "0" : ""}${index.toString()}:30`);
        }
    }
    if (nonZero && !restrictInterval) {
        result.push("24:00"); // Add 24:00 if nonZero is true
    }
    return result;
};

/**
 * Custom function to format the display label of the time picker
 * @param time
 * @param nonZero
 * @returns formatted time string
 */
const formatDisplayLabel = (time: string, nonZero: boolean, restrictInterval: boolean): string => {
    if (!restrictInterval && nonZero && time === "00:00") {
        return "24:00";
    }
    return time;
};

interface IOwnProps extends TimePickerProps {
    nonZero?: boolean;
    restrictInterval?: boolean;
}

export const GeovisTimePicker = ({ nonZero = false, restrictInterval = false, ...props }: IOwnProps) => {



    return (
        <div>
            <TimePicker
                timeFormat="HH:mm"
                times={getTimes(nonZero, restrictInterval)}
                formatDisplayLabel={(time) => formatDisplayLabel(time, nonZero, restrictInterval)}
                selectProps={{
                    menuPortalTarget: document.body,
                    styles: {
                        menuPortal: (base: any) => ({ ...base, zIndex: 510, marginTop: '-40px' }),
                    },
                    closeMenuOnScroll: true,
                }}
                {...props}
            />
        </div>
    );
};
