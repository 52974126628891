import { VibrationChartModel } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/VibrationChartModel";
import { ChartAlarmAreasControl, IChartAlarmAreasControlActionProps } from "../ChartAlarmAreasControl";
import { ChartAlarmLinesControl, IChartAlarmLinesControlActionProps } from "../ChartAlarmLinesControl";

interface IVibrationAlarmsTabProps extends IChartAlarmLinesControlActionProps, IChartAlarmAreasControlActionProps {
    chart: VibrationChartModel;
}

export const VibrationChartAlarmsTab = ({
    chart,
    onAlarmAreasChanged,
    onAlarmLineDeleteClickHandler,
    onChangeAlarmLines,
    onNewAlarmLinkClickHandler
}: IVibrationAlarmsTabProps) => {

    const { AlarmLines, BackgroundAreas } = chart;

    return (
        <div className="geovisChartTab">
            <div className="flexRowContainerLine">
                <ChartAlarmLinesControl
                    AlarmLines={AlarmLines}
                    chartType={chart.Type}
                    onAlarmLineDeleteClickHandler={onAlarmLineDeleteClickHandler}
                    onChangeAlarmLines={onChangeAlarmLines}
                    onNewAlarmLinkClickHandler={onNewAlarmLinkClickHandler}
                    yUnit={chart.LeftYAxisSettings.Unit}
                    xUnit={undefined}
                />
            </div>
            <div className="flexRowContainerLine">
                <ChartAlarmAreasControl
                    areas={BackgroundAreas || []}
                    onAlarmAreasChanged={onAlarmAreasChanged}
                />
            </div>
        </div>
    )
}