//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { IPopupContent } from '../../IPopupContent';
import { SensorBase } from '../../SensorBase';
import { AttachedDocumentModel } from '../../AttachedDocumentModel';
import { GeoLocation } from '../../GeoLocation';
import { CoordinateSystems } from '../../AVTService/TypeLibrary/Proj4Net/CoordinateSystems';
import { ImageBackgroundInfo } from '../../AVTService/TypeLibrary/ImageAsMap/ImageBackgroundInfo';

export class SensorInfo extends SensorBase implements IPopupContent
{
	public ShowAbsoluteValue: boolean;
	public ShowRelativeValue: boolean;
	public RelatedDocuments: AttachedDocumentModel[];
	public Selected: boolean;
	public SelectedSensorsCount: number;
	public NativeCoordinates: GeoLocation;
	public NativeCoordinateSystem: CoordinateSystems;
	public ImageInfo: ImageBackgroundInfo;
	public Param1: string;
	public Param2: string;
	public Param3: string;
	public Param4: string;
	public Param5: string;
}
