/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 25.10.2019
 * @description Contains common elements for local map object shapes: Tooltip, Popup
 */

import Button from '@atlaskit/button';
import { Popup, Tooltip } from 'react-leaflet';
import i18next from '../../../i18n';
import { LocalMapObject } from '../../../server/AVTService/TypeLibrary/LocalMapObjects/LocalMapObject';
import { LocalMapObjectContent } from '../../../server/LocalMapObjectContent';
import { LocalMapObjectContentType } from '../../../server/LocalMapObjectContentType';

interface ILocalMapObjectTooltipProps {
    key: string;
    element: LocalMapObject;
}

export const LocalMapObjectTooltip = ({ element }: ILocalMapObjectTooltipProps) => {
    let showDescriptionInToolTip: boolean = true;
    const contentElement = element as LocalMapObjectContent;
    if (contentElement) {
        if (contentElement.ContentType === LocalMapObjectContentType.Empty) {
            return null;
        }
        showDescriptionInToolTip = contentElement.ContentType !== LocalMapObjectContentType.Text;
    }
    return (
        <Tooltip
            key={`local_map_object_${element.Id}_tooltip`}
            direction="top"
            sticky={true}>
            <div>
                <div style={{ color: '#0052CC' }}>{element.Name}</div>
                {showDescriptionInToolTip && <div>{element.Description}</div>}
            </div>
        </Tooltip>
    )
}

interface ILocalMapObjectPopupProps {
    key: string;
    element: LocalMapObject;
    editMode: boolean;
    dragMode: boolean;
    onEdit: (id: string) => void;
    onRemove: (id: string) => void;
}

export const LocalMapObjectPopup = ({ element, onEdit, onRemove, editMode, dragMode }: ILocalMapObjectPopupProps) => {
    if (!editMode) {
        const contentElement = element as LocalMapObjectContent;
        if (contentElement && contentElement.ContentType === LocalMapObjectContentType.Empty) {
            return null;
        }
    }

    return (
        <Popup
            key={`local_map_object_${element.Id}_popup`}>
            <div>
                <h5>{element.Name}</h5>
                <div>{element.Description}</div>
                {editMode && (
                    <div>
                        <Button
                            appearance="link"
                            spacing="none"
                            onClick={() => onEdit(element.Id)}>
                            {i18next.t("edit")}
                        </Button>
                        <Button
                            style={{ marginLeft: '1em' }}
                            appearance="link"
                            isDisabled={dragMode}
                            spacing="none"
                            onClick={() => onRemove(element.Id)}>
                            {i18next.t("delete")}
                        </Button>
                    </div>
                )}
            </div>
        </Popup>
    )
}