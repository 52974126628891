//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum SemiCircleWdFilterType {
	AllInProject = 0,
	Filter = 1,
	Custom = 2
}

const SemiCircleWdFilterTypeToName = new Map<number, string>([
	[ 0, 'AllInProject' ],
	[ 1, 'Filter' ],
	[ 2, 'Custom' ],
])

export function getSemiCircleWdFilterTypeToName(element: SemiCircleWdFilterType) : string {
    return SemiCircleWdFilterTypeToName.get(element) || '';
}

const SemiCircleWdFilterTypeByName = new Map<string, number>([
	[ 'AllInProject', 0 ],
	[ 'Filter', 1 ],
	[ 'Custom', 2 ],
]);

export function getSemiCircleWdFilterTypeByName(name: string): SemiCircleWdFilterType {
    return SemiCircleWdFilterTypeByName.get(name)!;
}


export const SemiCircleWdFilterTypeList : Readonly<SemiCircleWdFilterType[]> = [
	SemiCircleWdFilterType.AllInProject,
	SemiCircleWdFilterType.Filter,
	SemiCircleWdFilterType.Custom,
];



const SemiCircleWdFilterTypeToDescription = new Map<number, string>([
	[ 0, 'All in project' ],
	[ 1, 'All match filter' ],
	[ 2, 'Custom selection' ],
])

export function getSemiCircleWdFilterTypeToDescription(element: SemiCircleWdFilterType) : string {
    return SemiCircleWdFilterTypeToDescription.get(element) || '';
}

