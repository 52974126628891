/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 03.08.2020
 * @description Marker cluster icon help methods
 * 
 * See documentation for MarkerCluster https://github.com/Leaflet/Leaflet.markercluster
 */

import { LeafletEvent, MarkerCluster } from 'leaflet';
import { flushSync } from 'react-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { MapSensorObject } from '../../helpers/MapHelper';
import { getSensorAlarmsForViewer } from '../../helpers/SensorHelper';
import { AlarmInfo } from '../../server/AlarmInfo';
import AuthService from '../../services/AuthService';
import Logger from '../../services/Logger';
import store from '../../store/configureStore';
import { IGeovisMarkerProps } from '../projectOverview/sensors/GeovisMarker';
import { isAnyActiveAlarm } from '../projectOverview/sensors/tools';
import {
    ISingleChainTooltipProps,
    ISingleSensorTooltipContentProps,
    StackedMapItemsTooltipContent
} from '../projectOverview/sensors/TooltipControls';
import { createClusterIconOfSensors } from './IconFactory';
import { ISensorsLastDataStorage } from '../../store/data.types';

interface ICreateMarkerClusterIconOptions {
    showName?: boolean;
    fontSize?: number;
    useSensorColor?: boolean;
    forceSingleMarkerMode?: boolean;
    useMapTextOrientation?: boolean;
    useGeovis4SensorTypeSymbol?: boolean;
    useSensorColorForText?: boolean;
    countLinesUnder?: number;
    fontWeight?: string;
    backgroundColor?: string;
    showBorder?: boolean
}

/**
 * Create icon of marker cluster.
 * Important, this function takes the project data storage directly. Be careful with tests implementation
 * @param cluster 
 */
export const createMarkerClusterIcon = (options: ICreateMarkerClusterIconOptions) => (cluster: MarkerCluster): L.DivIcon => {

    const mapSensorObjects: MapSensorObject[] = [];

    const { sensorsSymbolsStorage } = store.getState().data;
    const projectId = store.getState().data.projectInfo.project.Id;

    let hasAnyAlarms: boolean = false;

    for (const element of cluster.getAllChildMarkers()) {

        const markerProps = element.options as IGeovisMarkerProps;

        // do not check the marker without additional props, there are no any information about sensor or chain
        if (!markerProps || !markerProps.additionalProps) {
            continue;
        }

        const { chain, sensor } = markerProps.additionalProps;

        if (sensor) {
            const allowedSensor = AuthService.isActualAdminOfProject(projectId)
                ? sensor : { ...sensor, CausedAlarms: getSensorAlarmsForViewer<AlarmInfo>(projectId, sensor.CausedAlarms) };

            if (isAnyActiveAlarm(allowedSensor.CausedAlarms)) {
                hasAnyAlarms = true;
            }

            mapSensorObjects.push(allowedSensor);
        }

        if (chain) {
            if (isAnyActiveAlarm(chain.CausedAlarms)) {
                hasAnyAlarms = true;
            }
            mapSensorObjects.push(chain);
        }
    }

    // create and return icon
    const svgIcon = createClusterIconOfSensors(mapSensorObjects, { ...options, replaceIconMap: sensorsSymbolsStorage.iconSettings });

    if (hasAnyAlarms) {
        if (!svgIcon.options.className) {
            svgIcon.options.className = "";
        }
        svgIcon.options.className += " alarmedMarkerCluster";
    }

    return L.divIcon({ ...svgIcon.options });
}

/**
 * Add tooltip to cluster of markers
 * Important, this method takes the project data storage directly. Be careful with test implementations
 * @param event 
 */
export const applyClusterMarkerTooltip = (dataStorage: ISensorsLastDataStorage) => (event: LeafletEvent) => {

    const cluster = event.layer as MarkerCluster;
    if (!cluster) {
        Logger.warning(`Layer is not a cluster`, 'applyClusterMarkerTooltip');
        return;
    }

    const chainsTooltipProp: ISingleChainTooltipProps[] = [];
    const sensorsTooltipProps: ISingleSensorTooltipContentProps[] = [];

    const projectId = store.getState().data.projectInfo.project.Id;

    // collect all sensors and chains of marker
    for (const element of cluster.getAllChildMarkers()) {

        const markerProps = element.options as IGeovisMarkerProps;

        // do not check the marker without additional props, there are no any information about sensor or chain
        if (!markerProps || !markerProps.additionalProps) {
            continue;
        }

        const { chain, sensor } = markerProps.additionalProps;

        if (sensor) {
            const allowedSensor = AuthService.isActualAdminOfProject(projectId) ? sensor : { ...sensor, CausedAlarms: getSensorAlarmsForViewer(projectId, sensor.CausedAlarms) };

            sensorsTooltipProps.push({ sensor: allowedSensor, dataStorage });
        }

        if (chain) {
            chainsTooltipProp.push({ chain });
        }
    }

    // 'renderToString' is not recommended to use on the client side rendering
    // see https://beta.reactjs.org/reference/react-dom/server/renderToString
    const tooltipToHtml = () => {
        const div = document.createElement('div');
        const root = createRoot(div);
        flushSync(() => {
            root.render(
                <Provider store={store}>
                    <StackedMapItemsTooltipContent
                        key={`stacked_tooltip_of_element`}
                        chainsProps={chainsTooltipProp}
                        sensorsProps={sensorsTooltipProps}
                        dataStorage={dataStorage}
                    />
                </Provider>
            )
        });
        return div.innerHTML;
    }

    // replace and render the tooltip
    cluster
        .unbindTooltip()
        .bindTooltip(
            tooltipToHtml(), {
            className: "MapItemTooltip",
            direction: 'top'
        })
        .openTooltip();
}