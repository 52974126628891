/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 18.10.2019
 * @description The local map object text rendering implementation
 */

import L, { DragEndEvent } from 'leaflet';
import { useEffect, useState } from 'react';
import { Marker } from 'react-leaflet';
import LinkIcon from 'src/resources/icons/link-26.png';
import { locationToMapCoordinates } from '../../../helpers/MapHelper';
import { navigateToProjectReportInNewTab } from '../../../helpers/NavigationHelper';
import { fixHref } from '../../../helpers/UrlHelper';
import rotatingArrow from '../../../images/rotating-arrow.svg';
import { LocalMapObject } from '../../../server/AVTService/TypeLibrary/LocalMapObjects/LocalMapObject';
import { LocalMapObjectType } from '../../../server/AVTService/TypeLibrary/LocalMapObjects/LocalMapObjectType';
import { LocalMapObjectProperties } from '../../../server/GEOvis3/Model/LocalMapObjects/LocalMapObjectProperties';
import { LocalMapObjectContent } from '../../../server/LocalMapObjectContent';
import { LocalMapObjectContentType } from '../../../server/LocalMapObjectContentType';
import { LocalMapObjectLink } from '../../../server/LocalMapObjectLink';
import { LocalMapObjectText } from '../../../server/LocalMapObjectText';
import { ILocalMapShapeOptions } from '../ILocalMapShapeOptions';
import { LocalMapObjectViewMode } from '../types';
import { LocalMapObjectPopup, LocalMapObjectTooltip } from './LocalMapObjectMapElements';

type Position = {
    x: number;
    y: number;
}

const getAngle = (startPosition: Position, endPosition: Position): number => {
    const dx = endPosition.x - startPosition.x;
    const dy = endPosition.y - startPosition.y;

    let angle: number = 0;

    if (dy === 0 && dx === 0) {
        angle = 0;
    } else if (dy === 0 && dx > 0) {
        angle = 0;
    } else if (dy === 0 && dx < 0) {
        angle = -Math.PI;
    } else if (dx === 0 && dy > 0) {
        angle = Math.PI / 2;
    } else if (dx === 0 && dy < 0) {
        angle = 3 * Math.PI / 2;
    } else if (dx > 0) {
        angle = Math.atan(dy / dx);
    } else if (dx < 0) {
        angle = Math.atan(dy / dx) + Math.PI;
    }

    return angle + Math.PI / 2;
}

type RotationState = {
    angle: number;
    cursorPosition: Position;
    centerPosition: Position;
}

interface ILocalMapObjectTextProps {
    element: LocalMapObject;
    viewMode: LocalMapObjectViewMode;
    isEditMode: boolean;
    editState?: LocalMapObjectProperties;

    onEdit: (id: string) => void;
    onRemove: (id: string) => void;
    onClick: (id: string) => void;
    onOpenFile: (documentId: string) => void;

    setMapDragging?: (dragging: boolean) => void;
    onChangeLocation?: (elementId: string, latLng: L.LatLng) => void;
    onChangeRotationAngle?: (elementId: string, angle: number) => void;
}

/**
 * Get text of link on the map
 * @param element 
 * @returns 
 */
const getElementTextOnTheMap = (element: LocalMapObjectText): string => {

    if (element.TextOnTheMap) {
        return element.TextOnTheMap;
    }

    return element.Name;
}

export const LocalMapObjectTextShape = ({
    isEditMode,
    editState,
    element,
    onEdit,
    onRemove,
    onClick,
    viewMode,
    onOpenFile: onDownloadFile,
    setMapDragging,
    onChangeLocation,
    onChangeRotationAngle
}: ILocalMapObjectTextProps) => {

    const elementText = element as LocalMapObjectText;
    const { RotationAngle, Location } = elementText;
    const rotationAngle = editState && editState.rotationAngle !== undefined ? editState.rotationAngle : RotationAngle;
    const location = editState && editState.coordinates !== undefined ? editState.coordinates : Location;

    const [innerRotationAngle, setInnerRotationAngle] = useState<number>(rotationAngle);
    // const rotationOrigin = 'center center';
    const rotationOrigin = 'left top';
    useEffect(() => {
        setInnerRotationAngle(rotationAngle);
    }, [rotationAngle]);

    // const [bodyCursor] = useState<string>(document.body.style.cursor);

    const createDivIcon = () => {
        const html = (): HTMLDivElement => {
            const div = document.createElement('div') as HTMLDivElement;
            div.style.transformOrigin = rotationOrigin;
            div.style.transform = `rotate(${innerRotationAngle}deg)`;

            if (element.ObjectType === LocalMapObjectType.Link) {
                const img = document.createElement('img') as HTMLImageElement;
                img.src = LinkIcon;
                img.alt = 'Link Icon';
                img.style.width = '20px';
                img.style.height = '20px';
                div.append(img);
            }

            if (isEditMode) {
                let startRotationState: RotationState;
                let currentAngle: number = innerRotationAngle;

                const onMouseMoveDocument = (ev: MouseEvent) => {
                    ev.preventDefault();
                    ev.stopImmediatePropagation();

                    const angle = getAngle(startRotationState.centerPosition, { x: ev.clientX, y: ev.clientY });

                    div.style.transform = `rotate(${Math.round(angle * 100) / 100}deg)`;
                    currentAngle = angle * 180 / Math.PI;

                    setInnerRotationAngle(currentAngle);
                }

                const onMouseUpDocument = (ev: MouseEvent) => {
                    ev.preventDefault();
                    ev.stopImmediatePropagation();

                    // document.body.style.cursor = bodyCursor;
                    document.body.style.cursor = "";

                    document.removeEventListener('mousemove', onMouseMoveDocument);
                    document.removeEventListener('mouseup', onMouseUpDocument);

                    if (onChangeRotationAngle)
                        onChangeRotationAngle(element.Id, currentAngle);

                    if (setMapDragging) {
                        setMapDragging(true);
                    }
                }

                const img = document.createElement('img') as HTMLImageElement;
                img.src = rotatingArrow;
                // img.draggable = true;

                // img.addEventListener( "mouseenter" , function(evEnter: MouseEvent) {
                //     evEnter.preventDefault();
                //     evEnter.stopImmediatePropagation();
                //     // evEnter.stopPropagation();

                //     // eslint-disable-next-line no-console
                //     console.log(evEnter.type);

                //     document.body.style.cursor = "grab";
                // });

                // img.addEventListener( "mouseleave" , function(evLeave: MouseEvent) {
                //     evLeave.preventDefault();
                //     evLeave.stopImmediatePropagation();
                //     // evLeave.stopPropagation();

                //     if (!rotating) {
                //         document.body.style.cursor = bodyCursor;
                //     }

                //     // eslint-disable-next-line no-console
                //     console.log(evLeave.type);
                // });

                img.addEventListener("mousedown", function (evDown: MouseEvent) {
                    evDown.preventDefault();
                    evDown.stopImmediatePropagation();

                    const rect = img.parentElement ? img.parentElement.getBoundingClientRect() : { x: 0, y: 0 };

                    startRotationState = {
                        angle: RotationAngle,
                        cursorPosition: { x: evDown.clientX, y: evDown.clientY },
                        centerPosition: { x: rect.x, y: rect.y }
                    };
                    // setStartRotationState({
                    //     angle: RotationAngle,
                    //     cursorPosition: { x: evDown.clientX, y: evDown.clientY },
                    //     iconPosition: img.getBoundingClientRect()
                    // });

                    // setBodyCursor(document.body.style.cursor);
                    document.body.style.cursor = "grab";

                    document.addEventListener('mousemove', onMouseMoveDocument);

                    document.addEventListener('mouseup', onMouseUpDocument);

                    if (setMapDragging) {
                        setMapDragging(false);
                    }
                });

                // img.addEventListener( "touchstart" , function(ev: MouseEvent) {
                //     ev.preventDefault();
                //     // eslint-disable-next-line no-console
                //     console.log(ev.type);
                //     ev.stopImmediatePropagation();
                //     ev.stopPropagation();
                // });
                div.append(img);
            }

            const span = document.createElement('span') as HTMLSpanElement;
            span.textContent = getElementTextOnTheMap(elementText);
            span.style.fontSize = `${elementText.FontSize}px`;
            span.style.color = elementText.Color;
            div.append(span);

            return div;
        };

        return L.divIcon({
            html: html(),
            className: "geoJsonMarker geoJsonMarkerText"
        });
    };

    const onClickHandler = (event: L.LeafletMouseEvent) => {
        if (element.ObjectType === LocalMapObjectType.Text) {
            if ((isEditMode || viewMode === LocalMapObjectViewMode.Edit) && !event.originalEvent.ctrlKey) {
                return;
            }

            if (elementText.ContentType === LocalMapObjectContentType.Link) {
                const href = fixHref(elementText.Link);
                window.open(href, "_blank");

                event.originalEvent.preventDefault();
                return;
            }
            else if (elementText.ContentType === LocalMapObjectContentType.Document) {
                onDownloadFile(elementText.DocumentId);
            }
            else if (elementText.ContentType === LocalMapObjectContentType.Background) {
                onClick(element.Id);
            }
            else if (elementText.ContentType === LocalMapObjectContentType.Report) {
                if (!elementText.ReportId) {
                    return;
                }
                const storedView = sessionStorage.getItem("view");
                navigateToProjectReportInNewTab(element.ProjectId, elementText.ReportId, storedView !== null ? storedView : undefined);
            }
        }

        if (element.ObjectType === LocalMapObjectType.Link && viewMode !== LocalMapObjectViewMode.Edit) {

            if (isEditMode && !event.originalEvent.ctrlKey) {
                return;
            }

            event.originalEvent.preventDefault();
            const link = element as LocalMapObjectLink;

            const href = fixHref(link.Link);

            window.open(href, "_blank");
        }
    };

    const shapeOptions: ILocalMapShapeOptions = {
        localMapObjectId: element.Id,
        localMapObjectType: element.ObjectType
    };

    const getChildren = (): JSX.Element[] => {
        if (viewMode === LocalMapObjectViewMode.View) {
            const contentElement = element as LocalMapObjectContent;
            if (contentElement && contentElement.ContentType === LocalMapObjectContentType.Empty) {
                return [];
            }
        }

        const result: JSX.Element[] = [];
        if (viewMode !== LocalMapObjectViewMode.MapSection) {
            result.push(
                <LocalMapObjectPopup
                    key={`local_map_object_${element.Id}_marker_popup`}
                    element={element}
                    onEdit={onEdit}
                    onRemove={onRemove}
                    editMode={viewMode === LocalMapObjectViewMode.Edit}
                    dragMode={isEditMode} />
            );
        }
        result.push(
            <LocalMapObjectTooltip
                key={`local_map_object_${element.Id}_marker_tooltip`}
                element={element} />
        );

        return result;
    }

    const onDragEnd = (ev: DragEndEvent) => {
        if (onChangeLocation) {
            const latLng = ev.target.getLatLng();
            onChangeLocation(element.Id, latLng);
        }
    }

    return (
        <Marker
            key={`local_map_object_${element.Id}_marker`}
            position={locationToMapCoordinates(location)}
            icon={createDivIcon()}
            eventHandlers={viewMode === LocalMapObjectViewMode.MapSection ? undefined : {
                click: onClickHandler,
                dragend: onDragEnd
            }}
            draggable={isEditMode}
            {...shapeOptions}>
            {getChildren()}
        </Marker>);
}