/**
 * @author Ivan Kozlov <ik@ieskr.ru>
 * @created 23.05.2022
 * @description Render for popup (default) charts
 */

import React from "react";
import { connect } from "react-redux";
import { mapToListOfElements } from "../../../../helpers/StorageHelper";
import { ElementHeightType } from "../../../../server/AVTService/TypeLibrary/Common/ElementHeightType";
import { ReportElementType } from "../../../../server/AVTService/TypeLibrary/Common/ReportElementType";
import { getSensorValueAttributeByName, SensorValueAttribute } from "../../../../server/AVTService/TypeLibrary/Common/SensorValueAttribute";
import { GeovisChartModel } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartModel";
import { GeovisReportElementInfo } from "../../../../server/AVTService/TypeLibrary/Model/Reports/GeovisReportElementInfo";
import { GeovisReportPage } from "../../../../server/AVTService/TypeLibrary/Model/Reports/GeovisReportPage";
import { IGeovisReportSettings } from "../../../../store/projectReport.types";
import { IGeovisStoreState } from "../../../../store/store.types";
import { ChartElementRender } from "./ChartElementRender";
import GeovisChartRenderDataLayer from "./charts/GeovisChartRenderDataLayer";
import FooterRender from "./FooterRender";
import HeaderRender from "./header/HeaderRender";
import HeaderRenderDataLayer from "./header/HeaderRender.DataLayer";

interface IComponentStateProps {
    reportSettings: IGeovisReportSettings;
}

interface IComponentOwnProps {
    userId?: string;
    userToken?: string;
    isPrinting: boolean;
}

interface IComponentProps extends IComponentStateProps, IComponentOwnProps {

}

const DefaultChartsRender = ({
    reportSettings,
    userId,
    userToken,
    isPrinting
}: IComponentProps) => {

    const { geovisPages } = reportSettings;

    const page = geovisPages.get(0);
    if (!page) {
        return null;
    }

    const chartToDraw = mapToListOfElements(page.geovisCharts).map(c => c.Chart)[0];

    const calculateElementHeight = (chart: GeovisChartModel): number => {
        const sensorValueAttribute = chart.LeftYAxisSettings ? getSensorValueAttributeByName(chart.LeftYAxisSettings.sensorValue) : SensorValueAttribute.Value1;
        switch (sensorValueAttribute) {
            case SensorValueAttribute.ValuesXYZ:
            case SensorValueAttribute.Axis:
                return 1000;
            default:
                return 500;
        }
    }

    const genPage: GeovisReportPage = {
        Elements: [{
            Height: calculateElementHeight(chartToDraw),
            HeightType: ElementHeightType.Autofit,
            HideTitle: false,
            Id: chartToDraw.Id,
            ElementType: ReportElementType.GeovisChart,
            Children: [],
            DrawBorder: false,
            IsElementEmpty: false
        }]
    }

    const getElementRenderDataLayer = (element: GeovisReportElementInfo) => {
        return (
            <GeovisChartRenderDataLayer
                key={`element-render-data-layer-${element.ElementType}-${element.Id}`}
                reportId={0}
                userId={userId}
                userToken={userToken}
                isPrinting={isPrinting}
                pageNum={0}
                isVibrationEventChart={false}
                chartId={element.Id}
                isDefault={true}
                eventInfo={undefined}
            />
        )
    }

    return (
        <div key={`geovis-charts-report`}
            className="reportContentContainer">
            <div className="reportPageContainer">
                <div>
                    <HeaderRender isDefaultReport={true} />
                    <HeaderRenderDataLayer
                        reportId={0}
                        userId={userId}
                        userToken={userToken}
                    />
                </div>
                <div style={{ height: '100%' }}>
                    {genPage.Elements.map((element, elementIndex) => (
                        <React.Fragment key={`div-${elementIndex}`}>
                            <ChartElementRender
                                rootContainerWidth={0}
                                elementIndexOnPage={elementIndex}
                                isPrinting={isPrinting}
                                pageNum={0}
                                reportId={0}
                                isDefault={true}
                                userId={userId}
                                userToken={userToken}
                                isInGroup={false}
                                firstInGroup={true}
                                elementInfo={element}
                                key={`geovis-chart-${element.Id}-${elementIndex}`}
                                projectId={0}
                                eventInfo={undefined}
                                isVibrationEventChart={undefined}
                                lastInGroup={false}
                                settingsId=""
                            />
                            {getElementRenderDataLayer(element)}
                        </React.Fragment>

                    ))}
                </div>
                <div>
                    <FooterRender
                        countPages={1}
                        pageNumber={1}
                    />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ projectReport }: IGeovisStoreState): IComponentStateProps => ({
    reportSettings: projectReport.geovisReportSettings
});

export default connect<IComponentStateProps, never, IComponentOwnProps>(mapStateToProps)(DefaultChartsRender)


