import { connect } from 'react-redux';
import { formatDateTime } from "../../../../../helpers/DateHelper";
import { IReportHeaderState, IHeaderElementDataInfo } from "../../../../../store/reportRender.types";
import { IGeovisStoreState } from "../../../../../store/store.types";
import { HeaderRenderSlim } from './HeaderRenderSlim';

interface IStateProps {
    headerState: IReportHeaderState;
}

interface IOwnProps {
    isDefaultReport?: boolean;
}

interface IHeaderRenderConnectedProps extends IStateProps, IOwnProps {

}

const HeaderRenderConnected = (props: IHeaderRenderConnectedProps) => {

    const { headerState, isDefaultReport } = props;
    const timeSlotsFormat = isDefaultReport ? "DD.MM.yyyy" : "DD.MM.yyyy HH:mm:ss";

    const getMinMaxOfDates = (dates: Date[], func: "min" | "max"): Date => {
        if (dates.length === 0) {
            return new Date(Date.now());
        }

        if (dates.length === 1) {
            return dates[0];
        }

        let result = dates[0];

        for (let index = 1; index < dates.length; index++) {
            const value = dates[index];

            // max function
            if (func === 'max') {
                if (result < value) {
                    result = value;
                }
                continue;
            }

            // min function
            if (func === 'min') {
                if (result > value) {
                    result = value;
                }
                continue;
            }
        }

        return result;
    }

    const getElementsInfoOfPages = (): IHeaderElementDataInfo[] => {

        const result: IHeaderElementDataInfo[] = [];
        headerState.pageChartsInfo.forEach(elements => {
            result.push(...elements);
        });

        headerState.pageLogbooksInfo.forEach(elements => {
            result.push(...elements);
        });

        return result;
    }

    const headerElementsInfo = getElementsInfoOfPages();

    const getReportTimeFrom = (): string => {
        if (!headerState.isLoaded || headerElementsInfo.length === 0) {
            return '';
        }

        // const minDateTicks = Math.min(...headerState.chartsInfo.map(c => new Date(c.startTimeString)));
        const minDateTicks = getMinMaxOfDates(headerElementsInfo.map(c => new Date(c.startTimeString)), 'min');

        return formatDateTime(minDateTicks, timeSlotsFormat);
    }

    const getReportTimeTo = (): string => {
        // // eslint-disable-next-line no-console
        // console.log(headerState)

        // // eslint-disable-next-line no-console
        // console.log(headerElementsInfo)
        if (!headerState.isLoaded || headerElementsInfo.length === 0) {
            return '';
        }

        // const maxDateTicks = Math.max(...headerState.chartsInfo.map(c => c.endTimeTicks));
        const maxDateTicks = getMinMaxOfDates(headerElementsInfo.map(c => new Date(c.endTimeString)), 'max');

        return formatDateTime(maxDateTicks, timeSlotsFormat);
    }

    return (
        <HeaderRenderSlim
            header={headerState.reportHeaderInfo}
            minDateTime={getReportTimeFrom()}
            maxDateTime={getReportTimeTo()}
            timeSlotsFormat={timeSlotsFormat}
        />
    )

}

const mapStateToProps = ({ reportRenderState }: IGeovisStoreState): IStateProps => ({
    headerState: reportRenderState.headerState
});

export default connect<IStateProps, never, IOwnProps>(mapStateToProps)(HeaderRenderConnected);
