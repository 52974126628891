/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 14.10.2022
 * @description User profile details tab content
 * @refactored 14.10.2022 because of very hard implementation of whole profile component
 */

import { Checkbox } from '@atlaskit/checkbox';
import Textfield from '@atlaskit/textfield';
import { useEffect, useState } from 'react';
import { GroupType } from 'react-select';
import { IWithGeovisServerProps, withGeovisServer } from '../../helpers/GeovisHooks';
import { useGeovisDialogVisibilityHookWithState } from '../../helpers/Hooks.Toggles';
import { fetchServerElementsByPost } from '../../helpers/ProjectDataHelper';
import {
    onCheckboxPropertyChangedHandler,
    onMultySelectPropertyChangedHandler,
    onNumberPropertyChangedHandler,
    onSelectPropertyChangedHandler,
    onStringPropertyChangedHandler, onTextfieldPropertyChangedHandlerDebounced
} from '../../helpers/PropertiesHelper';
import { isEmailValid, userProfileReadonly } from '../../helpers/UsersHelper';
import i18next, { t } from "../../i18n";
import { GeovisCompanyType } from '../../server/AVTService/TypeLibrary/Common/GeovisCompanyType';
import { GeovisUserProfilePasswordModel } from '../../server/AVTService/TypeLibrary/Identity/GeovisUserProfilePasswordModel';
import { GeovisUserRESTAPIEnum, getGeovisUserRESTAPIEnumToName } from '../../server/AVTService/TypeLibrary/Identity/GeovisUserRESTAPIEnum';
import { GeovisUserRoleEnum, getGeovisUserRoleEnumToName } from '../../server/AVTService/TypeLibrary/Identity/GeovisUserRoleEnum';
import { GeovisUserAllowedDbsOfProject } from '../../server/GEOvis3/Model/User/GeovisUserAllowedDbsOfProject';
import { GeovisUserProfileInfo } from '../../server/GEOvis3/Model/User/GeovisUserProfileInfo';
import ServerRoutesGen from '../../server/Routes/ServerRoutesGen';
import AuthService from '../../services/AuthService';
import FlagService from '../../services/FlagService';
import { IBusinessCompaniesStorage } from '../../store/businessData.types';
import { processFetchedData } from '../../store/helpers/DataHelper';
import { defaultSomethingStorageState, ISomethingStorageBaseEx } from '../../store/types';
import { GeovisCheckboxSelect, GeovisSelect } from '../select/GeovisSelect';
import { getIGvOptionType, IGvOptionType } from '../select/GeovisSelect_tools';
import {
    companiesToOptions,
    companyToSelectOption,
    getUserRoleSelectOptions
} from '../select/tools';
import { DowngradeUserRoleDialog } from './DowngradeUserRoleDialog';
import { UserEditorControlAccessLevel, UserEditorMode } from './types';
import UserAvatar from './UserAvatar';
import { GeovisPhoneNumber } from '../geovisPhoneNumber/GeovisPhoneNumber';
import { UserFeedbackButton } from '../../pages/service/feedbacks/UserFeedbackButton';
import { LicensedFeatures } from '../../server/AVTService/TypeLibrary/Licensing/LicensedFeatures';
import { CultureInfoSelect } from '../culture/CultureInfoSelect';

interface IUserEditorProfileViewProps extends IWithGeovisServerProps {
    user: GeovisUserProfileInfo;
    editMode: UserEditorMode;

    companiesStorage: IBusinessCompaniesStorage;

    onUserPropertyChanged: (propertyName: keyof GeovisUserProfileInfo, value: any) => void;
    onUserPropertiesChanged: (properties: Partial<GeovisUserProfileInfo>) => void;
    onPasswordPropertyChanged: (propertyName: keyof GeovisUserProfilePasswordModel, value: string) => void;
}

type IGeovisUserAllowedDatabases = ISomethingStorageBaseEx<GeovisUserAllowedDbsOfProject[]>

const allowedDbsOfProjectInitialState: IGeovisUserAllowedDatabases = {
    ...defaultSomethingStorageState,
    data: []
}

const setFocus = (input: HTMLInputElement | null) => {
    if (input) {
        input.focus();
    }
}

const isNameInvalid = ({ Name }: GeovisUserProfileInfo): boolean => {
    return Name === undefined || Name.length === 0;
}

const isForenameInvalid = ({ Forename }: GeovisUserProfileInfo): boolean => {
    return !Forename || Forename.length === 0;
}

const isEmailInvalid = ({ Email }: GeovisUserProfileInfo): boolean => {
    return !isEmailValid(Email);
}

const UserProfileEditorDetailsTabView = ({
    user,
    editMode,
    Server,

    companiesStorage,

    onUserPropertyChanged,
    onPasswordPropertyChanged,
    onUserPropertiesChanged
}: IUserEditorProfileViewProps) => {

    const [isDowngradeRoleDialog, showDowngradeUserRoleDialog, hideDowngradeUserRoleDialog, downgradeUserRoleDialogState] = useGeovisDialogVisibilityHookWithState<{
        fromRole: GeovisUserRoleEnum,
        toRole: GeovisUserRoleEnum
    }>({
        fromRole: GeovisUserRoleEnum.Zero,
        toRole: GeovisUserRoleEnum.Zero
    });

    const [allowedDbsStorage, setAllowedDbsStorage] = useState<IGeovisUserAllowedDatabases>(allowedDbsOfProjectInitialState);

    useEffect(() => {

        (async function loadAllowedDbsOfProjects() {

            const url = ServerRoutesGen.Account.AllowedDbsOfProjects.patch(user.Id === "" ? "new_user_fake_id" : user.Id);

            const response = await fetchServerElementsByPost<GeovisUserAllowedDbsOfProject[], GeovisUserProfileInfo>(Server, url, user);

            setAllowedDbsStorage(processFetchedData(response, allowedDbsStorage, allowedDbsOfProjectInitialState, st => ({ data: st })));

            if (!response.Success) {
                FlagService.addErrors('Cannot load related databases info', response.Messages);
            }
        })();

    }, []);



    // const isEditingAllowed = (accessLevel: UserEditorControlAccessLevel): boolean => {
    //     switch(accessLevel){
    //         case UserEditorControlAccessLevel
    //     }
    // }

    const currentAccessLevel = (): UserEditorControlAccessLevel => {
        if (AuthService.hasUserTypeAsAdmin()) {
            return UserEditorControlAccessLevel.Admin;
        }

        if (editMode === UserEditorMode.Create) {
            return UserEditorControlAccessLevel.Creator;
        }

        if (AuthService.hasUserTypeAsCompanyAdmin() && user.CompanyId === AuthService.currentUserCompanyId()) {
            return UserEditorControlAccessLevel.AccountCompanyAdmin;
        }

        if (AuthService.hasUserTypeAsCompanyAdmin()) {
            return UserEditorControlAccessLevel.AnyCompanyAdmin;
        }

        if (AuthService.currentUserId() === user.Id) {
            return UserEditorControlAccessLevel.OwnAccount;
        }

        return UserEditorControlAccessLevel.Everyone;
    }

    /**
     * Process changing user role
     * @param option 
     */
    const onGeovisUserRoleChangedHandler = (option: IGvOptionType<GeovisUserRoleEnum>) => {

        if (!option) {
            return;
        }

        const role = option.value;

        if (user.Id === AuthService.currentUserId() && user.GeovisUserRole > role) {
            showDowngradeUserRoleDialog({ fromRole: user.GeovisUserRole, toRole: role });
        } else {
            onUserPropertyChanged("GeovisUserRole", role);
        }
    }

    const onDowngradeUserRole = async () => {
        onUserPropertyChanged("GeovisUserRole", downgradeUserRoleDialogState.toRole);
        hideDowngradeUserRoleDialog();
    }

    const isOwnProfile = AuthService.currentUserId() === user.Id;
    // const hasCompanyAdminRole = isOwnProfile ? user.GeovisUserRole >= GeovisUserRoleEnum.CompanyAdmin : AuthService.hasUserTypeAsCompanyAdmin();
    const hasAdminRole = isOwnProfile ? user.GeovisUserRole >= GeovisUserRoleEnum.Admin : AuthService.hasUserTypeAsAdmin();
    const isUnderAdminRole = isOwnProfile ? user.GeovisUserRole < GeovisUserRoleEnum.Admin : AuthService.isUnderAdminRole();
    //const isUnderCompanyAdminRole = isOwnProfile ? user.GeovisUserRole < GeovisUserRoleEnum.CompanyAdmin : AuthService.isUnderCompanyAdminRole();

    const readonly = userProfileReadonly(editMode);
    // const readOnlyNumber = userProfileNumberCanNotBeChanged(editMode, user, AuthService.getUserInfo() === undefined ? GeovisUserRoleEnum.Zero : AuthService.getUserInfo()!.GeovisUserRole);
    // const companyReadonly = readonly || (isOwnProfile && isUnderAdminRole);
    // const canChangePasswordForbiddenFlag = !isOwnProfile && AuthService.hasUserTypeAsCompanyAdmin();

    const ownCompanyId = AuthService.currentUserCompanyId();
    // const canChangeUserRole =
    //     !readonly &&
    //     (
    //         hasAdminRole ||
    //         (
    //             !isUnderCompanyAdminRole &&
    //             (user.CompanyId.length === 0 || ownCompanyId === user.CompanyId))
    //     );

    const editedUserIsAdminOrDeveloper = (): boolean => {
        return user.GeovisUserRole >= GeovisUserRoleEnum.Admin;
    }

    /**
     * Update company of the user and set related geovis user role, which depends of company type
     * @param option 
     * @returns 
     */
    const onCompanyChangedHandler = (option: IGvOptionType<string>) => {
        if (!option || !companiesStorage.isLoaded || companiesStorage.isError) {
            return;
        }

        const { value } = option;
        const companyId = value.toString();
        const company = companiesStorage.companies.get(companyId);

        if (!company) {
            throw Error('Cannot change user property. Company not found.');
        }

        let newUserRole = user.GeovisUserRole;

        // downgrade user geovis role according to a company type
        if (hasAdminRole && company.CompanyType === GeovisCompanyType.Viewer) {
            // onUserPropertyChanged("GeovisUserRole", GeovisUserRoleEnum.User);
            newUserRole = GeovisUserRoleEnum.User;
        }
        else if (hasAdminRole && (company.CompanyType === GeovisCompanyType.Partner) && editedUserIsAdminOrDeveloper()) {
            // onUserPropertyChanged("GeovisUserRole", GeovisUserRoleEnum.CompanyAdmin);
            newUserRole = GeovisUserRoleEnum.CompanyAdmin;
        }
        else if (user.GeovisUserRole.toString() !== GeovisUserRoleEnum.User.toString() && isUnderAdminRole && companyId.length > 0 && ownCompanyId !== companyId) {
            // onUserPropertyChanged("GeovisUserRole", GeovisUserRoleEnum.User);
            newUserRole = GeovisUserRoleEnum.User;
        }

        // update company and role
        onUserPropertiesChanged({
            CompanyId: companyId,
            GeovisUserRole: newUserRole,
            CompanyAvailableFeatures: company.AllCompanyFeatures
        });
    }

    /**
     * Geovis user roles for company
     * @returns 
     */
    const getUserRoleSelectOptionsForCompany = (): Array<IGvOptionType<GeovisUserRoleEnum>> => {

        const company = companiesStorage.companies.get(user.CompanyId);

        const result = getUserRoleSelectOptions(user.CompanyId);

        if (company && company.CompanyType === GeovisCompanyType.Viewer) {
            return result.filter(r => +r.value === GeovisUserRoleEnum.User);
        }
        else if (company && (company.CompanyType === GeovisCompanyType.Partner)) {
            return result.filter(r => +r.value < GeovisUserRoleEnum.Admin);
        }

        return result;
    }

    /**
     * Geovis user role options for RESTApi user
     * @returns 
     */
    const getUserRESTRoleOptions = (): ReadonlyArray<IGvOptionType<GeovisUserRESTAPIEnum>> => [
        { label: t("Read only access"), value: GeovisUserRESTAPIEnum.READ_ONLY },
        { label: t("Read-Write access"), value: GeovisUserRESTAPIEnum.READ_WRITE }
    ];

    const getSelectedRESTRole = (model: GeovisUserProfileInfo): IGvOptionType<GeovisUserRESTAPIEnum> => getIGvOptionType(model.RESTApiRole, getGeovisUserRESTAPIEnumToName);

    const getSelectedMsts = (): ReadonlyArray<IGvOptionType<string>> => {
        const result: Array<IGvOptionType<string>> = [];

        const { isLoading, isError, data } = allowedDbsStorage;

        if (isLoading || isError || !user.RESTApiAllowedDbs) {
            return result;
        }

        for (const project of data) {
            for (const mst of project.Msts) {
                if (user.RESTApiAllowedDbs.includes(mst.Alias)) {
                    result.push({ label: mst.Name, value: mst.Alias });
                }
            }
        }

        return result;
    }

    const getAliasesOptions = (): Array<GroupType<IGvOptionType<string>>> => {

        const { isLoading, isError, data } = allowedDbsStorage;
        if (isLoading || isError) {
            return [];
        }

        const result: Array<GroupType<IGvOptionType<string>>> = [];

        for (const project of data) {

            const group: GroupType<IGvOptionType<string>> = {
                label: project.ProjectName,
                options: project.Msts.map<IGvOptionType<string>>(m => ({ label: m.Name, value: m.Alias }))
            };

            result.push(group);
        }

        return result;
    }

    let companyName = "";
    {
        const company = companiesStorage.companies.get(user.CompanyId);
        if (company !== undefined) {
            companyName = company.Name;
        }
    }

    return (
        <div>
            <div className="flexRowContainer">
                <div className="flexCellContainer">
                    <UserAvatar
                        avatarSrc={user.AvatarBase64Content}
                        //readonly={readonly && !isOwnProfile}
                        readonly={currentAccessLevel() < UserEditorControlAccessLevel.OwnAccount}
                        onAvatarSrcChanged={onStringPropertyChangedHandler("AvatarBase64Content", onUserPropertyChanged)}
                    />
                </div>
                <div className="flexCellContainer_g1" style={{ marginRight: '0' }}>
                    <div className="flexRowContainer">
                        <div className="flexCellContainer_g1">
                            <div>
                                <label htmlFor="tbName">{i18next.t("Name")}</label>
                                <Textfield
                                    ref={tb => setFocus(tb)}
                                    name="tbName"
                                    isCompact={true}
                                    defaultValue={user.Name}
                                    isInvalid={isNameInvalid(user)}
                                    onChange={onTextfieldPropertyChangedHandlerDebounced<GeovisUserProfileInfo>("Name", onUserPropertyChanged)}
                                    //isDisabled={AuthService.isUnderAdminRole() && editMode !== UserEditorMode.Create}
                                    isDisabled={currentAccessLevel() < UserEditorControlAccessLevel.AnyCompanyAdmin}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flexRowContainer">
                        <div className="flexCellContainer_g1">
                            <div>
                                <label htmlFor="tbForename">{i18next.t("Forename")}</label>
                                <Textfield
                                    name="tbForename"
                                    isCompact={true}
                                    defaultValue={user.Forename}
                                    isInvalid={isForenameInvalid(user)}
                                    onChange={onTextfieldPropertyChangedHandlerDebounced<GeovisUserProfileInfo>("Forename", onUserPropertyChanged)}
                                    //isDisabled={AuthService.isUnderAdminRole() && editMode !== UserEditorMode.Create} 
                                    isDisabled={currentAccessLevel() < UserEditorControlAccessLevel.AnyCompanyAdmin}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flexRowContainer">
                <div className="flexCellContainer_g1">
                    <label htmlFor="selectCompany">{i18next.t("Company")}</label>
                    {currentAccessLevel() < UserEditorControlAccessLevel.AnyCompanyAdmin && (
                        <Textfield
                            name="selectCompany"
                            isCompact={true}
                            value={companyName}
                            readOnly={true}
                            isDisabled={true}
                        />
                    )}
                    {currentAccessLevel() >= UserEditorControlAccessLevel.AnyCompanyAdmin && (
                        <GeovisSelect
                            name="selectCompany"
                            placeholder={i18next.t("Select a company")}
                            isCompact={true}
                            value={companyToSelectOption(companiesStorage, user.CompanyId)}
                            isLoading={companiesStorage.isLoading}
                            options={companiesToOptions(companiesStorage)}
                            isSearchable={true}
                            defaultOptions={true}
                            onChange={onCompanyChangedHandler} />
                    )}
                </div>
            </div>

            <div className="flexRowContainer">
                <div className="flexCellContainer_g1">
                    <label htmlFor="tbEmail">{i18next.t("Email")}</label>
                    <Textfield
                        name="tbEmail"
                        isCompact={true}
                        defaultValue={user.Email}
                        isInvalid={isEmailInvalid(user)}
                        onChange={onTextfieldPropertyChangedHandlerDebounced<GeovisUserProfileInfo>("Email", onUserPropertyChanged)}
                        isDisabled={currentAccessLevel() < UserEditorControlAccessLevel.AnyCompanyAdmin}
                    />
                </div>
            </div>

            <div className="flexRowContainer">
                <div className="flexCellContainer_g1">
                    <label htmlFor="tbPhone">{i18next.t("Mobile Phone Number")}</label>
                    <GeovisPhoneNumber
                        key="tbPhone"
                        number={user.PhoneNumber}
                        countryCode={user.CountryCode}
                        //isDisabled={readOnlyNumber && !isOwnProfile}
                        isDisabled={currentAccessLevel() < UserEditorControlAccessLevel.OwnAccount}
                        onCountryCodeChanged={onNumberPropertyChangedHandler<GeovisUserProfileInfo>("CountryCode", onUserPropertyChanged)}
                        onNumberChanged={onStringPropertyChangedHandler<GeovisUserProfileInfo>("PhoneNumber", onUserPropertyChanged)}
                    />
                </div>
            </div>

            <div className='flexRowContainer'>
                <div className='flexCellContainer_g1'>
                    <label htmlFor='tbCultureInfo'>{t("User culture info")}</label>
                    <CultureInfoSelect
                        id='tbCultureInfo'
                        cultureId={user.CultureInfoId}
                        onCultureChanged={cId => onUserPropertyChanged("CultureInfoId", cId)}
                        labelContainerStyle={{ display: 'none' }}
                        selectContainerStyle={{ width: '100%' }}
                    />
                </div>
            </div>

            <div>
                <div className="flexRowContainer">
                    <div className="flexCellContainer_g1">
                        <label htmlFor="selectUserRole">{i18next.t("Role")}</label>
                        {currentAccessLevel() < UserEditorControlAccessLevel.AccountCompanyAdmin && (
                            <Textfield
                                name="selectUserRole"
                                isCompact={true}
                                defaultValue={getGeovisUserRoleEnumToName(user.GeovisUserRole)}
                                isDisabled={true} />
                        )}
                        {currentAccessLevel() >= UserEditorControlAccessLevel.AccountCompanyAdmin && (
                            <GeovisSelect<IGvOptionType<GeovisUserRoleEnum>>
                                name="selectUserRole"
                                value={getIGvOptionType(user.GeovisUserRole, getGeovisUserRoleEnumToName)}
                                options={getUserRoleSelectOptionsForCompany()}
                                onChange={onGeovisUserRoleChangedHandler} />
                        )}
                    </div>
                </div>
            </div>

            <div className="flexRowContainer">
                <div className="flexCellContainer_g1">
                    <Checkbox
                        key="cb-change-password-forbidden"
                        name="cb-change-password-forbidden"
                        label={t("User cannot change password")!}
                        isChecked={user.ChangePasswordForbidden}
                        //isDisabled={readonly || !canChangePasswordForbiddenFlag}
                        isDisabled={currentAccessLevel() < UserEditorControlAccessLevel.AccountCompanyAdmin}
                        onChange={onCheckboxPropertyChangedHandler<GeovisUserProfileInfo>("ChangePasswordForbidden", onUserPropertyChanged)} />
                </div>
            </div>

            <div className="flexRowContainer">
                <div style={{ margin: '5px' }}>
                    <Checkbox
                        key="cb-change-REST"
                        name="cb-change-REST"
                        label={t("Allow REST API using with ")!}
                        isChecked={user.RESTApiAllowed}
                        //isDisabled={readonly || !hasAdminRole || !user.CompanyAvailableFeatures.includes(LicensedFeatures.Rest_api)}
                        isDisabled={currentAccessLevel() < UserEditorControlAccessLevel.Admin || !user.CompanyAvailableFeatures.includes(LicensedFeatures.Rest_api)}
                        onChange={onCheckboxPropertyChangedHandler<GeovisUserProfileInfo>("RESTApiAllowed", onUserPropertyChanged)} />
                </div>
                <div style={{ width: '200px' }}>
                    <GeovisSelect
                        name="selectUserRESTRole"
                        isCompact={true}
                        options={getUserRESTRoleOptions()}
                        value={getSelectedRESTRole(user)}
                        //isDisabled={readonly || !user.RESTApiAllowed || !hasAdminRole || !user.CompanyAvailableFeatures.includes(LicensedFeatures.Rest_api)}
                        isDisabled={currentAccessLevel() < UserEditorControlAccessLevel.Admin || !user.CompanyAvailableFeatures.includes(LicensedFeatures.Rest_api) || !user.RESTApiAllowed}
                        onChange={onSelectPropertyChangedHandler<GeovisUserProfileInfo>("RESTApiRole", onUserPropertyChanged)}
                    />
                </div>
            </div>

            <div className="flexRowContainer">
                <div style={{ margin: '5px' }}>
                    <Checkbox
                        key="cb-change-Restr-REST"
                        name="cb-change-Restr-REST"
                        label={t("Restrict allowed databases for REST API access")}
                        isChecked={user.RESTApiRestrictDbs}
                        //isDisabled={readonly || !user.RESTApiAllowed || !hasAdminRole || !user.CompanyAvailableFeatures.includes(LicensedFeatures.Rest_api)}
                        isDisabled={currentAccessLevel() < UserEditorControlAccessLevel.Admin || !user.CompanyAvailableFeatures.includes(LicensedFeatures.Rest_api) || !user.RESTApiAllowed}
                        onChange={onCheckboxPropertyChangedHandler<GeovisUserProfileInfo>("RESTApiRestrictDbs", onUserPropertyChanged)} />
                </div>
            </div>

            <div className="flexRowContainer">
                <span style={{ alignSelf: 'center', marginLeft: '15px', marginRight: "10px" }}>{t("Allowed databases")}</span>
                <div style={{ width: '400px' }}>
                    <GeovisCheckboxSelect
                        name="selectUserRESTDbs"
                        isLoading={allowedDbsStorage.isLoading}
                        isMulti={true}
                        options={getAliasesOptions()}
                        value={getSelectedMsts()}
                        placeholder={`${user.RESTApiAllowedDbs.length} databases selected`}
                        controlShouldRenderValue={false}
                        isDisabled={currentAccessLevel() < UserEditorControlAccessLevel.Admin || !user.RESTApiAllowed || !user.RESTApiRestrictDbs || !user.CompanyAvailableFeatures.includes(LicensedFeatures.Rest_api)}
                        onChange={onMultySelectPropertyChangedHandler<GeovisUserProfileInfo>("RESTApiAllowedDbs", onUserPropertyChanged)}
                    />
                </div>
            </div>

            {(readonly || currentAccessLevel() < UserEditorControlAccessLevel.Creator) && (
                <div style={{ marginTop: '15px' }}>
                    <UserFeedbackButton
                        buttonText={t("Contact Administrator to change the user's profile")}
                        feedbackTitle={t("Request Change User Setting for '%1'").replace("%1", user.Email)} />
                </div>
            )}

            {!readonly && editMode === UserEditorMode.Create && (
                <div>
                    <div className="flexRowContainer">
                        <div className="flexCellContainer_g1">
                            <label htmlFor="tbPassword">{i18next.t("Password")}</label>
                            <Textfield
                                name="tbPassword"
                                type="password"
                                isCompact={true}
                                onChange={onTextfieldPropertyChangedHandlerDebounced<GeovisUserProfilePasswordModel>("Password", onPasswordPropertyChanged)}
                                isDisabled={readonly} />
                        </div>
                    </div>
                    <div className="flexRowContainer">
                        <div className="flexCellContainer_g1">
                            <label htmlFor="tbConfirmPassword">{i18next.t("ConfirmPassword")}</label>
                            <Textfield
                                name="tbConfirmPassword"
                                type="password"
                                isCompact={true}
                                onChange={onTextfieldPropertyChangedHandlerDebounced<GeovisUserProfilePasswordModel>("ConfirmPassword", onPasswordPropertyChanged)}
                                isDisabled={readonly} />
                        </div>
                    </div>
                </div>
            )}
            {isDowngradeRoleDialog && (
                <DowngradeUserRoleDialog
                    {...downgradeUserRoleDialogState}
                    onClose={hideDowngradeUserRoleDialog}
                    onDowngrade={onDowngradeUserRole}
                />
            )}
        </div>
    );
}

export const UserProfileEditorDetailsTab = withGeovisServer(UserProfileEditorDetailsTabView);