//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum SemiCircleParametersOrder {
	LowToHigh = 0,
	HighToLow = 1
}

const SemiCircleParametersOrderToName = new Map<number, string>([
	[ 0, 'LowToHigh' ],
	[ 1, 'HighToLow' ],
])

export function getSemiCircleParametersOrderToName(element: SemiCircleParametersOrder) : string {
    return SemiCircleParametersOrderToName.get(element) || '';
}

const SemiCircleParametersOrderByName = new Map<string, number>([
	[ 'LowToHigh', 0 ],
	[ 'HighToLow', 1 ],
]);

export function getSemiCircleParametersOrderByName(name: string): SemiCircleParametersOrder {
    return SemiCircleParametersOrderByName.get(name)!;
}


export const SemiCircleParametersOrderList : Readonly<SemiCircleParametersOrder[]> = [
	SemiCircleParametersOrder.LowToHigh,
	SemiCircleParametersOrder.HighToLow,
];



const SemiCircleParametersOrderToDescription = new Map<number, string>([
	[ 0, 'Low to high' ],
	[ 1, 'High to low' ],
])

export function getSemiCircleParametersOrderToDescription(element: SemiCircleParametersOrder) : string {
    return SemiCircleParametersOrderToDescription.get(element) || '';
}

