/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 24.04.2022
 * @description Geovis chart axis settings without sensors
 */

import { OptionType } from '@atlaskit/select';
import Textfield from '@atlaskit/textfield';
import React, { SyntheticEvent } from 'react';
import { t } from '../../../i18n';
import { ChartRegressionSettings } from '../../../server/AVTService/TypeLibrary/Common/ChartRegressionSettings';
import { ChartRegressionType, ChartRegressionTypeList, getChartRegressionTypeByName, getChartRegressionTypeToName } from '../../../server/AVTService/TypeLibrary/Common/ChartRegressionType';
import { ChartType } from '../../../server/AVTService/TypeLibrary/Common/ChartType';
import { getSensorValueAttributeByName, getSensorValueAttributeToDescription, getSensorValueAttributeToName, SensorValueAttribute } from '../../../server/AVTService/TypeLibrary/Common/SensorValueAttribute';
import { Distributors } from '../../../server/AVTService/TypeLibrary/DB/Distributors';
import { ChartSettingBool } from '../../../server/AVTService/TypeLibrary/Model/ChartSettingBool';
import { ChartSettingUint } from '../../../server/AVTService/TypeLibrary/Model/ChartSettingUint';
import { DestinationUnitConverter } from '../../../server/AVTService/TypeLibrary/Model/DestinationUnitConverter';
import { GeovisChartAxisSettings } from '../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartAxisSettings';
import { SensorHeatpulseOption } from '../../../server/AVTService/TypeLibrary/Model/GeovisCharts/SensorHeatpulseOption';
import { XyChartModel } from '../../../server/AVTService/TypeLibrary/Model/GeovisCharts/XyChartModel';
import { getPhysicalUnitSupportedName, PhysicalUnit, PhysicalUnitSupported } from '../../../server/AVTService/TypeLibrary/Sensors/PhysicalUnit';
import { getSensorCategoryName, SensorCategory } from '../../../server/AVTService/TypeLibrary/Sensors/SensorCategory';
import AuthService from '../../../services/AuthService';
import { GeovisSelect } from '../../select/GeovisSelect';
import { IGvOptionType } from '../../select/GeovisSelect_tools';
import AxisScaleLimitControl from './AxisScaleLimitControl';
import { ChartBooleanSettingProps, ChartSettingCheckbox, ChartTextSetting, CustomerChangeableChartBooleanSetting } from './ChartTab_tools';
import { DestUnitConverterComponent } from './DestUnitConverterComponent';
import SensorHeatpulseOptionControl from './SensorHeatpulseOptionControl';
import {
    compareChartTypes,
    compareNotChartTypes,
    compareValueAttribute,
    compareValueAttributes,
    getPhysicalUnitsOptionsOfCategory,
    getSensorCategory,
    getSensorHeatpulseOptions,
    isDtsChart,
    isVisibleSensorType,
    onChangeTextPropertyDebounced
} from './tools';
import { availableValueTypesForRelativeValues, AxisSettingsPropertyName, defaultChartLabelColumnStyle, defaultChartRowSettingsStyle, IGeovisChartAxisPropertyChangedProps } from './types';
import { XyChartLabelsHelp } from './xychart/XyChartLabelsHelp';
import { GeovisUnitSelect, getFullUnitName, IPhysicalUnitOption } from '../../select/GeovisUnitSelect';

export type AxisProperty = keyof GeovisChartAxisSettings;

interface IGeovisChartAxisSettingsControlProps extends IGeovisChartAxisPropertyChangedProps<GeovisChartAxisSettings> {
    xyChart?: XyChartModel;
    onXyChartPropertyChanged?: (propertyName: keyof XyChartModel, value: any) => void;

    chartType: ChartType;
    axisSettings: GeovisChartAxisSettings;

    sensorTypesToSelect: ReadonlyArray<SensorCategory>;
    isXAxis: boolean;
    isRightAxis: boolean;

    hideTypeOfSensor?: boolean;
    isHeatable?: boolean;

    isEventChart?: boolean;

    isDisabled: boolean;
}

const SensorTypeToUnitMap = new Map<SensorCategory, PhysicalUnit>([
    [SensorCategory.Unknown, PhysicalUnit.Undefined],
    [SensorCategory.Temperature, PhysicalUnit.Celsius],
    [SensorCategory.Stoke, PhysicalUnit.pWperpW],
    [SensorCategory.Attenuation, PhysicalUnit.dB],
    [SensorCategory.NormalizedTemperature, PhysicalUnit.NTemp],
    [SensorCategory.ThermalConductivity, PhysicalUnit.WpmpK]
])


const defaultSensorValueAttributes: ReadonlyArray<SensorValueAttribute> = [
    SensorValueAttribute.Axis,
    SensorValueAttribute.AxisDeviationLZ,
    SensorValueAttribute.AxisDeviationTZ,
    SensorValueAttribute.AxisValue1,
    SensorValueAttribute.AxisValue2,
    SensorValueAttribute.AxisValue3,
    SensorValueAttribute.ValuesXYZ,
    SensorValueAttribute.Value1,
    SensorValueAttribute.Value2,
    SensorValueAttribute.Value3,
    SensorValueAttribute.Deviation,
    SensorValueAttribute.DeviationXY

];

const xyChartSensorValueAttributes: ReadonlyArray<SensorValueAttribute> = [
    SensorValueAttribute.Axis,
    SensorValueAttribute.AxisDeviationLZ,
    SensorValueAttribute.AxisDeviationTZ,
    SensorValueAttribute.AxisValue1,
    SensorValueAttribute.AxisValue2,
    SensorValueAttribute.AxisValue3,
    SensorValueAttribute.ValuesXYZ,
    SensorValueAttribute.Value1,
    SensorValueAttribute.Value2,
    SensorValueAttribute.Value3,
    SensorValueAttribute.Deviation,
    SensorValueAttribute.DeviationXY,
    SensorValueAttribute.Reference1,
    SensorValueAttribute.Reference2,
    SensorValueAttribute.Reference3,
    SensorValueAttribute.AxisReference1,
    SensorValueAttribute.AxisReference2,
    SensorValueAttribute.AxisReference3,
    SensorValueAttribute.Param1,
    SensorValueAttribute.Param2,
    SensorValueAttribute.Param3,
    SensorValueAttribute.Param4,
    SensorValueAttribute.Param5,
    SensorValueAttribute.Km
];

const vibrationMainChartVelocitySensorValueAttributes: ReadonlyArray<SensorValueAttribute> = [
    SensorValueAttribute.PeakX,
    SensorValueAttribute.PeakY,
    SensorValueAttribute.PeakZ,
    SensorValueAttribute.PeakVector,
    SensorValueAttribute.VibrationVector
]

const vibrationMainChartFrequencySensorValueAttributes: ReadonlyArray<SensorValueAttribute> = [
    SensorValueAttribute.FrequencyX,
    SensorValueAttribute.FrequencyY,
    SensorValueAttribute.FrequencyZ,
    SensorValueAttribute.FrequencyVector,
]

const xyVibrationMainChartVelocitySensorValueAttributes: ReadonlyArray<SensorValueAttribute> = [
    SensorValueAttribute.PeakX,
    SensorValueAttribute.PeakY,
    SensorValueAttribute.PeakZ,
    SensorValueAttribute.PeakVector,
    SensorValueAttribute.VSum
]

const xyVibrationMainChartFrequencySensorValueAttributes: ReadonlyArray<SensorValueAttribute> = [
    SensorValueAttribute.DomFreqX,
    SensorValueAttribute.DomFreqY,
    SensorValueAttribute.DomFreqZ,
    SensorValueAttribute.DomFreqVector,
    SensorValueAttribute.FreqMaxVcomponent
]
const vibrationEventChartVelocitySensorValueAttributes: ReadonlyArray<SensorValueAttribute> = [
    SensorValueAttribute.PeakX,
    SensorValueAttribute.PeakY,
    SensorValueAttribute.PeakZ,
    SensorValueAttribute.PeakXYZ,
    SensorValueAttribute.PeakVector,
    SensorValueAttribute.VSum,
    SensorValueAttribute.Value1,
    SensorValueAttribute.Value2,
    SensorValueAttribute.Value3,
    SensorValueAttribute.ValuesXYZ,
    SensorValueAttribute.ValuesXYZAndVector,
    SensorValueAttribute.EventAV,
];

const vibrationEventChartFrequencySensorValueAttributes: ReadonlyArray<SensorValueAttribute> = [
    SensorValueAttribute.DomFreqX,
    SensorValueAttribute.DomFreqY,
    SensorValueAttribute.DomFreqZ,
    SensorValueAttribute.DomFreqXYZ,
    SensorValueAttribute.DomFreqVector,
];

export const GeovisChartAxisSettingsControl = ({
    axisSettings,
    chartType,
    onAxisPropertyChanged,
    onAxisPropertiesChanged,
    isXAxis,
    sensorTypesToSelect,
    hideTypeOfSensor,
    xyChart,
    isRightAxis,
    isHeatable,
    isEventChart,
    isDisabled
}: IGeovisChartAxisSettingsControlProps) => {
    const labelColumnStyle = defaultChartLabelColumnStyle;
    const settingsRowStyle = defaultChartRowSettingsStyle;

    const isPolynomial = axisSettings.RegressionSettings.FunctionType === ChartRegressionType.Polynomial;

    const getChartRegressionType = (type: any): ChartRegressionType => {
        let value = type as ChartRegressionType;
        if (typeof type === 'string') {
            value = getChartRegressionTypeByName(type);
        }
        return value;
    }

    const typeOfRegressionOption = (type: any): OptionType => {
        const value = getChartRegressionType(type);
        return { label: getChartRegressionTypeToName(value), value };
    }

    const typeOfRegressionOptions = (): OptionType[] => ChartRegressionTypeList.map(typeOfRegressionOption);

    const typeOfSensorOption = (sensorCategory: any): OptionType => {
        const value = getSensorCategory(sensorCategory);
        return { label: getSensorCategoryName(value), value };
    }

    const typeOfSensorOptions = (): OptionType[] => sensorTypesToSelect.filter(isVisibleSensorType).map(typeOfSensorOption);

    const getPhysicalUnitBySensorType = (sensorCategory: SensorCategory): PhysicalUnit => {
        const possiblePhysicalUnit = SensorTypeToUnitMap.get(getSensorCategory(sensorCategory));
        return possiblePhysicalUnit === undefined ? PhysicalUnit.Undefined : possiblePhysicalUnit;
    }

    const getPhysicalUnitOptions = (sensorCategory: SensorCategory): IPhysicalUnitOption[] => {
        if (compareChartTypes(chartType, ChartType.XYDiagram)) {
            const sensorValueAttribute = getSensorValueAttributeByName(axisSettings.sensorValue);
            if (compareValueAttribute(sensorValueAttribute, SensorValueAttribute.Param1, SensorValueAttribute.Param2, SensorValueAttribute.Param3, SensorValueAttribute.Param4, SensorValueAttribute.Param5)) {
                return getPhysicalUnitOptionsList(PhysicalUnitSupported);
            }
            if (compareValueAttribute(sensorValueAttribute, SensorValueAttribute.Km)) {
                return getPhysicalUnitOptionsList([PhysicalUnit.Meter, PhysicalUnit.Kilometer]);
            }
            if (isXAxis && hideTypeOfSensor && compareValueAttribute(sensorValueAttribute, SensorValueAttribute.LocalCoordAngle)) {
                return getPhysicalUnitOptionsList([PhysicalUnit.Degree]);
            }
            if (isXAxis && hideTypeOfSensor && compareValueAttribute(sensorValueAttribute, SensorValueAttribute.LocalCoordRadius, SensorValueAttribute.LocalCoordTunnelmeter)) {
                return getPhysicalUnitOptionsList([PhysicalUnit.Meter]);
            }
        }
        return getPhysicalUnitsOptionsOfCategory(sensorCategory);
    }

    const getPhysicalUnitOptionsList = (units: PhysicalUnit[]): IPhysicalUnitOption[] => {
        return units.map(unit => ({
            value: unit,
            label: getFullUnitName(unit)
        }));
    }

    const getBooleanSetting = (setting: ChartSettingBool): ChartSettingBool => {
        return setting || { value: false, customerChangeable: false };
    }

    const onChangeTypeOfSensor = (option: IGvOptionType<SensorCategory>) => {
        if (!option) {
            return;
        }

        const typeOfSensor = option.value;
        const changes: Partial<GeovisChartAxisSettings> = {
            TypeOfSensor: typeOfSensor,
            Unit: getPhysicalUnitBySensorType(typeOfSensor)
        }

        if (axisSettings.TypeOfSensor !== typeOfSensor) {
            changes.SensorIds = [];
        }

        if (isDtsChart(chartType)) {
            const possibleHeatpulseOptions = getSensorHeatpulseOptions(typeOfSensor);
            changes.HeatpulseOption = possibleHeatpulseOptions.length > 0 ? possibleHeatpulseOptions[0] : SensorHeatpulseOption.Default;
        }

        onAxisPropertiesChanged(changes);
    }

    const onAxisPropertyChangedFunc = <TValue extends any>(propertyName: AxisProperty) => (value: TValue) => {
        onAxisPropertyChanged(propertyName, value);
    }

    const onSelectablePropertyChangedFunc = (propertyName: AxisProperty) => (option: OptionType) => {
        switch (propertyName) {
            case "sensorValue":
                if (!availableValueTypesForRelativeValues.includes(getSensorValueAttributeByName(option.value.toString()))) {
                    const changes: Partial<GeovisChartAxisSettings> = {
                        sensorValue: option.value.toString(),
                        ShowAsRelative: {
                            value: false,
                            customerChangeable: axisSettings.ShowAsRelative.customerChangeable
                        }
                    };
                    onAxisPropertiesChanged(changes);
                } else {
                    onAxisPropertyChanged(propertyName, option.value);
                }
                break;

            case "DestConverter":
                onAxisPropertyChangedFunc<DestinationUnitConverter>(propertyName)({ ...axisSettings.DestConverter, unit: getPhysicalUnitSupportedName(+option.value) });
                break;

            default:
                onAxisPropertyChanged(propertyName, +option.value);
                break;
        }
    }

    const onSelectablePropertyUnitChangedFunc = (propertyName: AxisProperty) => (unit: PhysicalUnit) => {
        if (propertyName === "Unit") {
            onAxisPropertyChanged(propertyName, unit);
        }
    }

    const onBooleanPropertyChangedFunc = (propertyName: AxisProperty) => (value: boolean) => {
        switch (propertyName) {
            case "DestConverter":
                onAxisPropertyChangedFunc<DestinationUnitConverter>(propertyName)({ ...axisSettings.DestConverter, enable: value });
                break;

            case "showAxisLabels":
                onAxisPropertyChangedFunc<ChartSettingBool>(propertyName)({ ...axisSettings.showAxisLabels, value });
                break;

            default:
                onAxisPropertyChanged(propertyName, value);
                break;
        }
    }

    const onRegressionTypeChanged = (option: IGvOptionType<ChartRegressionType>) => {
        if (!option) {
            return;
        }

        const rs: ChartRegressionSettings = { ...axisSettings.RegressionSettings, FunctionType: option.value };
        onAxisPropertyChanged("RegressionSettings", rs);
    }

    const onChangeRegressionNumberPolynomialMembers = (event: SyntheticEvent<HTMLInputElement>) => {
        onChangeTextPropertyDebounced(+event.currentTarget.value, (value) => {
            const rs: ChartRegressionSettings = { ...axisSettings.RegressionSettings, NumberPolinomMembers: +value };
            onAxisPropertyChanged("RegressionSettings", rs);
        });
    }

    const onChangeStringProperty = (propertyName: AxisProperty) => (v1: string) => {
        onChangeTextPropertyDebounced(v1, (v2) => {
            onAxisPropertyChanged(propertyName, v2);
        });
    }

    const onChangeAxisNumberOfDigits = (value: string) => {
        const update: ChartSettingUint = {
            ...axisSettings.numberOfDigits,
            value: +value
        }
        onAxisPropertyChanged("numberOfDigits", update);
    }

    const onChangeSensorHeatpulseOption = (option: SensorHeatpulseOption) => {
        onAxisPropertyChanged("HeatpulseOption", option);
    }

    const getRightAxisRelativeTooltip = () => (
        <div>
            <div>{t("This setting only affects for Left Y axis")}</div>
        </div>
    )

    const getPossibleAttributesByType = (): ReadonlyArray<SensorValueAttribute> => {

        if (compareChartTypes(chartType, ChartType.XYDiagram)) {

            if (AuthService.getDistribution() === Distributors.Nagra) {

                const nagraXyAttributes: ReadonlyArray<SensorValueAttribute> = [
                    ...xyChartSensorValueAttributes,
                    SensorValueAttribute.LocalCoordAngle,
                    SensorValueAttribute.LocalCoordTunnelmeter,
                    SensorValueAttribute.LocalCoordRadius
                ];

                return nagraXyAttributes;
            }

            return xyChartSensorValueAttributes;
        }

        if (compareChartTypes(chartType, ChartType.VibrationDiagramm) && isEventChart && !isRightAxis) {
            return vibrationEventChartVelocitySensorValueAttributes;
        }

        if (compareChartTypes(chartType, ChartType.VibrationDiagramm) && isEventChart && isRightAxis) {
            return vibrationEventChartFrequencySensorValueAttributes;
        }

        if (compareChartTypes(chartType, ChartType.VibrationDiagramm) && !isEventChart && !isRightAxis) {
            return vibrationMainChartVelocitySensorValueAttributes;
        }

        if (compareChartTypes(chartType, ChartType.VibrationDiagramm) && !isEventChart && isRightAxis) {
            return vibrationMainChartFrequencySensorValueAttributes;
        }

        if (compareChartTypes(chartType, ChartType.XYVibrationEventDiagram) && isEventChart && isRightAxis) {
            return vibrationEventChartFrequencySensorValueAttributes;
        }

        if (compareChartTypes(chartType, ChartType.XYVibrationEventDiagram) && isEventChart && !isRightAxis) {
            return vibrationEventChartVelocitySensorValueAttributes;
        }

        if (compareChartTypes(chartType, ChartType.XYVibrationEventDiagram) && !isEventChart && isRightAxis) {
            return xyVibrationMainChartFrequencySensorValueAttributes;
        }

        if (compareChartTypes(chartType, ChartType.XYVibrationEventDiagram) && !isEventChart && !isRightAxis) {
            return xyVibrationMainChartVelocitySensorValueAttributes;
        }

        return defaultSensorValueAttributes;
    }

    const getSensorAttributeOptions = (): ReadonlyArray<IGvOptionType<string>> => {

        const result = getPossibleAttributesByType().map<IGvOptionType<string>>(a => ({
            label: getSensorValueAttributeToDescription(a),
            value: getSensorValueAttributeToName(a)
        }));

        return result;
    }

    const getSelectedValueType = (valAttr: string): OptionType => {
        return { value: valAttr, label: getSensorValueAttributeToDescription(getSensorValueAttributeByName(valAttr)) }
    }

    const drawShowUnitInLabels = (): boolean => {
        return !isXAxis || !isDtsChart(chartType);
    }

    const disableUnitsControl = (): boolean => {
        if (isDtsChart(chartType)) {
            return true;
        }
        return axisSettings.TypeOfSensor === SensorCategory.Unknown;
    }

    const drawValueTypeSelector = (): boolean => {
        if (isDtsChart(chartType)) {
            return false;
        }
        return true;
    }

    const checkAxisLabelEnabled = (labelNumber: number, showAxisLabels: boolean, onlyOneAxisPossible: boolean = false): boolean => {
        if (!showAxisLabels) {
            return false;
        }
        if (onlyOneAxisPossible) {
            return true;
        }
        switch (labelNumber) {
            case 1:
                return compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.ValuesXYZ)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.Value1)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.Km)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.Deviation)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.DeviationXY)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.AxisDeviationLZ)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.AxisDeviationTZ)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.Param1)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.Param2)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.Param3)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.Param4)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.Param5)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.References)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.Reference1)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.AxisReference1)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.Axis)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.AxisValue1)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.PeakX)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.PeakXYZ)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.PeakVector)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.DomFreqX)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.DomFreqXYZ)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.DomFreqVector)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.ValuesVector)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.ValuesXYZAndVector)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.EventAV)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.VSum)
            case 2:
                return compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.ValuesXYZ)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.Value2)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.Reference2)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.AxisReference2)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.Axis)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.AxisValue2)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.PeakY)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.PeakXYZ)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.DomFreqY)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.DomFreqXYZ)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.ValuesXYZAndVector)
            case 3:
                return compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.ValuesXYZ)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.Value3)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.Reference3)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.AxisReference3)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.Axis)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.AxisValue3)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.PeakXYZ)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.PeakZ)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.DomFreqZ)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.DomFreqXYZ)
                    || compareValueAttributes(axisSettings.sensorValue, SensorValueAttribute.ValuesXYZAndVector)
            default:
                return false;
        }
    }

    const componentKey = `${isXAxis ? "x_" : "y_"}${isEventChart ? "event_" : ""}axis_settings`;

    return (
        <React.Fragment key={componentKey}>
            {!hideTypeOfSensor &&
                <div className="flexRowContainerLine" style={settingsRowStyle}>
                    <div style={labelColumnStyle}>
                        <label htmlFor="cmbTypeOfSensors" >{t("Type of Sensor")}:</label>
                    </div>
                    <div className="flexCellContainer_g1_m0">
                        <GeovisSelect
                            id="cmbTypeOfSensors"
                            options={typeOfSensorOptions()}
                            value={typeOfSensorOption(axisSettings.TypeOfSensor)}
                            spacing='compact'
                            onChange={onChangeTypeOfSensor}
                            isDisabled={isDisabled}
                        />
                    </div>
                </div>
            }

            {isDtsChart(chartType) && !isXAxis && (
                <div className="flexRowContainerLine" style={settingsRowStyle}>
                    <div style={labelColumnStyle}>
                        <label htmlFor="cmbHeatpulseFilter">{t("Filter")}:</label>
                    </div>
                    <div className="flexCellContainer_g1_m0">
                        <SensorHeatpulseOptionControl
                            id="cmbHeatpulseFilter"
                            isHeatable={isHeatable === undefined ? false : isHeatable}
                            sensorType={axisSettings.TypeOfSensor}
                            heatpulseOption={axisSettings.HeatpulseOption}
                            onChangeOption={onChangeSensorHeatpulseOption} />
                    </div>
                </div>
            )}

            {drawValueTypeSelector() && (
                <div className="flexRowContainerLine" style={settingsRowStyle}>
                    <div style={labelColumnStyle}>
                        <label htmlFor="cmbValues">{t("Value")}:</label>
                    </div>
                    <div className="flexCellContainer_g1_m0">
                        <GeovisSelect
                            id="cmbValues"
                            value={getSelectedValueType(axisSettings.sensorValue)}
                            options={getSensorAttributeOptions()}
                            isDisabled={isDisabled}
                            onChange={onSelectablePropertyChangedFunc("sensorValue")}
                        />
                    </div>
                </div>
            )}

            <div className="flexRowContainerLine" style={settingsRowStyle}>
                <div style={labelColumnStyle}>
                    <label htmlFor="cmbUnits">{t("Unit")}:</label>
                </div>
                <div className="flexCellContainer_g1_m0">
                    <GeovisUnitSelect
                        id="cmbUnits"
                        value={axisSettings.Unit}
                        isDisabled={disableUnitsControl() || isDisabled}
                        options={getPhysicalUnitOptions(axisSettings.TypeOfSensor)}
                        spacing='compact'
                        onChange={(option) => onSelectablePropertyUnitChangedFunc("Unit")(option)}
                        customEditorStyle={{ width: '100%' }}
                    />
                </div>
            </div>

            <AxisScaleLimitControl
                scaleLimit={axisSettings.minScaleLimit}
                tbLabel={t("Min value")}
                onChange={onAxisPropertyChangedFunc("minScaleLimit")}
                hideCustomerChangeable={isEventChart}
                isDisabled={isDisabled}
            />

            <AxisScaleLimitControl
                scaleLimit={axisSettings.maxScaleLimit}
                tbLabel={t("Max value")}
                onChange={onAxisPropertyChangedFunc("maxScaleLimit")}
                hideCustomerChangeable={isEventChart}
                isDisabled={isDisabled}
            />

            {isXAxis && compareChartTypes(chartType, ChartType.DtsChart, ChartType.HeatMap) && (
                <CustomerChangeableChartBooleanSetting
                    isDisabled={isDisabled ?? false}
                    onChange={onAxisPropertyChangedFunc("ReverseAxis")}
                    setting={axisSettings.ReverseAxis}
                    settingLabel={t("Ascending from right to left instead from left to right")}
                    alignToSecondRow={true}
                />
            )}

            <ChartTextSetting
                labelColumnStyle={{ width: '30%' }}
                labelText={t("Axis number of digits")}
                settingsStyle={settingsRowStyle}
                value={axisSettings.numberOfDigits.value}
                type="number"
                htmlName="tbAxisNumberOfDigits"
                onChangeTextField={onChangeAxisNumberOfDigits}
                isDisabled={isDisabled} />

            {(!isXAxis && compareNotChartTypes(chartType, ChartType.VibrationDiagramm, ChartType.XYVibrationEventDiagram) || isXAxis && compareChartTypes(chartType, ChartType.XYDiagram)) &&
                <CustomerChangeableChartBooleanSetting
                    setting={getBooleanSetting(axisSettings.ShowAsRelative)}
                    settingLabel={t("Show as relative")}
                    onChange={onAxisPropertyChangedFunc("ShowAsRelative")}
                    isDisabled={isRightAxis || !availableValueTypesForRelativeValues.includes(getSensorValueAttributeByName(axisSettings.sensorValue)) || isDisabled}
                    tooltipContent={compareChartTypes(chartType, ChartType.XYDiagram) ? undefined : getRightAxisRelativeTooltip()}
                />
            }

            {!isXAxis && compareChartTypes(chartType, ChartType.TimeValue, ChartType.XYDiagram) && (
                <>
                    <CustomerChangeableChartBooleanSetting
                        setting={getBooleanSetting(axisSettings.ShowRegression)}
                        settingLabel={t("Show regression")}
                        onChange={onAxisPropertyChangedFunc("ShowRegression")}
                        isDisabled={isDisabled} />

                    {!isXAxis && compareChartTypes(chartType, ChartType.TimeValue, ChartType.XYDiagram) && (
                        <div className="flexRowContainerLine" style={{ alignItems: 'center', display: 'flex' }}>
                            <div style={{ width: '30%' }}>
                                <label htmlFor="cmbRegressionTypes">{t("Regression type")}:</label>
                            </div>
                            <div className="flexCellContainer_g1_m0" style={{ width: "100px", flexGrow: 4 }}>
                                <GeovisSelect
                                    id="cmbRegressionTypes"
                                    value={typeOfRegressionOption(axisSettings.RegressionSettings.FunctionType)}
                                    options={typeOfRegressionOptions()}
                                    onChange={onRegressionTypeChanged}
                                    spacing='compact'
                                    isDisabled={!axisSettings.ShowRegression.value || isDisabled}
                                />
                            </div>
                        </div>
                    )}

                    {!isXAxis && compareChartTypes(chartType, ChartType.TimeValue, ChartType.XYDiagram) && isPolynomial && (
                        <div className="flexRowContainerLine" style={{ alignItems: 'center', display: 'flex' }}>
                            <div style={{ width: '30%' }}>
                                <label htmlFor="tbRegressionNumberPolinomMembers">{t("Number of polinom members")}:</label>
                            </div>
                            <div className="flexCellContainer_g1_m0" style={{ width: "100px", flexGrow: 4 }}>
                                <Textfield
                                    name="tbRegressionNumberPolinomMembers"
                                    isCompact={true}
                                    defaultValue={axisSettings.RegressionSettings.NumberPolinomMembers}
                                    isDisabled={!axisSettings.ShowRegression.value || isDisabled}
                                    onChange={onChangeRegressionNumberPolynomialMembers} />
                            </div>
                        </div>
                    )}
                </>
            )}

            <ChartBooleanSettingProps
                label={t("Show axis labels")}
                isChecked={axisSettings.showAxisLabels.value}
                onChange={onBooleanPropertyChangedFunc("showAxisLabels")}
                isDisabled={isDisabled}
            />

            {drawShowUnitInLabels() && (
                <ChartSettingCheckbox
                    label={t("Show unit in labels")}
                    isChecked={axisSettings.showAxisLabelsUnit}
                    onChange={onBooleanPropertyChangedFunc("showAxisLabelsUnit")}
                    isDisabled={isDisabled}
                />
            )}

            {isDtsChart(chartType) &&
                <ChartTextSetting
                    labelColumnStyle={labelColumnStyle}
                    settingsStyle={settingsRowStyle}
                    labelText={t("Axis label")}
                    value={axisSettings.axisXLabel}
                    htmlName="tbAxisLabel"
                    onChangeTextField={onChangeStringProperty(AxisSettingsPropertyName("axisXLabel"))}
                    isDisabled={isDisabled} />
            }

            {compareChartTypes(chartType, ChartType.TimeValue, ChartType.XYDiagram, ChartType.VibrationDiagramm, ChartType.XYVibrationEventDiagram) &&
                <ChartTextSetting
                    labelColumnStyle={labelColumnStyle}
                    settingsStyle={settingsRowStyle}
                    labelText={t("Axis label 1")}
                    value={axisSettings.axisXLabel}
                    htmlName={isEventChart ? `tbAxisXLabel_event` : `tbAxisXLabel`}
                    isDisabled={!checkAxisLabelEnabled(1, axisSettings.showAxisLabels.value, compareChartTypes(chartType, ChartType.VibrationDiagramm, ChartType.XYVibrationEventDiagram) && !isEventChart) || isDisabled}
                    onChangeTextField={onChangeStringProperty(AxisSettingsPropertyName("axisXLabel"))} />
            }

            {(compareChartTypes(chartType, ChartType.TimeValue, ChartType.XYDiagram) || compareChartTypes(chartType, ChartType.VibrationDiagramm, ChartType.XYVibrationEventDiagram) && isEventChart) &&
                <ChartTextSetting
                    labelColumnStyle={labelColumnStyle}
                    settingsStyle={settingsRowStyle}
                    labelText={t("Axis label 2")}
                    value={axisSettings.axisYLabel}
                    isDisabled={!checkAxisLabelEnabled(2, axisSettings.showAxisLabels.value) || isDisabled}
                    htmlName="tbAxisYLabel"
                    onChangeTextField={onChangeStringProperty(AxisSettingsPropertyName("axisYLabel"))} />
            }

            {(compareChartTypes(chartType, ChartType.TimeValue, ChartType.XYDiagram) || compareChartTypes(chartType, ChartType.VibrationDiagramm, ChartType.XYVibrationEventDiagram) && isEventChart) &&
                <ChartTextSetting
                    labelColumnStyle={labelColumnStyle}
                    settingsStyle={settingsRowStyle}
                    labelText={t("Axis label 3")}
                    isDisabled={!checkAxisLabelEnabled(3, axisSettings.showAxisLabels.value) || isDisabled}
                    value={axisSettings.axisZLabel}
                    htmlName="tbAxisZLabel"
                    onChangeTextField={onChangeStringProperty(AxisSettingsPropertyName("axisZLabel"))} />
            }

            {xyChart && isXAxis && (
                <CustomerChangeableChartBooleanSetting
                    setting={getBooleanSetting(axisSettings.ShowXAxisSensorName)}
                    settingLabel={t("Show sensor name instead of value as axis label")}
                    onChange={onAxisPropertyChangedFunc("ShowXAxisSensorName")}
                    isDisabled={xyChart.UseXYAxisDataBasedOnDifferentSensors || isDisabled}
                />
            )}

            {xyChart && (
                <ChartSettingCheckbox
                    label={t("Vertical axis labels")}
                    isChecked={axisSettings.UseVerticalAxisLabels}
                    onChange={onBooleanPropertyChangedFunc("UseVerticalAxisLabels")}
                    isDisabled={isDisabled}
                />
            )}

            {isXAxis && compareChartTypes(chartType, ChartType.XYDiagram) && (
                <React.Fragment>

                    <ChartSettingCheckbox
                        label={t("Reduce number of Axis labels")}
                        isChecked={axisSettings.reduceAxisLabelsCount}
                        onChange={onBooleanPropertyChangedFunc("reduceAxisLabelsCount")}
                        isDisabled={isDisabled}
                    />

                    <div className='flexRowContainerLine'>
                        <ChartTextSetting
                            labelText={t("Skip labels")}
                            htmlName='txFirstLabel'
                            value={axisSettings.reduceAxisLabelsStartAt}
                            isDisabled={!axisSettings.reduceAxisLabelsCount || isDisabled}
                            type={'number'}
                            min={0}
                            onChangeTextField={onChangeStringProperty("reduceAxisLabelsStartAt")} />

                        <ChartTextSetting
                            htmlName='txLabelInterval'
                            labelText={t("Labels interval")}
                            value={axisSettings.reduceAxisLabelsInterval}
                            isDisabled={!axisSettings.reduceAxisLabelsCount || isDisabled}
                            type={'number'}
                            min={1}
                            onChangeTextField={onChangeStringProperty("reduceAxisLabelsInterval")} />

                    </div>

                    <XyChartLabelsHelp
                        enabled={axisSettings.reduceAxisLabelsCount && !isDisabled}
                        startAt={axisSettings.reduceAxisLabelsStartAt}
                        interval={axisSettings.reduceAxisLabelsInterval}
                        minAxisValue={axisSettings.minScaleLimit}
                        maxAxisValue={axisSettings.maxScaleLimit}
                    />
                </React.Fragment>
            )}

            {compareChartTypes(chartType, ChartType.TimeValue, ChartType.XYDiagram) && (
                <DestUnitConverterComponent
                    isDisabled={isDisabled}
                    converterValue={axisSettings.DestConverter}
                    onConverterChanged={onAxisPropertyChangedFunc("DestConverter")}
                />
            )}
        </React.Fragment>
    )
}