import { t } from "i18next";
import { formattedDateTime } from "../../../../../../helpers/DateHelper";
import { SemiCircleChartResponseModel } from "../../../../../../server/AVTService/Computation/ChartComputation/ResponseModel/SemiCircleChartResponseModel";
import { SemiCircleChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/SemiCircleChartModel";
import { SemiCircleVisualSettings } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/SemiCircleVisualSettings";
import { ProjectVisualSettings } from "../../../../../../server/AVTService/TypeLibrary/Model/ProjectVisualSettings";
import { SemiCircleChartDataModel } from "../../../../../../server/AVTService/TypeLibrary/Model/SemiCircleChartDataModel";
import { createChartRenderedFunc } from "../../tools";
import { SemiCirclePlotValuesMode } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/SemiCirclePlotValuesMode";
import { SemiCircleType } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/SemiCircleType";
import { SemiCircleValuesSettings } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/SemiCircleValuesSettings";
import { getSemiCirclePaletteData, IColorPalette } from "../tools";
import { getPhysicalUnitShortName } from "../../../../../../server/AVTService/TypeLibrary/Sensors/PhysicalUnit";

interface ISemiCircleRenderOptionsSettings {
    chart: SemiCircleChartModel,
    projectVisualSettings: ProjectVisualSettings
    onChartRender: () => void;
    startMeasurement: string;
    lastMeasurement: string;
    data: SemiCircleChartDataModel[];
    chartTitle: string;
    timeslot: string;
    isPreview: boolean;
}

export const getSemiCircleChartRenderOptions = (pageNum: number, chart: SemiCircleChartModel, chartData: SemiCircleChartResponseModel, isPreview: boolean): Highcharts.Options => {

    const renderOptionsSettings: ISemiCircleRenderOptionsSettings = {
        chart,
        onChartRender: createChartRenderedFunc(pageNum, chart.Id),
        startMeasurement: chartData.startMeasurementTime,
        lastMeasurement: chartData.LastMeasurementTime,
        projectVisualSettings: chartData.visualSettings,
        data: chartData.Data,
        chartTitle: chartData.SlimChartConfig.Title,
        timeslot: chartData.Timeslot,
        isPreview
    }

    return getSingleOptions(renderOptionsSettings);
}

const getSingleOptions = ({
    chart,
    onChartRender,
    startMeasurement,
    projectVisualSettings,
    data,
    chartTitle,
    isPreview
}: ISemiCircleRenderOptionsSettings): Highcharts.Options => {
    const { VisualSettings, ValueSettings } = chart;

    const series = getSensorValuesSeries(data, VisualSettings, ValueSettings, chart.SemiCircleType === SemiCircleType.SensorValues);

    const isHalfCircle = Math.abs(VisualSettings.StartAngle) <= 90 && Math.abs(VisualSettings.EndAngle) <= 90;

    const labelPlacementPercent = VisualSettings.InnerCircleSize <= 50 ? -VisualSettings.InnerCircleSize - 10 : -20;

    const getMarginByLines = (countOfLInes: number): number => {
        switch (countOfLInes) {
            case 1: return 4;
            case 2: return -14;
            case 3: return -22;
        }

        return 0;
    }

    const hasDataLabels = (): boolean => {
        return chart.SemiCircleType === SemiCircleType.SensorValues && (VisualSettings.ShowSensorsNames || VisualSettings.PlotValuesInCircle || VisualSettings.PlotParametersInCircle)
            || chart.SemiCircleType !== SemiCircleType.SensorValues && (VisualSettings.PlotGroupNameInCircle || VisualSettings.PlotAmountPerGroup);
    }

    const getChartSize = (): number => {
        if (isPreview) {
            return 75
        }

        return hasDataLabels()
            ? chart.DrawLabelsOutside
                ? 70
                : isHalfCircle
                    ? 100
                    : 80
            : 100
    }

    const options: Highcharts.Options = {
        title: !chartTitle ? {
            floating: false,
            text: "<div style='height:10px'></div>",
            useHTML: true
        } : {
            align: 'center',
            verticalAlign: 'middle',
            text: processCenterTitle(chartTitle, chart, data),
            floating: isHalfCircle,
            y: isHalfCircle ? 100 : 30
        },
        tooltip: {
            useHTML: true,
            formatter() {
                const point = this.point as any;

                let result = `<div style="display: flex; flex-direction: column; gap: 5px;">`;
                result += `<span style="fontWeight: bold">${point.name}</span>`;
                if (chart.SemiCircleType === SemiCircleType.SensorValues && point.value !== undefined && point.value !== null) {
                    result += `<span>${point.value.toFixed(ValueSettings.Decimals)} ${getPhysicalUnitShortName(ValueSettings.Unit)}</span>`;
                }
                else if (chart.SemiCircleType === SemiCircleType.SensorValues && (point.value === undefined || point.value === null)) {
                    result += `<span>No data</span>`;
                }
                // else if (chart.SemiCircleType === SemiCircleType.Grouping) {
                //     result += `<div><span style="fontWeight: bold">Count: </span><span>${point.value}</span></div>`;
                // }
                else if (chart.SemiCircleType !== SemiCircleType.SensorValues) {
                    result += `<div><span>${point.value.toFixed(1)}% (${point.custom.parameterValue})</span></div>`;
                }
                if (point.description && chart.SemiCircleType === SemiCircleType.SensorValues) {
                    result += `<span>${point.description}</span>`;
                }
                result += `</div>`;
                return result;
            }
        },

        chart: {
            animation: false,
            events: {
                render: onChartRender
            }
        },
        plotOptions: {
            pie: {
                startAngle: +VisualSettings.StartAngle,
                endAngle: +VisualSettings.EndAngle,
                size: `${getChartSize()}%`,
                center: isHalfCircle ? ['50%', '67%'] : ['50%', '50%'],
                animation: false,
                dataLabels: {
                    enabled: true,
                    defer: true,
                    crop: false,
                    connectorWidth: hasDataLabels() ? 1 : 0,
                    distance: chart.DrawLabelsOutside ? 20 : `${labelPlacementPercent}%`,
                    //alignTo: 'plotEdges',
                    //position: 'center',
                    style: {
                        fontSize: projectVisualSettings.chartAxisLabelFontSize,
                        color: 'black'
                    },
                    overflow: 'allow',
                    //padding: -15,

                    //format: 'SEnsor - {point.name}<br/>Line 2<br/>Line 3'
                    useHTML: true,
                    formatter() {
                        const point = this.point as any;
                        let countLines = 0;
                        if (chart.SemiCircleType === SemiCircleType.SensorValues) {
                            let result = `<div style="display: flex; flex-direction: column; gap: 2px; align-items: center; margin-top: {REPLACE_PIXELS}px">`;
                            if (VisualSettings.ShowSensorsNames) {
                                result += `<div><span style="fontWeight: bold">${point.name}</span></div>`;
                                countLines++;
                            }
                            if (VisualSettings.PlotValuesInCircle && point.value !== null && point.value !== undefined) {
                                result += `<div><span style="fontWeight: normal">${point.value.toFixed(ValueSettings.Decimals)}</span></div>`;
                                countLines++;
                            }
                            if (VisualSettings.PlotParametersInCircle && (VisualSettings.PlotValuesMode === SemiCirclePlotValuesMode.All || point.custom.isFirst || point.custom.isLast)) {
                                result += `<div><span style="fontWeight: normal">${point.custom.parameterValue}</span></div>`;
                                countLines++;
                            }
                            result += `</div>`;
                            return result.replace("{REPLACE_PIXELS}", getMarginByLines(countLines).toString());
                            //return result;
                        }
                        else {
                            let result = `<div style="display: flex; flex-direction: column; gap: 2px; align-items: center; margin-top: {REPLACE_PIXELS}px">`;
                            if (VisualSettings.PlotGroupNameInCircle) {
                                result += `<div><span style="fontWeight: bold">${point.name}</span></div>`;
                                countLines++;
                            }
                            if (VisualSettings.PlotAmountPerGroup) {
                                result += `<div><span>${point.value.toFixed(1)}% (${point.custom.parameterValue})</span></div>`;
                                countLines++;
                            }
                            result += `</div>`;
                            return result.replace("{REPLACE_PIXELS}", getMarginByLines(countLines).toString());
                            //return result;
                        }
                        return "";
                    }
                }
            }
        },
        series,
        credits: {
            enabled: false
        }
    }

    const subtitleContent: string[] = [];

    if (chart.ShowStartMeasurements) {
        const startMeasurementText = startMeasurement ? formattedDateTime(startMeasurement, "DD.MM.YYYY") : 'no data';
        subtitleContent.push(`${t("Start of measurements")}: ${startMeasurementText}`);
    }

    // if (chart.ShowNewestMeasInTime) {
    //     const lastMeasurementText = lastMeasurement ? formattedDateTime(lastMeasurement, "DD.MM.YYYY") : 'no data';
    //     subtitleContent.push(`${t("The last measurement time")}: ${lastMeasurementText}`);
    // }

    // if (chart.ShowActualTime && chart.SemiCircleType === SemiCircleType.SensorValues) {
    //     const timeslotText = timeslot ? formattedDateTime(timeslot, "DD.MM.YYYY HH:mm") : 'no data';
    //     subtitleContent.push(`${t("Displayed time")}: ${timeslotText}`);
    // }

    let subtitleText = "";

    if (subtitleContent.length === 1) {
        subtitleText = subtitleContent[0]
    }
    else if (subtitleContent.length > 1) {
        subtitleText = '<div style="display:flex; flex-direction:column; align-items:center; justify-content:center">';
        subtitleContent.forEach(cnt => {
            subtitleText += `<span>${cnt}</span>`
        });
        subtitleText += "</div>";
    }

    if (subtitleContent.length > 0) {
        options.subtitle = subtitleContent.length > 1 ? {
            align: 'center',
            useHTML: true,
            text: subtitleText,
            floating: false
        } : {
            align: 'center',
            text: subtitleText,
            floating: false
        }
    }


    return options;
}

const getSensorValuesSeries = (data: SemiCircleChartDataModel[], visualSettings: SemiCircleVisualSettings, valueSettings: SemiCircleValuesSettings, isSensorValue: boolean): Highcharts.SeriesOptionsType[] => {
    const paletteData = getSemiCirclePaletteData(valueSettings, data);
    return [{
        type: 'pie',
        innerSize: `${100 - visualSettings.InnerCircleSize}%`,
        data: data.map((d, index, collection) => ({
            //this value responsible for size of segment
            y: d.SegmentSizeValue,
            // name of seria for display in tooltips and labels
            name: d.Name,
            // this value displays as "Value" in tooltips and labels
            value: d.Data,
            // additional info for tooltip
            description: d.CustomTooltipText,
            //color of segment
            color: getValueColor(paletteData, d, isSensorValue),
            custom: {
                isFirst: index === 0,
                isLast: index === collection.length - 1,
                parameterValue: d.ParameterValue
            }
        }))
    }];
}

const getValueColor = (paletteData: IColorPalette, value: SemiCircleChartDataModel, isSensorValue: boolean): string => {

    if (value.Data === undefined || value.Data === null) {
        return "#f5f5f5";
    }

    if (!isSensorValue) {
        return value.SegmentColor;
    }

    const {
        max,
        min,
        palette
    } = paletteData;

    const relativeValue = (value.Data - min) / (max - min);
    const color = palette(relativeValue).hex();
    return color;
}

const processCenterTitle = (title: string, chart: SemiCircleChartModel, data: SemiCircleChartDataModel[]): string => {
    if ((!title.includes("<SensorName>") && !title.includes("<CustomerName>")) || data.length === 0 || chart.SemiCircleType !== SemiCircleType.SuccessfulMeasurementRate) {
        return title;
    }

    return title.replace('<SensorName>', data[0].Id).replace('<CustomerName>', data[0].SensorName);
}

