//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { GeovisChartModel } from './GeovisChartModel';
import { ChartMeasureTimeCalcType } from '../../Common/ChartMeasureTimeCalcType';
import { GeovisChartAxisSettings } from './GeovisChartAxisSettings';
import { XYSensorPair } from '../../Common/XYSensorPair';
import { ChartSettingInt } from '../ChartSettingInt';
import { ExactMeasurementSetting } from './ExactMeasurementSetting';
import { ChartSettingBool } from '../ChartSettingBool';
import { ReferenceGroupType } from '../../Common/ReferenceGroupType';

export class XyChartModel extends GeovisChartModel
{
	public MeasureTimeCalcType: ChartMeasureTimeCalcType;
	public MeasureTimeCalcCustomerChangeable: boolean;
	public XAxisSettings: GeovisChartAxisSettings;
	public SensorPairs: XYSensorPair[];
	public NumberOfMeasurementsToShow: ChartSettingInt;
	public DivideToEvenParts: boolean;
	public Times: string[];
	public ExactTimeslots: ExactMeasurementSetting[];
	public DivideIntervalAlarmIfNoData: boolean;
	public UseXYAxisDataBasedOnDifferentSensors: boolean;
	public ShowReferenceValue: ChartSettingBool;
	public ShowReferenceDate: boolean;
	public ReferenceGroup: ReferenceGroupType;
	public ReferenceSensorOrChainId: string;
	public SortByXAxis: boolean;
	public DrawZeroLine: boolean;
}
