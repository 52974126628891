import TextArea from "@atlaskit/textarea";
import React, { SyntheticEvent } from "react";
import { OptionType } from "@atlaskit/select";
import { t } from "../../../../i18n";
import { ChartSettingBool } from "../../../../server/AVTService/TypeLibrary/Model/ChartSettingBool";
import { GeovisChartAxisSettings } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartAxisSettings";
import { TimeValueChartModel } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/TimeValueChartModel";
import { ChartBooleanSettingProps, ChartSettingTooltip, ChartTextSetting, CustomerChangeableChartBooleanSetting } from "../ChartTab_tools";
import { AxisProperty } from "../GeovisChartAxisSettingsControl";
import { onChartPropertyChangedFunc } from "../tools";
import { defaultChartLabelColumnStyle, defaultChartRowSettingsStyle, IGeovisChartPropertyChangedProps } from "../types";
import { TimeValueChartShowLogbookSettings } from "./TimeValueChartShowLogbooksSettings";
import { GeovisSelect } from "../../../select/GeovisSelect";
import { ChartAlarmLineTypeList, getChartAlarmLineTypeToDescription } from "../../../../server/AVTService/TypeLibrary/Common/ChartAlarmLineType";
import { KindOfElementUsing } from "../../../../server/AVTService/TypeLibrary/Common/KindOfElementUsing";
import { AxisDrawMode, AxisDrawModeList, getAxisDrawModeToDescription } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/AxisDrawMode";

type IComponentProps = IGeovisChartPropertyChangedProps<TimeValueChartModel>

export const TimeValueChartOptionsTab = ({ chart, onChartPropertyChanged }: IComponentProps) => {

    const { LeftYAxisSettings, RightYAxisSettings } = chart

    const labelColumnStyle = defaultChartLabelColumnStyle;
    const settingRowStyle = defaultChartRowSettingsStyle;

    const onChangeFunc = onChartPropertyChangedFunc(onChartPropertyChanged);

    const onCustomTooltipChanged = (event: SyntheticEvent<HTMLTextAreaElement>) => {
        onChangeFunc("CustomTooltip")(event.currentTarget.value);
    }

    const legendTextInfo = () => (
        <div>
            <div>{"<SensorName>"}</div>
            <div>{"<CustomerName>"}</div>
            <div>{"<SensorLastValue>"}</div>
            <div>{"<Km>"}</div>
            <div>{"<Param{1-5}>"}</div>
        </div>
    )

    const customTooltipInfo = () => (
        <div>
            <div>{"<Km>"}</div>
            <div>{"<Param{1-5}>"}</div>
            <div>{"<Reference>"}</div>
            <div>{"<AxisReference>"}</div>
            <div>{"<Offset>"}</div>
            <div>{"<AxisOffset>"}</div>
        </div>
    )

    const commonTooltipContent = () => (
        <div>
            <div>{t("Additional sensor's attributes data will not be displayed if this option is selected")}</div>
        </div>
    )

    const onChangeBooleanChartValue = (propertyName: keyof TimeValueChartModel, invert?: boolean) => (value: boolean) => {
        onChartPropertyChanged(propertyName, invert ? !value : value);
    }

    const onAxisBoolPropertyChangedFunc = (propertyName: AxisProperty, isLeftAxis: boolean) => (value: ChartSettingBool) => {
        switch (propertyName) {
            case "ShowPointSymbols": {
                const newAxisSettings: GeovisChartAxisSettings = isLeftAxis
                    ? { ...LeftYAxisSettings, ShowPointSymbols: value }
                    : { ...RightYAxisSettings, ShowPointSymbols: value };
                onChartPropertyChanged(isLeftAxis ? "LeftYAxisSettings" : "RightYAxisSettings", newAxisSettings);
                break;
            }
        }
    }

    const onAxisLineWeightPropertyChangedFunc = (isLeftAxis: boolean) => (value: OptionType) => {
        const newAxisSettings: GeovisChartAxisSettings = isLeftAxis
            ? { ...chart.LeftYAxisSettings, LineWeight: +value.value }
            : { ...chart.RightYAxisSettings, LineWeight: +value.value };
        onChartPropertyChanged(isLeftAxis ? "LeftYAxisSettings" : "RightYAxisSettings", newAxisSettings);
    }

    const getLineWidthOptions = (): OptionType[] => {
        const result: OptionType[] = [];

        result.push({ label: '0.5', value: 0.5 });
        result.push({ label: '1', value: 1 });
        result.push({ label: '1.5', value: 1.5 });
        result.push({ label: '2', value: 2 });
        result.push({ label: '2.5', value: 2.5 });
        result.push({ label: '3', value: 3 });

        return result;
    }

    const getSelectedLineWidth = (isLeftAxis: boolean): OptionType => {
        const availableOptions = getLineWidthOptions();
        if (isLeftAxis) {
            const option = availableOptions.find(o => o.value === chart.LeftYAxisSettings.LineWeight);
            if (option) {
                return option;
            }
        } else {
            const option = availableOptions.find(o => o.value === chart.RightYAxisSettings.LineWeight);
            if (option) {
                return option;
            }
        }

        return availableOptions[0];
    }

    const getBooleanSetting = (setting: ChartSettingBool): ChartSettingBool => {
        return setting || { value: false, customerChangeable: false };
    }

    const getLineStyleOptions = (): OptionType[] => {
        return ChartAlarmLineTypeList.map(c => ({ label: getChartAlarmLineTypeToDescription(c), value: c }));
    }

    const getSelectedLienStyle = (isLeftAxis: boolean): OptionType => {
        if (isLeftAxis) {
            const option = getLineStyleOptions().find(o => o.value === LeftYAxisSettings.LineStyle);
            if (option) {
                return option;
            }
        } else {
            const option = getLineStyleOptions().find(o => o.value === RightYAxisSettings.LineStyle);
            if (option) {
                return option;
            }
        }

        return getLineStyleOptions()[0];
    }

    const onChangeLineStyle = (isLeftAxis: boolean) => (option: OptionType) => {
        const newAxisSettings: GeovisChartAxisSettings = isLeftAxis
            ? { ...LeftYAxisSettings, LineStyle: +option.value }
            : { ...RightYAxisSettings, LineStyle: +option.value };
        onChartPropertyChanged(isLeftAxis ? "LeftYAxisSettings" : "RightYAxisSettings", newAxisSettings);
    }

    const getDrawTypeOptions = (): OptionType[] => {
        return AxisDrawModeList.map(o => ({ label: getAxisDrawModeToDescription(o), value: o }));
    }

    const getSelectedDrawTypeOption = (axisSettings: GeovisChartAxisSettings): OptionType | undefined => {
        return getDrawTypeOptions().find(o => o.value === axisSettings.AxisDrawMode);
    }

    const onDrawTypeChangedFunc = (isLeftAxis: boolean) => (value: OptionType) => {
        const newAxisSettings: GeovisChartAxisSettings = isLeftAxis
            ? { ...chart.LeftYAxisSettings, AxisDrawMode: +value.value }
            : { ...chart.RightYAxisSettings, AxisDrawMode: +value.value };
        onChartPropertyChanged(isLeftAxis ? "LeftYAxisSettings" : "RightYAxisSettings", newAxisSettings);
    }

    return (
        <React.Fragment>

            <div className="flexRowContainerLine" style={{ alignItems: 'start' }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: "5px" }}>
                    <div style={{ alignSelf: 'center', fontWeight: 'bold' }}>
                        <span>{t("Left Y-Axis")}</span>
                    </div>
                    <div className="flexRowContainerLine" style={settingRowStyle}>
                        <div style={labelColumnStyle}>
                            <label htmlFor="line-mode">{t("Mode")}:</label>
                        </div>
                        <div className="flexCellContainer_g1_m0">
                            <GeovisSelect
                                id="line-mode"
                                spacing="compact"
                                options={getDrawTypeOptions()}
                                value={getSelectedDrawTypeOption(LeftYAxisSettings)}
                                onChange={onDrawTypeChangedFunc(true)} />
                        </div>
                    </div>
                    {LeftYAxisSettings.AxisDrawMode === AxisDrawMode.Line &&
                        <CustomerChangeableChartBooleanSetting
                            setting={getBooleanSetting(LeftYAxisSettings.ShowPointSymbols)}
                            settingLabel={t("Show point symbols")}
                            onChange={onAxisBoolPropertyChangedFunc("ShowPointSymbols", true)}
                            isDisabled={false} />
                    }
                    {LeftYAxisSettings.AxisDrawMode === AxisDrawMode.Line &&
                        <CustomerChangeableChartBooleanSetting
                            setting={chart.DrawLineBetweenPoints}
                            settingLabel={t("Draw line between points")}
                            onChange={onChangeFunc("DrawLineBetweenPoints")}
                            isDisabled={false}
                        />
                    }
                    {LeftYAxisSettings.AxisDrawMode === AxisDrawMode.Line &&
                        <div className="flexRowContainerLine" style={settingRowStyle}>
                            <div style={labelColumnStyle}>
                                <label htmlFor="line-width">{t("Line weight")}:</label>
                            </div>
                            <div className="flexCellContainer_g1_m0">
                                <GeovisSelect
                                    id="line-width"
                                    spacing="compact"
                                    options={getLineWidthOptions()}
                                    value={getSelectedLineWidth(true)}
                                    isDisabled={!chart.DrawLineBetweenPoints.value}
                                    onChange={onAxisLineWeightPropertyChangedFunc(true)} />
                            </div>
                        </div>
                    }
                    {LeftYAxisSettings.AxisDrawMode === AxisDrawMode.Line &&
                        <div className="flexRowContainerLine" style={settingRowStyle}>
                            <div style={labelColumnStyle}>
                                <label htmlFor="cmbLSL">{t("Line style")}:</label>
                            </div>
                            <div className="flexCellContainer_g1_m0">
                                <GeovisSelect
                                    id="cmbLSL"
                                    options={getLineStyleOptions()}
                                    value={getSelectedLienStyle(true)}
                                    onChange={onChangeLineStyle(true)}
                                    isDisabled={!chart.DrawLineBetweenPoints.value}
                                    isCompact={true}
                                    spacing='compact'
                                />
                            </div>
                        </div>
                    }
                </div>
                <div className="flexCellContainer_g1_m0 separator">
                    {/** */}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '5px' }}>
                    <div style={{ alignSelf: 'center', fontWeight: 'bold' }}>
                        <span>{t("Right Y-Axis")}</span>
                    </div>
                    <div className="flexRowContainerLine" style={settingRowStyle}>
                        <div style={labelColumnStyle}>
                            <label htmlFor="line-mode">{t("Mode")}:</label>
                        </div>
                        <div className="flexCellContainer_g1_m0">
                            <GeovisSelect
                                id="line-mode"
                                spacing="compact"
                                options={getDrawTypeOptions()}
                                value={getSelectedDrawTypeOption(RightYAxisSettings)}
                                onChange={onDrawTypeChangedFunc(false)} />
                        </div>
                    </div>
                    {RightYAxisSettings.AxisDrawMode === AxisDrawMode.Line &&
                        <CustomerChangeableChartBooleanSetting
                            setting={getBooleanSetting(RightYAxisSettings.ShowPointSymbols)}
                            settingLabel={t("Show point symbols")}
                            onChange={onAxisBoolPropertyChangedFunc("ShowPointSymbols", false)}
                            isDisabled={false} />
                    }
                    {RightYAxisSettings.AxisDrawMode === AxisDrawMode.Line &&
                        <CustomerChangeableChartBooleanSetting
                            setting={chart.DrawLineBetweenPointsSecondary}
                            settingLabel={t("Draw line between points")}
                            onChange={onChangeFunc("DrawLineBetweenPointsSecondary")}
                            isDisabled={false}
                        />
                    }
                    {RightYAxisSettings.AxisDrawMode === AxisDrawMode.Line &&
                        <div className="flexRowContainerLine" style={settingRowStyle}>
                            <div style={labelColumnStyle}>
                                <label htmlFor="line-width">{t("Line weight")}:</label>
                            </div>
                            <div className="flexCellContainer_g1_m0">
                                <GeovisSelect
                                    id="line-width"
                                    spacing="compact"
                                    options={getLineWidthOptions()}
                                    isDisabled={!chart.DrawLineBetweenPointsSecondary.value}
                                    value={getSelectedLineWidth(false)}
                                    onChange={onAxisLineWeightPropertyChangedFunc(false)} />
                            </div>
                        </div>
                    }
                    {RightYAxisSettings.AxisDrawMode === AxisDrawMode.Line &&
                        <div className="flexRowContainerLine" style={settingRowStyle}>
                            <div style={labelColumnStyle}>
                                <label htmlFor="cmbLSR">{t("Line style")}:</label>
                            </div>
                            <div className="flexCellContainer_g1_m0">
                                <GeovisSelect
                                    id="cmbLSR"
                                    options={getLineStyleOptions()}
                                    value={getSelectedLienStyle(false)}
                                    onChange={onChangeLineStyle(false)}
                                    isDisabled={!chart.DrawLineBetweenPointsSecondary.value}
                                    isCompact={true}
                                    spacing='compact'
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>

            <CustomerChangeableChartBooleanSetting
                setting={chart.ShowLegend}
                settingLabel={t("Show legend")}
                onChange={onChangeFunc("ShowLegend")}
                isDisabled={false} />

            <ChartTextSetting
                labelColumnStyle={labelColumnStyle}
                labelText={t("Legend text")}
                value={chart.LegendText}
                htmlName="tbLegendText"
                tooltipContent={legendTextInfo()}
                onChangeTextField={onChangeFunc("LegendText")} />

            <CustomerChangeableChartBooleanSetting
                setting={chart.ShowLogbooksInLegend}
                settingLabel={t("Show legend for manual logbook entries")}
                onChange={onChangeFunc("ShowLogbooksInLegend")}
                isDisabled={false} />

            <TimeValueChartShowLogbookSettings
                projectId={chart.ProjectId}
                isChecked={chart.ShowManualLogbooks}
                selectedLabels={chart.ManualLogbookLabels}
                isDisabled={chart.KindOfElementUsing === KindOfElementUsing.Template && chart.TemplateCompanyId !== ''}

                onShowLogbooksChanged={onChangeFunc("ShowManualLogbooks")}
                onLogbooksChanged={onChangeFunc("ManualLogbookLabels")}
            />

            <ChartBooleanSettingProps
                label={t("Disable mouse zoom")}
                isChecked={!chart.IsChartZoomAllow}
                onChange={onChangeBooleanChartValue("IsChartZoomAllow", true)}
                isDisabled={false} />

            <ChartBooleanSettingProps
                label={t("Show start of measurements")}
                isChecked={chart.ShowStartMeasurements}
                onChange={onChangeFunc("ShowStartMeasurements")}
                isDisabled={false} />

            <CustomerChangeableChartBooleanSetting
                settingLabel={t("Show all data in single tooltip")}
                setting={chart.ShowCommonTooltip}
                onChange={onChangeFunc("ShowCommonTooltip")}
                isDisabled={false}
                // infoText={t("Additional sensor's attributes data will not be displayed if this option is selected")}
                tooltipContent={commonTooltipContent()}
            />

            <ChartBooleanSettingProps
                label={t("showCustomTooltipLabel")}
                isChecked={chart.ShowCustomTooltip}
                onChange={onChangeFunc("ShowCustomTooltip")}
                isDisabled={chart.ShowCommonTooltip.value} />

            <div className="flexRowContainer">
                <div className="flexCellContainer_g1_m0">
                    <TextArea
                        name="tbCustomTooltip"
                        isCompact={true}
                        isDisabled={chart.ShowCommonTooltip.value}
                        defaultValue={chart.CustomTooltip}
                        maxHeight="250px"
                        onChange={onCustomTooltipChanged} />
                </div>
                <div className="flexCellContainer">
                    <ChartSettingTooltip content={customTooltipInfo()} />
                </div>
            </div>
        </React.Fragment>
    );
}