/**
 * @author Vyacheslav Skripin <vs@irskr.ru>
 * @created 09.06.2023
 * @description The host component to render local map objects (used for Map and Report layers)
 */

import { LocalMapObject } from "../../server/AVTService/TypeLibrary/LocalMapObjects/LocalMapObject"
import { LocalMapObjectCamera } from "../../server/AVTService/TypeLibrary/LocalMapObjects/LocalMapObjectCamera";
import { LocalMapObjectType } from "../../server/AVTService/TypeLibrary/LocalMapObjects/LocalMapObjectType";
import { LocalMapObjectProperties } from "../../server/GEOvis3/Model/LocalMapObjects/LocalMapObjectProperties";
import { LocalMapObjectCircle } from "../../server/LocalMapObjectCircle";
import { LocalMapObjectDocument } from "../../server/LocalMapObjectDocument";
import { LocalMapObjectPolygon } from "../../server/LocalMapObjectPolygon";
import { LocalMapObjectPolyline } from "../../server/LocalMapObjectPolyline";
import { LocalMapObjectCameraShape } from "./shapes/LocalMapObjectCameraShape";
import { LocalMapObjectCircleShape } from "./shapes/LocalMapObjectCircleShape";
import { LocalMapObjectDocumentShape } from "./shapes/LocalMapObjectDocumentShape";
import { LocalMapObjectPolygonShape } from "./shapes/LocalMapObjectPolygonShape";
import { LocalMapObjectPolylineShape } from "./shapes/LocalMapObjectPolylineShape";
import { LocalMapObjectTextShape } from "./shapes/LocalMapObjectTextShape";
import { LocalMapObjectViewMode } from "./types";

interface IComponentProps {
    lmo: LocalMapObject;

    viewMode: LocalMapObjectViewMode;
    isEditMode: boolean;
    editState?: LocalMapObjectProperties;
    onChangeLocation?: (elementId: string, latLng: L.LatLng) => void;
    onChangeRotationAngle?: (elementId: string, angle: number) => void;

    setMapDragging?: (dragging: boolean) => void;
    onEditLocalMapObjectHandler: (id: string) => void;
    onRemoveLocalMapObjectHandler: (id: string) => void;
    onClickLocalMapObjectHandler: (id: string) => void;
    onOpenAttachedDocumentById: (documentId: string) => void;
    onOpenLocalMapDocument: (lmoId: string) => void;
    openReportById: (reportId: number) => void;
    openViewById: (viewId: string) => void;
}

export const LocalMapObjectShapesRenderHost = ({
    lmo,
    isEditMode,
    viewMode,
    editState,
    onChangeLocation,
    onChangeRotationAngle,
    onClickLocalMapObjectHandler,
    onEditLocalMapObjectHandler,
    onRemoveLocalMapObjectHandler,
    setMapDragging,
    onOpenAttachedDocumentById,
    onOpenLocalMapDocument,
    openReportById,
    openViewById
}: IComponentProps) => {


    switch (lmo.ObjectType) {
        case LocalMapObjectType.Link:
        case LocalMapObjectType.Text: return (
            <LocalMapObjectTextShape
                key={`local_map_object_text_${lmo.Id}`}
                element={lmo}
                onEdit={onEditLocalMapObjectHandler}
                onRemove={onRemoveLocalMapObjectHandler}
                onClick={onClickLocalMapObjectHandler}
                onOpenFile={onOpenAttachedDocumentById}
                viewMode={viewMode}
                isEditMode={isEditMode}
                editState={editState}
                setMapDragging={setMapDragging}
                onChangeLocation={onChangeLocation}
                onChangeRotationAngle={onChangeRotationAngle}
            />
        );

        case LocalMapObjectType.Document: return (
            <LocalMapObjectDocumentShape
                key={`local_map_object_document_${lmo.Id}`}
                element={lmo as LocalMapObjectDocument}
                onEdit={onEditLocalMapObjectHandler}
                onRemove={onRemoveLocalMapObjectHandler}
                onOpenFile={onOpenLocalMapDocument}
                viewMode={viewMode}
                isEditMode={isEditMode}
                editState={editState}
                onChangeLocation={onChangeLocation}
            />
        );

        case LocalMapObjectType.Polyline: return (
            <LocalMapObjectPolylineShape
                key={`local_map_object_polyline_${lmo.Id}`}
                element={lmo as LocalMapObjectPolyline}
                onEdit={onEditLocalMapObjectHandler}
                onRemove={onRemoveLocalMapObjectHandler}
                onClick={onClickLocalMapObjectHandler}
                onOpenFile={onOpenAttachedDocumentById}
                viewMode={viewMode}
                onOpenReport={openReportById}
                isEditMode={isEditMode}
                onOpenView={openViewById} />
        )

        case LocalMapObjectType.Circle: return (
            <LocalMapObjectCircleShape
                key={`local_map_object_circle_${lmo.Id}`}
                element={lmo as LocalMapObjectCircle}
                onEdit={onEditLocalMapObjectHandler}
                onRemove={onRemoveLocalMapObjectHandler}
                onClick={onClickLocalMapObjectHandler}
                onOpenFile={onOpenAttachedDocumentById}
                viewMode={viewMode}
                onOpenReport={openReportById}
                isEditMode={isEditMode}
                onOpenView={openViewById}
            />
        )

        case LocalMapObjectType.Camera: return (
            <LocalMapObjectCameraShape
                key={`local_map_object_camera_${lmo.Id}`}
                element={lmo as LocalMapObjectCamera}
                onEdit={onEditLocalMapObjectHandler}
                onRemove={onRemoveLocalMapObjectHandler}
                viewMode={viewMode}
                isEditMode={isEditMode}
                editState={editState}
                onChangeLocation={onChangeLocation}
            />
        )

        case LocalMapObjectType.Polygon: return (
            <LocalMapObjectPolygonShape
                key={`local_map_object_polygon_${lmo.Id}`}
                element={lmo as LocalMapObjectPolygon}
                onEdit={onEditLocalMapObjectHandler}
                onRemove={onRemoveLocalMapObjectHandler}
                isEditMode={isEditMode}
                onClick={onClickLocalMapObjectHandler}
                onOpenFile={onOpenAttachedDocumentById}
                viewMode={viewMode}
                onOpenReport={openReportById}
                onOpenView={openViewById} />
        )
    }
}