/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 26.04.2022
 * @description Heatmap sensors select control for Nagra distributions
 */

import { Radio } from '@atlaskit/radio';
import { SyntheticEvent, useEffect, useState } from 'react';
import { IWithGeovisServerProps, withGeovisServer } from '../../../../helpers/GeovisHooks';
import { fetchServerElements } from '../../../../helpers/ProjectDataHelper';
import { elementsToMap, mapToListOfElements } from '../../../../helpers/StorageHelper';
import { t } from '../../../../i18n';
import { ChartType } from '../../../../server/AVTService/TypeLibrary/Common/ChartType';
import { DtsSectionLoop, DtsSectionLoopList, getDtsSectionLoopToName } from '../../../../server/AVTService/TypeLibrary/DtsConfiguration/DtsSectionLoop';
import { HeatmapChartModel } from '../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/HeatmapChartModel';
import { HeatmapSensorsSource } from '../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/HeatmapSensorsSource';
import { HeatmapValueSettings } from '../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/HeatmapValueSettings';
import { SensorCategory } from '../../../../server/AVTService/TypeLibrary/Sensors/SensorCategory';
import { GeovisMstShortInfoModel } from '../../../../server/GEOvis3/Model/Database/GeovisMstShortInfoModel';
import { DtsSectionInfoSlim } from '../../../../server/GEOvis3/Model/DtsConfiguration/DtsSectionInfoSlim';
import ServerRoutesGen from '../../../../server/Routes/ServerRoutesGen';
import { processFetchedData } from '../../../../store/helpers/DataHelper';
import { defaultSomethingStorageState, ISomethingStorageBaseEx } from '../../../../store/types';
import { GeovisSelectMultiT, GeovisSelectT } from '../../../select/GeovisSelect';
import { getIGvOptionTypeExList, getIGvOptionTypeList, IGvOptionType } from '../../../select/GeovisSelect_tools';
import { ChartAxisSensorsControl } from '../ChartAxisSensorsControl';
import { defaultChartLabelColumnStyle } from '../types';


interface IHeatmapSelectSensorsControlProps extends IWithGeovisServerProps {
    projectId: number;
    valueSettings: HeatmapValueSettings;

    onSettingsChanged: (valueSettings: HeatmapValueSettings) => void;
}

export const dtsSectionsStorageInitialState: ISomethingStorageBaseEx<Map<string, DtsSectionInfoSlim>> = { ...defaultSomethingStorageState, data: new Map<string, DtsSectionInfoSlim>() };

export const dbStorageInitialState: ISomethingStorageBaseEx<GeovisMstShortInfoModel[]> = { ...defaultSomethingStorageState, data: [] };

export const HeatmapSelectSensorsControl = withGeovisServer(({ Server, onSettingsChanged, projectId, valueSettings }: IHeatmapSelectSensorsControlProps) => {

    const labelSettingsStyle = defaultChartLabelColumnStyle;

    const [dtsSectionsStorage, setDtsSectionsStorage] = useState<ISomethingStorageBaseEx<Map<string, DtsSectionInfoSlim>>>(dtsSectionsStorageInitialState);
    const [dbStorage, setDbStorage] = useState<ISomethingStorageBaseEx<GeovisMstShortInfoModel[]>>(dbStorageInitialState);


    useEffect(() => {

        (async function getDtsSectionsInfoSlim() {
            const response = await fetchServerElements<DtsSectionInfoSlim[]>(Server, ServerRoutesGen.GeovisChart.GetDtsSectionsInfoSlim.patch(projectId));

            setDtsSectionsStorage(processFetchedData(response, dtsSectionsStorage, dtsSectionsStorageInitialState, st => ({
                data: elementsToMap<string, DtsSectionInfoSlim>(st)
            })));
        })();

        (async function loadDatabasesInfo() {

            const response = await fetchServerElements<GeovisMstShortInfoModel[]>(Server, ServerRoutesGen.ProjectDatabase.GetDatabasesShortInfo.patch(projectId));

            setDbStorage(processFetchedData(response, dbStorage, dbStorageInitialState, st => ({ data: st })));
        })();

    }, [1]);

    const {
        SensorsSource,
        SensorIds,
        IsSensorsCustomerChangeable,
        DtsSectionIds,
        DtsLoops,
        DtsLoopsDatabaseId,
        TypeOfSensor
    } = valueSettings;

    const onSettingsChangedFunc = (propertyName: keyof HeatmapValueSettings) => (value: any) => {
        const changes: Partial<HeatmapChartModel> = {};
        changes[propertyName] = value;

        onSettingsChanged({ ...valueSettings, ...changes });
    };

    const onSensorsSourceChangedFunc = (source: HeatmapSensorsSource) => (event: SyntheticEvent<HTMLInputElement>) => {
        if (event.currentTarget.checked) {
            onSettingsChangedFunc("SensorsSource")(source);
        }
    }

    const onSensorsListChanged = (sensorIds: string[], isCustomerChangeable: boolean) => {
        onSettingsChanged({ ...valueSettings, SensorIds: sensorIds, IsSensorsCustomerChangeable: isCustomerChangeable });
    }

    const selectedDtsSectionOptions = DtsSectionIds.filter(sId => dtsSectionsStorage.data.has(sId))
        .map(sId => ({ value: sId, label: dtsSectionsStorage.data.get(sId)!.Name }));


    const dtsSectionOptions = (): Array<IGvOptionType<string>> => {
        const availableSections = mapToListOfElements(dtsSectionsStorage.data, (val) => TypeOfSensor === SensorCategory.ThermalConductivity ? val.IsHeatable : true);
        return availableSections.map(section => ({ value: section.Id, label: section.Name }));
    }

    const onSelectedPropertyChanged = (propertyName: keyof HeatmapValueSettings) => (option: IGvOptionType<string>) => {
        if (option) {
            onSettingsChangedFunc(propertyName)(option.value);
        }
    }

    const onSelectedMultiPropertyChanged = (propertyName: keyof HeatmapValueSettings) => (values: Array<IGvOptionType<string | number>>) => {

        if (!values) {
            onSettingsChangedFunc(propertyName)([]);
            return;
        }

        const sectionIds = values.map(o => o.value);
        onSettingsChangedFunc(propertyName)(sectionIds);
    }

    const dbOptions: Array<IGvOptionType<string>> = dbStorage.isLoaded ? getIGvOptionTypeExList(dbStorage.data, s => s.Alias, s => s.Name) : [];
    const dbOptionSelected = dbOptions.find(d => d.value === DtsLoopsDatabaseId);

    const loopOptions = TypeOfSensor === SensorCategory.ThermalConductivity ? [DtsSectionLoop.Loop2] : DtsSectionLoopList;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            {/* sensors */}
            <div className='flexRowContainerLine'>
                <div style={labelSettingsStyle}>
                    <Radio
                        isChecked={SensorsSource === HeatmapSensorsSource.Sensors}
                        onChange={onSensorsSourceChangedFunc(HeatmapSensorsSource.Sensors)}
                        label={t("Sensors")}
                    />
                </div>
                <div className='flexCellContainer_g1_m0'>
                    <ChartAxisSensorsControl
                        isChain={false}
                        chartType={ChartType.HeatMap}
                        dtsSectionIds={[]}
                        projectId={projectId}
                        customerChangeable={IsSensorsCustomerChangeable}
                        onChange={onSensorsListChanged}
                        sensorChanges={[]}
                        sensorIds={SensorIds}
                        showPairsDialog={false}
                        ySensorType={valueSettings.TypeOfSensor}
                        isDisabled={SensorsSource !== HeatmapSensorsSource.Sensors}
                        selectedChainsIds={[]} // in this dialog we do not need those ids
                        isSingle={false}
                    />
                </div>
            </div>

            {/* dts sections */}
            <div className='flexRowContainerLine'>
                <div style={labelSettingsStyle}>
                    <Radio
                        isChecked={SensorsSource === HeatmapSensorsSource.DTS_Sections}
                        onChange={onSensorsSourceChangedFunc(HeatmapSensorsSource.DTS_Sections)}
                        label={t("DTS Sections")}
                    />
                </div>
                <div className='flexCellContainer_g1_m0'>
                    <GeovisSelectMultiT<string>
                        isDisabled={SensorsSource !== HeatmapSensorsSource.DTS_Sections}
                        value={selectedDtsSectionOptions}
                        options={dtsSectionOptions()}
                        onChange={onSelectedMultiPropertyChanged("DtsSectionIds")}
                        isLoading={dtsSectionsStorage.isLoading}
                    />
                </div>
            </div>
            {/* dts loops */}
            <div className='flexRowContainerLine'>
                <div style={labelSettingsStyle}>
                    <Radio
                        isChecked={SensorsSource === HeatmapSensorsSource.DTS_Loops}
                        onChange={onSensorsSourceChangedFunc(HeatmapSensorsSource.DTS_Loops)}
                        label={t("Database") + " / " + t("DTS Loops")}
                    />
                </div>
                <div className='flexCellContainer_g1_m0'>
                    <div className='flexRowContainerLine'>
                        <div className='flexCellContainer_g1_m0'>
                            <GeovisSelectT<string>
                                onChange={onSelectedPropertyChanged("DtsLoopsDatabaseId")}
                                placeholder={t("Select database of loops")}
                                options={dbOptions}
                                value={dbOptionSelected}
                                isDisabled={SensorsSource !== HeatmapSensorsSource.DTS_Loops}
                                isLoading={dbStorage.isLoading}
                            />
                        </div>
                        <div className='flexCellContainer_g1_m0'>
                            <GeovisSelectMultiT<DtsSectionLoop>
                                isDisabled={!dbOptionSelected || SensorsSource !== HeatmapSensorsSource.DTS_Loops}
                                onChange={onSelectedMultiPropertyChanged("DtsLoops")}
                                value={getIGvOptionTypeList<DtsSectionLoop>(DtsLoops, getDtsSectionLoopToName)}
                                options={getIGvOptionTypeList<DtsSectionLoop>(loopOptions, getDtsSectionLoopToName)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
})