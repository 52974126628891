import CrossIcon from '@atlaskit/icon/glyph/cross'
import Button from "@atlaskit/button";
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/glyph/chevron-up';
import Popup from "@atlaskit/popup";
import Textfield from "@atlaskit/textfield";
import { t } from "i18next";
import { debounce } from "lodash";
import { SyntheticEvent, useState } from "react";
import moment from 'moment';

interface ILongTimeSpanEditControlProps {
    value: string;
    onChange: (updValue: string) => void;
    placeholder?: string;
    showDays: boolean;
    showSeconds: boolean;
    isDisabled?: boolean;
}
/**
 * Control for processing TimeSpan values.
 * Value here is string which show amount of seconds.
 * @returns 
*/
export const LongTimeSpanEditControl = ({
    onChange,
    value,
    placeholder,
    showDays,
    showSeconds,
    isDisabled
}: ILongTimeSpanEditControlProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    interface IPopupProps {
        days: number;
        hours: number;
        minutes: number;
        seconds: number;
    }

    const parseRawValue = (): IPopupProps => {
        if (!value) {
            return {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
            }
        }
        let duration = moment.duration(value, "seconds");

        let days: number = 0;
        let hours: number = 0;
        let minutes: number = 0;
        let seconds: number = 0;

        if (showDays) {
            days = Math.floor(duration.asDays());
            duration = duration.add(-days, "days");
            hours = duration.hours();
            minutes = duration.minutes();
            seconds = showSeconds ? duration.seconds() : 0;
        }
        else {
            hours = Math.floor(duration.asHours());
            duration = duration.add(-hours, "hours");
            minutes = duration.minutes();
            seconds = showSeconds ? duration.seconds() : 0;
        }

        return ({
            days,
            hours,
            minutes,
            seconds,
        })

    }

    const createResultString = (properties: IPopupProps): string => {
        const duration = moment.duration("0", "seconds");
        duration.add(properties.days, "days");
        duration.add(properties.hours, "hours");
        duration.add(properties.minutes, "minutes");
        duration.add(properties.seconds, "seconds");

        return duration.asSeconds().toString();
    }

    const createDisplayString = (properties: IPopupProps): string => {
        const result: string[] = [];

        if (properties.days > 0) {
            result.push(`${properties.days} ${properties.days > 1 ? "days" : "day"}`);
        }
        if (properties.hours > 0) {
            result.push(`${properties.hours} ${properties.hours > 1 ? "hours" : "hour"}`);
        }
        if (properties.minutes > 0) {
            result.push(`${properties.minutes} ${properties.minutes > 1 ? "minutes" : "minute"}`);
        }
        if (properties.seconds > 0) {
            result.push(`${properties.seconds} ${properties.seconds > 1 ? "seconds" : "second"}`);
        }

        return result.join("; ");
    }

    const getPopupContent = () => {

        const onChangeTimeHandler = (type: keyof IPopupProps, original: IPopupProps) => (event: SyntheticEvent<HTMLInputElement>) => {
            onChangeItemsHandlerDebounced(+event.currentTarget.value, original, type);
        }

        const onChangeItemsHandlerDebounced = debounce((val: number, original: IPopupProps, type: keyof IPopupProps) => {
            if (!isNaN(val) && val >= 0) {
                const update: Partial<IPopupProps> = {};
                update[type] = val;
                onChange(createResultString({ ...original, ...update }));
            }
        }, 300);

        const parsedPopupValue = parseRawValue();

        return (
            <div style={{ display: 'flex', flexDirection: 'column', gap: "5px", padding: '5px' }}>
                <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'end', alignItems: 'center', width: '100%' }}>

                        <div>
                            <Button
                                iconBefore={<CrossIcon label="close" />}
                                onClick={onClosePopupHandler}
                                appearance="subtle-link"
                            />
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', gap: "5px" }}>
                    {showDays &&
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', justifyContent: 'center', alignItems: 'center' }}>

                            <div style={{ width: '75px' }}>
                                <Textfield
                                    isCompact={true}
                                    defaultValue={parsedPopupValue.days}
                                    type="number"
                                    min={0}
                                    onChange={onChangeTimeHandler("days", parsedPopupValue)}
                                />
                            </div>
                            <div>
                                <span>{t("days")};</span>
                            </div>
                        </div>
                    }
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ width: '75px' }}>
                            <Textfield
                                isCompact={true}
                                defaultValue={parsedPopupValue.hours}
                                type="number"
                                min={0}
                                onChange={onChangeTimeHandler("hours", parsedPopupValue)}
                            />
                        </div>
                        <div>
                            <span>{t("hours")};</span>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ width: '75px' }}>
                            <Textfield
                                isCompact={true}
                                defaultValue={parsedPopupValue.minutes}
                                type="number"
                                min={0}
                                onChange={onChangeTimeHandler("minutes", parsedPopupValue)}
                            />
                        </div>
                        <div>
                            <span>{t("minutes")};</span>
                        </div>
                    </div>
                    {showSeconds &&
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: '75px' }}>
                                <Textfield
                                    isCompact={true}
                                    defaultValue={parsedPopupValue.seconds}
                                    type="number"
                                    min={0}
                                    onChange={onChangeTimeHandler("seconds", parsedPopupValue)}
                                />
                            </div>
                            <div>
                                <span>{t("seconds")};</span>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }

    const parsedValue = parseRawValue();

    const onClosePopupHandler = () => {
        setIsOpen(false)
    }

    return (
        <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
            <div style={{ width: '100%' }}>
                <Textfield
                    isCompact={true}
                    placeholder={placeholder}
                    value={createDisplayString(parsedValue)}
                    isReadOnly={true}
                    isDisabled={isDisabled}
                    elemAfterInput={
                        <Popup
                            isOpen={isOpen}
                            placement="bottom-end"
                            zIndex={999}
                            content={() => <div>{getPopupContent()}</div>}
                            trigger={(triggerProps) => (
                                <Button
                                    {...triggerProps}
                                    appearance="subtle"
                                    isDisabled={isDisabled}
                                    iconBefore={isOpen ? <ChevronUpIcon label="up" /> : <ChevronDownIcon label="down" />}
                                    onClick={() => setIsOpen(!isOpen)}
                                />
                            )}
                        />
                    }
                />
            </div>

        </div>
    )
}
