import { Reducer } from "redux";
import { ITasksManagerAction, ITasksManagerState } from "../tasksManager.types";
import { 
    TASKS_MANAGER_DELETE_TASK, 
    TASKS_MANAGER_HIDE_DELETE_DIALOG, 
    TASKS_MANAGER_HIDE_DETAILS_DIALOG, 
    TASKS_MANAGER_ON_PROJECTS_FILTER_CHANGED, 
    TASKS_MANAGER_SHOW_DELETE_DIALOG, 
    TASKS_MANAGER_SHOW_DETAILS_DIALOG, 
    TASKS_MANAGER_TASKS_LOADED, 
    TASKS_MANAGER_TASKS_LOADING 
} from "../actions/tasksManagerActions";
import { defaultSomethingStorageState } from "../types";
import { processFetchedData } from "../helpers/DataHelper";
import { deleteElementOfMap, elementsToMap } from "../../helpers/StorageHelper";
import { GeovisTasksManagerTaskInfo } from "../../server/GEOvis3/Model/TasksManager/GeovisTasksManagerTaskInfo";

export const tasksManagerInitialState: ITasksManagerState = {
    filter: {
        ProjectIds: []
    },
    tasksStorage: {
        ...defaultSomethingStorageState,
        tasks: new Map<string, GeovisTasksManagerTaskInfo>()
    },
    taskId: '',
    showDeleteDialog: false,
    showDetailsDialog: false
}

const reducer: Reducer<ITasksManagerState> = (state: ITasksManagerState = tasksManagerInitialState, action: ITasksManagerAction): ITasksManagerState => {
    switch (action.type) {
        case TASKS_MANAGER_TASKS_LOADING: {
            return state;
        }

        case TASKS_MANAGER_TASKS_LOADED: {
            if (!action.taskListResponse) {
                return state;
            }

            return {
                ...state,
                tasksStorage: processFetchedData(action.taskListResponse, state.tasksStorage, tasksManagerInitialState.tasksStorage, elements => ({
                    tasks: elementsToMap(elements)
                }))
            }
        }

        case TASKS_MANAGER_SHOW_DELETE_DIALOG: {
            if(!action.taskId){
                return state;
            }

            return {
                ...state,
                taskId: action.taskId,
                showDeleteDialog: true
            }
        }

        case TASKS_MANAGER_HIDE_DELETE_DIALOG:{
            return{
                ...state,
                taskId: '',
                showDeleteDialog: false
            }
        }

        case TASKS_MANAGER_SHOW_DETAILS_DIALOG: {
            if(!action.taskId){
                return state;
            }

            return {
                ...state,
                taskId: action.taskId,
                showDetailsDialog: true
            }
        }

        case TASKS_MANAGER_HIDE_DETAILS_DIALOG: {
            return{
                ...state,
                taskId: '',
                showDetailsDialog: false
            }            
        }

        case TASKS_MANAGER_DELETE_TASK:{
            if(!action.taskId){
                return state;
            }

            return {
                ...state,
                tasksStorage: {
                    ...state.tasksStorage,
                    tasks: deleteElementOfMap(state.tasksStorage.tasks, action.taskId)
                }
            }
        }

        case TASKS_MANAGER_ON_PROJECTS_FILTER_CHANGED:{
            if(!action.projectIds){
                return state;
            }

            return {
                ...state,
                filter: {
                    ProjectIds: [...action.projectIds]
                } 
            }
        }
    }

    return state;
}

export default reducer;