/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 23.05.2022
 * @description Geovis reports render component
 */

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from "redux";
import { GeovisReportModel } from '../../../../server/AVTService/TypeLibrary/Model/Reports/GeovisReportModel';
import { projectReportGeovisReloadWithoutBlink } from '../../../../store/creators/projectReportCreators';
import { IGeovisStoreState } from '../../../../store/store.types';
import { IGeovisAction } from '../../../../store/types';
import { LoadingPageErrorSkeleton } from '../../../LoadingPageErrorSkeleton';
import { LoadingPageSkeleton } from '../../../LoadingPageSkeleton';
import HeaderRenderDataLayer from './header/HeaderRender.DataLayer';
import { autoRefreshIntervalToSeconds } from './tools';
import { IReportElementRenderOwnProps } from './types';
import GeovisReportPageRender from './GeovisReportPageRender';

interface IStateToProps {
    isLoading: boolean;
    isError: boolean;
    errorDescription: string;
    detailedReportInfo: GeovisReportModel;
}

interface IDispatchToProps {
    onLoadRefreshData: () => void;
}

type IOwnProps = Omit<IReportElementRenderOwnProps, 'reportId' | 'pageNum'>

interface IComponentProps extends IOwnProps, IStateToProps, IDispatchToProps {
    projectId: number;
    settingsId?: string;
}

const GeovisReportRender = ({ errorDescription, isError, isLoading, detailedReportInfo, isPrinting, userId, userToken, projectId, settingsId, onLoadRefreshData }: IComponentProps) => {
    const autoRefreshInSeconds = isPrinting || !detailedReportInfo ? 0 : autoRefreshIntervalToSeconds(detailedReportInfo.AutoRefreshInterval);

    const loadFreshData = async () => {
        onLoadRefreshData();
    }

    useEffect(() => {
        let intervalId: NodeJS.Timeout | string | number | undefined = undefined;
        (async function loadChartData() {
            if (autoRefreshInSeconds > 0) {
                intervalId = setInterval(loadFreshData, autoRefreshInSeconds * 1000);
            }
        })();

        return () => {
            clearInterval(intervalId);
        }

    }, [isLoading]);

    if (isLoading) {
        return <LoadingPageSkeleton loadingText='Loading report details' />
    }

    if (isError) {
        return <LoadingPageErrorSkeleton errorText='Error load report details' errorDescription={errorDescription} />
    }

    if (!detailedReportInfo || !detailedReportInfo.Pages) {
        return null;
    }


    return (
        <React.Fragment>
            {/* 
                there is right place for report
                header renders are in GeovisReportPage 
             */}
            <HeaderRenderDataLayer
                reportId={detailedReportInfo.Id}
                userId={userId}
                userToken={userToken}
            />
            {detailedReportInfo.Pages.map((page, index, pages) => (
                <GeovisReportPageRender
                    key={`report_page_${index}`}
                    countPages={pages.length}
                    isLastPage={index === pages.length - 1}
                    page={page}
                    reportId={detailedReportInfo.Id}
                    pageNum={index}
                    userId={userId}
                    userToken={userToken}
                    isPrinting={isPrinting}
                    projectId={projectId}
                    settingsId={settingsId}
                />
            ))}
        </React.Fragment>
    )
}

const mapStateToProps = ({ projectReport }: IGeovisStoreState): IStateToProps => ({
    detailedReportInfo: projectReport.geovisReportSettings.detailedReportInfo,
    isError: projectReport.isError,
    errorDescription: projectReport.errorDescription,
    isLoading: projectReport.isLoading
});

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>): IDispatchToProps => ({
    onLoadRefreshData: () => dispatch(projectReportGeovisReloadWithoutBlink())
})

const ReportRenderConnected = connect<IStateToProps, IDispatchToProps>(mapStateToProps, mapDispatchToProps)(GeovisReportRender);

export default ReportRenderConnected;