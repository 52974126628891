/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 14.07.2020
 * @description Common types of user/companies dialogs
 */

export enum UserEditorMode {
    /** create new user */
    Create,

    /** view user info (readonly state) */
    View,

    /** edit user profile */
    Edit,
    AddRelation,
    EditProjectRelation
}

export enum UserEditorControlAccessLevel {
    Everyone = 0,
    OwnAccount = 1,
    AnyCompanyAdmin = 2,
    AccountCompanyAdmin = 3,
    Creator = 4,
    Admin = 5
}
