/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 14.10.2022
 * @description User profile editor tabs
 */

import Tabs, { TabItem } from '@atlaskit/tabs';
import { TabData, TabItemComponentProvided } from '@atlaskit/tabs/dist/types/types';
import { useState } from 'react';

import { IWithGeovisServerProps, withGeovisServer } from '../../helpers/GeovisHooks';
import { useGeovisRelatedCompanies, useGeovisRelatedProjects } from '../../helpers/Hooks.DataStorage';
import { t } from '../../i18n';
import { GeovisUserProfileChangePasswordModel } from '../../server/AVTService/TypeLibrary/Identity/GeovisUserProfileChangePasswordModel';
import { GeovisUserProjectRelationModel } from '../../server/AVTService/TypeLibrary/Identity/GeovisUserProjectRelationModel';
import { GeovisUserToProjectRole } from '../../server/AVTService/TypeLibrary/Identity/GeovisUserToProjectRole';
import { GeovisUserProfileInfo } from '../../server/GEOvis3/Model/User/GeovisUserProfileInfo';
import AuthService from '../../services/AuthService';
import { UserEditorMode } from './types';
import { UserProfileEditorDetailsTab } from './UserProfileEditor.DetailsTab';
import { UserProfileEditorPasswordsTab } from './UserProfileEditor.PasswordsTab';
import { UserProfileEditorProjectRelationsTab } from './UserProfileEditor.ProjectRelationsTab';

const UserTabItemComponent = (props: TabItemComponentProvided) => (
    <div className="caption">
        <TabItem {...props} />
    </div>
)

interface IComponentProps extends IWithGeovisServerProps {
    user: GeovisUserProfileInfo;
    editMode: UserEditorMode;
    isSettingUserPasswordInProgress: boolean;

    onUserPropertyChanged: (propertyName: keyof GeovisUserProfileInfo, value: any) => void;
    onUserPropertiesChanged: (properties: Partial<GeovisUserProfileInfo>) => void;

    onPasswordPropertyChanged: (propertyName: keyof GeovisUserProfileChangePasswordModel, value: string) => void;

    onProjectRelationChanged: (projectId: number, role: GeovisUserToProjectRole) => void;
    onAddProjectRelations: (projectRelationsInfo: GeovisUserProjectRelationModel[]) => void;
    onDeleteProjectRelation: (projectId: number) => void;

    onSetUserPassword: (passwordInfo: GeovisUserProfileChangePasswordModel) => void;

}

/**
 * User profile tabs view
 * @param props
 * @returns 
 */
const UserProfileEditorTabsView = ({ Server, ...restProps }: IComponentProps) => {

    const [tabIndex, setTabIndex] = useState<number>(0);
    const onSelectTabHandler = (_: TabData, index: number) => setTabIndex(index);


    const { user, editMode } = restProps;
    const ownProfile = !!user.Id && user.Id === AuthService.currentUserId();
    const canSetPassword = AuthService.hasUserTypeAsCompanyAdmin()
        && !!user.Id
        && user.Id !== AuthService.currentUserId()
        && AuthService.currentUserRole() > user.GeovisUserRole
        || AuthService.hasUserTypeAsAdmin();

    // init storages for tabs
    const [companiesStorage] = useGeovisRelatedCompanies(Server, user.Id);
    const [projectsStorage] = useGeovisRelatedProjects(Server, user.Id);

    const tabs: TabData[] = [{
        label: t("User profile"),
        content: (
            <UserProfileEditorDetailsTab
                {...restProps}
                companiesStorage={companiesStorage}
            />
        )
    }];

    if (editMode !== UserEditorMode.Create) {
        tabs.push({
            label: t("Project assignments"),
            content: (
                <UserProfileEditorProjectRelationsTab
                    {...restProps}
                    projectsStorage={projectsStorage}
                />
            )
        })
    }

    if (editMode !== UserEditorMode.Create && canSetPassword) {
        tabs.push({
            label: t("Set password"),
            content: (
                <UserProfileEditorPasswordsTab
                    isOwnProfile={ownProfile}
                    inProgress={restProps.isSettingUserPasswordInProgress}
                    onSetUserPassword={restProps.onSetUserPassword}
                />
            )
        })
    }

    return (
        <Tabs
            components={{ Item: UserTabItemComponent }}
            selected={tabs[tabIndex]}
            tabs={tabs}
            onSelect={onSelectTabHandler}
        />
    )
}

export const UserProfileEditorTabs = withGeovisServer(UserProfileEditorTabsView);