import { Reducer } from "redux";
import { LogbookModel } from "../../server/AVTService/TypeLibrary/Logbook/LogbookModel";
import {
    LOGBOOK_ENTRY_IMAGE_DELETE,
    LOGBOOK_ENTRY_IMAGE_UPLOAD_ERROR,
    LOGBOOK_ENTRY_IMAGE_UPLOAD_FINISHED,
    LOGBOOK_ENTRY_IMAGE_UPLOAD_PROGRESS,
    LOGBOOK_ENTRY_IMAGE_UPLOAD_STARTED,
    LOGBOOK_ENTRY_IMAGES_CLOSE_GALLERY,
    LOGBOOK_ENTRY_IMAGES_INIT,
    LOGBOOK_ENTRY_IMAGES_LOADED,
    LOGBOOK_ENTRY_IMAGES_SHOW_GALLERY
} from "../actions/logbookEntryImagesActions";
import { IGeovisLogbookEntryImagesAction, IGeovisLogbookEntryImagesState } from "../logbookEntryImages.types";
import { GeovisUploadFileState } from "../uploading.types";

const logbookEntryInitState = {
    ...new LogbookModel(),
    id: "",
    imageIds: []
}

export const logbookEntryImagesInitialState: IGeovisLogbookEntryImagesState = {
    imageIds: [],
    uploadFilesInfo: [],
    showImagesGallery: false,
    logbookEntry: {
        ...logbookEntryInitState
    },
    selectedImageIndex: 0
}

const addImageId = (ids: string[], id: string): string[] => {
    if (ids.indexOf(id) === -1) {
        ids.push(id);
    }

    return ids;
}

const removeImageId = (ids: string[], id: string): string[] => {
    const index = ids.indexOf(id);
    if (index > -1) {
        ids.splice(index, 1);
    }

    return ids;
}

const logbookEntryImagesReducer: Reducer<IGeovisLogbookEntryImagesState> = (
    state: IGeovisLogbookEntryImagesState = logbookEntryImagesInitialState,
    action: IGeovisLogbookEntryImagesAction): IGeovisLogbookEntryImagesState => {

    switch (action.type) {
        case LOGBOOK_ENTRY_IMAGES_INIT:
            return {
                ...logbookEntryImagesInitialState,
                logbookEntry: action.logbookEntry ? { ...action.logbookEntry } : { ...logbookEntryInitState }
            }

        case LOGBOOK_ENTRY_IMAGES_LOADED:
            if (!action.logbookEntry) {
                return state;
            }

            return {
                ...state,
                logbookEntry: { ...action.logbookEntry },
                imageIds: [...action.logbookEntry.imageIds || []]
            };

        case LOGBOOK_ENTRY_IMAGE_UPLOAD_STARTED:
        case LOGBOOK_ENTRY_IMAGE_UPLOAD_PROGRESS:
            if (!action.uploadFilesInfo) {
                return state;
            }

            return {
                ...state,
                uploadFilesInfo: action.uploadFilesInfo.map<GeovisUploadFileState>(f => ({ ...new GeovisUploadFileState(), ...f }))
            };

        case LOGBOOK_ENTRY_IMAGE_UPLOAD_FINISHED:
            if (!action.linkId || !action.logbookEntryImageInfo) {
                return state;
            }

            return {
                ...state,
                uploadFilesInfo: state.uploadFilesInfo.filter(f => f.id !== action.linkId),
                imageIds: addImageId(state.imageIds, action.logbookEntryImageInfo.Id)
            };

        case LOGBOOK_ENTRY_IMAGE_UPLOAD_ERROR:
            return state;

        case LOGBOOK_ENTRY_IMAGES_SHOW_GALLERY:
            if (!action.logbookEntry) {
                return state;
            }

            return {
                ...state,
                showImagesGallery: true,
                logbookEntry: action.logbookEntry,
                selectedImageIndex: action.selectedImageIndex || 0
            }

        case LOGBOOK_ENTRY_IMAGES_CLOSE_GALLERY:
            return {
                ...state,
                showImagesGallery: false,
                selectedImageIndex: 0
            }

        case LOGBOOK_ENTRY_IMAGE_DELETE:
            if (!action.imageId) {
                return state;
            }

            return {
                ...state,
                imageIds: removeImageId(state.imageIds, action.imageId)
            }
    }

    return state;
}

export default logbookEntryImagesReducer;