/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 04.06.2020
 * @description Common types for Report Overlay
 */

import { ChartType } from "../../../server/AVTService/TypeLibrary/Common/ChartType";
import { TimePeriod } from "../../../server/AVTService/TypeLibrary/Common/TimePeriod";
import { DtsChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/DtsChartModel";
import { GeovisChartAxisSettings } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartAxisSettings";
import { GeovisChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartModel";
import { HeatmapChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/HeatmapChartModel";
import { InclinometerChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/InclinometerChartModel";
import { InclinometerVisibleRangeSettings } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/InclinometerVisibleRangeSettings";
import { SemiCircleChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/SemiCircleChartModel";
import { SemiCircleType } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/SemiCircleType";
import { TimeValueChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/TimeValueChartModel";
import { VibrationChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/VibrationChartModel";
import { XyChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/XyChartModel";
import { GeovisLogbookModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisProjectElements/GeovisLogbookModel";
import { GeovisReportPageSettings } from "../../../server/AVTService/TypeLibrary/Model/GeovisReportPageSettings";
import { GeovisReportModel } from "../../../server/AVTService/TypeLibrary/Model/Reports/GeovisReportModel";
import { ChartSensorsDetails } from "../../../server/AVTService/TypeLibrary/Report/GEOvis4/Model/ChartSensorsDetails";
import { IGeovisProjectReportChangedElements, IGeovisReportPageConfig, IReportChartSensorsSelectionInfo } from "../../../store/projectReport.types";

export const COMMON_CHART_ID = -1;
export const COMMON_PAGE_ID = -1;

export enum ReportObjectType {
    All = 'all',
    Chart = "chart",
    GeovisChart = "geovisChart",
    GEOvisTable = "table",
    Geovis4Table = 'geovis4Table',
    Page = 'page',
    MapSection = 'mapSection',
    AllMapSections = 'allMapSections',
    AllGeovis4Tables = 'allGeovis4Tables',
    GeovisImage = 'geovisImage',
    AllGeovisImages = 'allGeovisImages',
    GeovisLogbook = 'geovisLogbook'
}

export type ChartSelectionCollectionName = "unitASensors" | "unitBSensors" | "pairs" | "dtsCloseTSensors" | "dtsSections" | "chains";

export const UNIT_A_SENSORS_COLLECTION_NAME: ChartSelectionCollectionName = "unitASensors";
export const UNIT_B_SENSORS_COLLECTION_NAME: ChartSelectionCollectionName = "unitBSensors";
export const PAIRS_COLLECTION_NAME: ChartSelectionCollectionName = "pairs";
export const DTS_CLOSE_T_SENSORS_COLLECTION_NAME: ChartSelectionCollectionName = "dtsCloseTSensors";
export const DTS_SECTIONS_COLLECTION_NAME: ChartSelectionCollectionName = "dtsSections";
export const CHAINS_COLLECTION_NAME: ChartSelectionCollectionName = "chains";

/**
 * Chart sensors selection map
 * Key - type of sensors by collection
 * Value - map of selected sensor ids
 */
export type ChartSensorsSelectionMap = Map<ChartSelectionCollectionName, Map<string, boolean>>;


/**
 * Just a map with key->value (string -> boolean), 
 * where key is sensor or chain id
 * value - selected or not
 */
export type ReportElementItemsSelectionMap = Map<string, boolean>;

/**
 * Old legacy interface, related to Legacy Report 
 * Restriction is: chart or other report element cannot be added twice to the report
 * @deprecated - this interface related to legacy report
 */
export interface IReportSensorsSelectionInfoLegacy {
    charts: Map<number, IReportChartSensorsSelectionInfo>;
}

export interface IChartsSensorsDetails { [key: number]: ChartSensorsDetails }

export type DtsSectionCloseTSensorIdsMap = { [key: string]: string[] };

//
export interface IReportPropertyItem {

    /**
     * the item id
     */
    itemId: string;

    /**
     * Element type
     */
    type: ReportItemType;

    /**
     * Element name
     */
    label: string;

    /**
     * Depth of the property.
     * Calculates the right offset for the row
     */
    depth: number;

    /**
     * String value of the property
     */
    stringValue?: string;

    /**
     * String values array
     */
    stringValues?: string[];

    /**
     * Boolean value of the property
     */
    booleanValue?: boolean;

    /**
     * Number value of the property
     */
    numberValue?: number;

    /**
     * Number values of the property
     */
    numberValues?: number[];

    /**
     * Array value of the property
     */
    arrayValue?: string[];

    /**
     * An array of values of any type
     */
    arrayTValues?: any[];

    /**
     * Draw the field as disabled
     */
    isDisabled?: boolean;

    /**
     * Draw the row as expanded
     */
    isExpanded?: boolean;

    /**
     * Invalid property information
     */
    invalidProperty?: InvalidProperty;

    /**
     * Children of the property
     */
    children?: IReportPropertyItem[];

    possibleArrayValues?: string[];

    optionItemToNameFunc?: (item: string | number) => string;

    labelExtension?: string;
}

/**
 * Report item types
 */
export enum ReportItemType {
    // main types
    All = 'all',
    Page = 'page',
    Chart = 'chart',
    GeovisChart = 'geovisChart',
    GEOvisTable = 'geovistable',
    GEOvis4Table = 'geovis4Table',
    MapSection = 'mapSection',
    GeovisImage = 'geovisImage',
    GeovisLogbook = 'geovisLogbook',

    // properties types
    BucketReportDataKind = "bucket_report_data_kind",
    HeatmapRenderMode = 'heatmap_render_mode',
    HeatmapDtsSections = 'heatmap_dts_sections',
    HeatmapDtsLoops = 'heatmap_dts_loops',
    HeatmapDtsLoopsDatabaseId = 'heatmap_dts_loops_databaseId',
    BooleanProperty = 'boolean',
    StringProperty = 'string',
    NumberProperty = 'number',
    TimePeriodProperty = 'timeperiod',
    DateTimeProperty = 'datetime',
    // chart axis settings: UnitA...UnitD
    AxisSettings = "axis_settings",
    // one chart axis scale property
    AxisScaleProperty = 'axis_scale',
    // axis sensors property
    AxisSensors = 'axis_sensors',
    // one axis sensor item property
    AxisSensor = 'axis_sensor',

    // regression settings
    RegressionSettings = "regression_settings",

    MeasureTimeCalcType = 'measure_time_calc_type',

    BooleanDateTimeProperty = 'boolean_datetime',

    ArrayProperty = 'array',

    // updated measure calc type property
    GeovisMeasureTimeCalcType = 'geovis_measure_time_calc_type',

    TimesProperty = 'geovis_times_property_type',

    ExactTimeslotsProperty = 'geovis_exact_timeslots_property_type',

    MapSectionSensorsSelection = 'map_section_sensors_selection',

    MapSectionSensor = 'map_section_sensor',

    GeovisImageSensor = 'geovis_image_sensor',

    GeovisImageSensorsSelection = 'geovis_image_sensors_selection',

    DateTimeRangeProps = 'datetime_range_props',
}

export interface IInvalidPropertyInfo {
    /**
     * The property id path, it is full path to the property of the object
     */
    propertyPath: string[];

    /**
     * List of error descriptions to the property
     */
    messages: string[];
}

export type InvalidProperty = IInvalidPropertyInfo | false;

//#region Communication with Report Frame

type GeovisRequestXAction = 'setCustomReportSettings' | 'clearCustomReportSettings' |
    'getCustomReportSettings' | 'collectReportCustomerSettings' |
    'setCustomReportSettingsLastUpdateTime' | 'executeBenchmarkOfStage';

export interface ISendMessageToReportFrameRequest {
    id?: string,
    xaction: GeovisRequestXAction;
    data?: any;
}

export interface ISendMessageToReportFrameResponse<TResponse = any> {
    id: string,
    status: 'OK' | 'Fail' | "Pending";
    data?: TResponse;
}

//#endregion

export type BooleanPropertyChange = (propertyId: string, value: boolean) => void;
export type StringPropertyChange = (propertyId: string, value: string) => void;
export type NumberPropertyChange = (propertyId: string, value: number) => void;
export type ArrayPropertyChange = (propertyId: string, value: string[] | number[]) => void;
export type ArrayTPropertyChange<T> = (propertyId: string, value: T[]) => void;

export interface IReportChartCallbackInfo {
    id: string;
    start: string;
    end: string;
}

export const getSpecificXyPropertiesNames = (): string[] => {
    const xyProps = Object.keys(XyChartModel);
    const baseModelProps = Object.keys(GeovisChartModel);

    return xyProps.filter(pr => !baseModelProps.includes(pr));
}

const geovisChartMainUserChangeableProperties: ReadonlyArray<keyof GeovisChartModel> = [
    "ShowAllMeasurementsSetting",
    "Period",
    "StartDateSetting",
    "EndDateSetting",
    "IncludeInvalidMarkedData",
    "BucketReportValueKind",
    "DrawLineBetweenPoints",
    "ShowCommonTooltip",
    "ShowCustomTooltip",
    "ShowLegend"
]

export const getTimeValueGeovisChartMainUserChangeableProperties = (): Array<keyof TimeValueChartModel> => (
    [
        "ShowAllMeasurementsSetting",
        "Period",
        "StartDateSetting",
        "EndDateSetting",
        "BucketReportValueKind",
        "IncludeInvalidMarkedData",
        "DrawLineBetweenPoints",
        "DrawLineBetweenPointsSecondary",
        "ShowCommonTooltip",
        "ShowCustomTooltip",
        "ShowLegend",
        "UseRelativeTimeSetting",
        "ShowLogbooksInLegend"
    ]
)

export const getXyGeovisChartMainUserChangeableProperties = (): Array<keyof XyChartModel> => (
    [
        "ShowAllMeasurementsSetting",
        "Period",
        "StartDateSetting",
        "EndDateSetting",
        "IncludeInvalidMarkedData",
        "BucketReportValueKind",
        "DrawLineBetweenPoints",
        "ShowCommonTooltip",
        "ShowCustomTooltip",
        "ShowLegend",
        "ShowReferenceValue",
        "MeasureTimeCalcType",
        "NumberOfMeasurementsToShow",
        "Times",
        "ExactTimeslots"
    ]
)

export const getInclinometerGeovisChartMainUserChangeableProperties = (): Array<keyof InclinometerChartModel> => (
    [
        "ShowAllMeasurementsSetting",
        "Period",
        "StartDateSetting",
        "EndDateSetting",
        "IncludeInvalidMarkedData",
        "DrawLineBetweenPoints",
        "ShowLegend",
        "MeasureTimeCalcType",
        "NumberOfMeasurementsToShow",
        "Times",
        "ShowAxisLabels",
        "ExactTimeslots"
    ]
)

export const getVibrationGeovisChartMainUserChangeableProperties = (): Array<keyof VibrationChartModel> => (
    [
        "ShowAllMeasurementsSetting",
        "Period",
        "StartDateSetting",
        "EndDateSetting",
        "IncludeInvalidMarkedData",
        "ShowEventGraph",
        "ShowBackgroundData"
    ]
)

export const getSemiCircleChartSMMainUserChangeableProperties = (): Array<keyof SemiCircleChartModel> => (
    [
        "Period",
        "StartDateSetting",
        "EndDateSetting"
    ]
)

export const getSemiCircleChartSVAndGMainUserChangeableProperties = (): Array<keyof SemiCircleChartModel> => (
    [
        "UseLastMeasurementTime",
        "Timeslot",
        "TimeSearchDistance"
    ]
)

export const getDtsGeovisChartMainUserChangeableProperties = (): Array<keyof DtsChartModel> => (
    [
        "StartDateSetting",
        "IncludeInvalidMarkedData",
        "DrawLineBetweenPoints",
        "ShowCommonTooltip",
        "ShowCustomTooltip",
        "ShowLegend",
        "ShowCloseTemperatureSensorsAsPoints",
        "ShowTemperaturePointLabels"
    ]
)

export const getHeatmapGeovisChartMainUserChangeableProperties = (): Array<keyof HeatmapChartModel> => (
    [
        "UseLastMeasurementTime",
        "Timeslot",
        "TimeSearchDistance",
        "RenderingMode",
        "IncludeInvalidMarkedData"
    ]
)

export const getSemiCircleGeovisChartMainUserChangeableProperties = (): Array<keyof SemiCircleChartModel> => (
    [
        "UseLastMeasurementTime",
        "Timeslot",
        "TimeSearchDistance",
        "StartDateSetting",
        "EndDateSetting",
        "Period"
    ]
)

export const getGeovisChartAxisUserChangeableProperties = (): Array<keyof GeovisChartAxisSettings> => (
    [
        "minScaleLimit",
        "maxScaleLimit",
        "ReverseAxis",
        // "SensorIds",
        "ShowAsRelative",
        "ShowPointSymbols",
        "ShowRegression",
        "ShowXAxisSensorName"
    ]
)

export const getInclinometerGeovisChartAxisUserChangeableProperties = (): Array<keyof InclinometerVisibleRangeSettings> => (
    [
        "MaxXLimit",
        "MinXLimit",
        "ShowJunctionPoints",
        "ShowSensors"
    ]
)

export const getAllElementsMainChangeableProperties = (): string[] => (
    [
        "ShowAllMeasurementsSetting",
        "Period",
        "StartDateSetting",
        "EndDateSetting"
    ]
)

export interface IGeovisLogbookModelChangeableView {
    Period: TimePeriod;
    StartDateSetting: string;
    EndDateSetting: string;
    ShowAuthor: boolean;
    ShowComment: boolean;
}

export const getGeovisLogbookMainUserChangeableProperties = (): Array<keyof IGeovisLogbookModelChangeableView> => (
    [
        "Period",
        "StartDateSetting",
        "EndDateSetting"
    ]
)

export const getGeovisChartMainProperties = (type: ChartType): ReadonlyArray<string> => {
    switch (type) {
        case ChartType.DtsChart: return getDtsGeovisChartMainUserChangeableProperties();
        case ChartType.HeatMap: return getHeatmapGeovisChartMainUserChangeableProperties();
        case ChartType.TimeValue: return getTimeValueGeovisChartMainUserChangeableProperties();
        case ChartType.XYDiagram: return getXyGeovisChartMainUserChangeableProperties();
        case ChartType.Inclinometer: return getInclinometerGeovisChartMainUserChangeableProperties();
        case ChartType.VibrationDiagramm: return getVibrationGeovisChartMainUserChangeableProperties();
        case ChartType.XYVibrationEventDiagram: return getVibrationGeovisChartMainUserChangeableProperties();
        case ChartType.SemiCircle: return getSemiCircleGeovisChartMainUserChangeableProperties();
        default: return geovisChartMainUserChangeableProperties;
    }
}

export interface ICommonReportElements {
    commonGeovisChart: GeovisChartModel | false;
    commonGeovisLogbook: GeovisLogbookModel | false;
}

export const getFirstElementsInReport = (pages: IGeovisReportPageConfig[]): ICommonReportElements => {
    let commonGeovisChart: GeovisChartModel | false = false;
    let commonGeovisLogbook: GeovisLogbookModel | false = false;

    for (const page of pages) {
        if (!commonGeovisChart) {
            if (page.geovisCharts.size > 0) {
                const [chartInfo] = page.geovisCharts.values();
                commonGeovisChart = chartInfo.Chart;
            }
        }

        if (!commonGeovisLogbook) {
            if (page.geovisLogbooks.size > 0) {
                const [logbookInfo] = page.geovisLogbooks.values();
                commonGeovisLogbook = logbookInfo.GeovisLogbook;
            }
        }

        if (commonGeovisChart && commonGeovisLogbook) {
            break;
        }
    }

    return { commonGeovisChart, commonGeovisLogbook };
}

export const getCommonMainUserChangeableProperties = (geovisPages: IGeovisReportPageConfig[]): ReadonlyArray<string> => {

    const charsByChartType = new Map<ChartType, GeovisChartModel[]>();
    let totalChartsCount = 0;

    for (const page of geovisPages) {

        if (page.geovisImages.size > 0 || page.mapSections.size > 0 || page.tables.size > 0 || page.geovisLogbooks.size > 0) {
            return geovisChartMainUserChangeableProperties;
        }

        totalChartsCount += page.geovisCharts.size;

        page.geovisCharts.forEach(({ Chart }) => {
            if (charsByChartType.has(Chart.Type)) {
                const charts = charsByChartType.get(Chart.Type);
                charts?.push(Chart);
                charsByChartType.set(Chart.Type, charts!);
            }
            else {
                charsByChartType.set(Chart.Type, [Chart]);
            }
            //charsByChartType.set(Chart.Type, (charsByChartType.get(Chart.Type) || 0) + 1);
        })
    }

    const allIsTimeValue = totalChartsCount !== 0 && charsByChartType.get(ChartType.TimeValue)?.length === totalChartsCount;
    const allIsXy = totalChartsCount !== 0 && charsByChartType.get(ChartType.XYDiagram)?.length === totalChartsCount;
    const allIsInclinometer = totalChartsCount !== 0 && charsByChartType.get(ChartType.Inclinometer)?.length === totalChartsCount;
    const allIsDts = totalChartsCount !== 0 && charsByChartType.get(ChartType.DtsChart)?.length === totalChartsCount;
    const allIsHeatmap = totalChartsCount !== 0 && charsByChartType.get(ChartType.HeatMap)?.length === totalChartsCount;
    const allIsVibration = totalChartsCount !== 0 && charsByChartType.get(ChartType.VibrationDiagramm)?.length === totalChartsCount;
    const allIsXyVibration = totalChartsCount !== 0 && charsByChartType.get(ChartType.XYVibrationEventDiagram)?.length === totalChartsCount;
    const allIsSemiCircle = totalChartsCount !== 0 && charsByChartType.get(ChartType.SemiCircle)?.length === totalChartsCount;

    if (allIsTimeValue) {
        return getTimeValueGeovisChartMainUserChangeableProperties();
    }
    if (allIsDts) {
        return getDtsGeovisChartMainUserChangeableProperties();
    }
    if (allIsHeatmap) {
        return getHeatmapGeovisChartMainUserChangeableProperties();
    }
    if (allIsXy) {
        return getXyGeovisChartMainUserChangeableProperties();
    }
    if (allIsInclinometer) {
        return getInclinometerGeovisChartMainUserChangeableProperties();
    }
    if (allIsVibration) {
        return getVibrationGeovisChartMainUserChangeableProperties();
    }
    if (allIsXyVibration) {
        return getVibrationGeovisChartMainUserChangeableProperties();
    }

    if (allIsSemiCircle) {
        const allIsSuccessMeas = charsByChartType.get(ChartType.SemiCircle)?.filter(ch => (ch as SemiCircleChartModel).SemiCircleType === SemiCircleType.SuccessfulMeasurementRate).length === totalChartsCount;
        const allNotSuccessMeas = charsByChartType.get(ChartType.SemiCircle)?.filter(ch => (ch as SemiCircleChartModel).SemiCircleType !== SemiCircleType.SuccessfulMeasurementRate).length === totalChartsCount;

        if (allIsSuccessMeas) {
            return getSemiCircleChartSMMainUserChangeableProperties();
        }
        if (allNotSuccessMeas) {
            return getSemiCircleChartSVAndGMainUserChangeableProperties();
        }
    }

    return geovisChartMainUserChangeableProperties;
}

export interface IGeovisSettingsLocalStorageModel extends IGeovisProjectReportChangedElements {
    detailedReportInfo: GeovisReportModel;
    customerPages: GeovisReportPageSettings[];
    dtsSectionCloseTSensorIdsMap: { [key: string]: string[] };
    version: string;
}

export const DEFAULT_VALUE_PROPERTY_LABEL = "default_value_property";

export const DATE_TIME_RANGE_SELECTS_START_LABEL = "date_time_range_selects_start_label";
export const DATE_TIME_RANGE_SELECTS_END_LABEL = "date_time_range_selects_end_label";