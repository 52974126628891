/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 28.10.2020
 * @description The dynamic stateless table with virtual pagination
 */

import { DynamicTableStateless } from "@atlaskit/dynamic-table";
import { HeadType, RowType } from "@atlaskit/dynamic-table/types";
import Pagination from '@atlaskit/pagination';
import { OptionType } from "@atlaskit/select";
import Spinner from '@atlaskit/spinner';
import { SyntheticEvent } from 'react';
import { RowsPerPageSelect } from "./RowsPerPageSelect";

const defaultRowsPerPageValues: number[] = [20, 50, 100, 500, 1000];
// const defaultRowsPerPage = defaultRowsPerPageValues[0];

interface IDynamicTableStatelessWithVirtualPaginationProps {
    head: HeadType;
    rows: RowType[];
    selectedPage?: number
    totalRows?: number
    onSelectedPageChanged?: (page: number) => void;
    isLoading?: boolean
    showRowsPerPageSelectAnyway?: boolean;
    rowsPerPage?: number;
    rowsPerPageValues?: number[];
    sortKey?: string;
    sortOrder?: string;
    onRowPerPageChanged?: (value: number) => void;
    onSort?: (data: any, event: any) => void;
}

export const DynamicTableStatelessWithVirtualPagination = ({
    isLoading,
    head,
    rows,
    selectedPage,
    totalRows,
    onRowPerPageChanged,
    onSelectedPageChanged,
    rowsPerPage,
    rowsPerPageValues,
    showRowsPerPageSelectAnyway,
    onSort,
    sortKey,
    sortOrder
}: IDynamicTableStatelessWithVirtualPaginationProps) => {

    if (isLoading) {
        return (
            <div className="flexSpinnerContainer">
                <Spinner size="xlarge" testId="dynamic-tbl-with-v-pagination-spinner" />
            </div>
        )
    }

    const controlRowsPerPageValues = rowsPerPageValues || defaultRowsPerPageValues;
    const controlRowsPerPage = rowsPerPage || controlRowsPerPageValues[0];

    if (!selectedPage) {
        selectedPage = 1;
    }

    const pages = getPages(rows, totalRows, controlRowsPerPage);
    const selectedPageIndex = pages.indexOf(selectedPage);

    const onSelectedPageChangedHandler = (event: SyntheticEvent<any>, page: number) => {
        if (onSelectedPageChanged) {
            onSelectedPageChanged(page);
        }
    };

    const onRowsPerPageChangedHandler = (option: OptionType) => {
        if (onRowPerPageChanged) {
            onRowPerPageChanged(+option.value);
        }
    }

    return (
        <div className="flexContainer">
            <div className="flexRowMiddleContainer" style={{ width: '100%' }}>
                <DynamicTableStateless
                    head={head}
                    rows={rows}
                    testId="dynamic-rows"
                    onSort={onSort}
                    sortKey={sortKey}
                    sortOrder={sortOrder}
                />
            </div>
            <div className="flexRowContainer">
                {/* pagination */}
                <Pagination
                    selectedIndex={selectedPageIndex}
                    pages={pages}
                    onChange={onSelectedPageChangedHandler}
                />
            </div>
            {(showRowsPerPageSelectAnyway || pages.length > 1) && (
                <RowsPerPageSelect
                    options={getRowsPerPageOptions(controlRowsPerPageValues)}
                    selectedValue={controlRowsPerPage}
                    onChange={onRowsPerPageChangedHandler}
                />
            )}
        </div>
    )
}

export const getPages = (rows: RowType[], totalRows: number | undefined, rowsPerPage: number): number[] => {
    if (rows.length === 0) {
        return [1];
    }

    if (!totalRows) {
        totalRows = rows.length;
    }

    let countPages = totalRows / rowsPerPage;
    if (totalRows % rowsPerPage > 0) {
        countPages++;
    }

    const result: number[] = [];
    for (let page = 1; page <= countPages; page++) {
        result.push(page);
    }

    return result;
}

export const getRowsPerPageOptions = (rowsPerPageValues?: number[]): OptionType[] => {
    if (!rowsPerPageValues) {
        rowsPerPageValues = defaultRowsPerPageValues;
    }

    return rowsPerPageValues.map<OptionType>(v => ({
        label: v.toString(), value: v
    }))
}