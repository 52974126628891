import AddIcon from '@atlaskit/icon/glyph/add';
import LinkIcon from '@atlaskit/icon/glyph/link';
import InfoIcon from '@atlaskit/icon/glyph/info'
import Button from "@atlaskit/button";
import TrashIcon from '@atlaskit/icon/glyph/trash';
import EditFilledIcon from '@atlaskit/icon/glyph/edit-filled';
import { SyntheticEvent, useEffect, useState } from 'react';
import { HeadCellType, HeadType, RowCellType, RowType } from '@atlaskit/dynamic-table/dist/types/types';
import { ToggleStateless } from '@atlaskit/toggle';
import { Checkbox } from '@atlaskit/checkbox';
import { useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Tooltip from '@atlaskit/tooltip';
import { CompanyInfo } from "../../../../../server/GEOvis3/Model/Company/CompanyInfo";
import { IGeovisProjectUsersStorage } from "../../../../../store/data.types";
import { t } from "../../../../../i18n";
import { AlarmActionAddUserDialog } from './AlarmActionAddUserDialog';
import { GeovisUserCommonInfo } from '../../../../../server/GEOvis3/Model/User/GeovisUserCommonInfo';
import { AlarmCondition } from '../../../../../server/AVTService/TypeLibrary/Alarming/AlarmCondition';
import { DynamicTableWithFixedHeader } from '../../../../../components/DynamicTableWithFixedHeader';
import UserProfileEditorDialog from '../../../../../components/users/UserProfileEditorDialog';
import { getEditUserDialogMode } from '../../../../../helpers/UsersHelper';
import { AlarmActionNotifyUsersOnCondition } from '../../../../../server/AVTService/TypeLibrary/Alarming/AlarmActionNotifyUsersOnCondition';
import { GeovisUserTableInfo } from '../../../../../server/GEOvis3/Model/User/GeovisUserTableInfo';
import { GeovisAlarmActionModel } from '../../../../../server/AVTService/TypeLibrary/Alarming/GeovisAlarmActionModel';
import ServerRoutesGen from '../../../../../server/Routes/ServerRoutesGen';
import { fetchServerElements } from '../../../../../helpers/ProjectDataHelper';
import { IGeovisAction, ISomethingStorageBaseEx, defaultSomethingStorageState, errorSomethingStorageState, loadedSomethingStorageState } from '../../../../../store/types';
import IRouteParams from '../../../../../helpers/IRouteParams';
import { IWithGeovisServerProps, withGeovisServer } from '../../../../../helpers/GeovisHooks';
import { invertArray, verifyUsersPhone } from './tools';
import { IGeovisStoreState } from '../../../../../store/store.types';
import { projectDataUsersUpdate } from '../../../../../store/creators/dataCreators';
import ManageUsersPresetsDialog from '../../usersPresets/ManageUsersPresetsDialog';
import { AlarmActivationBlockModel } from '../../../../../server/GEOvis3/Model/Alarms/UsersPresets/AlarmActivationBlockModel';
import LinkPresetDialog from '../../usersPresets/LinkPresetDialog';
import { AlarmActionUsersPreset } from '../../../../../server/AVTService/TypeLibrary/Alarming/AlarmActionUsersPreset';
import FlagService from '../../../../../services/FlagService';
import { LicensedFeatures } from '../../../../../server/AVTService/TypeLibrary/Licensing/LicensedFeatures';

interface IComponentStateProps {
    projectUsersStorage: IGeovisProjectUsersStorage;
    availableFeatures: LicensedFeatures[];
}

interface IComponentDispatchProps {
    projectUsersUpdate: (...projectUsers: GeovisUserCommonInfo[]) => void;
}

interface IComponentOwnProps {
    actionState: GeovisAlarmActionModel;
    isReadonly: boolean;
    companies: CompanyInfo[];
    conditions: AlarmCondition[];
    updateAction: (propertyName: keyof GeovisAlarmActionModel, value: any) => void;
    onChangeAlarmActivationPossibility: (config: AlarmActivationBlockModel) => void;
}

interface IComponentProps extends IWithGeovisServerProps, IComponentStateProps, IComponentDispatchProps, IComponentOwnProps {

}

interface IComponentState extends ISomethingStorageBaseEx<CompanyInfo[]> {
    showAddUsersDialog: boolean;
    showEditUserDialog: boolean;
    showLinkPresetDialog: boolean;
    showManagePresetDialog: boolean;
    userId: string;
    sortOrder: string;
}

const Component = ({
    actionState,
    companies,
    isReadonly,
    projectUsersStorage,
    updateAction,
    conditions,
    projectUsersUpdate,
    Server,
    onChangeAlarmActivationPossibility,
    availableFeatures
}: IComponentProps) => {

    const { projectId } = useParams<IRouteParams>();



    const [state, setState] = useState<IComponentState>({
        ...defaultSomethingStorageState,
        showAddUsersDialog: false,
        showEditUserDialog: false,
        userId: "",
        sortOrder: "ASC",
        data: [],
        showLinkPresetDialog: false,
        showManagePresetDialog: false
    })

    const [presetState, setPresetState] = useState<AlarmActionUsersPreset>({ ... new AlarmActionUsersPreset(), Id: "" });

    useEffect(() => {
        (async function loadCompaniesData() {
            const url = ServerRoutesGen.Account.RelatedCompanies.patch(projectId);
            const response = await fetchServerElements<CompanyInfo[]>(Server, url);
            if (!response.Success) {
                setState({
                    ...state,
                    ...errorSomethingStorageState(response.Messages.join("; ")),
                    data: []
                })
                return;
            }
            setState({
                ...state,
                ...loadedSomethingStorageState,
                data: response.Data
            })
        })();
    }, [1])

    useEffect(() => {
        (async function loadPresetInfo() {
            if (actionState.ReceiversPresetId !== "" && !state.showManagePresetDialog) {
                setPresetState({ ... new AlarmActionUsersPreset(), Id: "" });
                const url = ServerRoutesGen.Alarms.GetUsersPreset.patch(projectId, actionState.ReceiversPresetId);
                const response = await fetchServerElements<AlarmActionUsersPreset>(Server, url);
                if (!response.Success) {
                    FlagService.addError("Failed to get users preset info", response.Messages.join("; "));
                    return;
                }
                setPresetState(response.Data);
            }
            else if (actionState.ReceiversPresetId === "") {
                setPresetState({ ... new AlarmActionUsersPreset(), Id: "" });
            }
        })();
    }, [actionState.ReceiversPresetId, state.showManagePresetDialog])

    const onCloseAddUserDialog = () => {
        setState({
            ...state,
            showAddUsersDialog: false
        })
    }

    const onShowAddUsersDialog = () => {
        setState({
            ...state,
            showAddUsersDialog: true
        })
    }

    const getAddedUsersIds = (): string[] => {
        const result: string[] = [];

        if (presetState.Id !== "") {
            result.push(...presetState.Users.map(u => u.UserId))
        }

        actionState.EmailNotificationsStrategy.forEach(item => {
            if (!result.includes(item.UserId)) {
                result.push(item.UserId);
            }
        })
        actionState.SmsNotificationsStrategy.forEach(item => {
            if (!result.includes(item.UserId)) {
                result.push(item.UserId);
            }
        })
        return result;
    }

    const onAddNewUserIds = (userIds: string[]) => {

        userIds.forEach(id => {
            const smsStrategy = actionState.SmsNotificationsStrategy.find(s => s.UserId === id);
            const emailStrategy = actionState.EmailNotificationsStrategy.find(s => s.UserId === id);
            if (!emailStrategy) {
                actionState.EmailNotificationsStrategy.push({
                    UserId: id,
                    ConditionsIds: conditions.map(c => c.Id)
                });
            }
            if (!smsStrategy) {
                actionState.SmsNotificationsStrategy.push({
                    UserId: id,
                    ConditionsIds: []
                })
            }
        })

        updateAction("EmailNotificationsStrategy", actionState.EmailNotificationsStrategy);
        updateAction("SmsNotificationsStrategy", actionState.SmsNotificationsStrategy);

        setState({
            ...state,
            showAddUsersDialog: false,
        })
    }

    const getUserName = (user: GeovisUserCommonInfo): string => {
        return `${user.Name}, ${user.Forename}`;
    }

    const getUserCompany = (user: GeovisUserCommonInfo): string => {
        if (!state.isLoaded) {
            return "";
        }
        const company = state.data.find(c => c.Id === user.CompanyId);
        return company === undefined ? "" : company.Name;
    }

    const isLinkedUser = (userId: string): boolean => {
        if (presetState.Id === "") {
            return false;
        }

        return presetState.Users.find(u => u.UserId === userId) !== undefined;
    }

    const getRows = (): RowType[] => {
        const users: GeovisUserCommonInfo[] = [];

        const usersIds: string[] = [];
        if (presetState.Id !== "") {
            usersIds.push(...presetState.Users.map(u => u.UserId))
        }
        actionState.SmsNotificationsStrategy.forEach(s => {
            if (!usersIds.includes(s.UserId)) {
                usersIds.push(s.UserId);
            }
        })
        actionState.EmailNotificationsStrategy.forEach(s => {
            if (!usersIds.includes(s.UserId)) {
                usersIds.push(s.UserId);
            }
        })

        usersIds.forEach(id => {
            const user = projectUsersStorage.users.get(id);
            if (user) {
                users.push(user);
            }
        })

        const sortUsers = (userA: GeovisUserCommonInfo, userB: GeovisUserCommonInfo): number => {
            const companyA = getUserCompany(userA);
            const companyB = getUserCompany(userB);
            const nameA = getUserName(userA);
            const nameB = getUserName(userB);

            if (nameA === nameB) {
                return 0;
            }
            const companyCompareResult = companyA.localeCompare(companyB, undefined, { sensitivity: 'base' });
            if (companyCompareResult === 0) {
                return nameA.localeCompare(nameB, undefined, { sensitivity: 'base' }) * (state.sortOrder === "ASC" ? 1 : -1);
            }
            return companyCompareResult * (state.sortOrder === "ASC" ? 1 : -1);
        }

        return users.sort((u1, u2) => sortUsers(u1, u2)).map(user => {
            const isLinked = isLinkedUser(user.Id);
            return ({
                key: `row-${user.Id}`,
                style: isLinked ? { background: 'lightGrey' } : undefined,
                cells: [{
                    key: 'name',
                    content: (
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '5px' }}>
                            <span>{getUserName(user)}</span>
                        </div>
                    )
                }, {
                    key: 'company',
                    content: (
                        <span>{getUserCompany(user)}</span>
                    )
                },
                ...getCellsFromConditions(user.Id), {
                    key: 'actions',
                    content: (
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            {!isLinked &&
                                <div>
                                    <Button
                                        iconBefore={<EditFilledIcon label='edit' />}
                                        spacing='compact'
                                        appearance="subtle-link"
                                        isDisabled={isReadonly || isLinked}
                                        onClick={showUserEditDialog(user.Id)}
                                    />
                                </div>
                            }
                            {!isLinked &&
                                <div>
                                    <Button
                                        iconBefore={<TrashIcon label='remove' />}
                                        spacing='compact'
                                        appearance="subtle-link"
                                        isDisabled={isReadonly || isLinked}
                                        onClick={removeUserFromReceivers(user.Id)}
                                    />
                                </div>
                            }
                            {isLinked &&
                                <div style={{ marginRight: '5px' }}>
                                    <Tooltip content={`${t("Alarm settings of this user are handled with preset ")}${presetState.Name}`}>
                                        <InfoIcon label='isLinked' />
                                    </Tooltip>
                                </div>
                            }
                        </div>
                    )
                }]
            })
        })
    }

    const getHead = (): HeadType => {
        return ({
            cells: [{
                key: 'name',
                width: 10,
                isSortable: false,
                content: (
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <span>{t("Receivers")}</span>
                    </div>
                )
            }, {
                key: 'company',
                width: 15,
                isSortable: false,
                content: (
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <span>{t("Company")}</span>
                    </div>
                )
            },
            ...getHeadFromConditions(),
            {
                key: "actions",
                width: 15,
            }]
        })
    }

    const getHeadFromConditions = (): HeadCellType[] => {
        const result: HeadCellType[] = [];

        invertArray(conditions).forEach((condition, index, collection) => {
            result.push({
                isSortable: false,
                key: condition.severityName,
                width: 70 / collection.length,
                content: (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ background: condition.color, display: 'flex', justifyContent: 'space-around' }}>
                            <span>{condition.severityName}</span>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <div style={{ width: '100%', textAlign: 'center', marginLeft: '-3px' }}>
                                    <span>{t("Email")}</span>
                                </div>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
                                    <Checkbox
                                        isDisabled={isReadonly}
                                        isChecked={allReceiversSelectedForCondition(true, condition.Id)}
                                        onChange={onAllConditionNotificationsChanged(true, condition.Id)}
                                    />
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <div style={{ width: '100%', textAlign: 'center', marginLeft: '-3px' }}>
                                    <span>{t("SMS")}</span>
                                </div>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
                                    <Checkbox
                                        isDisabled={isReadonly}
                                        isChecked={allReceiversSelectedForCondition(false, condition.Id)}
                                        onChange={onAllConditionNotificationsChanged(false, condition.Id)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        })

        return result;
    }

    const allReceiversSelectedForCondition = (email: boolean, conditionId: string): boolean => {
        //const strategy = (email ? actionState.EmailNotificationsStrategy : actionState.SmsNotificationsStrategy).find(s => s.ConditionsIds.includes(conditionId));
        let result = true;
        if (email) {
            if (actionState.EmailNotificationsStrategy.length === 0) {
                result = false;
            }
            actionState.EmailNotificationsStrategy.forEach(s => {
                if (!s.ConditionsIds.includes(conditionId)) {
                    result = false;
                }
            });
        }
        else {
            if (actionState.SmsNotificationsStrategy.length === 0) {
                result = false;
            }
            actionState.SmsNotificationsStrategy.forEach(s => {
                if (verifyUsersPhone(s.UserId, projectUsersStorage) && !s.ConditionsIds.includes(conditionId)) {
                    result = false;
                }
            });
        }

        return result;
    }

    const onAllConditionNotificationsChanged = (email: boolean, conditionId: string) => (event: SyntheticEvent<HTMLInputElement>) => {
        if (event.currentTarget.checked) {
            if (email) {
                actionState.EmailNotificationsStrategy.forEach(s => {
                    if (!s.ConditionsIds.includes(conditionId)) {
                        s.ConditionsIds.push(conditionId);
                    }
                })
                updateAction("EmailNotificationsStrategy", actionState.EmailNotificationsStrategy);
            } else {
                actionState.SmsNotificationsStrategy.forEach(s => {
                    if (verifyUsersPhone(s.UserId, projectUsersStorage) && !s.ConditionsIds.includes(conditionId)) {
                        s.ConditionsIds.push(conditionId);
                    }
                })
                updateAction("SmsNotificationsStrategy", actionState.SmsNotificationsStrategy);
            }
        }
        else {
            // remove all selected users from strategy
            if (email) {
                actionState.EmailNotificationsStrategy.forEach(s => {
                    s.ConditionsIds = s.ConditionsIds.filter(c => c !== conditionId);
                })
                updateAction("EmailNotificationsStrategy", actionState.EmailNotificationsStrategy);
            } else {
                actionState.SmsNotificationsStrategy.forEach(s => {
                    s.ConditionsIds = s.ConditionsIds.filter(c => c !== conditionId);
                })
                updateAction("SmsNotificationsStrategy", actionState.SmsNotificationsStrategy);
            }
        }
    }

    // const onChange

    const removeUserFromReceivers = (userId: string) => () => {

        actionState.EmailNotificationsStrategy = actionState.EmailNotificationsStrategy.filter(s => s.UserId !== userId)
        actionState.SmsNotificationsStrategy = actionState.SmsNotificationsStrategy.filter(s => s.UserId !== userId)

        updateAction("EmailNotificationsStrategy", actionState.EmailNotificationsStrategy);
        updateAction("SmsNotificationsStrategy", actionState.SmsNotificationsStrategy);

        setState({
            ...state,
            showAddUsersDialog: false,
        })
    }

    const showUserEditDialog = (userId: string) => () => {
        setState({
            ...state,
            showEditUserDialog: true,
            userId
        })
    }
    const hideUserEditDialog = () => {
        setState({
            ...state,
            showEditUserDialog: false,
            userId: ""
        })
    }

    const saveUserData = (user: GeovisUserTableInfo) => {
        projectUsersUpdate(user);
        hideUserEditDialog();
    }

    const onChangeUserReceivingState = (email: boolean, userId: string, conditionId: string) => (event: SyntheticEvent<HTMLInputElement>) => {
        const user = projectUsersStorage.users.get(userId);

        if (!user) {
            return;
        }

        const strategy = (email ? actionState.EmailNotificationsStrategy : actionState.SmsNotificationsStrategy).find(s => s.UserId === userId);

        if (event.currentTarget.checked) {
            if (strategy) {
                if (!strategy.ConditionsIds.includes(conditionId)) {
                    strategy.ConditionsIds.push(conditionId);
                }
            }
            else {
                const newStrategy: AlarmActionNotifyUsersOnCondition = {
                    ConditionsIds: [conditionId],
                    UserId: userId
                }

                if (email) {
                    actionState.EmailNotificationsStrategy.push(newStrategy);
                }
                else {
                    actionState.SmsNotificationsStrategy.push(newStrategy);
                }
            }
            if (email) {
                updateAction("EmailNotificationsStrategy", actionState.EmailNotificationsStrategy);
            } else {
                updateAction("SmsNotificationsStrategy", actionState.SmsNotificationsStrategy);
            }
        }
        else {
            if (!strategy) {
                return;
            }
            strategy.ConditionsIds = strategy.ConditionsIds.filter(u => u !== conditionId);
            if (email) {
                updateAction("EmailNotificationsStrategy", actionState.EmailNotificationsStrategy);
            } else {
                updateAction("SmsNotificationsStrategy", actionState.SmsNotificationsStrategy);
            }
        }
    }

    const getIsChecked = (conditionId: string, alarmConditions: AlarmCondition[], presetUserSettings?: number[], conditionStrategy?: AlarmActionNotifyUsersOnCondition): boolean => {
        const conditionIndex = alarmConditions.findIndex(c => c.Id === conditionId);
        if (conditionIndex < 0) {
            return false;
        }

        if (presetUserSettings !== undefined) {
            return presetUserSettings.includes(conditionIndex);
        }

        if (conditionStrategy !== undefined) {
            return conditionStrategy.ConditionsIds.includes(conditionId);
        }

        return false;
    }

    const getCellsFromConditions = (userId: string): RowCellType[] => {
        const isLinked = isLinkedUser(userId);
        const result: RowCellType[] = [];
        const emailConditionStrategy = actionState.EmailNotificationsStrategy.find(s => s.UserId === userId);
        const smsConditionStrategy = actionState.SmsNotificationsStrategy.find(s => s.UserId === userId);
        const presetUser = presetState.Id === "" ? undefined : presetState.Users.find(u => u.UserId === userId);
        const emailPreset = presetUser === undefined ? undefined : presetUser.EmailNotifyConditionsIndexes;
        const smsPreset = presetUser === undefined ? undefined : presetUser.SmsNotifyConditionsIndexes;
        invertArray(conditions).forEach((condition) => {
            result.push({
                key: condition.severityName,
                content: (
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
                            <ToggleStateless
                                isChecked={getIsChecked(condition.Id, conditions, emailPreset, emailConditionStrategy)}
                                onChange={isLinked ? undefined : onChangeUserReceivingState(true, userId, condition.Id)}
                                isDisabled={isReadonly}
                                css={{
                                    cursor: isLinked ? 'not-allowed' : 'pointer'
                                }}
                            />
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
                            <ToggleStateless
                                isChecked={getIsChecked(condition.Id, conditions, smsPreset, smsConditionStrategy)}
                                isDisabled={!verifyUsersPhone(userId, projectUsersStorage) || isReadonly}
                                onChange={isLinked ? undefined : onChangeUserReceivingState(false, userId, condition.Id)}
                                css={{
                                    cursor: isLinked ? 'not-allowed' : 'pointer'
                                }}
                            />
                        </div>
                    </div>
                )
            })
        })

        return result;
    }

    const onShowManagePresetDialog = () => {
        setState({
            ...state,
            showManagePresetDialog: true
        });
    }

    const onCloseManagePresetDialog = () => {
        setState({
            ...state,
            showManagePresetDialog: false
        });
    }

    const onShowLinkToPresetDialog = () => {
        setState({
            ...state,
            showLinkPresetDialog: true
        });
    }

    const onCloseLinkToPresetDialog = () => {
        setState({
            ...state,
            showLinkPresetDialog: false
        });
    }

    const onLinkToUsersPreset = (presetId: string) => {
        updateAction("ReceiversPresetId", presetId);
        setState({
            ...state,
            showLinkPresetDialog: false,
        })
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '10px', gap: '5px' }}>
            <div className="flexRowContainerLine" style={{ width: '100%' }}>
                <div style={{ flexGrow: 2 }}>
                    {/*  */}
                </div>
                <Button
                    appearance={isReadonly ? 'default' : 'primary'}
                    onClick={onShowManagePresetDialog}
                    isDisabled={isReadonly || !availableFeatures.includes(LicensedFeatures.Alarm_actions)}
                >
                    {t("Manage Email & Sms Receivers presets")}
                </Button>
                <Button
                    appearance={isReadonly ? 'default' : 'primary'}
                    iconBefore={<LinkIcon label={'link'} />}
                    onClick={onShowLinkToPresetDialog}
                    isDisabled={isReadonly}
                >
                    {t("Link users from preset")}
                </Button>
                <Button
                    appearance={isReadonly ? 'default' : 'primary'}
                    iconBefore={<AddIcon label={'add'} />}
                    isDisabled={isReadonly}
                    onClick={onShowAddUsersDialog}
                >
                    {t("Add user")}
                </Button>
            </div>
            <div style={{ marginTop: '10px', height: '100%' }}>
                <DynamicTableWithFixedHeader
                    rows={getRows()}
                    head={getHead()}
                    isLoading={state.isLoading}
                    emptyView={<span style={{ fontSize: '16px' }}>{t("No users selected to receive message")}</span>}
                />
            </div>
            <div>
                <span style={{ fontWeight: 'bold' }}>{`*${t("SMS sending only works with CH/FL network providers")}`}</span>
            </div>
            {state.showAddUsersDialog && (
                <AlarmActionAddUserDialog
                    addedUsersIds={getAddedUsersIds()}
                    companies={companies}
                    projectUsersStorage={projectUsersStorage}
                    onClose={onCloseAddUserDialog}
                    onAdd={onAddNewUserIds}
                />
            )}

            {state.showEditUserDialog && (
                <UserProfileEditorDialog
                    userId={state.userId}
                    editMode={getEditUserDialogMode()}
                    onSave={saveUserData}
                    onClose={hideUserEditDialog}
                />
            )}
            {state.showManagePresetDialog &&
                <ManageUsersPresetsDialog
                    onClose={onCloseManagePresetDialog}
                    onChangeAlarmActivationPossibility={onChangeAlarmActivationPossibility}
                />
            }
            {state.showLinkPresetDialog &&
                <LinkPresetDialog
                    linkedPresetId={actionState.ReceiversPresetId}
                    onClose={onCloseLinkToPresetDialog}
                    onLink={onLinkToUsersPreset}
                    countOfConditions={conditions.length}
                />
            }
        </div>
    )
}

const mapStateToProps = (state: IGeovisStoreState): IComponentStateProps => ({
    projectUsersStorage: state.data.projectUsersStorage,
    availableFeatures: state.data.projectInfo.project.AvailableFeatures
})

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>): IComponentDispatchProps => ({
    projectUsersUpdate: (users) => dispatch(projectDataUsersUpdate(users)),
})

export default connect<IComponentStateProps, IComponentDispatchProps, IComponentOwnProps>(
    mapStateToProps,
    mapDispatchToProps
)(withGeovisServer(Component));