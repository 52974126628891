/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 13.07.2023
 * @description The component to draw vibration event data on the page
 */

import { t } from "../../../../i18n";
import { GeovisReportElementInfo } from "../../../../server/AVTService/TypeLibrary/Model/Reports/GeovisReportElementInfo";
import { LoadingPageErrorSkeleton } from "../../../LoadingPageErrorSkeleton";
import { ChartElementRender } from "./ChartElementRender";
import { IReportElementRenderHostOwnProp } from "./types";

interface IComponentProps extends Omit<IReportElementRenderHostOwnProp, 'isVibrationEventChart' | 'showVibrationEventChartOnPage' | 'elementIndexOnPage' | 'isInGroup' | 'lastInGroup' | 'elementInfo' | 'isDefault'> {
    onHideEvent: () => void;
    elementInfo: GeovisReportElementInfo | undefined;
}

const Component = ({ elementInfo, onHideEvent, ...restProps }: IComponentProps) => {

    if (!elementInfo) {
        return (
            <LoadingPageErrorSkeleton errorText={t("Error to draw vibration event element")} />
        )
    }

    return (
        <ChartElementRender
            key={`geovis-chart-vibration-event`}
            {...restProps}
            elementInfo={elementInfo}
            elementIndexOnPage={0}
            lastInGroup={false}
            isVibrationEventChart={true}
            hideVibrationEventChartOnPage={onHideEvent}
            isInGroup={false}
            firstInGroup={true}
            isDefault={false}
            rootContainerWidth={0}
        />
    )
}

export default Component;