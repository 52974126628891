import { t } from "i18next";
import InfoIcon from '@atlaskit/icon/glyph/info';
import { OptionType } from "@atlaskit/select";
import { RadioGroup } from "@atlaskit/radio";
import { OptionsPropType } from "@atlaskit/radio/dist/types/types";
import Tooltip from "@atlaskit/tooltip";
import Button from "@atlaskit/button";
import Checkbox from "@atlaskit/checkbox";
import React, { SyntheticEvent } from "react";
import { SemiCircleChartModel } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/SemiCircleChartModel";
import { GeovisSelect } from "../../../select/GeovisSelect";
import { defaultChartLabelColumnStyle, defaultChartRowSettingsStyle, IGeovisChartPropertyChangedProps } from "../types";
import { getSemiCircleTypeToDescription, SemiCircleType, SemiCircleTypeList } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/SemiCircleType";
import { getTimePeriodToDescription, TimePeriod, TimePeriodList } from "../../../../server/AVTService/TypeLibrary/Common/TimePeriod";
import { ChartSettingTimePeriod } from "../../../../server/AVTService/TypeLibrary/Model/ChartSettingTimePeriod";
import { ElementSettingAllowCheckbox } from "../../ElementSettingAllowCheckbox";
import GeovisReactDateTimePicker from "../../../dateTimePickers/GeovisReactDateTimePicker";
import { GeovisChartDateTimeSettings } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartDateTimeSettings";
import { SemiCircleGroupType } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/SemiCircleGroupType";
import { SemiCircleWdFilterType } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/SemiCircleWdFilterType";

type ChartProperty = keyof SemiCircleChartModel;

type ISemiCircleMainSettingsProps = IGeovisChartPropertyChangedProps<SemiCircleChartModel>;

export const SemiCircleChartMainSettings = ({ chart, onChartPropertyChanged, onChartPropertiesChanged }: ISemiCircleMainSettingsProps) => {


    const labelColumnStyle = defaultChartLabelColumnStyle;
    const settingRowStyle = defaultChartRowSettingsStyle;

    const getSemiCircleTypeOptions = (): OptionType[] => {
        return SemiCircleTypeList.map(val => ({ value: val, label: getSemiCircleTypeToDescription(val) }))
    }

    const getSelectedSemiCircleType = (): OptionType => {
        return { value: chart.SemiCircleType, label: getSemiCircleTypeToDescription(chart.SemiCircleType) };
    }

    const onSelectablePropertyChangedFunc = (propertyName: ChartProperty) => (selected: OptionType) => {

        if (propertyName === "SemiCircleType") {
            const isSuccessMeas = +selected.value === SemiCircleType.SuccessfulMeasurementRate;
            const isSensorValues = +selected.value === SemiCircleType.SensorValues;
            //if we change type we should do some clean up
            const update: Partial<SemiCircleChartModel> = {};
            update.SemiCircleType = +selected.value;
            update.ShowCustomTooltip = false;
            update.ShowNewestMeasInTime = false;
            update.UseLastMeasurementTimeCustomerChangeable = !isSuccessMeas;
            update.TimeSearchDistance = { ...chart.TimeSearchDistance, customerChangeable: !isSuccessMeas },
                update.Timeslot = { ...chart.Timeslot, customerChangeable: !isSuccessMeas },
                update.IsPeriodCustomerChangeable = isSuccessMeas;
            update.StartDateSetting = { ...chart.StartDateSetting, customerChangeable: isSuccessMeas },
                update.EndDateSetting = { ...chart.EndDateSetting, customerChangeable: isSuccessMeas },
                update.ValueSettings = {
                    ...chart.ValueSettings,
                    SensorIds: [],
                    ChainsIds: [],
                    IsSensorsCustomerChangeable: false,
                    ShowColorBarScheme: false,
                    ShowAxisLabel: false,
                    AxisLabel: "",
                    GroupingFilter: {
                        ...chart.ValueSettings.GroupingFilter,
                        FilterType: chart.ValueSettings.GroupBy === SemiCircleGroupType.SensorValue ? SemiCircleWdFilterType.Filter : SemiCircleWdFilterType.AllInProject
                    },
                    ShowUnitInAxisLabel: false,
                    TemperatureColorSettings: {
                        ...chart.ValueSettings.TemperatureColorSettings,
                        EnableCustomColorScheme: false
                    },
                    MaxValue: {
                        ...chart.ValueSettings.MaxValue,
                        customerChangeable: isSensorValues
                    },
                    MinValue: {
                        ...chart.ValueSettings.MinValue,
                        customerChangeable: isSensorValues
                    },
                    ShowAsRelative: {
                        ...chart.ValueSettings.ShowAsRelative, customerChangeable: isSensorValues
                    }
                };
            update.VisualSettings = {
                ...chart.VisualSettings,
                PlotAmountPerGroup: false,
                CalculateDistanceByParameter: false,
                PlotGroupNameInCircle: false,
                PlotParametersInCircle: false,
                PlotValuesInCircle: false,
            }

            onChartPropertiesChanged(update);
        }
        else {
            onChartPropertyChanged(propertyName, +selected.value);
        }


    }

    const onChartPropertyChangedFunc = (propertyName: keyof SemiCircleChartModel) => (value: any) => {
        switch (propertyName) {
            case "Timeslot": {
                const formattedDateTime = value || "";
                onChartPropertyChanged<GeovisChartDateTimeSettings>(propertyName, { ...chart.Timeslot, Value: formattedDateTime });
                break;
            }
        }
    }

    const onCustomerChangeablePropertyChangedFunc = (propertyName: keyof SemiCircleChartModel) => (value: boolean) => {
        switch (propertyName) {
            case "Timeslot": {
                onChartPropertyChanged<GeovisChartDateTimeSettings>(propertyName, { ...chart.Timeslot, customerChangeable: value });
                break;
            }
            case "StartDateSetting":
                onChartPropertyChanged<GeovisChartDateTimeSettings>(propertyName, { ...chart.StartDateSetting, customerChangeable: value });
                break;
            case "EndDateSetting":
                onChartPropertyChanged<GeovisChartDateTimeSettings>(propertyName, { ...chart.EndDateSetting, customerChangeable: value });
                break;

            case "IsPeriodCustomerChangeable": {
                onChartPropertyChanged<boolean>("IsPeriodCustomerChangeable", value);
                break;
            }
            case "TimeSearchDistance": {
                onChartPropertyChanged<ChartSettingTimePeriod>(propertyName, { ...chart.TimeSearchDistance, customerChangeable: value });
                break;
            }

            case "UseLastMeasurementTimeCustomerChangeable": {
                onChartPropertyChanged(propertyName, value);
                break;
            }
        }
    }

    const onStringPropertyChangedFunc = (propertyName: ChartProperty) => (value: string) => {
        switch (propertyName) {
            case "StartDateSetting":
                onChartPropertyChanged(propertyName, { ...chart.StartDateSetting, Value: value });
                break;
            case "EndDateSetting":
                onChartPropertyChanged(propertyName, { ...chart.EndDateSetting, Value: value });
                break;
        }
    }

    const getMeasurementTypeOptions = (): OptionsPropType => {
        return [{
            label: t("Use data of last measurement timeslot"),
            value: "1"
        }, {
            label: t("Use nearest data to following datetime") + ":",
            value: "-1"
        }]
    }
    const onBooleanPropertyChangedFunc = (propertyName: ChartProperty) => (event: SyntheticEvent<HTMLInputElement>) => {
        const value = event.currentTarget.checked;

        onChartPropertyChanged(propertyName, value);
    }

    const getMeasurementTypeValue = () => {
        return chart.UseLastMeasurementTime ? "1" : "-1";
    }

    const onMeasurementTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChartPropertyChanged("UseLastMeasurementTime", +e.currentTarget.value > 0);
    }

    const getTimeSearchDistanceOption = (timeDistance: TimePeriod): OptionType => {
        return { value: timeDistance, label: (timeDistance === TimePeriod.Custom ? t("Exact") : getTimePeriodToDescription(timeDistance)) };
    }

    const getTimeSearchDistanceOptions = (): OptionType[] => {
        return TimePeriodList.map(val => (getTimeSearchDistanceOption(val)))
    }

    const onTimeSearchDistancePropertyChangedFunc = (selected: OptionType) => {
        onChartPropertyChanged<ChartSettingTimePeriod>("TimeSearchDistance", { ...chart.TimeSearchDistance, value: +selected.value });
    }

    const getPeriodOptions = (): OptionType[] => {
        return TimePeriodList.map(val => ({ value: val, label: getTimePeriodToDescription(val) }))
    }

    const getSelectedPeriod = (): OptionType => {
        return { value: chart.Period, label: getTimePeriodToDescription(chart.Period) };
    }


    return (
        <React.Fragment>
            {/* display type */}
            <div className="flexRowContainerLine" style={settingRowStyle}>
                <div style={labelColumnStyle}>
                    <label htmlFor="cmbPeriod">{t("Type of displayed data")}:</label>
                </div>
                <div className="flexCellContainer_g1_m0">
                    <GeovisSelect
                        id="cmbPeriod"
                        options={getSemiCircleTypeOptions()}
                        value={getSelectedSemiCircleType()}
                        onChange={onSelectablePropertyChangedFunc("SemiCircleType")}
                        isCompact={true}
                        spacing='compact'
                    />
                </div>
            </div>

            {chart.SemiCircleType !== SemiCircleType.SuccessfulMeasurementRate &&
                <div className="flexRowContainerLine">
                    <div style={labelColumnStyle}>
                        {/* */}
                    </div>
                    <div className="flexCellContainer_g1_m0">
                        <RadioGroup
                            options={getMeasurementTypeOptions()}
                            value={getMeasurementTypeValue()}
                            onChange={onMeasurementTypeChange}
                        />
                    </div>
                    <ElementSettingAllowCheckbox
                        isChecked={chart.UseLastMeasurementTimeCustomerChangeable}
                        onChange={onCustomerChangeablePropertyChangedFunc("UseLastMeasurementTimeCustomerChangeable")}
                        isDisabled={false}
                    />
                </div>
            }

            {/* Date time around*/}
            {chart.SemiCircleType !== SemiCircleType.SuccessfulMeasurementRate &&
                <div className="flexRowContainerLine" style={settingRowStyle}>
                    <div style={labelColumnStyle}>
                        <label htmlFor="dtStartDateTime">{t("Date / Time")}:</label>
                    </div>
                    <div className="flexCellContainer_g1_m0">
                        <GeovisReactDateTimePicker
                            locale="en-GB"
                            key="dtStartDateTime"
                            value={chart.Timeslot.Value}
                            disabled={chart.UseLastMeasurementTime}
                            onChange={onChartPropertyChangedFunc("Timeslot")} />
                    </div>
                    <ElementSettingAllowCheckbox
                        isChecked={chart.Timeslot.customerChangeable}
                        onChange={onCustomerChangeablePropertyChangedFunc("Timeslot")}
                        isDisabled={chart.UseLastMeasurementTime}
                    />
                </div>
            }

            {chart.SemiCircleType !== SemiCircleType.SuccessfulMeasurementRate &&
                <div className="flexRowContainerLine" style={settingRowStyle}>
                    <div style={labelColumnStyle}>
                        <label htmlFor="cmbTimeSearchDistance">{t("Time-Searchdistance")}:</label>
                    </div>
                    <div className="flexCellContainer_g1_m0">
                        <GeovisSelect
                            id="cmbPeriod"
                            options={getTimeSearchDistanceOptions()}
                            value={getTimeSearchDistanceOption(chart.TimeSearchDistance.value)}
                            onChange={onTimeSearchDistancePropertyChangedFunc}
                            isCompact={true}
                            spacing='compact'
                        />
                    </div>
                    <div >
                        <Tooltip
                            content={t("labelTimeSearchDistanceTooltip")}>
                            <Button
                                appearance='subtle-link'
                                iconBefore={<InfoIcon label="Information" size='small' primaryColor="#42526E" />}
                            />
                        </Tooltip>
                    </div>
                    <ElementSettingAllowCheckbox
                        isChecked={chart.TimeSearchDistance.customerChangeable}
                        onChange={onCustomerChangeablePropertyChangedFunc("TimeSearchDistance")}
                    />
                </div>
            }

            {/* Period */}
            {chart.SemiCircleType === SemiCircleType.SuccessfulMeasurementRate &&
                <div className="flexRowContainerLine" style={settingRowStyle}>
                    <div style={labelColumnStyle}>
                        <label htmlFor="cmbPeriod">{t("Period")}:</label>
                    </div>
                    <div className="flexCellContainer_g1_m0">
                        <GeovisSelect
                            id="cmbPeriod"
                            options={getPeriodOptions()}
                            value={getSelectedPeriod()}
                            onChange={onSelectablePropertyChangedFunc("Period")}
                            isCompact={true}
                            spacing='compact'
                        />
                    </div>
                    {ElementSettingAllowCheckbox({
                        isChecked: chart.IsPeriodCustomerChangeable,
                        onChange: onCustomerChangeablePropertyChangedFunc("IsPeriodCustomerChangeable")
                    })}
                </div>
            }

            {/* Use last measurements */}
            {chart.SemiCircleType === SemiCircleType.SuccessfulMeasurementRate &&
                <div className="flexRowContainerLine">
                    <div style={labelColumnStyle}>
                        {/*  */}
                    </div>
                    <div className="flexCellContainer_g1_m0">
                        <Checkbox
                            label={t("Using the last measurement time for the report end time")}
                            isChecked={chart.UseLastMeasurementTime}
                            isDisabled={chart.Period === TimePeriod.Custom}
                            onChange={onBooleanPropertyChangedFunc("UseLastMeasurementTime")}
                        />
                    </div>
                </div>
            }

            {/* Start date */}
            {chart.SemiCircleType === SemiCircleType.SuccessfulMeasurementRate &&
                <div className="flexRowContainerLine" style={settingRowStyle}>
                    <div style={labelColumnStyle}>
                        <label htmlFor="dtStartDateTime">{t("Start date")}:</label>
                    </div>
                    <div className="flexCellContainer_g1_m0">
                        <GeovisReactDateTimePicker
                            locale="en-GB"
                            key="dtStartDateTime"
                            id="dtStartDateTime"
                            value={chart.StartDateSetting.Value}
                            isPairedPicker={true}
                            isThisPickerOfStartDate={true}
                            pairPickerDate={chart.EndDateSetting.Value}
                            disabled={chart.Period !== TimePeriod.Custom}
                            onChange={onStringPropertyChangedFunc("StartDateSetting")} />
                    </div>
                    {ElementSettingAllowCheckbox({
                        isChecked: chart.StartDateSetting.customerChangeable,
                        onChange: onCustomerChangeablePropertyChangedFunc("StartDateSetting"),
                        isDisabled: chart.Period !== TimePeriod.Custom
                    })}
                </div>
            }

            {/* End date */}
            {chart.SemiCircleType === SemiCircleType.SuccessfulMeasurementRate &&
                <div className="flexRowContainerLine" style={settingRowStyle}>
                    <div style={labelColumnStyle}>
                        <label htmlFor="dtEndDateTime">{t("End date")}:</label>
                    </div>
                    <div className="flexCellContainer_g1_m0">
                        <GeovisReactDateTimePicker
                            locale="en-GB"
                            key="dtEndDateTime"
                            id="dtEndDateTime"
                            value={chart.EndDateSetting.Value}
                            isPairedPicker={true}
                            isThisPickerOfStartDate={false}
                            pairPickerDate={chart.StartDateSetting.Value}
                            onChange={onStringPropertyChangedFunc("EndDateSetting")}
                            disabled={chart.Period !== TimePeriod.Custom}
                        />
                    </div>
                    {ElementSettingAllowCheckbox({
                        isChecked: chart.EndDateSetting.customerChangeable,
                        onChange: onCustomerChangeablePropertyChangedFunc("EndDateSetting"),
                        isDisabled: chart.Period !== TimePeriod.Custom
                    })}
                </div>
            }
        </React.Fragment>
    )
}