import { SemiCircleChartModel } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/SemiCircleChartModel"
import { ChartOptionsTab } from "../ChartOptionsTab"
import { IGeovisChartPropertyChangedProps } from "../types"

type ISemiCircleOptionsSettingsProps = IGeovisChartPropertyChangedProps<SemiCircleChartModel>

export const SemiCircleChartOptionsSettings = ({ chart, onChartPropertyChanged, onChartPropertiesChanged }: ISemiCircleOptionsSettingsProps) => {
    // const onChangeBoolProp = (propertyName: keyof SemiCircleChartModel) => (value: boolean) => {
    //     onChartPropertyChanged(propertyName, value);
    // }

    // const isMeas = chart.SemiCircleType === SemiCircleType.SuccessfulMeasurementRate;
    // const isGroup = chart.SemiCircleType === SemiCircleType.Grouping;
    // const isSensorValues = chart.SemiCircleType === SemiCircleType.SensorValues;
    return (
        <div className='geovisChartTab'>
            <ChartOptionsTab
                chart={chart}
                onChartPropertyChanged={onChartPropertyChanged}
                onChartPropertiesChanged={onChartPropertiesChanged}
            />
        </div>
    )
}