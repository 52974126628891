//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { ProjectBusinessInfo } from './ProjectBusinessInfo';
import { GeoLocation } from './GeoLocation';
import { GeovisUserTableInfo } from './GEOvis3/Model/User/GeovisUserTableInfo';
import { ProjectAutomaticState } from './AVTService/TypeLibrary/Common/ProjectAutomaticState';
import { MstState } from './MstState';
import { MapViewport } from './MapViewport';
import { NotificationSettingModel } from './AVTService/TypeLibrary/Notifications/NotificationSettingModel';
import { DetailedNotificationSettingsModel } from './AVTService/TypeLibrary/Notifications/DetailedNotificationSettingsModel';
import { ProjectType } from './AVTService/TypeLibrary/Common/ProjectType';
import { ServiceModel } from './AVTService/TypeLibrary/Common/ServiceModel';
import { ProjectManualState } from './AVTService/TypeLibrary/Common/ProjectManualState';
import { ProjectViewConfig } from './AVTService/TypeLibrary/ProjectViews/ProjectViewConfig';
import { ProjectVisualSettings } from './AVTService/TypeLibrary/Model/ProjectVisualSettings';
import { MigratebleElements } from './AVTService/TypeLibrary/Common/ElementsMigrations/MigratebleElements';
import { CoordinateSystems } from './AVTService/TypeLibrary/Proj4Net/CoordinateSystems';

export class ProjectInfo extends ProjectBusinessInfo
{
	public ParentId: number;
	public Note: string;
	public InternalInformation: string;
	public AdminInformation: string;
	public Location: GeoLocation;
	public ProjectLeader: GeovisUserTableInfo;
	public States: ProjectAutomaticState[];
	public MstState: MstState;
	public EnableFADolphinNotification: boolean;
	public EnableVADolphinNotification: boolean;
	public DefaultMapViewport: MapViewport;
	public DefaultProjectViewId: string;
	public CompanyLogoId: string;
	public UserLogoId: string;
	public KeepZoomLevel: boolean;
	public SupportMessageTemplateId: string;
	public VANotificationsSettings: NotificationSettingModel;
	public FANotificationsSettings: NotificationSettingModel;
	public WDNotificationsSettings: NotificationSettingModel;
	public FACategoriesNotificationsSettings: DetailedNotificationSettingsModel[];
	public DolphinId: string;
	public ProjectType: ProjectType;
	public ServiceModel: ServiceModel;
	public ProjectState: ProjectManualState;
	public UseUserLogoForReports: boolean;
	public AllowAlarms: boolean;
	public DefaultColorSchemeId: number;
	public ProjectOverview: ProjectViewConfig;
	public VisualSettings: ProjectVisualSettings;
	public AllElementsMigrationCompleted: boolean;
	public ProjectElementsMigrationInfo: { [key in MigratebleElements]: boolean };
	public CoordinateSystem: CoordinateSystems;
}
