import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighChartAnnotations from 'highcharts/modules/annotations';
import HighchartsBoost from 'highcharts/modules/boost';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { connect } from "react-redux";
import { cloneDeep } from "lodash";
import { useLayoutEffect, useRef, useState } from "react";
// eslint-disable-next-line @atlaskit/design-system/no-deprecated-imports
import { withNavigationUIController } from "@atlaskit/navigation-next";
import { t } from "../../../../../../i18n";
import { XyChartData } from "../../../../../../server/AVTService/TypeLibrary/Computation/XyChartData";
import { XyChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/XyChartModel";
import Logger from "../../../../../../services/Logger";
import { IGeovisStoreState } from "../../../../../../store/store.types";
import { LoadingPageErrorSkeleton } from "../../../../../LoadingPageErrorSkeleton";
import { IReportElementRenderOwnProps } from "../../types";
import { getGeovisChartConfigFromStorage } from "../options/tools";
import { getXyChartRenderOptions } from "../options/xyChartRenderOptions";
import { getGeovisChartData } from "../tools";
import { IChartRenderStateToProps } from "../types";
import { ChartLoadingSkeleton } from "./ChartLoadingComponent";
import IUINavigationControllerProps from "../../../../../atlaskit/UIControllerInterface";

type IStateToProps = IChartRenderStateToProps<XyChartData>


interface IOwnProps extends IReportElementRenderOwnProps {
    chartId: number;
}

interface IComponentProps extends IStateToProps, IOwnProps, IUINavigationControllerProps {

}

const XyChartRender = ({ chartData, chartId, isPrinting, pageNum, navigationUIController }: IComponentProps) => {

    if (!chartData) {
        return (<ChartLoadingSkeleton text={t("Initializing XY chart settings")} />)
    }

    const { data, errorDescription, isError, isLoaded } = chartData;

    // getting changed chart config directly from storage, do not subscribe on UPDATE event
    const chart = getGeovisChartConfigFromStorage<XyChartModel>(pageNum, chartId);
    if (!chart) {
        return null;
    }

    if (isError) {
        return (<LoadingPageErrorSkeleton errorText={t("Error load xy chart data")} errorDescription={errorDescription} />);
    }

    if (!isLoaded) {
        return (<ChartLoadingSkeleton text={t("Loading xy chart data...")} />)
    }

    Logger.render('XyChartRender');

    const containerRef = useRef<HTMLDivElement>(null);

    const [state, setState] = useState<boolean>(false);

    useLayoutEffect(() => {
        if (navigationUIController && state !== navigationUIController.state.isCollapsed) {
            setTimeout(() => {
                setState(navigationUIController.state.isCollapsed);
            }, 200);
        }
    });

    const renderOptions = getXyChartRenderOptions(pageNum, cloneDeep(chart), data);

    NoDataToDisplay(Highcharts);
    HighChartAnnotations(Highcharts);

    // disable boost if chart is in Printing mode
    if (!isPrinting) {
        HighchartsBoost(Highcharts);
    }

    return (
        <div className="report-chart-render" ref={containerRef} style={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', flexGrow: 1 }}>
            <HighchartsReact
                key={`highcharts-${chart.Id}-0-${chartData.Timestamp}-${containerRef.current?.clientWidth}-${state}`}
                highcharts={Highcharts}
                options={renderOptions}
                constructorType={'chart'}
                containerProps={{ style: { height: `100%` } }}
            />
        </div>
    )
}

const mapStateToProps = ({ projectReport }: IGeovisStoreState, { chartId, pageNum }: IOwnProps): IStateToProps => ({
    chartData: getGeovisChartData<XyChartData>(projectReport.geovisReportSettings, pageNum, chartId)
});

export default connect<IStateToProps, never, IOwnProps>(mapStateToProps)(withNavigationUIController(XyChartRender));