import { SemiCircleValuesSettings } from "../../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/SemiCircleValuesSettings";
import { SemiCircleChartDataModel } from "../../../../../../../server/AVTService/TypeLibrary/Model/SemiCircleChartDataModel";
import { getDigitsAfterDecimalPoint, getPhysicalUnitShortName } from "../../../../../../../server/AVTService/TypeLibrary/Sensors/PhysicalUnit";
import { getSemiCirclePaletteData } from "../../tools";

interface IComponentProps {
    containerHeight: number;
    valueSettings: SemiCircleValuesSettings;
    data: SemiCircleChartDataModel[]
}

export const SemiCircleChartLegend = ({
    containerHeight,
    valueSettings,
    data
}: IComponentProps) => {

    const getGradientFunc = (): string => {
        const { palette } = getSemiCirclePaletteData(valueSettings, data);
        const paletteColors = palette.colors(10);

        let result = "linear-gradient(to top,";
        paletteColors.forEach((color, index, collection) => {
            result += `${color}${index === collection.length - 1 ? ")" : ","}`
        });

        return result;
    }

    const getScaleArray = (): string[] => {
        const { min, max } = getSemiCirclePaletteData(valueSettings, data);

        return [
            `${max.toFixed(getDigitsAfterDecimalPoint(valueSettings.Unit))}`,
            `${(min + 3 * (max - min) / 4).toFixed(getDigitsAfterDecimalPoint(valueSettings.Unit))}`,
            `${(min + (max - min) / 2).toFixed(getDigitsAfterDecimalPoint(valueSettings.Unit))}`,
            `${(min + (max - min) / 4).toFixed(getDigitsAfterDecimalPoint(valueSettings.Unit))}`,
            `${min.toFixed(getDigitsAfterDecimalPoint(valueSettings.Unit))}`,
        ]
    }

    if (!valueSettings.ShowColorBarScheme) {
        return null;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', justifyContent: 'center', flexShrink: 0, alignItems: 'end', paddingRight: '3px', position: 'absolute', right: '10px', height: '100%' }}>
            <div style={{ height: '100%' }}>
                {/* Filler */}
            </div>
            {valueSettings.ShowAxisLabel &&
                <span style={{ width: '200px', textAlign: 'end' }}>{`${valueSettings.AxisLabel} ${valueSettings.ShowUnitInAxisLabel ? `[${getPhysicalUnitShortName(valueSettings.Unit)}]` : ""}`}</span>
            }
            <div style={{ display: 'flex', alignItems: 'center', gap: "3px" }}>
                <div style={{ display: 'flex', flexDirection: 'column', height: `${containerHeight}px`, gap: `${containerHeight / 4 - 21}px`, marginTop: "-17px" }}>
                    {getScaleArray().map((val) => (
                        <div key={`scale-${val}`} style={{ textAlign: 'center' }}>
                            <span>{val}</span>
                        </div>
                    ))}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', height: `${containerHeight}px`, gap: `${containerHeight / 4 - 3}px`, }}>
                    {getScaleArray().map(val => (
                        <div key={`scale-mark-${val}`} style={{ border: 'solid black 1px', width: '10px' }}></div>
                    ))}
                </div>
                <div style={{ width: '25px', height: `${containerHeight}px`, background: getGradientFunc() }}>
                    {/* colored heat bar*/}
                </div>
            </div>
            <div style={{ height: '100%' }}>
                {/* Filler */}
            </div>
        </div>
    )
}