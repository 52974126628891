//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum AxisDrawMode {
	Line = 0,
	Column = 1
}

const AxisDrawModeToName = new Map<number, string>([
	[ 0, 'Line' ],
	[ 1, 'Column' ],
])

export function getAxisDrawModeToName(element: AxisDrawMode) : string {
    return AxisDrawModeToName.get(element) || '';
}

const AxisDrawModeByName = new Map<string, number>([
	[ 'Line', 0 ],
	[ 'Column', 1 ],
]);

export function getAxisDrawModeByName(name: string): AxisDrawMode {
    return AxisDrawModeByName.get(name)!;
}


export const AxisDrawModeList : Readonly<AxisDrawMode[]> = [
	AxisDrawMode.Line,
	AxisDrawMode.Column,
];



const AxisDrawModeToDescription = new Map<number, string>([
	[ 0, 'Line-Chart' ],
	[ 1, 'Bar-Chart' ],
])

export function getAxisDrawModeToDescription(element: AxisDrawMode) : string {
    return AxisDrawModeToDescription.get(element) || '';
}

