/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 10.09.2024
 * @description The geovis select to choose a culture info. Used for import and export tasks
 */

import { CSSProperties, useEffect, useMemo, useState } from "react";
import { IWithGeovisServerProps, withGeovisServer } from "../../helpers/GeovisHooks";
import { GeovisSelect } from "../select/GeovisSelect";
import { IGvOptionType } from "../select/GeovisSelect_tools";
import { GeovisLabelInline } from "../GeovisLabel";
import { t } from "../../i18n";
import ServerRoutesGen from "../../server/Routes/ServerRoutesGen";
import { fetchServerElements } from "../../helpers/ProjectDataHelper";
import { defaultSomethingStorageState, ISomethingStoreBase } from "../../store/types";
import { processFetchedData } from "../../store/helpers/DataHelper";
import { GeovisCultureInfo } from "../../server/GEOvis3/Model/GeovisCulturesInfo/GeovisCultureInfo";

interface IComponentProps extends IWithGeovisServerProps {

    id?: string;

    cultureId: string;

    labelContainerStyle: CSSProperties;
    selectContainerStyle: CSSProperties;

    alignToRight?: boolean;

    onCultureChanged: (culture: string) => void;
}

interface IComponentState extends ISomethingStoreBase {
    cultures: GeovisCultureInfo[];
}

const componentInitialState: IComponentState = {
    ...defaultSomethingStorageState,
    cultures: []
}


export const CultureInfoSelect = withGeovisServer(({ id, labelContainerStyle, selectContainerStyle, onCultureChanged, alignToRight, cultureId, Server }: IComponentProps) => {

    const [state, setState] = useState<IComponentState>(componentInitialState);

    // detect settings
    useEffect(() => {

        (async function loadSupportedCultures() {

            const url = ServerRoutesGen.CultureInfo.GetSupportedCultures;
            const response = await fetchServerElements<GeovisCultureInfo[]>(Server, url);

            setState(processFetchedData(response, state, componentInitialState, st => ({
                cultures: st
            })));

        })();

    }, []);

    const options = useMemo<ReadonlyArray<IGvOptionType<string>>>(() => {

        if (state.isLoading || state.isError) {
            return [];
        }

        return state.cultures.map<IGvOptionType<string>>(c => ({
            label: c.Name,
            value: c.Id
        }));

    }, [state]);

    const onSelectChanged = (option: IGvOptionType<string>) => {
        onCultureChanged(option.value);
    }

    const additionalContainerStyle: CSSProperties = {};
    if (alignToRight) {
        additionalContainerStyle.justifyContent = 'right';
    }

    return (
        <div className="flexRowContainerLine" style={additionalContainerStyle}>
            <div style={labelContainerStyle}>
                <GeovisLabelInline
                    label={t("Regional settings")}
                />
            </div>

            <div style={selectContainerStyle}>
                <GeovisSelect
                    id={id}
                    isLoading={state.isLoading}
                    isDisabled={state.isError}
                    value={options.find(c => c.value === cultureId)}
                    options={options}
                    onChange={onSelectChanged}
                />
            </div>
        </div >
    )
})