/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 22.05.2020
 * @description Local map object camera shape
 */

import L, { DragEndEvent } from 'leaflet';
import { Marker } from 'react-leaflet';
import { locationToMapCoordinates } from '../../../helpers/MapHelper';
import { fixHref } from '../../../helpers/UrlHelper';
import { LocalMapObjectCamera } from '../../../server/AVTService/TypeLibrary/LocalMapObjects/LocalMapObjectCamera';
import '../LocalMapObjectIconsJs';
import { getLocalMapObjectCameraIconProps } from '../LocalMapObjectIconsRenderers';
import { LocalMapObjectViewMode } from '../types';
import { LocalMapObjectPopup, LocalMapObjectTooltip } from './LocalMapObjectMapElements';
import { ILocalMapShapeOptions } from '../ILocalMapShapeOptions';
import { LocalMapObjectProperties } from '../../../server/GEOvis3/Model/LocalMapObjects/LocalMapObjectProperties';

interface ILocalMapObjectCameraShapeProps {
    element: LocalMapObjectCamera;
    viewMode: LocalMapObjectViewMode;
    isEditMode: boolean;
    editState?: LocalMapObjectProperties;

    onEdit: (id: string) => void;
    onRemove: (id: string) => void;
    onChangeLocation?: (elementId: string, latLng: L.LatLng) => void;
    onChangeRotationAngle?: (elementId: string, angle: number) => void;
}

export const LocalMapObjectCameraShape = ({
    element,
    editState,
    onEdit,
    onRemove,
    viewMode,
    isEditMode,
    onChangeLocation,
}: ILocalMapObjectCameraShapeProps) => {

    const elementCamera = element as LocalMapObjectCamera;
    const { Location } = elementCamera;
    const location = editState && editState.coordinates !== undefined ? editState.coordinates : Location;

    const cameraIcon = L.divIcon.svgIcon.localMapObjectCameraIcon(getLocalMapObjectCameraIconProps(element));

    const onClickHandler = (event: L.LeafletMouseEvent) => {
        if (viewMode === LocalMapObjectViewMode.Edit && !event.originalEvent.ctrlKey) {
            return;
        }

        if (elementCamera.Link !== "") {
            const href = fixHref(elementCamera.Link);
            window.open(href, "_blank");
        }

        event.originalEvent.preventDefault();
    };

    const cameraOptions: ILocalMapShapeOptions = {
        localMapObjectId: element.Id,
        localMapObjectType: element.ObjectType
    };

    const getChildren = (): JSX.Element[] => {
        const result: JSX.Element[] = [];
        if (viewMode !== LocalMapObjectViewMode.MapSection) {
            result.push(<LocalMapObjectPopup key={`lmo-${element.Id}-popup-key`} element={element} onEdit={onEdit} onRemove={onRemove} editMode={viewMode === LocalMapObjectViewMode.Edit} dragMode={isEditMode} />)
        }
        result.push(<LocalMapObjectTooltip key={`lmo-${element.Id}-tooltip-key`} element={element} />);

        return result;
    }

    const onDragEnd = (ev: DragEndEvent) => {
        if (onChangeLocation) {
            const latLng = ev.target.getLatLng();
            onChangeLocation(element.Id, latLng);
        }
    }

    return (
        <Marker
            key={`local_map_camera_${element.Id}_marker`}
            position={locationToMapCoordinates(location)}
            icon={cameraIcon}
            eventHandlers={viewMode === LocalMapObjectViewMode.MapSection ? undefined : {
                click: onClickHandler,
                dragend: onDragEnd
            }}
            draggable={isEditMode}
            {...cameraOptions}>
            {getChildren()}
        </Marker>
    )
}
