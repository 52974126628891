import React, { CSSProperties } from 'react';
import Select, { CheckboxSelect, SelectProps } from '@atlaskit/select';
import Checkbox from '@atlaskit/checkbox'; // Import Checkbox component
import { getPhysicalUnitShortName, getPhysicalUnitSupportedName, PhysicalUnit } from '../../server/AVTService/TypeLibrary/Sensors/PhysicalUnit';

export interface IPhysicalUnitOption {
    label: string;
    value: PhysicalUnit;
}

export interface IGeovisUnitSelectProps extends Omit<SelectProps<IPhysicalUnitOption, false>, 'value'> {
    useAllWidth?: boolean;
    value: PhysicalUnit;
    options: IPhysicalUnitOption[];
    customLabelStyle?: React.CSSProperties;
    customEditorStyle?: React.CSSProperties;
    label?: string;
    selectable?: boolean; 
    selectedValue?: boolean; 
    onSelectionChanged?: (selected: boolean) => void; 
    onChange: (value: PhysicalUnit) => void;
}

export const getFullUnitName = (unit: PhysicalUnit): string => {
    const fullName = getPhysicalUnitSupportedName(unit);
    const shortName = getPhysicalUnitShortName(unit);
    return `${fullName} (${shortName})`;
}

export const getPhysicalUnitOption = (unit: PhysicalUnit): IPhysicalUnitOption => ({
    value: unit,
    label: getFullUnitName(unit),
});

const getEditorStyle = (selectable?: boolean): CSSProperties => ({
    width: selectable ? '50%' : '60%',
});

export const GeovisUnitSelect = ({
    useAllWidth,
    value,
    options,
    customLabelStyle,
    customEditorStyle,
    label,
    selectable,
    selectedValue,
    onSelectionChanged,
    onChange,
    ...props
}: IGeovisUnitSelectProps) => {
    const selectedOption = options.find(option => option.value === value);

    const containerStyle: CSSProperties = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: useAllWidth ? '100%' : 'auto',
    };

    const labelStyle: CSSProperties = {
        width: '40%',
        ...customLabelStyle,
    };

    const editorStyle: CSSProperties = {
        ...getEditorStyle(selectable),
        ...customEditorStyle,
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (onSelectionChanged) {
            onSelectionChanged(e.target.checked);
        }
    };

    const handleSelectChange = (option: IPhysicalUnitOption | null) => {
        if (option) {
            onChange(option.value);
        }
    };

    return (
        <div style={containerStyle}>
            {selectable && (
                <Checkbox 
                    isChecked={selectedValue} 
                    onChange={handleCheckboxChange}
                />
            )}
            {label && (
                <div style={labelStyle}>
                    <span>{label}</span>
                </div>
            )}
            <div style={editorStyle}>
                <Select
                    menuPortalTarget={document.body}
                    spacing="compact"
                    value={selectedOption || null}
                    options={options}
                    onChange={handleSelectChange}
                    styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        container: (base) => (useAllWidth ? { ...base, width: '100%' } : base),
                    }}
                    {...props}
                />
            </div>
        </div>
    );
};

export interface IGeovisUnitSelectMultiProps extends Omit<SelectProps<IPhysicalUnitOption, true>, 'value'> {
    useAllWidth?: boolean;
    value: PhysicalUnit[];
    options: IPhysicalUnitOption[];
}

export const GeovisUnitSelectMulti = ({
    useAllWidth,
    value,
    options,
    ...props
}: IGeovisUnitSelectMultiProps) => {
    const selectedOptions = value.map(unit => getPhysicalUnitOption(unit));

    return (
        <Select
            isMulti
            menuPortalTarget={document.body}
            spacing="compact"
            value={selectedOptions}
            options={options}
            styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                container: (base) => (useAllWidth ? { ...base, width: '100%' } : base),
            }}
            {...props}
        />
    );
};

export interface IGeovisCheckboxUnitSelectProps extends Omit<SelectProps<IPhysicalUnitOption, true>, 'value'> {
    useAllWidth?: boolean;
    options: IPhysicalUnitOption[];
    value: PhysicalUnit[];
}

export const GeovisCheckboxUnitSelect = ({
    useAllWidth,
    value,
    options,
    ...props
}: IGeovisCheckboxUnitSelectProps) => {
    const selectedOptions = value.map(getPhysicalUnitOption);

    return (
        <CheckboxSelect
            isMulti
            menuPortalTarget={document.body}
            spacing="compact"
            value={selectedOptions}
            options={options}
            styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                container: (base) => (useAllWidth ? { ...base, width: '100%' } : base),
            }}
            {...props}
        />
    );
};