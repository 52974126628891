import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighChartAnnotations from 'highcharts/modules/annotations';
import HighchartsBoost from 'highcharts/modules/boost';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { connect } from "react-redux";
import { cloneDeep } from "lodash";
import { useLayoutEffect, useRef, useState } from "react";
// eslint-disable-next-line @atlaskit/design-system/no-deprecated-imports
import { withNavigationUIController } from "@atlaskit/navigation-next";
import { t } from "../../../../../../i18n";
import { InclinometerChartData } from "../../../../../../server/AVTService/TypeLibrary/Computation/InclinometerChartData";
import { InclinometerChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/InclinometerChartModel";
import Logger from "../../../../../../services/Logger";
import { IGeovisStoreState } from "../../../../../../store/store.types";
import { LoadingPageErrorSkeleton } from "../../../../../LoadingPageErrorSkeleton";
import { IReportElementRenderOwnProps } from "../../types";
import { getInclinometerChartRenderOptions } from "../options/inclinometerChartRenderOptions";
import { getGeovisChartConfigFromStorage } from "../options/tools";
import { getGeovisChartData } from "../tools";
import { IChartRenderStateToProps } from "../types";
import { ChartLoadingSkeleton } from "./ChartLoadingComponent";
import { InclinometerChartAdditionalInfo } from "./extraComponents/InclinometerChartAdditionalInfo";
import { InclinometerChartLegend } from "./extraComponents/InclinometerChartLegend";
import IUINavigationControllerProps from "../../../../../atlaskit/UIControllerInterface";

type IStateToProps = IChartRenderStateToProps<InclinometerChartData>

interface IOwnProps extends IReportElementRenderOwnProps {
    chartId: number;
}

interface IComponentProps extends IStateToProps, IOwnProps, IUINavigationControllerProps {

}

const InclinometerChartRender = ({ chartData, chartId, isPrinting, pageNum, navigationUIController }: IComponentProps) => {

    if (!chartData) {
        return (<ChartLoadingSkeleton text={t("Initializing Inclinometer chart settings")} />)
    }

    const { data, errorDescription, isError, isLoaded } = chartData;

    // getting changed chart config directly from storage, do not subscribe on UPDATE event
    const chart = getGeovisChartConfigFromStorage<InclinometerChartModel>(pageNum, chartId);
    if (!chart) {
        return null;
    }

    if (isError) {
        return (<LoadingPageErrorSkeleton errorText={t("Error load Inclinometer chart data")} errorDescription={errorDescription} />);
    }

    if (!isLoaded) {
        return (<ChartLoadingSkeleton text={t("Loading Inclinometer chart data...")} />)
    }

    const containerRef = useRef<HTMLDivElement>(null);

    const [state, setState] = useState<boolean>(false);

    useLayoutEffect(() => {
        if (navigationUIController && state !== navigationUIController.state.isCollapsed) {
            setTimeout(() => {
                setState(navigationUIController.state.isCollapsed);
            }, 200);
        }
    });

    Logger.render('InclinometerChartRender');

    const renderOptions = getInclinometerChartRenderOptions(pageNum, cloneDeep(chart), data);

    // const singleContainerHeight = renderOptions.length > 1 ? 100 / renderOptions.length : 100;

    NoDataToDisplay(Highcharts);
    HighChartAnnotations(Highcharts);

    // disable boost if chart is in Printing mode
    if (!isPrinting) {
        HighchartsBoost(Highcharts);
    }

    return (
        <div className="report-chart-render" ref={containerRef} style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <InclinometerChartAdditionalInfo
                showDateOfReference={chart.ShowReferenceDate}
                showStartMeasurements={chart.ShowStartMeasurements}
                startMeasurements={chartData.data.startMeasurementTime}
                referenceDate={chartData.data.ReferencesTime}
            />
            <div style={{ display: 'flex', flexDirection: chart.IsHorizontal ? 'column' : 'row', flexWrap: 'nowrap', flexGrow: 1 }}>
                {renderOptions.map<JSX.Element | null>((option, index) => (
                    <HighchartsReact
                        key={`highcharts-${chart.Id}-${index}-${chartData.Timestamp}-${state}`}
                        highcharts={Highcharts}
                        options={option}
                        constructorType={'chart'}
                        containerProps={{ style: { width: '100%', height: '100%' } }}
                    />
                ))}
            </div>
            {chart.ShowLegend.value &&
                <InclinometerChartLegend
                    legendText={chart.LegendText}
                    linesData={chartData.data.LineInfo}
                />
            }
        </div>
    )
}

const mapStateToProps = ({ projectReport }: IGeovisStoreState, { chartId, pageNum }: IOwnProps): IStateToProps => ({
    chartData: getGeovisChartData<InclinometerChartData>(projectReport.geovisReportSettings, pageNum, chartId)
});

export default connect<IStateToProps, never, IOwnProps>(mapStateToProps)(withNavigationUIController(InclinometerChartRender));