import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighChartAnnotations from 'highcharts/modules/annotations';
import HighchartsBoost from 'highcharts/modules/boost';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { t } from "i18next";
import { cloneDeep } from "lodash";
import { IWithGeovisServerProps, withGeovisServer } from "../../../../../../helpers/GeovisHooks";
import { fetchServerElementsByPost } from "../../../../../../helpers/ProjectDataHelper";
import { SemiCircleChartResponseModel } from "../../../../../../server/AVTService/Computation/ChartComputation/ResponseModel/SemiCircleChartResponseModel";
import { GeovisChartData } from "../../../../../../server/AVTService/TypeLibrary/Computation/GeovisChartData";
import { GeovisChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartModel";
import { SemiCircleChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/SemiCircleChartModel";
import ServerRoutesGen from "../../../../../../server/Routes/ServerRoutesGen";
import { IGeovisChartReportData } from "../../../../../../store/projectReport.types";
import { defaultSomethingStorageState, errorSomethingStorageState, loadedSomethingStorageState } from "../../../../../../store/types";
import { LoadingContainerSkeleton } from "../../../../../LoadingContainerSkeleton";
import { ErrorContainerSkeleton } from "../../../../../ErrorContainerSkeleton";
import { getSemiCircleChartRenderOptions } from "../options/semiCircleChartRenderOptions";
import { SemiCircleChartLegend } from "../renders/extraComponents/SemiCircleChartLegend";

interface IComponentProps extends IWithGeovisServerProps {
    chart: GeovisChartModel;
    projectId: number;
    onChartDataLoaded: (timeslot: string) => void;
}

interface IComponentState {
    chartData: IGeovisChartReportData<SemiCircleChartResponseModel>;
    containerHeight: number;
    calculated: boolean
}
const Component = ({
    chart,
    Server,
    projectId,
    onChartDataLoaded
}: IComponentProps) => {
    const [state, setState] = useState<IComponentState>({
        chartData: {
            ...defaultSomethingStorageState,
            dtsSectionInfos: [],
            Timestamp: 0,
            data: {
                ...new SemiCircleChartResponseModel()
            }
        },
        containerHeight: 300,
        calculated: false
    });

    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        (async function loadChartData() {
            const url = ServerRoutesGen.ReportRenderData.GetGeovisPreviewChartCustomData.patch(projectId);
            const response = await fetchServerElementsByPost<GeovisChartData, GeovisChartModel>(Server, url, chart);
            if (!response.Success) {
                setState({
                    ...state,
                    chartData: {
                        ...errorSomethingStorageState(...response.Messages),
                        dtsSectionInfos: [],
                        Timestamp: 0,
                        data: {
                            ...new SemiCircleChartResponseModel()
                        }
                    }
                });
                return;
            }

            const chartData = response.Data as SemiCircleChartResponseModel;
            onChartDataLoaded(chartData.Timeslot);
            setState({
                ...state,
                chartData: {
                    ...loadedSomethingStorageState,
                    data: chartData,
                    dtsSectionInfos: [],
                    Timestamp: 0
                }
            })
        })()
    }, [1])

    useLayoutEffect(() => {
        if (containerRef.current !== null && !state.calculated) {
            setState({
                ...state,
                containerHeight: containerRef.current.clientHeight / 2,
                calculated: true
            })
        }
    });

    if (!state.chartData.isLoaded) {
        return (
            <div style={{ width: '100%', height: '100%' }}>
                <LoadingContainerSkeleton loadingText={t("Loading Donut preview chart data")} />
            </div>
        )
    }

    if (state.chartData.isError) {
        return (
            <div style={{ width: '100%', height: '100%' }}>
                <ErrorContainerSkeleton errorText={`${t("Failed to load Donut chart data")}: ${state.chartData.errorDescription}`} />
            </div>
        )
    }
    const renderOptions = getSemiCircleChartRenderOptions(0, cloneDeep(chart as SemiCircleChartModel), state.chartData.data, true);

    NoDataToDisplay(Highcharts);
    HighChartAnnotations(Highcharts);
    HighchartsBoost(Highcharts);

    const semiCircleChart = chart as SemiCircleChartModel;

    return (
        <div id={"semiChartMainContainer"} ref={containerRef} style={{ display: 'flex', width: '100%', height: '100%', position: 'relative' }}>
            <div style={{ width: '100%', height: '100%' }}>
                <HighchartsReact
                    key={`highcharts-${chart.Id}-0-${state.chartData.Timestamp}`}
                    highcharts={Highcharts}
                    options={renderOptions}
                    constructorType={'chart'}
                    containerProps={{ style: { flexGrow: 2, height: `100%` } }}
                />
            </div>
            <SemiCircleChartLegend
                containerHeight={state.containerHeight}
                data={state.chartData.data.Data}
                valueSettings={semiCircleChart.ValueSettings}
            />
        </div>
    )
}

export const SemiCirclePreviewChartRender = withGeovisServer(Component);