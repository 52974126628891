import moment from "moment";
import { Reducer } from "redux";
import { ArchivingProjectSettingsModel } from "../../server/AVTService/TypeLibrary/Model/ArchivingProjectSettingsModel";
import { ArchivingTargetType } from "../../server/AVTService/TypeLibrary/Model/ArchivingTargetType";
import { FolderListingModel } from "../../server/GEOvis3/Model/Archiving/FolderListingModel";
import {
    GEOVIS_ARCHIVING_CHANGED_SETTING,
    GEOVIS_ARCHIVING_CHANGED_SETTINGS,
    GEOVIS_ARCHIVING_DEFAULT_SETTINGS_LOADED,
    GEOVIS_ARCHIVING_FILE_DOWNLOAD_FINISHED,
    GEOVIS_ARCHIVING_HIDE_CONFIRM_DIALOG,
    GEOVIS_ARCHIVING_HIDE_DIALOG,
    GEOVIS_ARCHIVING_LISTING_LOADED,
    GEOVIS_ARCHIVING_LISTING_LOADING,
    GEOVIS_ARCHIVING_LISTING_LOADING_ERROR,
    GEOVIS_ARCHIVING_PROGRESS_STARTED,
    GEOVIS_ARCHIVING_PROJECTS_LOADED,
    GEOVIS_ARCHIVING_PROJECTS_LOADING,
    GEOVIS_ARCHIVING_PROJECTS_LOADING_ERROR,
    GEOVIS_ARCHIVING_RESTORING_CHANGE,
    GEOVIS_ARCHIVING_SETTINGS_SET,
    GEOVIS_ARCHIVING_SHOW_CONFIRM_DIALOG,
    GEOVIS_ARCHIVING_SHOW_DIALOG,
    GEOVIS_ARCHIVING_SHOW_FEEDBACK_DIALOG,
    GEOVIS_RESTORE_FILES_UPLOAD_STARTED,
    GEOVIS_RESTORE_FILE_UPLOAD_ERROR,
    GEOVIS_RESTORE_FILE_UPLOAD_FINISHED,
    GEOVIS_RESTORE_FILE_UPLOAD_PROGRESS
} from "../actions/archivingActions";
import { IGeovisArchivingAction, IGeovisArchivingState } from "../archiving.types";
import { defaultSomethingStorageState, errorSomethingStorageState, loadedSomethingStorageState } from "../types";
import { GeovisUploadFileState } from "../uploading.types";
import { ArchiveTaskProgressInfo } from "../../server/AVTService/TypeLibrary/ArchiveRestoreProject/ArchiveTaskProgressInfo";
import { TaskProgressState } from "../../server/AVTService/TypeLibrary/ArchiveRestoreProject/TaskProgressState";

export const getNewArchivingSettings = (localPath: string): ArchivingProjectSettingsModel => {
    const localFtpHost = localStorage.getItem("archiving_FTP_Host");
    const localFtpUser = localStorage.getItem("archiving_FTP_User");
    const localFtpPassword = localStorage.getItem("archiving_FTP_Password");

    return ({
        DeleteAfterArchiving: false,
        FtpHost: localFtpHost !== null ? localFtpHost : "",
        FtpPassword: localFtpPassword !== null ? localFtpPassword : "",
        FtpPath: "\\",
        FtpUser: localFtpUser !== null ? localFtpUser : "",
        LocalPath: localPath,
        TargetType: ArchivingTargetType.DirectDownload,
        ArchivingFileName: '',
        RestoreFileName: '',
        ProjectId: 0
    });
}

export const getFileNameForProject = (prj: any) => {
    const now = moment(new Date()).format("yyyy_MM_DD");
    return `${prj.Number}_${prj.Name}_${now}.zip`;
}

const lastTaskProgressInitialState: ArchiveTaskProgressInfo = {
    ...new ArchiveTaskProgressInfo(),
    TaskId: "",
    ProgressState: TaskProgressState.Pending,
    TargetType: ArchivingTargetType.FTP
}

export const archivingInitialState: IGeovisArchivingState = {
    listingState: {
        ...defaultSomethingStorageState,
        lastRequestMark: '',
        listing: new FolderListingModel()
    },
    archivingSettings: getNewArchivingSettings(''),
    showArchivingDialog: false,
    isArchiving: true,
    oldArchivingFileName: '',
    oldRestoreFileName: '',
    oldFtpPath: '',
    oldLocalPath: '',
    showConfirmDialog: false,
    defaultLocalPath: '',
    projectsStore: {
        ...defaultSomethingStorageState,
        projects: []
    },
    showFeedbackDialog: false,
    successFeedback: false,
    lastTaskProgress: { ...lastTaskProgressInitialState },
    directDownloadLinkAvailable: false,
    uploadFilesInfo: []
}

const isDirectDownloadLinkAvailable = (prevLastTaskProgress: ArchiveTaskProgressInfo, newLastTaskProgress: ArchiveTaskProgressInfo) => {
    if (prevLastTaskProgress.TaskId.length > 0 && prevLastTaskProgress.TaskId === newLastTaskProgress.TaskId) {
        return prevLastTaskProgress.TargetType === ArchivingTargetType.DirectDownload && newLastTaskProgress.TargetType === ArchivingTargetType.DirectDownload &&
            prevLastTaskProgress.ProgressState !== TaskProgressState.CancelFinished && prevLastTaskProgress.ProgressState !== TaskProgressState.FailedFinished && newLastTaskProgress.ProgressState === TaskProgressState.SuccessFinished;
    }
    return false;
}

const archivingReducer: Reducer<IGeovisArchivingState> = (
    state: IGeovisArchivingState = archivingInitialState,
    action: IGeovisArchivingAction): IGeovisArchivingState => {

    const changeSettingsValue = (originalSettings: any, propertyName: keyof (ArchivingProjectSettingsModel), value: any) => {
        originalSettings[propertyName] = value;
        return originalSettings;
    }

    const changeSettingsValues = (originalSettings: ArchivingProjectSettingsModel, changes: Partial<ArchivingProjectSettingsModel>) => {
        return { ...originalSettings, ...changes };
    }

    switch (action.type) {
        case GEOVIS_ARCHIVING_CHANGED_SETTING: {
            if (!action.archivingPropertyName || action.propertyValue === undefined) {
                return state;
            }

            return ({
                ...state,
                archivingSettings: changeSettingsValue(state.archivingSettings, action.archivingPropertyName, action.propertyValue)
            });
        }

        case GEOVIS_ARCHIVING_CHANGED_SETTINGS: {
            if (!action.changes) {
                return state;
            }

            return ({
                ...state,
                archivingSettings: changeSettingsValues(state.archivingSettings, action.changes)
            })
        }

        case GEOVIS_ARCHIVING_LISTING_LOADING: {
            if (!action.stringProperty) {
                return state;
            }
            return {
                ...state,
                listingState: {
                    isError: false,
                    isLoaded: false,
                    isLoading: true,
                    isInProgress: false,
                    errorDescription: '',
                    lastRequestMark: action.stringProperty,
                    listing: new FolderListingModel()
                }
            }
        }

        case GEOVIS_ARCHIVING_LISTING_LOADED: {
            if (!action.listing) {
                return state;
            }

            return {
                ...state,
                listingState: {
                    isError: false,
                    isLoaded: true,
                    isLoading: false,
                    isInProgress: false,
                    errorDescription: '',
                    lastRequestMark: '',
                    listing: action.listing
                }
            }
        }

        case GEOVIS_ARCHIVING_LISTING_LOADING_ERROR: {
            if (!action.stringProperty) {
                return state;
            }
            return {
                ...state,
                listingState: {
                    isError: true,
                    isLoaded: false,
                    isLoading: false,
                    isInProgress: false,
                    errorDescription: action.stringProperty,
                    lastRequestMark: '',
                    listing: new FolderListingModel()
                }
            }
        }

        case GEOVIS_ARCHIVING_SHOW_DIALOG: {

            return {
                ...state,
                showArchivingDialog: true,
                oldArchivingFileName: state.archivingSettings.ArchivingFileName,
                oldRestoreFileName: state.archivingSettings.RestoreFileName,
                oldFtpPath: state.archivingSettings.FtpPath,
                oldLocalPath: state.archivingSettings.LocalPath
            }
        }

        case GEOVIS_ARCHIVING_HIDE_DIALOG: {
            if (action.booleanProperty === undefined) {
                return state;
            }

            return {
                ...state,
                archivingSettings: {
                    ...state.archivingSettings,
                    ArchivingFileName: action.booleanProperty ? state.archivingSettings.ArchivingFileName : state.oldArchivingFileName,
                    RestoreFileName: action.booleanProperty ? state.archivingSettings.RestoreFileName : state.oldRestoreFileName,
                    FtpPath: action.booleanProperty ? state.archivingSettings.FtpPath : state.oldFtpPath,
                    LocalPath: action.booleanProperty ? state.archivingSettings.LocalPath : state.oldLocalPath
                },
                oldArchivingFileName: '',
                oldFtpPath: '',
                oldLocalPath: '',
                showArchivingDialog: false
            }
        }

        case GEOVIS_ARCHIVING_SETTINGS_SET: {
            if (!action.archivingSettings) {
                return state;
            }

            return {
                ...state,
                showConfirmDialog: false,
                showFeedbackDialog: false,
                archivingSettings: action.archivingSettings
            }
        }

        case GEOVIS_ARCHIVING_SHOW_CONFIRM_DIALOG: {
            return {
                ...state,
                showConfirmDialog: true
            }
        }

        case GEOVIS_ARCHIVING_HIDE_CONFIRM_DIALOG: {
            return {
                ...state,
                showConfirmDialog: false
            }
        }

        case GEOVIS_ARCHIVING_DEFAULT_SETTINGS_LOADED: {
            if (!action.stringProperty) {
                return state;
            }

            return {
                ...state,
                defaultLocalPath: action.stringProperty
            }
        }

        case GEOVIS_ARCHIVING_RESTORING_CHANGE: {
            if (action.booleanProperty === undefined) {
                return state;
            }

            return {
                ...state,
                isArchiving: action.booleanProperty,
                archivingSettings: getNewArchivingSettings(''),
                lastTaskProgress: { ...lastTaskProgressInitialState },
                directDownloadLinkAvailable: false
            }
        }

        case GEOVIS_ARCHIVING_PROJECTS_LOADED: {
            if (!action.projects || !action.taskProgress) {
                return state;
            }

            const currentTaskProgress = { ...state.lastTaskProgress };

            return {
                ...state,
                projectsStore: {
                    ...loadedSomethingStorageState,
                    projects: action.projects
                },
                lastTaskProgress: action.taskProgress.TaskId ? { ...action.taskProgress } : state.lastTaskProgress,
                directDownloadLinkAvailable: isDirectDownloadLinkAvailable(currentTaskProgress, action.taskProgress)
            }
        }

        case GEOVIS_ARCHIVING_PROJECTS_LOADING: {
            return {
                ...state,
                projectsStore: {
                    ...defaultSomethingStorageState,
                    projects: []
                }
            }
        }

        case GEOVIS_ARCHIVING_PROJECTS_LOADING_ERROR: {
            if (!action.stringProperty) {
                return state;
            }

            return {
                ...state,
                projectsStore: {
                    ...errorSomethingStorageState(action.stringProperty),
                    projects: []
                }
            }
        }

        case GEOVIS_ARCHIVING_SHOW_FEEDBACK_DIALOG: {
            if (action.booleanProperty === undefined) {
                return state;
            }
            return {
                ...state,
                showFeedbackDialog: true,
                successFeedback: action.booleanProperty
            }
        }

        case GEOVIS_ARCHIVING_PROGRESS_STARTED: {
            if (!action.taskProgress) {
                return state;
            }

            return {
                ...state,
                lastTaskProgress: { ...action.taskProgress },
                showFeedbackDialog: true,
                successFeedback: true
            }
        }

        case GEOVIS_ARCHIVING_FILE_DOWNLOAD_FINISHED: {
            if (!action.archivingSettings) {
                return state;
            }

            return {
                ...state,
                lastTaskProgress: { ...lastTaskProgressInitialState },
                directDownloadLinkAvailable: false,
                showConfirmDialog: false,
                showFeedbackDialog: false,
                archivingSettings: action.archivingSettings
            }
        }

        case GEOVIS_RESTORE_FILES_UPLOAD_STARTED: {
            if (!action.uploadFilesInfo) {
                return state;
            }

            return {
                ...state,
                uploadFilesInfo: action.uploadFilesInfo.map<GeovisUploadFileState>(f => ({ ...new GeovisUploadFileState(), ...f }))
            };
        }

        case GEOVIS_RESTORE_FILE_UPLOAD_PROGRESS: {
            if (!action.uploadFileInfo) {
                return state;
            }

            return {
                ...state,
                uploadFilesInfo: state.uploadFilesInfo.map<GeovisUploadFileState>(f => {
                    if (f.linkId === action.uploadFileInfo?.id) {
                        return ({ ...new GeovisUploadFileState(), ...action.uploadFileInfo })
                    }
                    return f;
                })
            };
        }

        case GEOVIS_RESTORE_FILE_UPLOAD_FINISHED: {
            if (!action.uploadFileId || !action.stringProperty) {
                return state;
            }

            return {
                ...state,
                uploadFilesInfo: state.uploadFilesInfo.filter(f => f.id !== action.uploadFileId),
                archivingSettings: {
                    ...state.archivingSettings,
                    RestoreFileName: action.stringProperty
                }
            };
        }

        case GEOVIS_RESTORE_FILE_UPLOAD_ERROR: {
            if (!action.uploadFileId) {
                return state;
            }

            return {
                ...state,
                uploadFilesInfo: state.uploadFilesInfo.filter(f => f.id !== action.uploadFileId),
            };
        }


    }

    return state;
}

export default archivingReducer;