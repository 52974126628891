import Checkbox from "@atlaskit/checkbox";
import { OptionType } from "@atlaskit/select";
import { Fragment, SyntheticEvent } from "react";
import { getGeovisCurrentDateTime, getGeovisDateTimeShift } from "../../../../helpers/DateHelper";
import { t } from "../../../../i18n";
import { getTimePeriodToDescription, TimePeriod, TimePeriodList } from "../../../../server/AVTService/TypeLibrary/Common/TimePeriod";
import { GeovisChartAxisSettings } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartAxisSettings";
import { VibrationChartModel } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/VibrationChartModel";
import { SensorChangesModel } from "../../../../server/GEOvis3/Model/GeovisChart/SensorChangesModel";
import GeovisReactDateTimePicker from "../../../dateTimePickers/GeovisReactDateTimePicker";
import { GeovisSelect } from "../../../select/GeovisSelect";
import { ChartAxisSensorsControl } from "../ChartAxisSensorsControl";
import { getTimeOfPeriodInHours, isChartTemplate } from "../tools";
import { defaultChartLabelColumnStyle, defaultChartRowSettingsStyle, IGeovisChartPropertyChangedProps, IGeovisChartSensorsPropertyProps } from "../types";
import { ElementSettingAllowCheckbox } from "../../ElementSettingAllowCheckbox";

type ChartProperty = keyof VibrationChartModel;

interface IVibrationMainSettingsProps extends IGeovisChartPropertyChangedProps<VibrationChartModel>, IGeovisChartSensorsPropertyProps {

}

export const VibrationChartMainSettings = ({ chart, onChartPropertyChanged, onChartPropertiesChanged, sensorsChanges }: IVibrationMainSettingsProps) => {

    const {
        StartDateSetting,
        EndDateSetting,
        ShowAllMeasurementsSetting,
        Period,
        IsPeriodCustomerChangeable,
        UseLastMeasurementTime,
    } = chart;

    const onBooleanPropertyChangedFunc = (propertyName: ChartProperty) => (event: SyntheticEvent<HTMLInputElement>) => {
        const value = event.currentTarget.checked;

        switch (propertyName) {
            case "ShowAllMeasurementsSetting":
                onChartPropertyChanged(propertyName, { ...ShowAllMeasurementsSetting, value });
                break;

            case "IsPeriodCustomerChangeable":
            case "UseLastMeasurementTime":
                onChartPropertyChanged(propertyName, value);
                break;
        }
    }

    const onCustomerChangeablePropertyChangedFunc = (propertyName: ChartProperty) => (value: boolean) => {

        switch (propertyName) {
            case "ShowAllMeasurementsSetting":
                onChartPropertyChanged(propertyName, { ...ShowAllMeasurementsSetting, customerChangeable: value })
                break;
            case "StartDateSetting":
                onChartPropertyChanged(propertyName, { ...StartDateSetting, customerChangeable: value });
                break;
            case "EndDateSetting":
                onChartPropertyChanged(propertyName, { ...EndDateSetting, customerChangeable: value });
                break;
        }
    }

    const onStringPropertyChangedFunc = (propertyName: ChartProperty) => (value: string) => {
        switch (propertyName) {
            case "StartDateSetting":
                onChartPropertyChanged(propertyName, { ...StartDateSetting, Value: value });
                break;
            case "EndDateSetting":
                onChartPropertyChanged(propertyName, { ...EndDateSetting, Value: value });
                break;
        }
    }

    const onSelectablePropertyChangedFunc = (propertyName: ChartProperty) => (selected: OptionType) => {
        switch (propertyName) {
            case "Period":
                if (+selected.value === TimePeriod.Custom) {
                    onChartPropertyChanged(propertyName, +selected.value);
                }
                else {
                    const delta = getTimeOfPeriodInHours(+selected.value);
                    const endDate = getGeovisCurrentDateTime();
                    const startDate = getGeovisDateTimeShift(new Date(), -delta, 'hours')
                    onChartPropertiesChanged({
                        StartDateSetting: { Value: startDate, customerChangeable: StartDateSetting.customerChangeable },
                        EndDateSetting: { Value: endDate, customerChangeable: EndDateSetting.customerChangeable },
                        Period: +selected.value
                    })
                }
                break;
            case "BucketReportValueKind":
                onChartPropertyChanged(propertyName, +selected.value);
                break;
        }
    }

    const onAxisSensorsChangedHandler = (axisPropertyName: keyof VibrationChartModel, axisSettings: GeovisChartAxisSettings) => (sensorIds: string[], customerChangeable: boolean, sensorChanges: SensorChangesModel[]) => {

        const newAxisSettings: GeovisChartAxisSettings = {
            ...axisSettings,
            SensorIds: [...sensorIds],
            IsSensorsCustomerChangeable: customerChangeable
        }
        onChartPropertyChanged(axisPropertyName, newAxisSettings, sensorChanges);
    }

    const getPeriodOptions = (): OptionType[] => {
        return TimePeriodList.map(val => ({ value: val, label: getTimePeriodToDescription(val) }))
    }

    const getSelectedPeriod = (): OptionType => {
        return { value: Period, label: getTimePeriodToDescription(Period) };
    }

    const labelColumnStyle = defaultChartLabelColumnStyle;
    const settingRowStyle = defaultChartRowSettingsStyle;

    return (
        <Fragment>
            {/* Show all measurements */}
            <div className="flexRowContainerLine">
                <div style={labelColumnStyle}>
                    {/*  */}
                </div>
                <div className="flexCellContainer_g1_m0">
                    <Checkbox
                        label={t("Show all measurements")}
                        isChecked={ShowAllMeasurementsSetting.value}
                        onChange={onBooleanPropertyChangedFunc("ShowAllMeasurementsSetting")}
                    />
                </div>
                <ElementSettingAllowCheckbox
                    isChecked={ShowAllMeasurementsSetting.customerChangeable}
                    onChange={onCustomerChangeablePropertyChangedFunc("ShowAllMeasurementsSetting")}
                    isDisabled={false}
                />
            </div>

            {/* Period */}
            <div className="flexRowContainerLine" style={settingRowStyle}>
                <div style={labelColumnStyle}>
                    <label htmlFor="cmbPeriod">{t("Period")}:</label>
                </div>
                <div className="flexCellContainer_g1_m0">
                    <GeovisSelect
                        id="cmbPeriod"
                        options={getPeriodOptions()}
                        value={getSelectedPeriod()}
                        onChange={onSelectablePropertyChangedFunc("Period")}
                        isCompact={true}
                        spacing='compact'
                        isDisabled={ShowAllMeasurementsSetting.value}
                    />
                </div>
                {ElementSettingAllowCheckbox({
                    isChecked: IsPeriodCustomerChangeable,
                    onChange: onCustomerChangeablePropertyChangedFunc("IsPeriodCustomerChangeable"),
                    isDisabled: ShowAllMeasurementsSetting.value
                })}
            </div>

            {/* Use last measurements */}
            <div className="flexRowContainerLine">
                <div style={labelColumnStyle}>
                    {/*  */}
                </div>
                <div className="flexCellContainer_g1_m0">
                    <Checkbox
                        label={t("Using the last measurement time for the report end time")}
                        isChecked={UseLastMeasurementTime}
                        isDisabled={ShowAllMeasurementsSetting.value || Period === TimePeriod.Custom}
                        onChange={onBooleanPropertyChangedFunc("UseLastMeasurementTime")}
                    />
                </div>
            </div>

            {/* Start date */}
            <div className="flexRowContainerLine" style={settingRowStyle}>
                <div style={labelColumnStyle}>
                    <label htmlFor="dtStartDateTime">{t("Start date")}:</label>
                </div>
                <div className="flexCellContainer_g1_m0">
                    <GeovisReactDateTimePicker
                        locale="en-GB"
                        key="dtStartDateTime"
                        value={StartDateSetting.Value}
                        isPairedPicker={true}
                        isThisPickerOfStartDate={true}
                        pairPickerDate={EndDateSetting.Value}
                        disabled={ShowAllMeasurementsSetting.value || Period !== TimePeriod.Custom}
                        onChange={onStringPropertyChangedFunc("StartDateSetting")} />
                </div>
                {ElementSettingAllowCheckbox({
                    isChecked: StartDateSetting.customerChangeable,
                    onChange: onCustomerChangeablePropertyChangedFunc("StartDateSetting"),
                    isDisabled: ShowAllMeasurementsSetting.value || Period !== TimePeriod.Custom
                })}
            </div>

            {/* End date */}
            <div className="flexRowContainerLine" style={settingRowStyle}>
                <div style={labelColumnStyle}>
                    <label htmlFor="dtEndDateTime">{t("End date")}:</label>
                </div>
                <div className="flexCellContainer_g1_m0">
                    <GeovisReactDateTimePicker
                        locale="en-GB"
                        key="dtEndDateTime"
                        value={EndDateSetting.Value}
                        isPairedPicker={true}
                        pairPickerDate={StartDateSetting.Value}
                        onChange={onStringPropertyChangedFunc("EndDateSetting")}
                        disabled={ShowAllMeasurementsSetting.value || Period !== TimePeriod.Custom}
                    />
                </div>
                {ElementSettingAllowCheckbox({
                    isChecked: EndDateSetting.customerChangeable,
                    onChange: onCustomerChangeablePropertyChangedFunc("EndDateSetting"),
                    isDisabled: ShowAllMeasurementsSetting.value || Period !== TimePeriod.Custom
                })}
            </div>

            {/* Sensor selection */}
            {!isChartTemplate(chart) &&
                <ChartAxisSensorsControl
                    isChain={false}
                    chartType={chart.Type}
                    dtsSectionIds={[]}
                    projectId={chart.ProjectId}
                    ySensorType={chart.LeftYAxisSettings.TypeOfSensor}
                    sensorIds={chart.LeftYAxisSettings.SensorIds}
                    customerChangeable={false}
                    onChange={onAxisSensorsChangedHandler("LeftYAxisSettings", chart.LeftYAxisSettings)}
                    sensorChanges={sensorsChanges}
                    showPairsDialog={false}
                    selectedChainsIds={[]}
                    isSingle={true}
                    hideCustomerChangeable={true}
                />
            }
        </Fragment>
    );
}