import Checkbox from "@atlaskit/checkbox";
import { OptionType } from "@atlaskit/select";
import { Fragment, SyntheticEvent } from "react";
import { getGeovisCurrentDateTime, getGeovisDateTimeShift } from "../../../../helpers/DateHelper";
import { t } from "../../../../i18n";
import { getTimePeriodToDescription, TimePeriod, TimePeriodList } from "../../../../server/AVTService/TypeLibrary/Common/TimePeriod";
import { BucketReportValueKindList, getBucketReportValueKindToName } from "../../../../server/AVTService/TypeLibrary/DB/ArgusDataStorage/Bucket/Types/BucketReportValueKind";
import { TimeValueChartModel } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/TimeValueChartModel";
import GeovisReactDateTimePicker from "../../../dateTimePickers/GeovisReactDateTimePicker";
import { GeovisSelect } from "../../../select/GeovisSelect";
import { getTimeOfPeriodInHours } from "../tools";
import {
    defaultChartLabelColumnStyle,
    defaultChartRowSettingsStyle,
    IGeovisChartPropertyChangedProps
} from "../types";
import { ElementSettingAllowCheckbox } from "../../ElementSettingAllowCheckbox";

type ChartProperty = keyof TimeValueChartModel;

type ITimeValueMainSettingsProps = IGeovisChartPropertyChangedProps<TimeValueChartModel>

export const TimeValueChartMainSettings = ({ chart, onChartPropertyChanged, onChartPropertiesChanged }: ITimeValueMainSettingsProps) => {

    const {
        StartDateSetting,
        EndDateSetting,
        ShowAllMeasurementsSetting,
        Period,
        IsPeriodCustomerChangeable,
        UseLastMeasurementTime,
        ShowReferenceAndOffset,
        UseRelativeTimeSetting,
        RelativeTime,
        BucketReportValueKind,
        BucketReportValueKindCustomerChangeable
    } = chart;

    const onBooleanPropertyChangedFunc = (propertyName: ChartProperty) => (event: SyntheticEvent<HTMLInputElement>) => {
        const value = event.currentTarget.checked;

        switch (propertyName) {
            case "ShowAllMeasurementsSetting":
                onChartPropertyChanged(propertyName, { ...ShowAllMeasurementsSetting, value });
                break;
            case "UseRelativeTimeSetting":
                onChartPropertyChanged(propertyName, { ...UseRelativeTimeSetting, value });
                break;

            case "IsPeriodCustomerChangeable":
            case "UseLastMeasurementTime":
            case "ShowReferenceAndOffset":
                onChartPropertyChanged(propertyName, value);
                break;
        }
    }

    const onCustomerChangeablePropertyChangedFunc = (propertyName: ChartProperty) => (value: boolean) => {

        switch (propertyName) {
            case "ShowAllMeasurementsSetting":
                onChartPropertyChanged(propertyName, { ...ShowAllMeasurementsSetting, customerChangeable: value })
                break;
            case "UseRelativeTimeSetting":
                onChartPropertyChanged(propertyName, { ...UseRelativeTimeSetting, customerChangeable: value });
                break;
            case "StartDateSetting":
                onChartPropertyChanged(propertyName, { ...StartDateSetting, customerChangeable: value });
                break;
            case "EndDateSetting":
                onChartPropertyChanged(propertyName, { ...EndDateSetting, customerChangeable: value });
                break;
            case "BucketReportValueKindCustomerChangeable":
            case "IsPeriodCustomerChangeable":
                onChartPropertyChanged(propertyName, value);
                break;
        }
    }

    const onStringPropertyChangedFunc = (propertyName: ChartProperty) => (value: string) => {
        switch (propertyName) {
            case "StartDateSetting":
                onChartPropertyChanged(propertyName, { ...StartDateSetting, Value: value });
                break;
            case "EndDateSetting":
                onChartPropertyChanged(propertyName, { ...EndDateSetting, Value: value });
                break;
            case "RelativeTime":
                onChartPropertyChanged(propertyName, value);
                break
        }
    }

    const onSelectablePropertyChangedFunc = (propertyName: ChartProperty) => (selected: OptionType) => {
        switch (propertyName) {
            case "Period":
                if (+selected.value === TimePeriod.Custom) {
                    onChartPropertyChanged(propertyName, +selected.value);
                }
                else {
                    const delta = getTimeOfPeriodInHours(+selected.value);
                    const endDate = getGeovisCurrentDateTime();
                    const startDate = getGeovisDateTimeShift(new Date(), -delta, 'hours')
                    onChartPropertiesChanged({
                        StartDateSetting: { Value: startDate, customerChangeable: StartDateSetting.customerChangeable },
                        EndDateSetting: { Value: endDate, customerChangeable: EndDateSetting.customerChangeable },
                        Period: +selected.value
                    })
                }
                break;
            case "BucketReportValueKind":
                onChartPropertyChanged(propertyName, +selected.value);
                break;
        }
    }

    const getPeriodOptions = (): OptionType[] => {
        return TimePeriodList.map(val => ({ value: val, label: getTimePeriodToDescription(val) }))
    }

    const getSelectedPeriod = (): OptionType => {
        return { value: Period, label: getTimePeriodToDescription(Period) };
    }

    const getBVKOptions = (): OptionType[] => {
        return BucketReportValueKindList.map(val => ({ value: val, label: getBucketReportValueKindToName(val) }))
    }

    const getSelectedBVK = (): OptionType => {
        return { value: BucketReportValueKind, label: getBucketReportValueKindToName(BucketReportValueKind) };
    }

    const labelColumnStyle = defaultChartLabelColumnStyle;
    const settingRowStyle = defaultChartRowSettingsStyle;

    return (
        <Fragment>
            {/* Show all measurements */}
            <div className="flexRowContainerLine">
                <div style={labelColumnStyle}>
                    {/*  */}
                </div>
                <div className="flexCellContainer_g1_m0">
                    <Checkbox
                        label={t("Show all measurements")}
                        isChecked={ShowAllMeasurementsSetting.value}
                        onChange={onBooleanPropertyChangedFunc("ShowAllMeasurementsSetting")}
                    />
                </div>
                <ElementSettingAllowCheckbox
                    isChecked={ShowAllMeasurementsSetting.customerChangeable}
                    onChange={onCustomerChangeablePropertyChangedFunc("ShowAllMeasurementsSetting")}
                    isDisabled={false}
                />
            </div>

            {/* Period */}
            <div className="flexRowContainerLine" style={settingRowStyle}>
                <div style={labelColumnStyle}>
                    <label htmlFor="cmbPeriod">{t("Period")}:</label>
                </div>
                <div className="flexCellContainer_g1_m0">
                    <GeovisSelect
                        id="cmbPeriod"
                        options={getPeriodOptions()}
                        value={getSelectedPeriod()}
                        onChange={onSelectablePropertyChangedFunc("Period")}
                        isCompact={true}
                        spacing='compact'
                        isDisabled={ShowAllMeasurementsSetting.value}
                    />
                </div>
                {ElementSettingAllowCheckbox({
                    isChecked: IsPeriodCustomerChangeable,
                    onChange: onCustomerChangeablePropertyChangedFunc("IsPeriodCustomerChangeable"),
                    isDisabled: ShowAllMeasurementsSetting.value
                })}
            </div>

            {/* Use last measurements */}
            <div className="flexRowContainerLine">
                <div style={labelColumnStyle}>
                    {/*  */}
                </div>
                <div className="flexCellContainer_g1_m0">
                    <Checkbox
                        label={t("Using the last measurement time for the report end time")}
                        isChecked={UseLastMeasurementTime}
                        isDisabled={ShowAllMeasurementsSetting.value || Period === TimePeriod.Custom}
                        onChange={onBooleanPropertyChangedFunc("UseLastMeasurementTime")}
                    />
                </div>
            </div>

            {/* Start date */}
            <div className="flexRowContainerLine" style={settingRowStyle}>
                <div style={labelColumnStyle}>
                    <label htmlFor="dtStartDateTime">{t("Start date")}:</label>
                </div>
                <div className="flexCellContainer_g1_m0">
                    <GeovisReactDateTimePicker
                        locale="en-GB"
                        key="dtStartDateTime"
                        id="dtStartDateTime"
                        value={StartDateSetting.Value}
                        isPairedPicker={true}
                        isThisPickerOfStartDate={true}
                        pairPickerDate={EndDateSetting.Value}
                        disabled={ShowAllMeasurementsSetting.value || Period !== TimePeriod.Custom}
                        onChange={onStringPropertyChangedFunc("StartDateSetting")} />
                </div>
                {ElementSettingAllowCheckbox({
                    isChecked: StartDateSetting.customerChangeable,
                    onChange: onCustomerChangeablePropertyChangedFunc("StartDateSetting"),
                    isDisabled: ShowAllMeasurementsSetting.value || Period !== TimePeriod.Custom
                })}
            </div>

            {/* End date */}
            <div className="flexRowContainerLine" style={settingRowStyle}>
                <div style={labelColumnStyle}>
                    <label htmlFor="dtEndDateTime">{t("End date")}:</label>
                </div>
                <div className="flexCellContainer_g1_m0">
                    <GeovisReactDateTimePicker
                        locale="en-GB"
                        key="dtEndDateTime"
                        id="dtEndDateTime"
                        value={EndDateSetting.Value}
                        isPairedPicker={true}
                        isThisPickerOfStartDate={false}
                        pairPickerDate={StartDateSetting.Value}
                        onChange={onStringPropertyChangedFunc("EndDateSetting")}
                        disabled={ShowAllMeasurementsSetting.value || Period !== TimePeriod.Custom}
                    />
                </div>
                {ElementSettingAllowCheckbox({
                    isChecked: EndDateSetting.customerChangeable,
                    onChange: onCustomerChangeablePropertyChangedFunc("EndDateSetting"),
                    isDisabled: ShowAllMeasurementsSetting.value || Period !== TimePeriod.Custom
                })}
            </div>

            {/* Use relative time */}
            <div className="flexRowContainerLine">
                <div style={labelColumnStyle}>
                    {/*  */}
                </div>
                <div className="flexCellContainer_g1_m0" style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: '5px' }}>
                        <Checkbox
                            label={`${t("Use relative time with start at")}: `}
                            isChecked={UseRelativeTimeSetting.value}
                            onChange={onBooleanPropertyChangedFunc("UseRelativeTimeSetting")}
                        />
                    </div>
                    <div style={{ width: '200px' }}>
                        <GeovisReactDateTimePicker
                            locale="en-GB"
                            key="dtRelativeTime"
                            id="dtRelativeTime"
                            disabled={!UseRelativeTimeSetting.value}
                            value={RelativeTime}
                            onChange={onStringPropertyChangedFunc("RelativeTime")}
                        />
                    </div>
                </div>
                {ElementSettingAllowCheckbox({
                    isChecked: UseRelativeTimeSetting.customerChangeable,
                    onChange: onCustomerChangeablePropertyChangedFunc("UseRelativeTimeSetting"),
                    isDisabled: false
                })}
            </div>

            {/* Show Reference */}
            <div className="flexRowContainerLine">
                <div style={labelColumnStyle}>
                    {/*  */}
                </div>
                <div className="flexCellContainer_g1_m0">
                    <Checkbox
                        label={t("Show Reference and Offset Timestamp")}
                        isChecked={ShowReferenceAndOffset}
                        onChange={onBooleanPropertyChangedFunc("ShowReferenceAndOffset")}
                    />
                </div>
            </div>

            {/* Bucket value source */}
            <div className="flexRowContainerLine" style={settingRowStyle}>
                <div style={labelColumnStyle}>
                    <label htmlFor="cmbBVS">{t("Bucket value source")}:</label>
                </div>
                <div className="flexCellContainer_g1_m0">
                    <GeovisSelect
                        id="cmbBVS"
                        options={getBVKOptions()}
                        value={getSelectedBVK()}
                        onChange={onSelectablePropertyChangedFunc("BucketReportValueKind")}
                        isCompact={true}
                        spacing='compact'
                        isDisabled={false}
                    />
                </div>
                {ElementSettingAllowCheckbox({
                    isChecked: BucketReportValueKindCustomerChangeable,
                    onChange: onCustomerChangeablePropertyChangedFunc("BucketReportValueKindCustomerChangeable"),
                    isDisabled: false
                })}
            </div>
        </Fragment>
    );
}