/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 29.10.2021
 * @description Lazy loading components to group in the separate files
 */


import loadable from '@loadable/component';
import { LoadingPageSkeleton } from './components/LoadingPageSkeleton';

const LoadingFallback = () => (<LoadingPageSkeleton />)

//#region Data migration

/**
 * Data migration main page
 * MigrationsPage.tsx
 */
export const DataMigrationPageLazy = loadable(() => import('./pages/business/settings/migrations/MigrationsPage'), { fallback: LoadingFallback() });

// #endregion

//#region Message templates

/**
 * MessagesOverviewPage.tsx
 */
export const MessagesOverviewPageLazy = loadable(() => import('./pages/business/messages/MessagesOverviewPage'), { fallback: LoadingFallback() });

/**
 * MessageTemplateEditPage.tsx
 */
export const MessageTemplateEditPageLazy = loadable(() => import('./pages/business/messages/MessageTemplateEditPage'), { fallback: LoadingFallback() });

//#endregion

//#region Inventory

export const GeovisInventoryPageLazy = loadable(() => import('./pages/business/inventory/GeovisInventoryPage'), { fallback: LoadingFallback() });

export const GeovisInventoryTreePageLazy = loadable(() => import('./pages/business/inventory/GeovisInventoryTreePage'), { fallback: LoadingFallback() });

export const GeovisInventoryReservationPageLazy = loadable(() => import('./pages/business/inventory/GeovisInventoryReservationPage'), { fallback: LoadingFallback() });

export const GeovisInventoryCalendarPageLazy = loadable(() => import('./pages/business/inventory/GeovisInventoryCalendarPage'), { fallback: LoadingFallback() });

//#endregion

//#region Logs

export const LogsFollowPageLazy = loadable(() => import('./pages/logs/LogsFollowPage'), { fallback: LoadingFallback() });

export const LogsNavigationLazy = loadable(() => import('./pages/logs/LogsNavigation'), { fallback: LoadingFallback() });

export const LogsPageLazy = loadable(() => import('./pages/logs/LogsPage'), { fallback: LoadingFallback() });

//#endregion

//#region Global imports

// export const GlobalImportsNavigationLazy = loadable(() => import('./pages/globalImports/GlobalImportsNavigation'), { fallback: LoadingFallback() });

export const GlobalImportsSwissMeteoPageLazy = loadable(() => import('./pages/globalImports/swissMeteo/SwissMeteoPage'), { fallback: LoadingFallback() });

export const GlobalImportsSwissMeteoDataPageLazy = loadable(() => import('./pages/globalImports/swissMeteo/SwissMeteoSensorDataPage'), { fallback: LoadingFallback() });

export const GlobalImportsSettingsPageLazy = loadable(() => import('./pages/globalImports/GlobalImportsSettingsPage'), { fallback: LoadingFallback() });

//#endregion

//#region Project overview

export const ArchivingNavigationLazy = loadable(() => import('./pages/business/archiving/ArchivingNavigation'), { fallback: LoadingFallback() });

export const ArchivingProjectsPageLazy = loadable(() => import('./pages/business/archiving/ArchivingProjectsPage'), { fallback: LoadingFallback() });

export const RestoringProjectsPageLazy = loadable(() => import('./pages/business/archiving/RestoringProjectsPage'), { fallback: LoadingFallback() });

export const ProjectOverviewNavigationLazy = loadable(() => import('./components/navigation/ProjectOverviewNavigation'), { fallback: LoadingFallback() });

export const ReportOverlayNavigationLazy = loadable(() => import('./components/navigation/ReportOverlayNavigation'), { fallback: LoadingFallback() });

export const ProjectInventoryPageLazy = loadable(() => import('./pages/overview/ProjectInventoryPage'), { fallback: LoadingFallback() });

export const ProjectLogbookElementRecordsPageLazy = loadable(() => import('./pages/overview/ProjectLogbookElementRecordsPage'), { fallback: LoadingFallback() });

export const ProjectLogbookPageLazy = loadable(() => import('./pages/overview/ProjectLogbookPage'), { fallback: LoadingFallback() });

export const ProjectReportPageLazy = loadable(() => import('./pages/overview/ProjectReportPage'), { fallback: LoadingFallback() });

export const ProjectStatisticPageLazy = loadable(() => import('./pages/overview/ProjectStatisticPage'), { fallback: LoadingFallback() });

export const ProjectInvoiceStatisticPageLazy = loadable(() => import('./pages/overview/ProjectInvoiceStatisticPage'), { fallback: LoadingFallback() });

export const ProjectSupportPageLazy = loadable(() => import('./pages/overview/ProjectSupportPage'), { fallback: LoadingFallback() });

export const ProjectCreationPageLazy = loadable(() => import('./pages/ProjectCreationPage'), { fallback: LoadingFallback() });

export const DashboardPageLazy = loadable(() => import('./pages/DashboardPage'), { fallback: LoadingFallback() });

export const ProjectDocumentsPageLazy = loadable(() => import('./pages/ProjectDocumentsPage'), { fallback: LoadingFallback() });

export const ProjectOverviewPageLazy = loadable(() => import('./pages/ProjectOverviewPage'), { fallback: LoadingFallback() });

export const ProjectReportsPageLazy = loadable(() => import('./pages/ProjectReportsPage'), { fallback: LoadingFallback() });

export const ProjectsOverviewPageLazy = loadable(() => import('./pages/ProjectsOverviewPage'), { fallback: LoadingFallback() });

//#endregion

//#region Project edit

export const ProjectEditNavigationPageLazy = loadable(() => import('./pages/edit/ProjectEditNavigationPage'), { fallback: LoadingFallback() });

//#endregion

export const ProjectStatisticNavigationPageLazy = loadable(() => import('./pages/overview/ProjectStatisticNavigationPage'), { fallback: LoadingFallback() });


//#region About and servicing pages

export const FeedbacksPageLazy = loadable(() => import('./pages/service/feedbacks/FeedbacksPage'), { fallback: LoadingFallback() });

export const UserFeedbackPageLazy = loadable(() => import('./pages/service/feedbacks/UserFeedbackPage'), { fallback: LoadingFallback() });

export const DocumentationPageLazy = loadable(() => import('./pages/softwareHelp/DocumentationPage'), { fallback: LoadingFallback() });

export const LogosManagerPageLazy = loadable(() => import('./pages/service/LogosManagerPage'), { fallback: LoadingFallback() });

export const AboutPageLazy = loadable(() => import('./pages/softwareHelp/AboutPage'), { fallback: LoadingFallback() });

export const FaqPageLazy = loadable(() => import('./pages/softwareHelp/FaqPage'), { fallback: LoadingFallback() });

export const FormulaSyntaxHelpPageLazy = loadable(() => import('./pages/softwareHelp/FormulaSyntaxHelpPage'), { fallback: LoadingFallback() });

export const ReleaseNoteEditPageLazy = loadable(() => import('./pages/softwareHelp/releaseNotes/ReleaseNoteEditPage'), { fallback: LoadingFallback() });

export const ReleaseNotesPageLazy = loadable(() => import('./pages/softwareHelp/ReleaseNotesPage'), { fallback: LoadingFallback() });

export const CostCalculatorPageLazy = loadable(() => import('./pages/softwareHelp/CostCalculatorPage'), { fallback: LoadingFallback() });

export const SensorTypesHelpPageLazy = loadable(() => import('./pages/softwareHelp/SensorTypesHelpPage'), { fallback: LoadingFallback() });

export const UnitsHelpPageLazy = loadable(() => import('./pages/softwareHelp/UnitsHelpPage'), { fallback: LoadingFallback() });

export const SoftwareHelpNavigationLazy = loadable(() => import('./components/navigation/SoftwareHelpNavigation'), { fallback: LoadingFallback() });

//#endregion

//#region Company and user management

export const UserProfileEditPageLazy = loadable(() => import('./pages/business/user/UserProfileEditPage'), { fallback: LoadingFallback() });

export const AddCompanyPageLazy = loadable(() => import('./pages/business/companies/AddCompanyPage'), { fallback: LoadingFallback() });

export const CompaniesPageLazy = loadable(() => import('./pages/business/companies/CompaniesPage'), { fallback: LoadingFallback() });

export const ExportPageLazy = loadable(() => import('./pages/business/ExportPage'), { fallback: LoadingFallback() });

export const ImportPageLazy = loadable(() => import('./pages/business/ImportPage'), { fallback: LoadingFallback() });

export const PartnerCompanyManagerPageLazy = loadable(() => import('./pages/business/PartnerCompaniesManagerPage'), { fallback: LoadingFallback() });

export const AddUserPageLazy = loadable(() => import('./pages/business/user/AddUserPage'), { fallback: LoadingFallback() });

export const UsersPageLazy = loadable(() => import('./pages/business/user/UsersPage'), { fallback: LoadingFallback() });

//#endregion

//#region Settings pages

export const GeneralSettingsPageLaze = loadable(() => import("./pages/business/settings/general/GeneralSettingsPage"), { fallback: LoadingFallback() });

export const SettingsNavigationLazy = loadable(() => import('./pages/business/settings/SettingsNavigation'), { fallback: LoadingFallback() });

export const SettingsBackupPageLazy = loadable(() => import('./pages/business/settings/backup/BackupSettingsPage'), { fallback: LoadingFallback() });

export const LogsSettingsPageLazy = loadable(() => import('./pages/business/settings/LogsSettingsPage'), { fallback: LoadingFallback() });

export const NotificationsSettingsPageLazy = loadable(() => import('./pages/business/settings/NotificationsSettingsPage'), { fallback: LoadingFallback() });

export const WatchdogServiceSettingsPageLazy = loadable(() => import('./pages/business/settings/WatchdogServiceSettingsPage'), { fallback: LoadingFallback() });

export const GeoRasterSettingsPageLazy = loadable(() => import('./pages/business/settings/GeoRasterSettingsPage'), { fallback: LoadingFallback() });

//#endregion

//#region Invoice and statistic

export const GlobalInvoicePageLazy = loadable(() => import('./pages/business/statistics/GlobalInvoicePage'), { fallback: LoadingFallback() });

export const GlobalNotificationsStatisticPageLazy = loadable(() => import('./pages/business/statistics/GlobalNotificationsStatisticPage'), { fallback: LoadingFallback() });

export const GlobalProjectsHistoryPageLazy = loadable(() => import('./pages/business/statistics/GlobalProjectsHistoryPage'), { fallback: LoadingFallback() });

export const GlobalStatisticsPageLazy = loadable(() => import('./pages/business/statistics/GlobalStatisticsPage'), { fallback: LoadingFallback() });

export const GlobalInventoryNavigationLazy = loadable(() => import('./components/navigation/GlobalInventoryNavigation'), { fallback: LoadingFallback() });

export const GlobalStatisticsNavigationLazy = loadable(() => import('./components/navigation/GlobalStatisticNavigation'), { fallback: LoadingFallback() });

//#endregion

//#endregion Change password pages

export const UserProfileChangePasswordPageLazy = loadable(() => import('./pages/business/user/UserProfile.ChangePasswordPage'), { fallback: LoadingFallback() });

export const ForgotPasswordConfirmationPageLazy = loadable(() => import('./pages/login/ForgotPasswordConfirmation'), { fallback: LoadingFallback() });

export const ForgotPasswordPageLazy = loadable(() => import('./pages/login/ForgotPasswordPage'), { fallback: LoadingFallback() });

export const ResetPasswordPageLazy = loadable(() => import('./pages/login/ResetPasswordPage'), { fallback: LoadingFallback() });

//#endregion

// #region

export const SyncTimeslotsNavigationLazy = loadable(() => import("./pages/business/syncTimeslots/SyncTimeslotsNavigation"), { fallback: LoadingFallback() });

export const SyncTimeslotsProjectInfoPageLazy = loadable(() => import('./pages/business/syncTimeslots/SyncTimeslotsProjectInfoPage'), { fallback: LoadingFallback() });

export const SyncTimeslotOverviewPageLazy = loadable(() => import('./pages/business/syncTimeslots/SyncTimeslotsOverviewPage'), { fallback: LoadingFallback() });

export const SyncTimeslotProcessingQueuePageLazy = loadable(() => import("./pages/business/syncTimeslots/processingQueue/SyncTimeslotsProcessingQueuePage"), { fallback: LoadingFallback() });

// #endregion

// #region

export const MaintenanceServicePageLazy = loadable(() => import("./pages/business/settings/maintenanceService/MaintenanceServicePage"));

export const SyncTimeslotsServiceSettingsPageLazy = loadable(() => import('./pages/business/syncTimeslots/SyncTimeslotsServiceSettingsPage'), { fallback: LoadingFallback() });

export const SyncTimeslotsSyncRequestsPageLazy = loadable(() => import('./pages/business/syncTimeslots/syncRequests/SyncRequestsPage'), { fallback: LoadingFallback() });

// #endregion

// #region

export const ErrorPageNavigationLazy = loadable(() => import('./pages/GeovisErrorSamplePage'), { fallback: LoadingFallback() });

// #endregion

// #region

export const TasksManagerNavigationLazy = loadable(() => import('./pages/business/settings/tasksManager/TasksManagerNavigation'), { fallback: LoadingFallback() });

export const VsComputationTasksPageLazy = loadable(() => import('./pages/business/settings/tasksManager/VsComputationTasksPage'), { fallback: LoadingFallback() });

// #endregion