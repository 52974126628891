/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 14.11.2022
 * @description Geovis4Table report renderer
 */

import React from 'react';
import { connect } from 'react-redux';
import { t } from '../../../../../i18n';
import { Geovis4TableData } from '../../../../../server/AVTService/TypeLibrary/Computation/Geovis4TableData';
import { GeovisReportElementInfo } from '../../../../../server/AVTService/TypeLibrary/Model/Reports/GeovisReportElementInfo';
import { IGeovis4TableReportInfo } from '../../../../../store/projectReport.types';
import { IGeovisStoreState } from '../../../../../store/store.types';
import { ISomethingStorageBaseEx } from '../../../../../store/types';
import { LoadingPageErrorSkeleton } from '../../../../LoadingPageErrorSkeleton';
import { LoadingPageSkeleton } from '../../../../LoadingPageSkeleton';
import { ChartLoadingSkeleton } from '../charts/renders/ChartLoadingComponent';
import { IReportElementRenderOwnProps } from '../types';
import { Geovis4TableHotRenderLazy } from './Geovis4TableHotRenderLazy';
import { getGeovis4TableReportData, getGeovis4TableReportInfo, mergeGeovis4TableData } from './renderTools';
import { HotTableRenderMode } from './renderTypes';
import { IWithGeovisServerProps, withGeovisServer } from '../../../../../helpers/GeovisHooks';
import ServerRoutesGen from '../../../../../server/Routes/ServerRoutesGen';
import FlagService from '../../../../../services/FlagService';

interface IStateToProps {
    tableInfo: IGeovis4TableReportInfo | undefined;
    tableData: ISomethingStorageBaseEx<Geovis4TableData>;
}

interface IOwnProps extends Omit<IReportElementRenderOwnProps, 'isPrinting' | 'isDefault' | 'isVibrationEventChart' | 'eventInfo' | 'showVibrationEventChartOnPage'> {
    elementInfo: GeovisReportElementInfo;
    renderMode: HotTableRenderMode;
    reportPagesCount: number;
    isLastReportPage: boolean;
    pageNum: number;
}

interface IComponentProps extends IStateToProps, IOwnProps, IWithGeovisServerProps { }

export const Component = ({ renderMode, Server, reportPagesCount, isLastReportPage, tableData, tableInfo, pageNum, elementInfo }: IComponentProps) => {
    const { isLoading, isError, errorDescription, data } = tableData;

    if (!tableInfo) {
        return (<ChartLoadingSkeleton text={t("Initializing table data")} />);
    }

    const { Table } = tableInfo;

    if (isLoading) {
        return (<ChartLoadingSkeleton text={t("Loading table data...")} />);
    }

    if (isError) {
        if (errorDescription && errorDescription.startsWith("Request was canceled")) {
            return (<ChartLoadingSkeleton text={t("Loading table data...")} />); // there is no need to show an error message in case of cancelling a request
        }
        return (<LoadingPageErrorSkeleton errorText={t("Error load table data")} errorDescription={errorDescription} />);
    }

    const destinationTable = mergeGeovis4TableData(Table, data);

    /**
     * Do export to excel of this Geovis4Table
     */
    const onExportToExcel = async () => {
        try {

            const { Id, ProjectId } = Table;
            const fileUrl = ServerRoutesGen.ReportRenderData.GetExcelFromTableData.patch(ProjectId, Id).path;

            await Server.downloadFilePost(fileUrl, data, `${data.Title}Report.xlsx`);
        }
        catch (error) {
            FlagService.addError("Error while exporting report table", error);
        }
    }

    return (
        <React.Suspense fallback={<LoadingPageSkeleton />}>
            <Geovis4TableHotRenderLazy
                renderMode={renderMode}
                table={destinationTable}
                reportPageNumber={pageNum}
                reportPagesCount={reportPagesCount}
                isLastReportPage={isLastReportPage}
                onExportToExcel={onExportToExcel}
                showTitle={!elementInfo.HideTitle}
            />
        </React.Suspense>
    );
};

const mapStateToProps = ({ projectReport }: IGeovisStoreState, { elementInfo, pageNum }: IOwnProps): IStateToProps => ({
    tableInfo: getGeovis4TableReportInfo(projectReport.geovisReportSettings, pageNum, elementInfo.Id),
    tableData: getGeovis4TableReportData(projectReport.geovisReportSettings, pageNum, elementInfo.Id),
});

export const Geovis4TableReportRenderView = withGeovisServer(Component);
export default connect<IStateToProps, never, IOwnProps>(mapStateToProps)(Geovis4TableReportRenderView);