/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 16.08.2023
 * @description Select sensors table in right panel
 */

import { colors } from "@atlaskit/theme";
import DynamicTable from "@atlaskit/dynamic-table";
import { HeadType, RowType } from "@atlaskit/dynamic-table/types";
import { CSSProperties } from "react";
import { t } from "../../i18n";
import { ImageSensorModel } from "../../server/ImageSensorModel"
import { IChainsInfoStorage, ISensorsInfoStorage } from "../../store/data.types";
import { isEmptyLocation2D } from "../../helpers/MapHelper";
import { mapToListOfElementsOfIds } from "../../helpers/StorageHelper";
import { SensorInfo } from "../../server/GEOvis3/Model/SensorInfo";
import { ChainInfo } from "../../server/ChainInfo";

interface IComponentProps {

    sensors: ImageSensorModel[];
    chains: ImageSensorModel[];

    selectedChainId: string;
    selectedSensorId: string;

    sensorsInfoStorage: ISensorsInfoStorage;
    chainsInfoStorage: IChainsInfoStorage;

    onSelectChain: (chainId: string) => void;
    onSelectSensor: (sensorId: string) => void;
}

const sensorsWithoutCoordinatesTableHead = (): HeadType => ({
    cells: [{
        key: 'sensorName',
        content: t("Sensor"),
        isSortable: true
    }]
});

const chainsWithoutCoordinatesTableHead = (): HeadType => ({
    cells: [{
        key: 'sensorName',
        content: t("Chain"),
        isSortable: true
    }]
});

const getSensorWithoutCoordinatesRowStyle = (sensor: SensorInfo, selectedSensorId: string): CSSProperties | undefined => {
    if (sensor.Id === selectedSensorId) {
        return { backgroundColor: colors.B100 };
    }

    return undefined;
};

const getChainWithoutCoordinatesRowStyle = (chain: ChainInfo, selectedChainId: string): CSSProperties | undefined => {
    if (chain.Id === selectedChainId) {
        return { backgroundColor: colors.B100 };
    }

    return undefined;
}

export const ImageMapSelectSensorTable = ({
    chains,
    sensors,
    chainsInfoStorage,
    sensorsInfoStorage,
    onSelectChain,
    onSelectSensor,
    selectedChainId,
    selectedSensorId
}: IComponentProps) => {

    /**
    * Get sensors list
    * @returns 
    */
    const sensorsWithoutCoordinatesTableRows = (): RowType[] => {
        const sensorsInfo = mapToListOfElementsOfIds(sensorsInfoStorage.sensorsInfo, sensors.filter(s => isEmptyLocation2D(s.RelativeLocation)).map(s => s.FullId));

        return sensorsInfo.map<RowType>(s => ({
            key: `row-${s.Id}`,
            style: getSensorWithoutCoordinatesRowStyle(s, selectedSensorId),
            cells: [{
                key: s.Name,
                content: s.Name
            }],
            onClick: () => onSelectSensor(s.Id)
        }));
    }
    /**
    * Get chains list
    * @returns 
    */
    const chainsWithoutCoordinatesTableRows = (): RowType[] => {
        const chainsInfo = mapToListOfElementsOfIds(chainsInfoStorage.chainsInfo, chains.filter(c => isEmptyLocation2D(c.RelativeLocation)).map(c => c.FullId));

        return chainsInfo.map<RowType>(chain => ({
            key: `row-${chain.Id}`,
            style: getChainWithoutCoordinatesRowStyle(chain, selectedChainId),
            cells: [{
                key: chain.Name,
                content: chain.Name
            }],
            onClick: () => onSelectChain(chain.Id)
        }));
    }

    return (
        <div className="flexCellContainer" style={{ minWidth: '20%' }}>
            <DynamicTable
                head={sensorsWithoutCoordinatesTableHead()}
                rows={sensorsWithoutCoordinatesTableRows()}
            />
            <DynamicTable
                head={chainsWithoutCoordinatesTableHead()}
                rows={chainsWithoutCoordinatesTableRows()}
            />
        </div>
    )
}
