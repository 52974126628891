import TextArea from "@atlaskit/textarea";
import React, { SyntheticEvent } from "react";
import { OptionType } from "@atlaskit/select";
import { t } from '../../../i18n';
import { ChartType } from "../../../server/AVTService/TypeLibrary/Common/ChartType";
import { GeovisChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartModel";
import {
    ChartBooleanSettingProps,
    ChartSettingTooltip,
    ChartTextSetting,
    CustomerChangeableChartBooleanSetting
} from "./ChartTab_tools"
import { compareChartTypes, compareNotChartTypes, onChartPropertyChangedFunc } from "./tools";
import {
    defaultChartLabelColumnStyle,
    defaultChartRowSettingsStyle,
    IGeovisChartPropertyChangedProps
} from "./types"
import { GeovisSelect } from "../../select/GeovisSelect";
import { ChartAlarmLineTypeList, getChartAlarmLineTypeToDescription } from "../../../server/AVTService/TypeLibrary/Common/ChartAlarmLineType";
import { GeovisChartAxisSettings } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartAxisSettings";
import { SemiCircleChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/SemiCircleChartModel";
import { SemiCircleType } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/SemiCircleType";

type IComponentProps = IGeovisChartPropertyChangedProps<GeovisChartModel>

export const ChartOptionsTab = ({ chart, onChartPropertyChanged }: IComponentProps) => {

    const labelColumnStyle = defaultChartLabelColumnStyle;
    const settingRowStyle = defaultChartRowSettingsStyle;

    const onChangeFunc = onChartPropertyChangedFunc(onChartPropertyChanged);

    const onCustomTooltipChanged = (event: SyntheticEvent<HTMLTextAreaElement>) => {
        onChangeFunc("CustomTooltip")(event.currentTarget.value);
    }

    const legendTextInfo = () => (
        <div>
            <div>{"<SensorName>"}</div>
            <div>{"<CustomerName>"}</div>
            <div>{"<SensorLastValue>"}</div>
            <div>{"<Km>"}</div>
            <div>{"<Param{1-5}>"}</div>
        </div>
    )

    const customTooltipInfo = () => (
        <div>
            <div>{"<Km>"}</div>
            <div>{"<Param{1-5}>"}</div>
            <div>{"<Reference>"}</div>
            <div>{"<AxisReference>"}</div>
            <div>{"<Offset>"}</div>
            <div>{"<AxisOffset>"}</div>
        </div>
    )

    const commonTooltipContent = () => (
        <div>
            <div>{t("Additional sensor's attributes data will not be displayed if this option is selected")}</div>
        </div>
    )

    const onChangeBooleanChartValue = (propertyName: keyof GeovisChartModel, invert?: boolean) => (value: boolean) => {
        onChartPropertyChanged(propertyName, invert ? !value : value);
    }

    const getLineStyleOptions = (): OptionType[] => {
        return ChartAlarmLineTypeList.map(c => ({ label: getChartAlarmLineTypeToDescription(c), value: c }));
    }

    const getSelectedLienStyle = (isLeftAxis: boolean): OptionType => {
        if (isLeftAxis) {
            const option = getLineStyleOptions().find(o => o.value === chart.LeftYAxisSettings.LineStyle);
            if (option) {
                return option;
            }
        } else {
            const option = getLineStyleOptions().find(o => o.value === chart.RightYAxisSettings.LineStyle);
            if (option) {
                return option;
            }
        }

        return getLineStyleOptions()[0];
    }

    const onAxisLineWeightPropertyChangedFunc = (isLeftAxis: boolean) => (value: OptionType) => {
        const newAxisSettings: GeovisChartAxisSettings = isLeftAxis
            ? { ...chart.LeftYAxisSettings, LineWeight: +value.value }
            : { ...chart.RightYAxisSettings, LineWeight: +value.value };
        onChartPropertyChanged(isLeftAxis ? "LeftYAxisSettings" : "RightYAxisSettings", newAxisSettings);
    }

    const getLineWidthOptions = (): OptionType[] => {
        const result: OptionType[] = [];

        result.push({ label: '0.5', value: 0.5 });
        result.push({ label: '1', value: 1 });
        result.push({ label: '1.5', value: 1.5 });
        result.push({ label: '2', value: 2 });
        result.push({ label: '2.5', value: 2.5 });
        result.push({ label: '3', value: 3 });

        return result;
    }

    const getSelectedLineWidth = (isLeftAxis: boolean): OptionType => {
        const availableOptions = getLineWidthOptions();
        if (isLeftAxis) {
            const option = availableOptions.find(o => o.value === chart.LeftYAxisSettings.LineWeight);
            if (option) {
                return option;
            }
        } else {
            const option = availableOptions.find(o => o.value === chart.RightYAxisSettings.LineWeight);
            if (option) {
                return option;
            }
        }

        return availableOptions[0];
    }

    const onChangeLineStyle = (isLeftAxis: boolean) => (option: OptionType) => {
        const newAxisSettings: GeovisChartAxisSettings = isLeftAxis
            ? { ...chart.LeftYAxisSettings, LineStyle: +option.value }
            : { ...chart.RightYAxisSettings, LineStyle: +option.value };
        onChartPropertyChanged(isLeftAxis ? "LeftYAxisSettings" : "RightYAxisSettings", newAxisSettings);
    }

    const isSemiCircleGrouping = (): boolean => {
        if (chart.Type !== ChartType.SemiCircle) {
            return false;
        }
        const semiChart = chart as SemiCircleChartModel;
        return semiChart.SemiCircleType === SemiCircleType.Grouping;
    }

    const isSemiCircleMeas = (): boolean => {
        if (chart.Type !== ChartType.SemiCircle) {
            return false;
        }
        const semiChart = chart as SemiCircleChartModel;
        return semiChart.SemiCircleType === SemiCircleType.SuccessfulMeasurementRate;
    }

    return (
        <React.Fragment>
            {compareNotChartTypes(chart.Type, ChartType.HeatMap, ChartType.SemiCircle) && (
                <React.Fragment>
                    <CustomerChangeableChartBooleanSetting
                        setting={chart.DrawLineBetweenPoints}
                        settingLabel={t("Draw line between points")}
                        onChange={onChangeFunc("DrawLineBetweenPoints")}
                        isDisabled={false}
                    />

                    {compareChartTypes(chart.Type, ChartType.Inclinometer) &&
                        <React.Fragment>
                            <div className="flexRowContainerLine" style={settingRowStyle}>
                                <div style={labelColumnStyle}>
                                    <label htmlFor="line-width">{t("Line weight")}:</label>
                                </div>
                                <div className="flexCellContainer_g1_m0">
                                    <GeovisSelect
                                        id="line-width"
                                        spacing="compact"
                                        options={getLineWidthOptions()}
                                        value={getSelectedLineWidth(true)}
                                        isDisabled={!chart.DrawLineBetweenPoints.value}
                                        onChange={onAxisLineWeightPropertyChangedFunc(true)} />
                                </div>
                            </div>
                            <div className="flexRowContainerLine" style={settingRowStyle}>
                                <div style={labelColumnStyle}>
                                    <label htmlFor="cmbLSL">{t("Line style")}:</label>
                                </div>
                                <div className="flexCellContainer_g1_m0">
                                    <GeovisSelect
                                        id="cmbLSL"
                                        options={getLineStyleOptions()}
                                        value={getSelectedLienStyle(true)}
                                        onChange={onChangeLineStyle(true)}
                                        isDisabled={!chart.DrawLineBetweenPoints.value}
                                        isCompact={true}
                                        spacing='compact'
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    }

                    <CustomerChangeableChartBooleanSetting
                        setting={chart.ShowLegend}
                        settingLabel={t("Show legend")}
                        onChange={onChangeFunc("ShowLegend")}
                        isDisabled={false} />
                </React.Fragment>
            )}

            {compareNotChartTypes(chart.Type, ChartType.DtsChart, ChartType.HeatMap, ChartType.SemiCircle) && (
                <ChartTextSetting
                    labelColumnStyle={labelColumnStyle}
                    labelText={t("Legend text")}
                    value={chart.LegendText}
                    htmlName="tbLegendText"
                    tooltipContent={legendTextInfo()}
                    onChangeTextField={onChangeFunc("LegendText")} />
            )}

            {compareNotChartTypes(chart.Type, ChartType.Inclinometer, ChartType.SemiCircle) && (
                <ChartBooleanSettingProps
                    label={t("Disable mouse zoom")}
                    isChecked={!chart.IsChartZoomAllow}
                    onChange={onChangeBooleanChartValue("IsChartZoomAllow", true)}
                    isDisabled={false} />
            )}

            <ChartBooleanSettingProps
                label={t("Show start of measurements")}
                isChecked={chart.ShowStartMeasurements}
                onChange={onChangeFunc("ShowStartMeasurements")}
                isDisabled={false} />

            {compareNotChartTypes(chart.Type, ...[ChartType.HeatMap, ChartType.Inclinometer, ChartType.SemiCircle]) && (
                <CustomerChangeableChartBooleanSetting
                    settingLabel={t("Show all data in single tooltip")}
                    setting={chart.ShowCommonTooltip}
                    onChange={onChangeFunc("ShowCommonTooltip")}
                    isDisabled={false}
                    // infoText={t("Additional sensor's attributes data will not be displayed if this option is selected")}
                    tooltipContent={commonTooltipContent()}
                />
            )}

            {compareNotChartTypes(chart.Type, ChartType.Inclinometer) && !isSemiCircleGrouping() && !isSemiCircleMeas() && (
                <ChartBooleanSettingProps
                    label={t("showCustomTooltipLabel")}
                    isChecked={chart.ShowCustomTooltip}
                    onChange={onChangeFunc("ShowCustomTooltip")}
                    isDisabled={chart.ShowCommonTooltip.value} />
            )}

            {compareNotChartTypes(chart.Type, ChartType.Inclinometer) && !isSemiCircleGrouping() && !isSemiCircleMeas() &&
                <div className="flexRowContainerLine">
                    <div className="flexCellContainer_g1_m0">
                        <TextArea
                            name="tbCustomTooltip"
                            isCompact={true}
                            isDisabled={chart.ShowCommonTooltip.value}
                            defaultValue={chart.CustomTooltip}
                            maxHeight="250px"
                            onChange={onCustomTooltipChanged} />
                    </div>
                    <div className="flexCellContainer">
                        <ChartSettingTooltip content={customTooltipInfo()} />
                    </div>
                </div>
            }
        </React.Fragment>
    );
}