//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum SemiCircleType {
	SensorValues = 0,
	Grouping = 1,
	SuccessfulMeasurementRate = 2
}

const SemiCircleTypeToName = new Map<number, string>([
	[ 0, 'SensorValues' ],
	[ 1, 'Grouping' ],
	[ 2, 'SuccessfulMeasurementRate' ],
])

export function getSemiCircleTypeToName(element: SemiCircleType) : string {
    return SemiCircleTypeToName.get(element) || '';
}

const SemiCircleTypeByName = new Map<string, number>([
	[ 'SensorValues', 0 ],
	[ 'Grouping', 1 ],
	[ 'SuccessfulMeasurementRate', 2 ],
]);

export function getSemiCircleTypeByName(name: string): SemiCircleType {
    return SemiCircleTypeByName.get(name)!;
}


export const SemiCircleTypeList : Readonly<SemiCircleType[]> = [
	SemiCircleType.SensorValues,
	SemiCircleType.Grouping,
	SemiCircleType.SuccessfulMeasurementRate,
];



const SemiCircleTypeToDescription = new Map<number, string>([
	[ 0, 'Sensors values' ],
	[ 1, 'Groups' ],
	[ 2, 'Successful measurement rate' ],
])

export function getSemiCircleTypeToDescription(element: SemiCircleType) : string {
    return SemiCircleTypeToDescription.get(element) || '';
}

