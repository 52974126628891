/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 06.05.2022
 * @description Edit heatmap color temperature scheme
 */

import Button from '@atlaskit/button';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { DynamicTableStateless } from '@atlaskit/dynamic-table';
import { HeadType, RowType } from '@atlaskit/dynamic-table/types';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/glyph/chevron-up';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import EditorRemoveIcon from '@atlaskit/icon/glyph/editor/remove';
import Textfield from '@atlaskit/textfield';
import { SyntheticEvent } from 'react';
import { Guid } from 'guid-typescript';
import { t } from '../../../../i18n';
import { HeatmapTemperatureColorEntry } from '../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/HeatmapTemperatureColorEntry';
import { HeatmapTemperatureColorSettings } from '../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/HeatmapTemperatureColorSettings';
import { getPhysicalUnitShortName, PhysicalUnit } from '../../../../server/AVTService/TypeLibrary/Sensors/PhysicalUnit';
import GeovisColorPicker from '../../../GeovisColorPicker';
import { getHeatmapDefaultPalette } from '../../reportOverlay/reportRender/charts/options/heatmapChartRenderOptions';
import { ChartSettingCheckbox } from '../ChartTab_tools';
import { onChangeTextPropertyDebounced } from '../tools';
import { defaultChartRowSettingsStyle } from '../types';

interface IHeatmapColorTemperatureSchemeProps {
    minValue: number,
    maxValue: number,
    colorSettings: HeatmapTemperatureColorSettings,
    unit: PhysicalUnit,
    onPropertyChanged: (colorSettings: HeatmapTemperatureColorSettings) => void;
}

export const HeatmapColorTemperatureSchemeControl = ({ colorSettings, maxValue, minValue, unit, onPropertyChanged }: IHeatmapColorTemperatureSchemeProps) => {

    const unitShortName = getPhysicalUnitShortName(unit);

    const onPropertyChangedFunc = (propertyName: keyof HeatmapTemperatureColorSettings) => (value: any) => {
        const update: Partial<HeatmapTemperatureColorSettings> = {};
        update[propertyName] = value;

        onPropertyChanged({ ...colorSettings, ...update });
    }

    const onTemperaturePropertyChangedFunc = (index: number, propertyName: keyof HeatmapTemperatureColorEntry) => (event: SyntheticEvent<HTMLInputElement>) => {
        onChangeTextPropertyDebounced(event.currentTarget.value, onTemperaturePropertyChanged(index, propertyName));
    }

    const onTemperaturePropertyChanged = (index: number, propertyName: keyof HeatmapTemperatureColorEntry) => (value: any) => {

        const entries = colorSettings.ColorEntries;
        if (entries.length === 0 || entries.length <= index) {
            return; // wrong index there or no any elements
        }

        const entryUpdate: Partial<HeatmapTemperatureColorEntry> = {};
        entryUpdate[propertyName] = value;

        const updatedEntries = entries.map<HeatmapTemperatureColorEntry>((source, sourceIndex) => {
            if (index === sourceIndex) {
                return ({ ...source, ...entryUpdate });
            }

            return source;
        });

        onPropertyChangedFunc("ColorEntries")(updatedEntries);
    }

    const onRemoveColorEntryFunc = (index: number) => () => {
        const entries = colorSettings.ColorEntries.filter((_, entryIndex) => entryIndex !== index);
        onPropertyChangedFunc("ColorEntries")(entries);
    }

    const onAddNewColorLine = () => {
        const entries: HeatmapTemperatureColorEntry[] = [...colorSettings.ColorEntries, { ColorHex: '#f00', Temperature: maxValue, Id: Guid.create().toString() }];
        onPropertyChangedFunc("ColorEntries")(entries);
    }

    const onSetDefaultPalette = () => onPropertyChangedFunc("ColorEntries")(getHeatmapDefaultPalette(minValue, maxValue));

    const onMoveTemperatureLineUpFunc = (index: number) => () => {
        if (index <= 0) {
            return;
        }

        const row = colorSettings.ColorEntries[index];
        const prevRow = colorSettings.ColorEntries[index - 1];

        const result: HeatmapTemperatureColorEntry[] = [...colorSettings.ColorEntries];
        result[index - 1] = row;
        result[index] = prevRow;

        onPropertyChangedFunc("ColorEntries")(result);
    }

    const onMoveTemperatureLineDownFunc = (index: number) => () => {
        if (index >= colorSettings.ColorEntries.length) {
            return;
        }

        const row = colorSettings.ColorEntries[index];
        const nextRow = colorSettings.ColorEntries[index + 1];

        const result: HeatmapTemperatureColorEntry[] = [...colorSettings.ColorEntries];
        result[index] = nextRow;
        result[index + 1] = row;

        onPropertyChangedFunc("ColorEntries")(result);
    }

    const isCustom = colorSettings.EnableCustomColorScheme;

    const tableHead = (): HeadType => ({
        cells: [{
            content: (
                <div style={{ marginTop: '6px' }}>
                    {t("Value")}
                </div>
            )
        }, {
            content: (
                <div style={{ marginTop: '5px' }}>
                    {t("Color")}
                </div>
            )
        }, {
            content: (
                <div className='flexRowContainerLine'>
                    <Button
                        iconBefore={<EditorAddIcon label='add row' />}
                        appearance='subtle'
                        onClick={onAddNewColorLine}
                        isDisabled={!isCustom}
                    />

                    <DropdownMenu
                        triggerType='button'
                        triggerButtonProps={{
                            style: { marginLeft: '1em' },
                            appearance: 'subtle',
                            iconBefore: <ChevronDownIcon label='more options' />,
                            isDisabled: !isCustom
                        }}>
                        <DropdownItemGroup>
                            <DropdownItem onClick={onSetDefaultPalette}>{t("Default palette")}</DropdownItem>
                        </DropdownItemGroup>
                    </DropdownMenu>
                </div>
            )
        }]
    });

    const tableRows = (): RowType[] => {


        const rows = colorSettings.ColorEntries.map<RowType>((ce, index) => ({
            key: `row-${index}-${ce.ColorHex}-${ce.Id}`,
            cells: [{
                content: (
                    <div className='flexRowContainerLine' style={{ width: '8em' }}>
                        <Textfield
                            defaultValue={ce.Temperature}
                            isCompact={true}
                            isDisabled={!isCustom}
                            onChange={onTemperaturePropertyChangedFunc(index, "Temperature")}
                        />
                        {unitShortName}
                        {/* &#x2103; */}
                    </div>
                )
            }, {
                content: (
                    <GeovisColorPicker
                        //hexColor={ce.ColorHex}
                        defaultHexColor={ce.ColorHex}
                        onColorChange={(hex: string, isPickerClosed: boolean) => {
                            if (isPickerClosed) {
                                onTemperaturePropertyChanged(index, "ColorHex")(hex)
                            }
                        }}
                    />
                )
            }, {
                content: (
                    <div className='flexRowContainerLine'>
                        <Button
                            appearance='subtle'
                            isDisabled={!isCustom}
                            iconBefore={<EditorRemoveIcon label='Remove entry' />}
                            onClick={onRemoveColorEntryFunc(index)}
                        />

                        <Button
                            style={{ marginLeft: '0.5em' }}
                            appearance='subtle'
                            iconBefore={<ChevronUpIcon label='up' size='medium' />}
                            onClick={onMoveTemperatureLineUpFunc(index)}
                            isDisabled={index === 0 || !isCustom}
                        />
                        <Button
                            appearance='subtle'
                            iconBefore={<ChevronDownIcon label='down' size='medium' />}
                            onClick={onMoveTemperatureLineDownFunc(index)}
                            isDisabled={index === colorSettings.ColorEntries.length - 1 || !isCustom}
                        />
                    </div>
                )
            }]
        }))

        return rows;
    }

    return (
        <div className="flexRowContainerLine" style={defaultChartRowSettingsStyle}>
            <div>
                <div className='flexRowContainerLine'>
                    <ChartSettingCheckbox
                        isChecked={colorSettings.EnableCustomColorScheme}
                        isDisabled={false}
                        label={t("Use custom color scheme")}
                        onChange={onPropertyChangedFunc("EnableCustomColorScheme")}
                    />
                </div>
                <div className='flexRowContainerLine'>
                    <DynamicTableStateless
                        head={tableHead()}
                        rows={tableRows()}
                    />
                </div>
                <div className='flexRowContainerLine'>
                    {/*  */}
                </div>
            </div>
        </div>
    )
}