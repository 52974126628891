import { Circle, CircleMarkerProps } from "react-leaflet";
import { locationToMapCoordinates } from "../../../helpers/MapHelper";
import { fixHref } from '../../../helpers/UrlHelper';
import { LocalMapObjectCircle } from "../../../server/LocalMapObjectCircle";
import { LocalMapObjectContentType } from "../../../server/LocalMapObjectContentType";
import { ILocalMapShapeOptions } from "../ILocalMapShapeOptions";
import { LocalMapObjectViewMode } from "../types";
import { LocalMapObjectPopup, LocalMapObjectTooltip } from "./LocalMapObjectMapElements";
import { getLocalMapObjectShapeClassName } from "./tools";

interface ILocalMapObjectCircleProps {
    element: LocalMapObjectCircle;
    viewMode: LocalMapObjectViewMode;
    isEditMode: boolean;

    onEdit: (id: string) => void;
    onRemove: (id: string) => void;
    onClick: (id: string) => void;
    onOpenFile: (documentId: string) => void;
    onOpenView: (viewId: string) => void;
    onOpenReport: (reportId: number) => void;
}

export const LocalMapObjectCircleShape = ({
    element,
    viewMode,
    onEdit,
    onRemove,
    onClick,
    onOpenFile: onDownloadFile,
    onOpenReport,
    onOpenView,
    isEditMode
}: ILocalMapObjectCircleProps) => {

    const shapeOptions: ILocalMapShapeOptions = {
        localMapObjectId: element.Id,
        localMapObjectType: element.ObjectType
    };

    const circleProps: CircleMarkerProps = {
        center: locationToMapCoordinates(element.Center),
        radius: element.Radius,
        stroke: true,
        weight: element.Weight,
        color: element.Color,
        opacity: 1 - (1 - element.Opacity) / 2,
        fillOpacity: element.Opacity,
        fill: element.Fill,
        className: getLocalMapObjectShapeClassName(viewMode)
    };

    const onClickHandler = (event: L.LeafletMouseEvent) => {
        if (viewMode === LocalMapObjectViewMode.View || event.originalEvent.ctrlKey) {
            if (element.ContentType === LocalMapObjectContentType.Link) {

                const href = fixHref(element.Link);
                window.open(href, "_blank");

                event.originalEvent.preventDefault();
                return;
            }
            else if (element.ContentType === LocalMapObjectContentType.Document) {
                onDownloadFile(element.DocumentId);
            }
            else if (element.ContentType === LocalMapObjectContentType.Report) {
                onOpenReport(element.ReportId);
            }
            else if (element.ContentType === LocalMapObjectContentType.View) {
                onOpenView(element.ViewId);
            }
            else {
                onClick(element.Id);
            }
        }
    }

    const getChildren = (): JSX.Element[] => {
        const result: JSX.Element[] = [];
        if (viewMode !== LocalMapObjectViewMode.MapSection) {
            result.push(<LocalMapObjectPopup key={`lmo-${element.Id}-popup-key`} element={element} onEdit={onEdit} onRemove={onRemove} editMode={viewMode === LocalMapObjectViewMode.Edit} dragMode={isEditMode} />)
        }
        result.push(<LocalMapObjectTooltip key={`lmo-${element.Id}-tooltip-key`} element={element} />);

        return result;
    }

    return (
        <Circle
            {...circleProps}
            {...shapeOptions}
            eventHandlers={viewMode === LocalMapObjectViewMode.MapSection ? undefined : {
                click: onClickHandler
            }}
            key={`lmo-c-${element.Weight}-${element.Color}-${element.Opacity}`}
        >
            {getChildren()}
        </Circle>

    )
}