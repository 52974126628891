import Modal, { ModalTransition, } from "@atlaskit/modal-dialog";
import { useState } from "react";
import { t } from "../../../../../i18n";
import { AlarmActionEditTabControl } from "./AlarmActionEditTabControl";
import { CompanyInfo } from "../../../../../server/GEOvis3/Model/Company/CompanyInfo";
import { AlarmCondition } from "../../../../../server/AVTService/TypeLibrary/Alarming/AlarmCondition";
import { GeovisAlarmActionModel } from "../../../../../server/AVTService/TypeLibrary/Alarming/GeovisAlarmActionModel";
import { AlarmActivationBlockModel } from "../../../../../server/GEOvis3/Model/Alarms/UsersPresets/AlarmActivationBlockModel";
import { AlarmActionUIType } from "../../../../../server/AVTService/TypeLibrary/Alarming/AlarmActionUIType";
import FlagService from "../../../../../services/FlagService";

interface IComponentProps {
    action: GeovisAlarmActionModel;
    isReadonly: boolean;
    isCreateMode: boolean;
    forConfirm: boolean;
    companies: CompanyInfo[];
    conditions: AlarmCondition[]
    isTemplate: boolean;
    onClose: () => void;
    onSave: (alarmAction: GeovisAlarmActionModel) => void;
    onChangeAlarmActivationPossibility: (config: AlarmActivationBlockModel) => void;
}

export const AlarmActionEditDialog = ({
    action,
    isReadonly,
    isCreateMode,
    onClose,
    onSave,
    forConfirm,
    companies,
    conditions,
    isTemplate,
    onChangeAlarmActivationPossibility
}: IComponentProps) => {

    const [state, setState] = useState<GeovisAlarmActionModel>(action);

    const onUpdateState = (propertyName: keyof GeovisAlarmActionModel, value: any) => {
        const update: Partial<GeovisAlarmActionModel> = {};
        update[propertyName] = value;
        setState({
            ...state,
            ...update
        })
    }

    const onUpdateFullState = (upd: GeovisAlarmActionModel) => {
        setState({
            ...upd
        })
    }

    const getHeaderText = (): string => {
        if (isReadonly) {
            return forConfirm ? t("Watch alarm confirmation") : t('Watch alarm action');
        }
        if (isCreateMode) {
            return forConfirm ? t("Create alarm confirmation") : t('Create alarm action');
        }

        return forConfirm ? t("Edit alarm confirmation") : t('Edit alarm action');
    }

    const onSaveActionHandler = () => {
        if (state.ActionType === AlarmActionUIType.Message && (!state.Subject)) {
            FlagService.addError(t("Failed to save alarm action"), t("Email/Sms action must have non-empty subject"));
            return;
        }

        if (state.ActionType === AlarmActionUIType.Message && (!state.Message)) {
            FlagService.addError(t("Failed to save alarm action"), t("Email/Sms action must have non-empty message"));
            return;
        }

        onSave(state);
    }

    const onCloseHandler = () => {
        onClose();
    }

    return (
        <ModalTransition>
            <Modal
                heading={getHeaderText()}
                autoFocus={false}
                width={'70%'}
                height={'800px'}
                actions={[
                    { text: isCreateMode ? t("Create") : t("Save"), onClick: onSaveActionHandler, appearance: 'primary', isDisabled: isReadonly },
                    { text: t("Cancel"), onClick: onCloseHandler },
                ]}
            >
                <div style={{ height: '100%' }}>
                    <AlarmActionEditTabControl
                        actionState={state}
                        forConfirm={forConfirm}
                        isReadonly={isReadonly}
                        updateAction={onUpdateState}
                        companies={companies}
                        conditions={conditions}
                        isTemplate={isTemplate}
                        updateFullAction={onUpdateFullState}
                        onChangeAlarmActivationPossibility={onChangeAlarmActivationPossibility}
                    />
                </div>

            </Modal>
        </ModalTransition>
    )
}