import { InclinometerChartModel } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/InclinometerChartModel";
import { PhysicalUnit } from "../../../../server/AVTService/TypeLibrary/Sensors/PhysicalUnit";
import { GeovisChartAlarmInfo } from "../../../../server/GEOvis3/Model/GeovisChart/GeovisChartAlarmInfo";
import { ChartAlarmLinesControl, IChartAlarmLinesControlActionProps } from "../ChartAlarmLinesControl";
import { ChartAlarmPlansControl, IChartAlarmPlansControlActionProps } from "../ChartAlarmPlansControl";
import { isChartTemplate } from "../tools";

interface IInclinometerChartAlarmTabProps extends IChartAlarmLinesControlActionProps, IChartAlarmPlansControlActionProps {
    chart: InclinometerChartModel;
    alarmPlansInfo: GeovisChartAlarmInfo[];
}

export const InclinometerChartAlarmTab = ({
    chart,
    alarmPlansInfo,
    onAlarmLineDeleteClickHandler,
    onChangeAlarmLines,
    onNewAlarmLinkClickHandler,
    onChangeLinkedAlarms
}: IInclinometerChartAlarmTabProps) => {

    const { AlarmLines } = chart;

    return (
        <div className="geovisChartTab">
            <div className="flexRowContainerLine">
                <ChartAlarmLinesControl
                    AlarmLines={AlarmLines}
                    chartType={chart.Type}
                    onAlarmLineDeleteClickHandler={onAlarmLineDeleteClickHandler}
                    onChangeAlarmLines={onChangeAlarmLines}
                    onNewAlarmLinkClickHandler={onNewAlarmLinkClickHandler}
                    yUnit={PhysicalUnit.Meter}
                    xUnit={chart.LeftYAxisSettings.Unit}
                />
            </div>
            {!isChartTemplate(chart) &&
                <div className="flexRowContainerLine">
                    <ChartAlarmPlansControl
                        allAlarms={alarmPlansInfo || []}
                        onChangeLinkedAlarms={onChangeLinkedAlarms}
                        chart={chart}
                    />
                </div>
            }
        </div>
    )
}