//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum CoordinateSystems {
	WGS84 = 0,
	CH1903 = 1,
	CH1903plus = 2,
	DB_REF_GK3 = 3,
	EPSG_3857 = 4,
	EPSG_31466 = 5,
	EPSG_31467 = 6,
	EPSG_31468 = 7,
	EPSG_31469 = 8,
	EPSG_3949 = 9,
	DB_REF_GK2 = 10,
	DB_REF_GK4 = 11,
	DB_REF_GK5 = 12,
	EPSG_9932 = 13,
	EPSG_9933 = 14,
	EPSG_9934 = 15,
	EPSG_9935 = 16,
	EPSG_31276 = 17,
	EPSG_5950 = 18,
	EPSG_3908 = 19,
	EPSG_31266 = 20,
	ETRS_89 = 21
}

const CoordinateSystemsToName = new Map<number, string>([
	[ 0, 'WGS84' ],
	[ 1, 'CH1903' ],
	[ 2, 'CH1903plus' ],
	[ 3, 'DB_REF_GK3' ],
	[ 4, 'EPSG_3857' ],
	[ 5, 'EPSG_31466' ],
	[ 6, 'EPSG_31467' ],
	[ 7, 'EPSG_31468' ],
	[ 8, 'EPSG_31469' ],
	[ 9, 'EPSG_3949' ],
	[ 10, 'DB_REF_GK2' ],
	[ 11, 'DB_REF_GK4' ],
	[ 12, 'DB_REF_GK5' ],
	[ 13, 'EPSG_9932' ],
	[ 14, 'EPSG_9933' ],
	[ 15, 'EPSG_9934' ],
	[ 16, 'EPSG_9935' ],
	[ 17, 'EPSG_31276' ],
	[ 18, 'EPSG_5950' ],
	[ 19, 'EPSG_3908' ],
	[ 20, 'EPSG_31266' ],
	[ 21, 'ETRS_89' ],
])

export function getCoordinateSystemsToName(element: CoordinateSystems) : string {
    return CoordinateSystemsToName.get(element) || '';
}

const CoordinateSystemsByName = new Map<string, number>([
	[ 'WGS84', 0 ],
	[ 'CH1903', 1 ],
	[ 'CH1903plus', 2 ],
	[ 'DB_REF_GK3', 3 ],
	[ 'EPSG_3857', 4 ],
	[ 'EPSG_31466', 5 ],
	[ 'EPSG_31467', 6 ],
	[ 'EPSG_31468', 7 ],
	[ 'EPSG_31469', 8 ],
	[ 'EPSG_3949', 9 ],
	[ 'DB_REF_GK2', 10 ],
	[ 'DB_REF_GK4', 11 ],
	[ 'DB_REF_GK5', 12 ],
	[ 'EPSG_9932', 13 ],
	[ 'EPSG_9933', 14 ],
	[ 'EPSG_9934', 15 ],
	[ 'EPSG_9935', 16 ],
	[ 'EPSG_31276', 17 ],
	[ 'EPSG_5950', 18 ],
	[ 'EPSG_3908', 19 ],
	[ 'EPSG_31266', 20 ],
	[ 'ETRS_89', 21 ],
]);

export function getCoordinateSystemsByName(name: string): CoordinateSystems {
    return CoordinateSystemsByName.get(name)!;
}

export const CoordinateSystemsList : Readonly<CoordinateSystems[]> = [
    CoordinateSystems.CH1903plus,
    CoordinateSystems.CH1903,
    CoordinateSystems.WGS84,
    CoordinateSystems.EPSG_3857,
    CoordinateSystems.DB_REF_GK2,
    CoordinateSystems.DB_REF_GK3,
    CoordinateSystems.DB_REF_GK4,
    CoordinateSystems.DB_REF_GK5,
    CoordinateSystems.EPSG_31466,
    CoordinateSystems.EPSG_31467,
    CoordinateSystems.EPSG_31468,
    CoordinateSystems.EPSG_31469,
    CoordinateSystems.EPSG_3949,
    CoordinateSystems.EPSG_9932,
    CoordinateSystems.EPSG_9933,
    CoordinateSystems.EPSG_9934,
    CoordinateSystems.EPSG_9935,
    CoordinateSystems.EPSG_31276,
    CoordinateSystems.EPSG_5950,
    CoordinateSystems.EPSG_3908,
    CoordinateSystems.EPSG_31266,
    CoordinateSystems.ETRS_89,
];


const CoordinateSystemsToDescription = new Map<number, string>([
	[ 0, 'WGS84 GPS (EPSG:4326)' ],
	[ 1, 'LV03 (EPSG:21781)' ],
	[ 2, 'LV95 (EPSG:2056)' ],
	[ 3, 'DB_REF_GK3 (EPSG:5683)' ],
	[ 4, 'WGS84 Pseudo Mercator (EPSG:3857)' ],
	[ 5, '3-deg. Gauss-Kruger z.2 (EPSG:31466)' ],
	[ 6, '3-deg. Gauss-Kruger z.3 (EPSG:31467)' ],
	[ 7, '3-deg. Gauss-Kruger z.4 (EPSG:31468)' ],
	[ 8, '3-deg. Gauss-Kruger z.5 (EPSG:31469)' ],
	[ 9, 'RGF93 / CC49 (EPSG:3949)' ],
	[ 10, 'DB_REF_GK2 (EPSG: 5682)' ],
	[ 11, 'DB_REF_GK4 (EPSG: 5684)' ],
	[ 12, 'DB_REF_GK5 (EPSG: 5685)' ],
	[ 13, 'DB_REF2016 zone 2 (EPSG: 9932)' ],
	[ 14, 'DB_REF2016 zone 3 (EPSG: 9933)' ],
	[ 15, 'DB_REF2016 zone 4 (EPSG: 9934)' ],
	[ 16, 'DB_REF2016 zone 5 (EPSG: 9935)' ],
	[ 17, 'MGI/Balcans zone 6 (EPSG: 31276)' ],
	[ 18, 'NTM zone 10 + NN2000 height (EPSG: 5950)' ],
	[ 19, 'MGI 1901/Balkans zone 6 (EPSG: 3908)' ],
	[ 20, 'MGI/3-degree Gauss zone 6 (EPSG: 31266)' ],
	[ 21, 'Hamburg / Gauss-Krüger-LS 320 (ETRS 89)' ],
])

export function getCoordinateSystemsToDescription(element: CoordinateSystems) : string {
    return CoordinateSystemsToDescription.get(element) || '';
}


const CoordinateSystemsToGeovisDescription = new Map<number, string>([
	[ CoordinateSystems.WGS84, 'WGS84 GPS (EPSG:4326)' ],
	[ CoordinateSystems.CH1903, 'LV03 (EPSG:21781)' ],
	[ CoordinateSystems.CH1903plus, 'LV95 (EPSG:2056)' ],
	[ CoordinateSystems.DB_REF_GK3, 'DB_REF_GK3 (EPSG:5683)' ],
	[ CoordinateSystems.EPSG_3857, 'WGS84 Pseudo Mercator (EPSG:3857)' ],
	[ CoordinateSystems.EPSG_31466, '3-deg. Gauss-Kruger z.2 (EPSG:31466)' ],
	[ CoordinateSystems.EPSG_31467, '3-deg. Gauss-Kruger z.3 (EPSG:31467)' ],
	[ CoordinateSystems.EPSG_31468, '3-deg. Gauss-Kruger z.4 (EPSG:31468)' ],
	[ CoordinateSystems.EPSG_31469, '3-deg. Gauss-Kruger z.5 (EPSG:31469)' ],
	[ CoordinateSystems.EPSG_3949, 'RGF93 / CC49 (EPSG:3949)' ],
	[ CoordinateSystems.DB_REF_GK2, 'DB_REF_GK2 (EPSG: 5682)' ],
	[ CoordinateSystems.DB_REF_GK4, 'DB_REF_GK4 (EPSG: 5684)' ],
	[ CoordinateSystems.DB_REF_GK5, 'DB_REF_GK5 (EPSG: 5685)' ],
	[ CoordinateSystems.EPSG_9932, 'DB_REF2016 zone 2 (EPSG: 9932)' ],
	[ CoordinateSystems.EPSG_9933, 'DB_REF2016 zone 3 (EPSG: 9933)' ],
	[ CoordinateSystems.EPSG_9934, 'DB_REF2016 zone 4 (EPSG: 9934)' ],
	[ CoordinateSystems.EPSG_9935, 'DB_REF2016 zone 5 (EPSG: 9935)' ],
	[ CoordinateSystems.EPSG_31276, 'MGI/Balcans zone 6 (EPSG: 31276)' ],
	[ CoordinateSystems.EPSG_5950, 'NTM zone 10 + NN2000 height (EPSG: 5950)' ],
	[ CoordinateSystems.EPSG_3908, 'MGI 1901/Balkans zone 6 (EPSG: 3908)' ],
	[ CoordinateSystems.EPSG_31266, 'MGI/3-degree Gauss zone 6 (EPSG: 31266)' ],
	[ CoordinateSystems.ETRS_89, 'Hamburg / Gauss-Krüger-LS 320 (ETRS 89)' ]]);

export function getGeovisDescription(enumValue: CoordinateSystems): string {
    return CoordinateSystemsToGeovisDescription.get(enumValue) || '';
}


const CoordinateSystemsToOrder = new Map<number, number>([
	[ CoordinateSystems.WGS84, 2 ],
	[ CoordinateSystems.CH1903, 1 ],
	[ CoordinateSystems.CH1903plus, 0 ],
	[ CoordinateSystems.DB_REF_GK3, 5 ],
	[ CoordinateSystems.EPSG_3857, 3 ],
	[ CoordinateSystems.EPSG_31466, 8 ],
	[ CoordinateSystems.EPSG_31467, 9 ],
	[ CoordinateSystems.EPSG_31468, 10 ],
	[ CoordinateSystems.EPSG_31469, 11 ],
	[ CoordinateSystems.EPSG_3949, 12 ],
	[ CoordinateSystems.DB_REF_GK2, 4 ],
	[ CoordinateSystems.DB_REF_GK4, 6 ],
	[ CoordinateSystems.DB_REF_GK5, 7 ],
	[ CoordinateSystems.EPSG_9932, 13 ],
	[ CoordinateSystems.EPSG_9933, 14 ],
	[ CoordinateSystems.EPSG_9934, 15 ],
	[ CoordinateSystems.EPSG_9935, 16 ],
	[ CoordinateSystems.EPSG_31276, 17 ],
	[ CoordinateSystems.EPSG_5950, 18 ],
	[ CoordinateSystems.EPSG_3908, 19 ],
	[ CoordinateSystems.EPSG_31266, 20 ],
	[ CoordinateSystems.ETRS_89, 21 ]]);

export function getOrder(enumValue: CoordinateSystems): number {
    return CoordinateSystemsToOrder.get(enumValue) || 0;
}

