/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 21.04.2022
 * @description Heatmap values settings component
 */

import { t } from '../../../../i18n';
import { getSensorValueAttributeToName, SensorValueAttribute } from '../../../../server/AVTService/TypeLibrary/Common/SensorValueAttribute';
import { Distributors } from '../../../../server/AVTService/TypeLibrary/DB/Distributors';
import { ChartSettingBool } from '../../../../server/AVTService/TypeLibrary/Model/ChartSettingBool';
import { HeatmapChartModel } from '../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/HeatmapChartModel';
import { HeatmapValueSettings } from '../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/HeatmapValueSettings';
import { PhysicalUnit } from '../../../../server/AVTService/TypeLibrary/Sensors/PhysicalUnit';
import { getSensorCategoryName, SensorCategoryOrdered } from '../../../../server/AVTService/TypeLibrary/Sensors/SensorCategory';
import AuthService from '../../../../services/AuthService';
import { GeovisSelect } from '../../../select/GeovisSelect';
import { getIGvOptionType, getIGvOptionTypeList, IGvOptionType } from '../../../select/GeovisSelect_tools';
import { GeovisUnitSelect } from '../../../select/GeovisUnitSelect';
import AxisScaleLimitControl from '../AxisScaleLimitControl';
import { ChartAxisSensorsControl } from '../ChartAxisSensorsControl';
import { ChartBooleanSettingProps, ChartSettingCheckbox, ChartTextSetting, CustomerChangeableChartBooleanSetting } from '../ChartTab_tools';
import { DestUnitConverterComponent } from '../DestUnitConverterComponent';
import { getPhysicalUnitsOptionsOfCategory, isChartTemplate, isVisibleSensorType, onChangeTextPropertyDebounced, onChartPropertyChangedFunc } from '../tools';
import { availableValueTypesForRelativeValues, defaultChartLabelColumnStyle, defaultChartRowSettingsStyle, IGeovisChartPropertyChangedProps } from '../types';
import { HeatmapColorTemperatureSchemeControl } from './HeatmapColorTemperatureSchemeControl';
import { HeatmapSelectSensorsControl } from './HeatmapSelectSensorsControl';

type IChartHeatmapValuesTabProps = IGeovisChartPropertyChangedProps<HeatmapChartModel>

const possibleSensorAttributes: ReadonlyArray<SensorValueAttribute> = [
    SensorValueAttribute.Value1,
    SensorValueAttribute.Value2,
    SensorValueAttribute.Value3,
    SensorValueAttribute.AxisDeviationLZ,
    SensorValueAttribute.AxisDeviationTZ,
    SensorValueAttribute.AxisValue1,
    SensorValueAttribute.AxisValue2,
    SensorValueAttribute.AxisValue3,
    SensorValueAttribute.Deviation,
    SensorValueAttribute.DeviationXY,
];

export const ChartHeatmapValuesTab = ({ chart, onChartPropertyChanged }: IChartHeatmapValuesTabProps) => {

    const { ValueSettings } = chart;

    const labelColumnStyle = defaultChartLabelColumnStyle;
    const settingsRowStyle = defaultChartRowSettingsStyle;

    const typeOfSensorOptions = getIGvOptionTypeList(SensorCategoryOrdered.filter(st => isVisibleSensorType(st)), getSensorCategoryName);
    const selectedTypeOfSensorOption = typeOfSensorOptions.find(o => o.value === ValueSettings.TypeOfSensor);

    const onChartChangedFunc = onChartPropertyChangedFunc(onChartPropertyChanged);

    const onValuePropertyChangedFunc = (propertyName: keyof HeatmapValueSettings) => (value: any) => {

        const changes: Partial<HeatmapValueSettings> = {};
        changes[propertyName] = value;

        if (propertyName === "Value" && !availableValueTypesForRelativeValues.includes(value)) {
            changes.ShowAsRelative = {
                value: false,
                customerChangeable: ValueSettings.ShowAsRelative.customerChangeable
            }
        }

        onChartPropertyChanged<HeatmapValueSettings>("ValueSettings", { ...ValueSettings, ...changes });
    }

    const onSelectedPropertyChanged = (propertyName: keyof HeatmapValueSettings) => (option: IGvOptionType<number>) => {
        onValuePropertyChangedFunc(propertyName)(option.value);
    }

    const onChangeStringProperty = (propertyName: keyof HeatmapValueSettings) => (v1: string) => {
        onChangeTextPropertyDebounced(v1, (v2) => {
            onValuePropertyChangedFunc(propertyName)(v2);
        });
    }

    const onSensorsChanged = (sensorIds: string[], isSensorsCustomerChangeable: boolean) => {
        onChartPropertyChanged<HeatmapValueSettings>("ValueSettings", { ...ValueSettings, SensorIds: sensorIds, IsSensorsCustomerChangeable: isSensorsCustomerChangeable });
    }

    const onSelectedUnitChanged = (unit: PhysicalUnit) => {
        onValuePropertyChangedFunc("Unit")(unit);
    }

    const getBooleanSetting = (setting: ChartSettingBool): ChartSettingBool => {
        return setting || { value: false, customerChangeable: false };
    }

    const isTemplate = isChartTemplate(chart);

    return (
        <div className='geovisChartTab'>
            <div className="flexRowContainerLine" style={settingsRowStyle}>
                <div style={labelColumnStyle}>
                    <label htmlFor="cmbTypeOfSensors" >{t("Type of Sensor")}:</label>
                </div>
                <div className="flexCellContainer_g1_m0">
                    <GeovisSelect
                        id="cmbTypeOfSensors"
                        options={typeOfSensorOptions}
                        value={selectedTypeOfSensorOption}
                        spacing='compact'
                        onChange={onSelectedPropertyChanged("TypeOfSensor")}
                    />
                </div>
            </div>

            <div className="flexRowContainerLine" style={settingsRowStyle}>
                <div style={labelColumnStyle}>
                    <label htmlFor="cmbValues">{t("Value")}:</label>
                </div>
                <div className="flexCellContainer_g1_m0">
                    <GeovisSelect
                        id="cmbValues"
                        value={getIGvOptionType(ValueSettings.Value, getSensorValueAttributeToName)}
                        options={getIGvOptionTypeList(possibleSensorAttributes, getSensorValueAttributeToName)}
                        onChange={onSelectedPropertyChanged("Value")}
                    />
                </div>
            </div>

            <div className="flexRowContainerLine" style={settingsRowStyle}>
                <div style={labelColumnStyle}>
                    <label htmlFor="cmbUnits">{t("Unit")}:</label>
                </div>
                <div className="flexCellContainer_g1_m0">
                    <GeovisUnitSelect
                        id="cmbUnits"
                        value={ValueSettings.Unit}
                        options={getPhysicalUnitsOptionsOfCategory(ValueSettings.TypeOfSensor)}
                        spacing='compact'
                        onChange={onSelectedUnitChanged}
                        customEditorStyle={{ width: '100%' }}
                    />
                </div>
            </div>

            <CustomerChangeableChartBooleanSetting
                setting={getBooleanSetting(ValueSettings.ShowAsRelative)}
                settingLabel={t("Show as relative")}
                onChange={onValuePropertyChangedFunc("ShowAsRelative")}
                isDisabled={!availableValueTypesForRelativeValues.includes(ValueSettings.Value)}
            />

            <AxisScaleLimitControl
                scaleLimit={ValueSettings.MinValue}
                tbLabel={t("Min value")}
                hideAuto={false}
                hideExact={true}
                onChange={onValuePropertyChangedFunc("MinValue")} />

            <AxisScaleLimitControl
                scaleLimit={ValueSettings.MaxValue}
                tbLabel={t("Max value")}
                hideAuto={false}
                hideExact={true}
                onChange={onValuePropertyChangedFunc("MaxValue")} />

            <ChartTextSetting
                labelColumnStyle={{ width: '50%' }}
                labelText={t("Number of digits")}
                settingsStyle={settingsRowStyle}
                value={ValueSettings.NumberOfDigits}
                type="number"
                htmlName="tbAxisNumberOfDigits"
                onChangeTextField={onValuePropertyChangedFunc("NumberOfDigits")} />

            <HeatmapColorTemperatureSchemeControl
                colorSettings={chart.ValueSettings.TemperatureColorSettings}
                maxValue={chart.ValueSettings.MaxValue.value}
                minValue={chart.ValueSettings.MinValue.value}
                unit={chart.ValueSettings.Unit}
                onPropertyChanged={onValuePropertyChangedFunc("TemperatureColorSettings")}
            />

            <ChartBooleanSettingProps
                label={t("Show axis labels")}
                isChecked={ValueSettings.ShowAxisLabel}
                onChange={onValuePropertyChangedFunc("ShowAxisLabel")}
                isDisabled={false}
            />

            <ChartSettingCheckbox
                label={t("Show unit in labels")}
                isChecked={ValueSettings.ShowUnitInAxisLabel}
                onChange={onValuePropertyChangedFunc("ShowUnitInAxisLabel")}
                isDisabled={false}
            />

            <ChartTextSetting
                labelColumnStyle={labelColumnStyle}
                settingsStyle={settingsRowStyle}
                labelText={t("Axis label")}
                value={ValueSettings.AxisLabel}
                htmlName="tbAxisLabel"
                onChangeTextField={onChangeStringProperty("AxisLabel")} />

            <DestUnitConverterComponent
                converterValue={ValueSettings.DestConverter}
                onConverterChanged={onValuePropertyChangedFunc("DestConverter")}
                isDisabled={false}
            />

            {!isTemplate && AuthService.getDistribution() === Distributors.Amberg && (
                <ChartAxisSensorsControl
                    isChain={false}
                    chartType={chart.Type}
                    dtsSectionIds={[]}
                    projectId={chart.ProjectId}
                    ySensorType={ValueSettings.TypeOfSensor}
                    sensorIds={ValueSettings.SensorIds}
                    customerChangeable={ValueSettings.IsSensorsCustomerChangeable}
                    onChange={onSensorsChanged}
                    sensorChanges={[]}
                    showPairsDialog={false} // pairs dialog for XyChart
                    selectedChainsIds={[]} // in this dialog we do not need those ids
                    isSingle={false}
                />
            )}

            {!isTemplate && AuthService.getDistribution() === Distributors.Nagra && (
                <HeatmapSelectSensorsControl
                    projectId={chart.ProjectId}
                    valueSettings={ValueSettings}
                    onSettingsChanged={onChartChangedFunc("ValueSettings")}
                />
            )}

        </div>
    )
}

