/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 05.08.2021
 * @description Sensor last measurement content component
 */

import Spinner from '@atlaskit/spinner';
import React, { CSSProperties } from 'react';
import { dateTimeToReactMoment } from '../../../helpers/DateHelper';
import { toFixedSensorValue } from '../../../helpers/SensorHelper';
import { t } from '../../../i18n';
import { getPhysicalUnitShortName } from '../../../server/AVTService/TypeLibrary/Sensors/PhysicalUnit';
import { SensorInfo } from '../../../server/GEOvis3/Model/SensorInfo';
import { SensorMeasurementInfo } from '../../../server/SensorMeasurementInfo';
import { SensorVibrationData } from '../../../server/SensorVibrationData';
import { ISensorsLastDataStorage } from '../../../store/data.types';
import {
    drawAxisRelativeValues,
    drawDeviation,
    drawUnitValues,
    getSensorLastMeasurement,
    isAlarmAboutNoData
} from './tools';
import { LastMeasOf } from './types';

/**
 * Custom sensor tooltip content properties
 */
export interface ICustomSensorTooltipContentProps {

    /**
     * If it set, then this storage will be used as data source for Last measurements
     * If not, then default global data storage will be used
     */
    customSensorsLastDataStorage?: ISensorsLastDataStorage;
}

/**
 * Own properties of the component, but without last measurement data
 * There are only initialization properties, which required to draw the last data of the sensor
 */
interface ISensorLastMeasurementOwnProps {
    sensor: SensorInfo;
    dataStorage: ISensorsLastDataStorage;
    isPopup: boolean;
    showEvenNoDataAlarm?: boolean;
    showMeasurementAt?: boolean;
    style?: CSSProperties;
}

/**
 * The component properties with last measurement of the sensor
 * This interface is hidden, like private, only for usage in this file
 */
interface ISensorLastMeasurementComponentProps extends ISensorLastMeasurementOwnProps {
    lastMeasurement: LastMeasOf<SensorMeasurementInfo>;
}

/**
 * The component to draw a content of the last measurement
 * @param props - properties of the component
 * @returns 
 */
const SensorLastMeasurementComponent = ({
    sensor,
    style,
    isPopup,
    lastMeasurement,
    showEvenNoDataAlarm,
    showMeasurementAt
}: ISensorLastMeasurementComponentProps) => {

    const hasNoDataAlarms = sensor.CausedAlarms.filter(a => isAlarmAboutNoData(a)).length > 0;
    const showData = isPopup ? true : (!hasNoDataAlarms || showEvenNoDataAlarm || showMeasurementAt);

    const wrapInRoot = (element: React.ReactNode) => {
        const target = isPopup ? 'popup' : 'tooltip';
        return (
            <div key={`sensor_last_measurement_${target}_${sensor.Id}`} style={style}>
                {element}
            </div>
        );
    }

    if (!showData) {
        return null;
    }

    // is loading
    if (lastMeasurement === undefined) {
        return wrapInRoot(<Spinner size="small" />);
    }

    const hasSensorMeasurementData = (): boolean => {
        if (!lastMeasurement || lastMeasurement === undefined) {
            return false;
        }

        if (lastMeasurement.Data) {
            return true;
        }

        if (lastMeasurement.AxisData) {
            return true;
        }

        if (lastMeasurement.RelativeData) {
            return true;
        }

        if (lastMeasurement.AxisRelativeData) {
            return true;
        }

        if (lastMeasurement.LastVibrationBackgroundData || lastMeasurement.LastVibrationEventData) {
            return true;
        }

        return false;
    }

    if (!lastMeasurement || !hasSensorMeasurementData()) {
        return wrapInRoot(<b>{t("No data")}</b>);
    }

    const printVibrationData = (title: string, data: SensorVibrationData, printValues: boolean) => {
        if (!data) {
            return;
        }

        return (
            <div>
                {t(title)}&nbsp;{t("at")}&nbsp;{dateTimeToReactMoment(data.Time)}
                {printValues && (
                    <div>
                        <div>&nbsp;{t("VSum")}:&nbsp;{toFixedSensorValue(data.VSum, sensor)}&nbsp;{t("mm/s")}</div>
                        <div>&nbsp;{t("Freq max velocity component")}:&nbsp;{toFixedSensorValue(data.FreqMaxVcomponent, sensor)}&nbsp;{t("Hz")}</div>
                    </div>
                )}
            </div>);
    }

    const hasLastData = lastMeasurement.Data !== undefined && lastMeasurement.Data !== null;
    const hasLastAxisData = lastMeasurement.AxisData !== undefined && lastMeasurement.AxisData !== null;
    const lastIsAxisData =
        !hasLastData && hasLastAxisData ||
        hasLastData && hasLastAxisData && new Date(lastMeasurement.Data.Time) < new Date(lastMeasurement.AxisData.Time);

    const lastMeasurementAtLabel = showMeasurementAt
        ? "Measurement at"
        : "Last measurement at";

    return wrapInRoot(
        <React.Fragment>
            {!lastIsAxisData && (
                <div>
                    <div>
                        {(isPopup || lastMeasurementAtLabel) && hasLastData && (
                            <div key={`last_measurement_date_${sensor.Id}`}>
                                {t(lastMeasurementAtLabel)}&nbsp;{dateTimeToReactMoment(lastMeasurement.Data.Time)}
                            </div>
                        )}
                        {lastMeasurement.RelativeData && sensor.ShowRelativeValue && (
                            <div>
                                {sensor.ShowAbsoluteValue && (
                                    <span>{t("Relative value")}:&nbsp;</span>
                                )}
                                {drawUnitValues(lastMeasurement.RelativeData.Values, sensor)}
                                &nbsp;
                                {getPhysicalUnitShortName(sensor.Unit)}
                            </div>
                        )}
                        {lastMeasurement.AbsoluteData && sensor.ShowAbsoluteValue && (
                            <div>
                                <span>{t("Absolute value")}:&nbsp;</span>
                                {drawUnitValues(lastMeasurement.AbsoluteData.Values, sensor)}
                                &nbsp;
                                {getPhysicalUnitShortName(sensor.Unit)}
                            </div>
                        )}
                    </div>
                    {lastMeasurement.Deviation && (
                        <div>
                            {drawDeviation(lastMeasurement.Deviation, sensor)}
                            &nbsp;
                            {getPhysicalUnitShortName(sensor.Unit)}
                        </div>
                    )}
                </div>
            )}

            {lastIsAxisData && (
                <div>
                    {isPopup && (
                        <div key={`last_measurement_date_${sensor.Id}`}>
                            {t("Last measurement at")}&nbsp;{dateTimeToReactMoment(lastMeasurement.AxisData.Time)}
                        </div>
                    )}
                </div>
            )}

            {lastMeasurement.AxisRelativeData && sensor.ShowRelativeValue && (
                <div>
                    {drawAxisRelativeValues(lastMeasurement.AxisRelativeData.Values, sensor)}
                </div>
            )}

            <div>{printVibrationData("Last measurement", lastMeasurement.LastVibrationBackgroundData, false)}</div>

            <div>{printVibrationData("Last event", lastMeasurement.LastVibrationEventData, true)}</div>

        </React.Fragment>
    )
}


/**
 * The component to draw the last sensors data with connection to GeovisStorage
 * This component should be rendered/changed only when last sensors data changed
 * @param param0 
 * @returns 
 */
export const SensorLastMeasurementData = ({ ...rest }: ISensorLastMeasurementOwnProps) => {

    const { sensor } = rest;
    const lastMeasurement = getSensorLastMeasurement(rest.dataStorage, sensor.Id);

    return (
        <SensorLastMeasurementComponent
            lastMeasurement={lastMeasurement}
            {...rest}
        />
    )
}