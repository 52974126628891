// eslint-disable-next-line @atlaskit/design-system/no-deprecated-imports
import { Label, LabelProps } from '@atlaskit/field-base';

export const GeovisLabel = (props: LabelProps) => (
    <Label {...props} />
)

export const GeovisLabelInline = (props: LabelProps) => (
    <GeovisLabel appearance='inline-edit' {...props} />
)
