
// MAIN INFO: that components can be used for all Project elements dialogs and they must be arranged in their own directory

import Button from "@atlaskit/button";
import Checkbox from "@atlaskit/checkbox";
import EditorInfoIcon from '@atlaskit/icon/glyph/editor/info';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { Radio } from "@atlaskit/radio";
import Textfield from "@atlaskit/textfield";
import Tooltip from '@atlaskit/tooltip';
import { debounce } from "lodash";
import React, { CSSProperties, SyntheticEvent } from "react";
import { ChartSettingBool } from "../../../server/AVTService/TypeLibrary/Model/ChartSettingBool";
import { GeovisFieldFailInfo } from '../../../server/Geovis/ClientServerIntermediate/GeovisFieldFailInfo';
import { GeovisSelectT } from "../../select/GeovisSelect";
import { IGvOptionType } from "../../select/GeovisSelect_tools";
import { GeovisFieldValidationError } from "../../validationErrors/GeovisFieldValidationError";
import { defaultChartLabelColumnStyle, defaultChartRowSettingsStyle } from "./types";
import { ElementSettingAllowCheckbox } from "../ElementSettingAllowCheckbox";

interface IChartSettingCheckboxProps {
    label: string;
    isChecked: boolean;
    isDisabled: boolean;
    infoText?: string;

    onChange: (value: boolean) => void;
}

export const ChartSettingCheckbox = ({
    label,
    isChecked,
    isDisabled,
    infoText,
    onChange
}: IChartSettingCheckboxProps) => {
    const onChangeHandler = (event: SyntheticEvent<HTMLInputElement>) => {
        onChange(event.currentTarget.checked);
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '-5px' }}>
            <Checkbox
                label={label}
                isChecked={isChecked}
                isDisabled={isDisabled}
                onChange={onChangeHandler} />
            {infoText &&
                <Tooltip content={infoText}>
                    <Button appearance={'subtle-link'} iconBefore={<EditorInfoIcon label={''} size='small' />} />
                </Tooltip>
            }
        </div>
    )
}

export const ChartSettingRadio = ({
    label,
    isChecked,
    isDisabled,
    infoText,
    onChange
}: IChartSettingCheckboxProps) => {
    const onChangeHandler = (event: SyntheticEvent<HTMLInputElement>) => {
        onChange(event.currentTarget.checked);
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '0px' }}>
            <Radio
                label={label}
                isChecked={isChecked}
                isDisabled={isDisabled}
                onChange={onChangeHandler} />
            {infoText &&
                <Tooltip content={infoText}>
                    <Button appearance={'subtle-link'} iconBefore={<EditorInfoIcon label={''} size='small' />} />
                </Tooltip>
            }
        </div>
    )
}

export const ChartBooleanSettingProps = ({
    label,
    isChecked,
    isDisabled,
    onChange
}: IChartSettingCheckboxProps) => {
    return (
        <div className="flexRowContainerLine">
            <ChartSettingCheckbox
                label={label}
                isChecked={isChecked}
                isDisabled={isDisabled}
                onChange={onChange} />
        </div>
    );
}

export const ChartRadioBooleanSettingProps = ({
    label,
    isChecked,
    isDisabled,
    onChange
}: IChartSettingCheckboxProps) => {
    return (
        <div className="flexRowContainerLine">
            <ChartSettingRadio
                label={label}
                isChecked={isChecked}
                isDisabled={isDisabled}
                onChange={onChange} />
        </div>
    );
}

interface ICustomerChangeableChartBooleanSettingProps {
    setting: ChartSettingBool;
    settingLabel: string;
    isDisabled: boolean;
    infoText?: string;
    tooltipContent?: React.ReactNode;
    alignToSecondRow?: boolean;

    onChange: (setting: ChartSettingBool) => void;
}

export const CustomerChangeableChartBooleanSetting = ({
    setting,
    settingLabel,
    isDisabled,
    infoText,
    onChange,
    tooltipContent,
    alignToSecondRow
}: ICustomerChangeableChartBooleanSettingProps) => {
    const onChangeSettingValue = (value: boolean) => {
        onChange({ value, customerChangeable: setting.customerChangeable });
    }

    const onChangeAllowValue = (allowValue: boolean) => {
        onChange({ value: setting.value, customerChangeable: allowValue });
    }

    return (
        <div className="flexRowContainerLine">
            {alignToSecondRow && (
                <div style={{ width: '30%' }}>
                    {/* test */}
                </div>
            )}

            <ChartSettingCheckbox
                label={settingLabel}
                isChecked={setting.value}
                isDisabled={isDisabled}
                onChange={onChangeSettingValue}
                infoText={infoText}
            />

            {tooltipContent && (
                <div>
                    <ChartSettingTooltip content={tooltipContent} />
                </div>
            )}

            <ElementSettingAllowCheckbox
                isChecked={setting.customerChangeable}
                isDisabled={isDisabled}
                onChange={onChangeAllowValue} />
        </div>
    );
}

interface IChartSettingTooltip {
    content?: React.ReactNode;
}

export const ChartSettingTooltip = ({
    content
}: IChartSettingTooltip) => (
    <Tooltip
        content={content}>
        <Button
            appearance='subtle-link'
            iconBefore={<InfoIcon label="Information" size='small' primaryColor="#42526E" />}
        />
    </Tooltip>
)


interface IChartTextSettingProps {
    labelColumnStyle?: CSSProperties;
    settingsStyle?: CSSProperties;
    labelText: string;
    value: string | number;
    type?: string,
    htmlName: string;
    tooltipContent?: React.ReactNode;
    isDisabled?: boolean;
    min?: number;
    validationError?: GeovisFieldFailInfo;

    onChangeTextField: (text: string) => void;
}

export const ChartTextSetting = ({
    labelColumnStyle,
    labelText,
    value,
    type,
    htmlName,
    isDisabled,
    settingsStyle,
    tooltipContent,
    onChangeTextField,
    validationError,
    min
}: IChartTextSettingProps) => {

    const onTextChangedHandlerDebounced = debounce((textValue: string) => onChangeTextField(textValue), 200);

    const onTextChangedHandlerCallback = ({ currentTarget }: SyntheticEvent<HTMLInputElement>) => onTextChangedHandlerDebounced(currentTarget.value);

    return (
        <div className="flexRowContainerLine" style={settingsStyle}>
            <div style={labelColumnStyle}>
                <label htmlFor={htmlName}>{labelText}:</label>
            </div>
            <div style={{ flexGrow: 1 }}>
                <Textfield
                    name={htmlName}
                    key={htmlName}
                    isCompact={true}
                    defaultValue={value}
                    isDisabled={isDisabled}
                    type={type}
                    max={type === 'number' ? 6 : undefined}
                    min={min !== undefined ? min : type === 'number' ? 0 : undefined}
                    onChange={onTextChangedHandlerCallback} />
                {validationError && (
                    <GeovisFieldValidationError validationError={validationError} />
                )}
            </div>
            {tooltipContent && (
                <div>
                    <ChartSettingTooltip content={tooltipContent} />
                </div>
            )}
        </div>
    )
}

interface IChartSelectSettingsProps<TValue extends number | string> {
    label: string;
    options: ReadonlyArray<IGvOptionType<TValue>>;
    value: TValue;
    customerChangeable: boolean;

    onValueChanged: (value: TValue) => void;
    onCustomerChangeableChanged: (value: boolean) => void;
}

export const ChartSelectSettings = <TValue extends number | string>({
    customerChangeable,
    label,
    onCustomerChangeableChanged,
    onValueChanged,
    options,
    value
}: IChartSelectSettingsProps<TValue>) => {

    const onSelectPropertyChanged = (option: IGvOptionType<TValue>) => {
        if (option) {
            onValueChanged(option.value);
        }
    }

    const selected = options.find(o => o.value === value);

    return (
        <div className="flexRowContainerLine" style={defaultChartRowSettingsStyle}>
            <div style={defaultChartLabelColumnStyle}>
                <label htmlFor="cmbTypeOfSensors" >{label}:</label>
            </div>
            <div className="flexCellContainer_g1_m0">
                <GeovisSelectT<TValue>
                    id="cmbTypeOfSensors"
                    options={options}
                    value={selected}
                    spacing='compact'
                    onChange={onSelectPropertyChanged}
                />
            </div>
            <ElementSettingAllowCheckbox
                isChecked={customerChangeable}
                onChange={onCustomerChangeableChanged}
                isDisabled={false}
            />
        </div>
    )
}