import { XYVibrationChartModel } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/XYVibrationChartModel";
import { GeovisChartAlarmInfo } from "../../../../server/GEOvis3/Model/GeovisChart/GeovisChartAlarmInfo";
import { IChartAlarmAreasControlActionProps } from "../ChartAlarmAreasControl";
import { ChartAlarmLinesControl, IChartAlarmLinesControlActionProps } from "../ChartAlarmLinesControl";
import { ChartAlarmPlansControl, IChartAlarmPlansControlActionProps } from "../ChartAlarmPlansControl";
import { isChartTemplate } from "../tools";

interface IVibrationAlarmsTabProps extends IChartAlarmLinesControlActionProps, IChartAlarmAreasControlActionProps, IChartAlarmPlansControlActionProps {
    chart: XYVibrationChartModel;
    alarmPlansInfo: GeovisChartAlarmInfo[];
}

export const XYVibrationChartAlarmsTab = ({
    chart,
    onAlarmLineDeleteClickHandler,
    onChangeAlarmLines,
    onNewAlarmLinkClickHandler,
    onChangeLinkedAlarms,
    alarmPlansInfo
}: IVibrationAlarmsTabProps) => {

    const { AlarmLines } = chart;

    return (
        <div className="geovisChartTab">
            <div className="flexRowContainerLine">
                <ChartAlarmLinesControl
                    AlarmLines={AlarmLines}
                    chartType={chart.Type}
                    onAlarmLineDeleteClickHandler={onAlarmLineDeleteClickHandler}
                    onChangeAlarmLines={onChangeAlarmLines}
                    onNewAlarmLinkClickHandler={onNewAlarmLinkClickHandler}
                    yUnit={chart.LeftYAxisSettings.Unit}
                    xUnit={chart.XAxisSettings.Unit}
                />
            </div>
            {!isChartTemplate(chart) &&
                <div className="flexRowContainerLine">
                    <ChartAlarmPlansControl
                        allAlarms={alarmPlansInfo || []}
                        onChangeLinkedAlarms={onChangeLinkedAlarms}
                        chart={chart}
                    />
                </div>
            }
        </div>
    )
}