import Checkbox from "@atlaskit/checkbox";
import { SyntheticEvent, useEffect, useState } from "react";
import { GeovisSelectMultiT } from "../../../../components/select/GeovisSelect";
import { IGvOptionType } from "../../../../components/select/GeovisSelect_tools";
import { IWithGeovisServerProps, withGeovisServer } from "../../../../helpers/GeovisHooks";
import { fetchServerElements } from "../../../../helpers/ProjectDataHelper";
import { t } from "../../../../i18n";
import { LabelModel } from "../../../../server/AVTService/TypeLibrary/Model/LabelModel";
import ServerRoutesGen from "../../../../server/Routes/ServerRoutesGen";
import { processFetchedData } from "../../../../store/helpers/DataHelper";
import { defaultSomethingStorageState, ISomethingStorageBaseEx } from "../../../../store/types";
import { defaultChartRowSettingsStyle } from "../types";

type ILogbooksState = ISomethingStorageBaseEx<LabelModel[]>

const getLabelsInitialState = (): ILogbooksState => {
    const result: ILogbooksState = {
        ...defaultSomethingStorageState,
        data: []
    };

    return result;
}

interface ITimeValueChartShowLogbookSettingsProps extends IWithGeovisServerProps {
    projectId: number;
    isChecked: boolean;
    selectedLabels: string[];
    isDisabled: boolean;

    onLogbooksChanged: (value: string[]) => void;
    onShowLogbooksChanged: (value: boolean) => void;
}

export const TimeValueChartShowLogbookSettingsComponent = ({
    Server,
    projectId,
    isChecked,
    selectedLabels,
    onShowLogbooksChanged,
    onLogbooksChanged,
    isDisabled
}: ITimeValueChartShowLogbookSettingsProps) => {

    const [labelsState, setLabelsState] = useState<ILogbooksState>(getLabelsInitialState());

    useEffect(() => {

        (async function loadLogbooks() {


            const response = await fetchServerElements<LabelModel[]>(Server, ServerRoutesGen.Project.Labels.patch(projectId));

            setLabelsState(processFetchedData(response, labelsState, getLabelsInitialState(), st => ({ data: st })));
        })();

    }, []);


    const onShowLogbooksChangedHandle = (event: SyntheticEvent<HTMLInputElement>) => {
        onShowLogbooksChanged(event.currentTarget.checked);
    }

    const onSelectedMultiPropertyChanged = (values: Array<IGvOptionType<string | number>>) => {

        if (!values) {
            onLogbooksChanged([]);
            return;
        }

        const ids = values.map(o => o.value.toString());
        onLogbooksChanged(ids);
    }

    const allLabels = labelsState.isLoaded ? labelsState.data : [];

    const getSelectedSelectOptions = (): Array<IGvOptionType<string>> => {
        return allLabels.filter(record => selectedLabels.indexOf(record.id) > -1).map(record => ({ value: record.id, label: record.name }));
    }
    const getAllSelectOptions = (): Array<IGvOptionType<string>> => {
        return allLabels.map(record => ({ value: record.id, label: record.name }));
    }

    return (
        <div className="flexRowContainerLine" style={defaultChartRowSettingsStyle}>
            <div style={{ marginLeft: '-5px' }}>
                <Checkbox
                    id="show-logbooks-checkbox-id"
                    label={t("Show manual logbook entries with following labels")}
                    isChecked={isChecked}
                    isDisabled={isDisabled}
                    onChange={onShowLogbooksChangedHandle}
                />
            </div>
            <div className="flexCellContainer_g1_m0">
                <GeovisSelectMultiT<string>
                    id='labels-id'
                    spacing='compact'
                    isLoading={labelsState.isLoading}
                    isDisabled={isDisabled}
                    value={getSelectedSelectOptions()}
                    onChange={onSelectedMultiPropertyChanged}
                    options={getAllSelectOptions()}
                />
            </div>
        </div>
    )
}

export const TimeValueChartShowLogbookSettings = withGeovisServer(TimeValueChartShowLogbookSettingsComponent);