import Tabs from "@atlaskit/tabs";
import { TabData } from "@atlaskit/tabs/dist/types/types";
import { cloneDeep } from "lodash";
import { useState } from "react";
import { t } from '../../../i18n';
import { ChartType } from "../../../server/AVTService/TypeLibrary/Common/ChartType";
import { KindOfElementUsing } from "../../../server/AVTService/TypeLibrary/Common/KindOfElementUsing";
import { AlarmInfoRecord } from "../../../server/AVTService/TypeLibrary/Model/AlarmInfoRecord";
import { ChartBackground } from "../../../server/AVTService/TypeLibrary/Model/ChartBackground";
import { ChartAlarmPlanLinkModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/ChartAlarmPlanLinkModel";
import { DefaultTimeValueChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/DefaultTimeValueChartModel";
import { DtsChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/DtsChartModel";
import { GeovisChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartModel";
import { HeatmapChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/HeatmapChartModel";
import { InclinometerChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/InclinometerChartModel";
import { TimeValueChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/TimeValueChartModel";
import { VibrationChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/VibrationChartModel";
import { XyChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/XyChartModel";
import { XYVibrationChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/XYVibrationChartModel";
import { GeovisChartAlarmInfo } from "../../../server/GEOvis3/Model/GeovisChart/GeovisChartAlarmInfo";
import { SensorChangesModel } from "../../../server/GEOvis3/Model/GeovisChart/SensorChangesModel";
import { ChartMainSettingsTab } from "./ChartMainSettingsTab";
import { ChartOptionsTab } from "./ChartOptionsTab";
import { CommonChartProperties, CommonChartType } from "./ChartTabControl.types";
import { DefaultChartOptionsTab } from "./default/DefaultChartOptionsSettings";
import { DefaultChartYAxisTab } from "./default/DefaultChartYAxisTab";
import { DtsChartAlarmsTab } from "./dtschart/DtsChartAlarmsTab";
import { IGeovisDtsSectionLoadActions } from "./dtschart/dtsChartMainSettings";
import { DtsChartOptionsTab } from "./dtschart/DtsChartOptionsTab";
import { DtsChartXAxisTab } from "./dtschart/DtsChartXAxisTab";
import { DtsChartYAxisTab } from "./dtschart/DtsChartYAxisTab";
import { ChartHeatmapAxisTab } from "./heatmap/ChartHeatmapAxisTab";
import { ChartHeatmapValuesTab } from "./heatmap/ChartHeatmapValuesTab";
import { InclinometerChartAlarmTab } from "./inclinometer/InclinometerChartAlarmTab";
import { InclinometerChartOptionsTab } from "./inclinometer/InclinometerChartOptionsTab";
import { InclinometerChartVisualSettingsTab } from "./inclinometer/InclinometerChartVisualSettingsTab";
import { TimeValueChartAlarmsTab } from "./timevalue/TimeValueChartAlarmsTab";
import { TimeValueChartOptionsTab } from "./timevalue/TimeValueChartOptionsSettings";
import { TimeValueChartYAxisTab } from "./timevalue/TimeValueChartYAxisTab";
import { IChartInfoStorage, IDtsChartSensorsInfoStorage } from "./types";
import { VibrationChartAlarmsTab } from "./vibration/VibrationChartAlarmSettings";
import { VibrationChartYAxisTab } from "./vibration/VibrationChartAxesSettings";
import { XyChartAlarmsTab } from "./xychart/XyChartAlarmsTab";
import { XyChartOptionsTab } from "./xychart/XyChartOptionsTab";
import { XyChartXAxisTab } from "./xychart/XyChartXAxisTab";
import { XyChartYAxisTab } from "./xychart/XyChartYAxisTab";
import { XYVibrationChartAlarmsTab } from "./xyVibration/XYVibrationChartAlarmSettings";
import { XYVibrationChartAxesTab } from "./xyVibration/XYVibrationChartAxesSettings";
import { ChartPreviewTab } from "./ChartPreviewTab";
import { SemiCircleChartModel } from "../../../server/AVTService/TypeLibrary/Model/GeovisCharts/SemiCircleChartModel";
import { SemiCircleChartValueSettings } from "./semiCircle/SemiCircleChartValueSettingsTab";
import { SemiCircleChartVisualSettings } from "./semiCircle/SemiCircleChartVisualSettingsTab";
import { SemiCircleChartOptionsSettings } from "./semiCircle/SemiCircleChartOptionsSettingsTab";


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IChartTabData extends TabData {

}

interface IComponentProps extends IGeovisDtsSectionLoadActions {
    chartInfoStorage: IChartInfoStorage;
    dtsChartSensorsInfoStorage: IDtsChartSensorsInfoStorage;
    projectId: number;

    onChartModelChanged: (chart: GeovisChartModel, changes?: SensorChangesModel[]) => void;
    onSensorsChangesUpdated: (changes: SensorChangesModel[]) => void;
}

export const ChartTabControl = ({
    chartInfoStorage,
    dtsChartSensorsInfoStorage,
    onChartModelChanged,
    onDtsChartSectionLoaded,
    onDtsChartSectionLoading,
    projectId
}: IComponentProps) => {

    const { chartInfo: { Chart, DtsSectionInfos } } = chartInfoStorage;
    const [tabIndex, setTabIndex] = useState<number>(0);
    //const isTemplate = Chart.KindOfElementUsing === KindOfElementUsing.DefaultTemplate || Chart.KindOfElementUsing === KindOfElementUsing.Template;

    const onChartPropertyChanged = (propertyName: CommonChartProperties, value: any, changes?: SensorChangesModel[]) => {
        const update: Partial<CommonChartType> = {};
        update[propertyName] = value;

        onChartModelChanged({ ...Chart, ...update }, changes);
    }

    const onChartPropertiesChanged = (changes: Partial<CommonChartType>) => {

        onChartModelChanged({ ...Chart, ...changes });
    }



    const onNewAlarmLinkClickHandler = (alarmLine: AlarmInfoRecord) => {
        onChartModelChanged({ ...Chart, AlarmLines: [...Chart.AlarmLines, alarmLine] });
    }

    const onAlarmLineDeleteClickHandler = (index: number) => {

        const alarmLines = cloneDeep(Chart.AlarmLines);
        alarmLines.splice(index, 1);

        onChartModelChanged({ ...Chart, AlarmLines: alarmLines });
    }

    const onAlarmAreasChanged = (areas: ChartBackground[]) => {
        onChartModelChanged({ ...Chart, BackgroundAreas: areas });
    }

    const onChangeLinkedAlarms = (alarmPlans: ChartAlarmPlanLinkModel[]) => {
        onChartModelChanged({ ...Chart, AlarmPlans: alarmPlans });
    }

    const onChangeAlarmLines = (alarmLines: AlarmInfoRecord[]) => {
        onChartModelChanged({ ...Chart, AlarmLines: [...alarmLines] });
    }

    const createMainSettingsTab = (): IChartTabData => {
        return ({
            label: t("Main settings"),
            content: (
                <ChartMainSettingsTab
                    chartInfoStorage={chartInfoStorage}
                    onChartPropertyChanged={onChartPropertyChanged}
                    onDtsChartSectionLoaded={onDtsChartSectionLoaded}
                    onDtsChartSectionLoading={onDtsChartSectionLoading}
                    onChartPropertiesChanged={onChartPropertiesChanged}
                />
            )
        });
    }


    // #region Dts tabs

    const createDtsYAxisTab = (chart: DtsChartModel): IChartTabData => ({
        label: t("Y-Axis"),
        content: (
            <DtsChartYAxisTab
                chart={chart}
                dtsSections={DtsSectionInfos}
                dtsChartSensorsInfoStorage={dtsChartSensorsInfoStorage}
                onChartPropertyChanged={onChartPropertyChanged}
                onChartPropertiesChanged={onChartPropertiesChanged}
                sensorsChanges={chartInfoStorage.sensorsChanges}
            />
        )
    })

    const createDtsXAxisTab = (chart: DtsChartModel): IChartTabData => ({
        label: t("X-Axis"),
        content: (
            <DtsChartXAxisTab
                chart={chart}
                onChartPropertiesChanged={onChartPropertiesChanged}
                onChartPropertyChanged={onChartPropertyChanged}
            />
        )
    })

    // #endregion

    // #region Timevalue tabs

    const createTimeValueYAxisTab = (chart: TimeValueChartModel): IChartTabData => {
        return ({
            label: t("Y-Axis"),
            content: (
                <TimeValueChartYAxisTab
                    chart={chart}
                    sensorsChanges={chartInfoStorage.sensorsChanges}
                    onChartPropertyChanged={onChartPropertyChanged}
                    onChartPropertiesChanged={onChartPropertiesChanged}
                    DtsSections={DtsSectionInfos}
                />
            )
        })
    }

    const createDefaultChartYAxisTab = (chart: DefaultTimeValueChartModel): IChartTabData => ({
        label: t("Y-Axis"),
        content: (
            <DefaultChartYAxisTab
                chart={chart}
                sensorsChanges={chartInfoStorage.sensorsChanges}
                onChartPropertyChanged={onChartPropertyChanged}
                onChartPropertiesChanged={onChartPropertiesChanged}
            />
        )
    })

    // #endregion

    // #region XyChart tabs

    const createXyYAxisTab = (chart: XyChartModel): IChartTabData => ({
        label: t("Y-Axis"),
        content: (
            <XyChartYAxisTab
                chart={chart}
                sensorsChanges={chartInfoStorage.sensorsChanges}
                onChartPropertyChanged={onChartPropertyChanged}
                onChartPropertiesChanged={onChartPropertiesChanged}
            />
        )
    })

    const createXyXAxisTab = (chart: XyChartModel): IChartTabData => ({
        label: t("X-Axis"),
        content: (
            <XyChartXAxisTab
                chart={chart}
                onChartPropertyChanged={onChartPropertyChanged}
                onChartPropertiesChanged={onChartPropertiesChanged}
            />
        )
    })

    // #endregion

    //#region Vibration charts

    const createVibrationChartYAxesTab = (chart: VibrationChartModel): IChartTabData => ({
        label: t("Main chart"),
        content: (
            <VibrationChartYAxisTab
                chart={chart}
                sensorsChanges={chartInfoStorage.sensorsChanges}
                onChartPropertyChanged={onChartPropertyChanged}
                onChartPropertiesChanged={onChartPropertiesChanged}
                isEventAxes={false}
            />
        )
    })

    const createVibrationChartEventAxesTab = (chart: VibrationChartModel): IChartTabData => ({
        label: t("Event chart"),
        content: (
            <VibrationChartYAxisTab
                chart={chart}
                sensorsChanges={chartInfoStorage.sensorsChanges}
                onChartPropertyChanged={onChartPropertyChanged}
                onChartPropertiesChanged={onChartPropertiesChanged}
                isEventAxes={true}
            />
        )
    })

    const createVibrationChartAlarmsTab = (chart: VibrationChartModel): IChartTabData => ({
        label: t("Alarms"),
        content: (
            <VibrationChartAlarmsTab
                chart={chart}
                onAlarmAreasChanged={onAlarmAreasChanged}
                onAlarmLineDeleteClickHandler={onAlarmLineDeleteClickHandler}
                onChangeAlarmLines={onChangeAlarmLines}
                onNewAlarmLinkClickHandler={onNewAlarmLinkClickHandler}
            />
        )
    })

    //#endregion

    //#region XYVibration charts

    const createXYVibrationChartYAxesTab = (chart: XYVibrationChartModel): IChartTabData => ({
        label: t("Main chart"),
        content: (
            <XYVibrationChartAxesTab
                chart={chart}
                sensorsChanges={chartInfoStorage.sensorsChanges}
                onChartPropertyChanged={onChartPropertyChanged}
                onChartPropertiesChanged={onChartPropertiesChanged}
                isEventAxes={false}
            />
        )
    })

    const createXYVibrationChartEventAxesTab = (chart: XYVibrationChartModel): IChartTabData => ({
        label: t("Event chart"),
        content: (
            <XYVibrationChartAxesTab
                chart={chart}
                sensorsChanges={chartInfoStorage.sensorsChanges}
                onChartPropertyChanged={onChartPropertyChanged}
                onChartPropertiesChanged={onChartPropertiesChanged}
                isEventAxes={true}
            />
        )
    })

    const createXYVibrationChartAlarmsTab = (chart: XYVibrationChartModel, alarmPlansInfo: GeovisChartAlarmInfo[]): IChartTabData => ({
        label: t("Alarms"),
        content: (
            <XYVibrationChartAlarmsTab
                chart={chart}
                onAlarmAreasChanged={onAlarmAreasChanged}
                onAlarmLineDeleteClickHandler={onAlarmLineDeleteClickHandler}
                onChangeAlarmLines={onChangeAlarmLines}
                onNewAlarmLinkClickHandler={onNewAlarmLinkClickHandler}
                alarmPlansInfo={alarmPlansInfo}
                onChangeLinkedAlarms={onChangeLinkedAlarms}
            />
        )
    })

    //#endregion

    //#region Donut charts

    const createSemiCircleValuesTab = (chart: SemiCircleChartModel): IChartTabData => ({
        label: t("Values"),
        content: (
            <SemiCircleChartValueSettings
                chart={chart}
                aliases={chartInfoStorage.aliases}
                onChartPropertyChanged={onChartPropertyChanged}
                onChartPropertiesChanged={onChartPropertiesChanged}
            />
        )
    });

    const createSemiCircleVisualSettingsTab = (chart: SemiCircleChartModel): IChartTabData => ({
        label: t("Circle"),
        content: (
            <SemiCircleChartVisualSettings
                chart={chart}
                onChartPropertyChanged={onChartPropertyChanged}
                onChartPropertiesChanged={onChartPropertiesChanged}
            />
        )
    })

    const createSemiCircleOptionsSettingsTab = (chart: SemiCircleChartModel): IChartTabData => ({
        label: t("Options"),
        content: (
            <SemiCircleChartOptionsSettings
                chart={chart}
                onChartPropertyChanged={onChartPropertyChanged}
                onChartPropertiesChanged={onChartPropertiesChanged}
            />
        )
    })

    //#endregion

    const createDtsChartOptionsTab = (chart: DtsChartModel): IChartTabData => ({
        label: t("Options"),
        content: (
            <DtsChartOptionsTab
                chart={chart}
                onChartPropertyChanged={onChartPropertyChanged}
                onChartPropertiesChanged={onChartPropertiesChanged}
            />
        )
    });

    const createTimeValueChartOptionsTab = (chart: TimeValueChartModel): IChartTabData => ({
        label: t("Options"),
        content: (
            <div className="geovisChartTab">
                <TimeValueChartOptionsTab
                    chart={chart}
                    onChartPropertyChanged={onChartPropertyChanged}
                    onChartPropertiesChanged={onChartPropertiesChanged}
                />
            </div>
        )
    })

    const createDefaultChartOptionsTab = (chart: DefaultTimeValueChartModel): IChartTabData => ({
        label: t("Options"),
        content: (
            <DefaultChartOptionsTab
                chart={chart}
                onChartPropertyChanged={onChartPropertyChanged}
                onChartPropertiesChanged={onChartPropertiesChanged}
            />
        )
    });

    const createBaseChartOptionsTab = (chart: GeovisChartModel): IChartTabData => ({
        label: t("Options"),
        content: (
            <div className="geovisChartTab">
                <ChartOptionsTab
                    chart={chart}
                    onChartPropertyChanged={onChartPropertyChanged}
                    onChartPropertiesChanged={onChartPropertiesChanged}
                />
            </div>
        )
    });

    const createXyChartOptionsTab = (chart: XyChartModel): IChartTabData => ({
        label: t("Options"),
        content: (
            <XyChartOptionsTab
                chart={chart}
                onChartPropertyChanged={onChartPropertyChanged}
                onChartPropertiesChanged={onChartPropertiesChanged}
            />
        )
    })

    const createDtsChartAlarmsTab = (chart: DtsChartModel): IChartTabData => ({
        label: t("Alarms"),
        content: (
            <DtsChartAlarmsTab
                chart={chart}
                onAlarmLineDeleteClickHandler={onAlarmLineDeleteClickHandler}
                onChangeAlarmLines={onChangeAlarmLines}
                onNewAlarmLinkClickHandler={onNewAlarmLinkClickHandler}
            />
        )
    });

    const createTimeValueChartAlarmsTab = (chart: TimeValueChartModel, alarmPlansInfo: GeovisChartAlarmInfo[]): IChartTabData => ({
        label: t("Alarms"),
        content: (
            <TimeValueChartAlarmsTab
                chart={chart}
                alarmPlansInfo={alarmPlansInfo}
                onAlarmAreasChanged={onAlarmAreasChanged}
                onAlarmLineDeleteClickHandler={onAlarmLineDeleteClickHandler}
                onChangeAlarmLines={onChangeAlarmLines}
                onChangeLinkedAlarms={onChangeLinkedAlarms}
                onNewAlarmLinkClickHandler={onNewAlarmLinkClickHandler}
            />
        )
    });

    const createXyChartAlarmsTab = (chart: XyChartModel, alarmPlansInfo: GeovisChartAlarmInfo[]): IChartTabData => ({
        label: t("Alarms"),
        content: (
            <XyChartAlarmsTab
                chart={chart}
                alarmPlansInfo={alarmPlansInfo}
                onAlarmLineDeleteClickHandler={onAlarmLineDeleteClickHandler}
                onChangeAlarmLines={onChangeAlarmLines}
                onChangeLinkedAlarms={onChangeLinkedAlarms}
                onNewAlarmLinkClickHandler={onNewAlarmLinkClickHandler}
            />
        )
    });

    const createHeatmapAxesTab = (chart: HeatmapChartModel): IChartTabData => ({
        label: t("Axes"),
        content: (
            <ChartHeatmapAxisTab
                chart={chart}
                onChartPropertyChanged={onChartPropertyChanged}
                onChartPropertiesChanged={onChartPropertiesChanged}
            />
        )
    })

    const createHeatmapValuesTab = (chart: HeatmapChartModel): IChartTabData => ({
        label: t("Values"),
        content: (
            <ChartHeatmapValuesTab
                chart={chart}
                onChartPropertyChanged={onChartPropertyChanged}
                onChartPropertiesChanged={onChartPropertiesChanged}
            />
        )
    })

    const createInclinometerVisualSettingsTab = (chart: InclinometerChartModel): IChartTabData => ({
        label: t("Axis"),
        content: (
            <InclinometerChartVisualSettingsTab
                chart={chart}
                onChartPropertyChanged={onChartPropertyChanged}
                onChartPropertiesChanged={onChartPropertiesChanged}
            />
        )
    });

    const createInclinometerOptionsTab = (chart: InclinometerChartModel): IChartTabData => ({
        label: t("Options"),
        content: (
            <InclinometerChartOptionsTab
                chart={chart}
                onChartPropertyChanged={onChartPropertyChanged}
                onChartPropertiesChanged={onChartPropertiesChanged}
            />
        )
    });

    const createInclinometerAlarmsTab = (chart: InclinometerChartModel, alarmPlansInfo: GeovisChartAlarmInfo[]): IChartTabData => ({
        label: t("Alarms"),
        content: (
            <InclinometerChartAlarmTab
                chart={chart}
                alarmPlansInfo={alarmPlansInfo}
                onAlarmLineDeleteClickHandler={onAlarmLineDeleteClickHandler}
                onChangeAlarmLines={onChangeAlarmLines}
                onChangeLinkedAlarms={onChangeLinkedAlarms}
                onNewAlarmLinkClickHandler={onNewAlarmLinkClickHandler}
            />
        )
    });

    const createChartPreviewTab = (chart: GeovisChartModel) => ({
        label: t("Preview"),
        content: (
            <ChartPreviewTab
                chart={chart}
                sensorsChanges={chartInfoStorage.sensorsChanges}
                onChartPropertyChanged={onChartPropertyChanged}
                onChartPropertiesChanged={onChartPropertiesChanged}
                projectId={projectId}
            />
        )
    })

    const getTabs = (typeOfChart: ChartType): IChartTabData[] => {

        const { AlarmPlansInfo } = chartInfoStorage.chartInfo;

        switch (typeOfChart) {
            case ChartType.DtsChart: {
                const chart = Chart as DtsChartModel;
                return [
                    createMainSettingsTab(),
                    createDtsYAxisTab(chart),
                    createDtsXAxisTab(chart),
                    createDtsChartOptionsTab(chart),
                    createDtsChartAlarmsTab(chart)
                ];
            }

            case ChartType.TimeValue: {
                if (Chart.KindOfElementUsing === KindOfElementUsing.Default || Chart.KindOfElementUsing === KindOfElementUsing.DefaultTemplate) {
                    const chart = Chart as DefaultTimeValueChartModel;
                    return [
                        createMainSettingsTab(),
                        createDefaultChartYAxisTab(chart),
                        createDefaultChartOptionsTab(chart),
                        createTimeValueChartAlarmsTab(chart, AlarmPlansInfo),
                        createChartPreviewTab(chart)
                    ];
                }
                else {
                    const chart = Chart as TimeValueChartModel;
                    const result = [
                        createMainSettingsTab(),
                        createTimeValueYAxisTab(chart),
                        createTimeValueChartOptionsTab(chart),
                        createTimeValueChartAlarmsTab(chart, AlarmPlansInfo),
                        createChartPreviewTab(chart)
                    ];

                    return result;
                }

            }

            case ChartType.XYDiagram: {
                const chart = Chart as XyChartModel;

                const result = [
                    createMainSettingsTab(),
                    createXyYAxisTab(chart),
                    createXyXAxisTab(chart),
                    createXyChartOptionsTab(chart),
                    createXyChartAlarmsTab(chart, AlarmPlansInfo),
                    createChartPreviewTab(chart)
                ];

                return result;
            }

            case ChartType.HeatMap: {
                const chart = chartInfoStorage.chartInfo.Chart as HeatmapChartModel;

                const result = [
                    createMainSettingsTab(),
                    createHeatmapValuesTab(chart),
                    createHeatmapAxesTab(chart),
                    createBaseChartOptionsTab(chart),
                    createChartPreviewTab(chart)
                ];

                return result;
            }

            case ChartType.Inclinometer: {
                const chart = chartInfoStorage.chartInfo.Chart as InclinometerChartModel;

                const result = [
                    createMainSettingsTab(),
                    createInclinometerVisualSettingsTab(chart),
                    createInclinometerOptionsTab(chart),
                    createInclinometerAlarmsTab(chart, AlarmPlansInfo),
                    createChartPreviewTab(chart)
                ];

                return result;
            }

            case ChartType.VibrationDiagramm: {
                const chart = chartInfoStorage.chartInfo.Chart as VibrationChartModel;

                const result = [
                    createMainSettingsTab(),
                    createVibrationChartYAxesTab(chart),
                    createVibrationChartEventAxesTab(chart),
                    createVibrationChartAlarmsTab(chart),
                    createChartPreviewTab(chart)
                ];

                return result;
            }

            case ChartType.XYVibrationEventDiagram: {
                const chart = chartInfoStorage.chartInfo.Chart as XYVibrationChartModel;

                const result = [
                    createMainSettingsTab(),
                    createXYVibrationChartYAxesTab(chart),
                    createXYVibrationChartEventAxesTab(chart),
                    createXYVibrationChartAlarmsTab(chart, AlarmPlansInfo),
                    createChartPreviewTab(chart)
                ];

                return result;
            }

            case ChartType.SemiCircle: {
                const chart = chartInfoStorage.chartInfo.Chart as SemiCircleChartModel;

                const result = [
                    createMainSettingsTab(),
                    createSemiCircleValuesTab(chart),
                    createSemiCircleVisualSettingsTab(chart),
                    createSemiCircleOptionsSettingsTab(chart),
                    createChartPreviewTab(chart)
                ]

                return result;
            }
        }

        return [];
    }

    const tabs = getTabs(Chart.Type);

    const onSelectedTabChangedHandler = (tab: TabData, index: number) => setTabIndex(index);

    return (
        <div style={{ height: '99%', display: 'flex', flexDirection: 'column' }}>
            <Tabs
                tabs={tabs}
                selected={tabs[tabIndex || 0]}
                onSelect={onSelectedTabChangedHandler} />
        </div>
    );
}