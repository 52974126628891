import { HeadType, RowType } from "@atlaskit/dynamic-table/dist/types/types";
import { useState } from "react";
import { DynamicTableStateless } from "@atlaskit/dynamic-table";
import Button from "@atlaskit/button";
import AddCircleIcon from '@atlaskit/icon/glyph/add-circle';
import CrossCircleIcon from '@atlaskit/icon/glyph/cross-circle';
import { Guid } from "guid-typescript";
import { t } from "../../../../i18n";
import { VibrationFrequencyBand } from "../../../../server/AVTService/TypeLibrary/Alarming/VibrationFrequencyBand";
import { GeovisValidatableTextfield } from "../../../../components/textFields/GeovisValidatableTextfield";
import { PhysicalUnit, getPhysicalUnitShortName } from "../../../../server/AVTService/TypeLibrary/Sensors/PhysicalUnit";

interface IComponentProps {
    bands: VibrationFrequencyBand[];
    isDisabled: boolean;
    onChange: (updBands: VibrationFrequencyBand[]) => void;
    onBandsValidationEnd: (bandId: string, propertyName: string, isValid: boolean) => void;
}

interface IComponentState {
    timeStamp: number;
    selectedPage: number
}

export const AlarmConditionFrequencyBandsEditor = ({
    bands,
    isDisabled,
    onChange,
    onBandsValidationEnd
}: IComponentProps) => {

    const [state, setState] = useState<IComponentState>({
        timeStamp: new Date().getTime(),
        selectedPage: 1
    })

    const onChangeHandler = (updBands: VibrationFrequencyBand[]) => {
        onChange(updBands);
        setState({
            ...state,
            timeStamp: new Date().getTime()
        });
    }

    const getRows = (): RowType[] => {

        const onRemoveBand = (index: number) => () => {
            bands.splice(index, 1);
            onChangeHandler(bands);
        }

        return bands.map((band, index, collection) => ({
            key: `band-${band.Id}`,
            cells: [{
                key: 'startFreq',
                content: (
                    <GeovisValidatableTextfield
                        type="number"
                        //key={`start-freq-${band.Id}-${state.timeStamp}`}
                        value={band.StartFrequency.toString()}
                        isDisabled={isDisabled}
                        isClearable={true}
                        onCheckValidate={validateBandValue(band.Id, "StartFrequency")}
                        onChanged={onChangeBandHandler(band.Id, "StartFrequency")}
                        passValueAnyway={true}
                        debounceTimer={400}
                        updateTimeStamp={state.timeStamp}
                    />
                )
            }, {
                key: 'endFreq',
                content: (
                    <GeovisValidatableTextfield
                        type="number"
                        //key={`end-freq-${band.Id}-${state.timeStamp}`}
                        value={band.EndFrequency.toString()}
                        isDisabled={isDisabled}
                        isClearable={true}
                        onCheckValidate={validateBandValue(band.Id, "EndFrequency")}
                        onChanged={onChangeBandHandler(band.Id, "EndFrequency")}
                        passValueAnyway={true}
                        debounceTimer={400}
                        updateTimeStamp={state.timeStamp}
                    />
                )
            }, {
                key: 'val',
                content: (
                    <GeovisValidatableTextfield
                        type="number"
                        //key={`value-${band.Id}-${state.timeStamp}`}
                        value={band.Value.toString()}
                        isDisabled={isDisabled}
                        isClearable={true}
                        onCheckValidate={validateBandValue(band.Id, "Value")}
                        onChanged={onChangeBandHandler(band.Id, "Value")}
                        passValueAnyway={true}
                        debounceTimer={400}
                        updateTimeStamp={state.timeStamp}
                    />
                )
            }, {
                key: 'actions',
                content: (
                    <Button
                        iconBefore={<CrossCircleIcon label="add" />}
                        appearance="subtle"
                        onClick={onRemoveBand(index)}
                        isDisabled={isDisabled || collection.length === 1}
                    />
                )
            }]
        }))
    }

    const getHead = (): HeadType => ({
        cells: [{
            content: `${t("Low limit")} [${getPhysicalUnitShortName(PhysicalUnit.Herz)}]`
        }, {
            content: `${t("High limit")} [${getPhysicalUnitShortName(PhysicalUnit.Herz)}]`
        }, {
            content: `${t("Alarm Value")} [${getPhysicalUnitShortName(PhysicalUnit.MillimeterPerSecond)}]`
        }]
    })

    const onAddBand = () => {
        if (bands.length > 0) {
            bands.push({
                Id: Guid.create().toString(),
                StartFrequency: bands[bands.length - 1].EndFrequency,
                Value: bands[bands.length - 1].EndFrequency + 10,
                EndFrequency: bands[bands.length - 1].EndFrequency + 20
            })
        }
        else {
            bands.push({
                Id: Guid.create().toString(),
                StartFrequency: 0,
                Value: 10,
                EndFrequency: 20
            })
        }

        onChangeHandler(bands);
    }

    const onSetPageChangedHandler = (page: number) => {
        setState({
            ...state,
            selectedPage: page
        })
    };

    const isBandValueValid = (bandId: string, propertyName: keyof VibrationFrequencyBand, value: number, existingBands: VibrationFrequencyBand[]): { isValid: boolean, error: string } => {
        if (isNaN(value)) {
            onBandsValidationEnd(bandId, propertyName, false);
            return { error: "Wrong data", isValid: false };
        }

        if (value < 0) {
            onBandsValidationEnd(bandId, propertyName, false);
            return { error: "Negative values are not allowed", isValid: false };
        }

        const band = existingBands.find(b => b.Id === bandId);

        if (!band) {
            onBandsValidationEnd(bandId, propertyName, false);
            return { error: "No band with specified ID was found", isValid: false };
        }

        switch (propertyName) {
            // case "Value":
            //     if (existingBands.filter(b => b.EndFrequency < band.EndFrequency && b.Value > value && b.Id !== bandId || b.StartFrequency > band.StartFrequency && b.Value < value && b.Id !== bandId).length > 0) {
            //         onBandsValidationEnd(band.Id, propertyName, false);
            //         return { error: "Bands not ordered by value", isValid: false };
            //     }
            //     break;
            case "EndFrequency":
                if (band.StartFrequency >= value) {
                    onBandsValidationEnd(band.Id, propertyName, false);
                    return { error: "High limit should be greater then Low limit", isValid: false };
                }
                //if (existingBands.filter(b => b.Id !== bandId && (b.StartFrequency > band.StartFrequency && b.StartFrequency < value || b.EndFrequency > band.StartFrequency && b.EndFrequency < value)).length > 0) {
                if (existingBands.filter(b => b.Id !== bandId && b.StartFrequency < value && b.EndFrequency > value).length > 0) {
                    onBandsValidationEnd(band.Id, propertyName, false);
                    return { error: "Crossing bands detected", isValid: false };
                }
                break;
            case "StartFrequency":
                if (value >= band.EndFrequency) {
                    onBandsValidationEnd(band.Id, propertyName, false);
                    return { error: "Low limit should be less then High limit", isValid: false };
                }
                //if (existingBands.filter(b => b.Id !== bandId && (b.StartFrequency > value && b.StartFrequency < band.EndFrequency || b.EndFrequency > value && b.EndFrequency < band.EndFrequency)).length > 0) {
                if (existingBands.filter(b => b.Id !== bandId && b.StartFrequency <= value && b.EndFrequency > value).length > 0) {
                    onBandsValidationEnd(band.Id, propertyName, false);
                    return { error: "Crossing bands detected", isValid: false };
                }
                break;
        }

        onBandsValidationEnd(bandId, propertyName, true);
        return { error: '', isValid: true }
    }

    const validateBandValue = (bandId: string, propertyName: keyof VibrationFrequencyBand) => (value: string): { isValid: boolean, error: string } => {
        return isBandValueValid(bandId, propertyName, +value, bands);
    }

    const onChangeBandHandler = (bandId: string, propertyName: keyof VibrationFrequencyBand) => (value: string) => {
        //onChangeHandlerDebounced(bandId, propertyName, value);
        if (isNaN(+value)) {
            return;
        }
        const band = bands.find(b => b.Id === bandId);
        if (band) {
            switch (propertyName) {
                case "EndFrequency": band.EndFrequency = +value; break;
                case "StartFrequency": band.StartFrequency = +value; break;
                case "Value": band.Value = +value; break;
            }
            onChangeHandler(bands);
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '350px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ flexGrow: 5, textAlign: 'center', marginLeft: '10px', fontWeight: 'bold', paddingLeft: '100px' }}>
                    {t("Frequency bands")}
                </div>
                <div style={{ marginLeft: '5px' }}>
                    <Button
                        iconBefore={<AddCircleIcon label={"addBand"} size="large" />}
                        appearance="subtle"
                        onClick={onAddBand}
                        isDisabled={isDisabled}
                    >
                        {t("Add band")}
                    </Button>
                </div>
            </div>
            <div style={{ flexGrow: 5 }}>
                <DynamicTableStateless
                    head={getHead()}
                    rows={getRows()}
                    rowsPerPage={5}
                    page={state.selectedPage}
                    onSetPage={onSetPageChangedHandler}
                />

            </div>
        </div>
    )
}