/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 18.07.2023
 * @description Geovis report map section view
 */

//import { useState } from "react";
import { TimeSearchDistanceDataRequest } from "../../../../../../server/AVTService/Computation/TimeSearchDistance/TimeSearchDistanceDataRequest";
import { IChainsInfoStorage, IChainsLastDataStorage, ISensorsInfoStorage, ISensorsLastDataStorage } from "../../../../../../store/data.types";
import { IGeovisMapSectionReportInfo } from "../../../../../../store/projectReport.types";
import GeovisMapSectionMap from "../../../../mapSection/render/GeovisMapSection.Map";
import ElementReportTitle, { TitleComponentHeightPx } from "../../ElementReportTitle";
import { processTitleTimeslotTemplate } from "../../tools";
import { IReportElementRenderHostOwnProp } from "../../types";
//import { getScale } from "../../../../../imageMap/tools";

interface IComponentProps extends Omit<IReportElementRenderHostOwnProp, 'eventInfo' | 'isDefault' | 'isVibrationEventChart' | 'showVibrationEventChartOnPage' | 'settingsId'> {
    mapSectionInfo: IGeovisMapSectionReportInfo;
    groupedMapSectionData: IGeovisMapSectionReportInfo | false;

    // takes from report data settings
    globalTimeslot: string;

    /**
     * This storage of sensors info, related to this map section
     */
    sensorsInfoStorage: ISensorsInfoStorage;

    /**
     * Sensors last data storage
     */
    sensorsLastDataStorage: ISensorsLastDataStorage;

    /**
     * This storage of chains, related to this map section
     */
    chainsInfoStorage: IChainsInfoStorage;

    /**
     * Chains data storage
     */
    chainsLastDataStorage: IChainsLastDataStorage

    /**
     * Request payload (data) for movement vectors and bars
     */
    timeSearchDistanceDataRequest: TimeSearchDistanceDataRequest;

    rootContainerWidth: number;
    allContainersWidth: number;

    isPreview: boolean
}

// interface IContainerSizeState {
//     width: number;
//     height: number;
//     ready: boolean;
// }

const Component = ({
    mapSectionInfo,
    elementInfo,
    elementIndexOnPage,
    globalTimeslot,
    projectId,
    isInGroup,
    lastInGroup,
    pageNum,
    userId,
    userToken,
    sensorsInfoStorage,
    sensorsLastDataStorage,
    chainsInfoStorage,
    chainsLastDataStorage,
    timeSearchDistanceDataRequest,
    rootContainerWidth,
    groupedMapSectionData,
    allContainersWidth,
    isPreview,
    isPrinting,
    reportId,
    firstInGroup
}: IComponentProps) => {

    const { MapSection } = mapSectionInfo;
    const { Height, DrawBorder, HideTitle } = elementInfo;
    const elementTitle = processTitleTimeslotTemplate(MapSection.Title, globalTimeslot);

    const scale = MapSection.MapArea.Width / rootContainerWidth;
    let itemHeight = MapSection.MapArea.Height / scale;
    let maxItemHeight = Height - (!HideTitle ? TitleComponentHeightPx : 0);
    let itemWidth = rootContainerWidth;

    //Check second ms in group if exists
    // 1. Only 2 map sections can be one group
    // 2. All Map sections in group should have same height
    if (groupedMapSectionData) {
        const secondContainerWidth = allContainersWidth - rootContainerWidth;
        const secondScale = groupedMapSectionData.MapSection.MapArea.Width / secondContainerWidth;
        const secondItemHeight = groupedMapSectionData.MapSection.MapArea.Height / secondScale;
        if (secondItemHeight < maxItemHeight) {
            maxItemHeight = secondItemHeight;
        }
    }

    if (itemHeight > maxItemHeight) {
        const updScale = itemHeight / maxItemHeight;
        itemWidth = rootContainerWidth / updScale;
        itemHeight = maxItemHeight;
    }

    if (!isPreview) {
        itemHeight = Height - (!HideTitle ? TitleComponentHeightPx : 0);
        itemWidth = rootContainerWidth;
    }

    const borderRightStyle = lastInGroup
        ? ""
        : DrawBorder ? "2px solid black" : "2px solid transparent";

    return (
        <div
            key={`geovis-map-section-render-view-${pageNum}-${MapSection.Id}`}
            style={{
                height: `${Height}px`,
                width: `100%`,
                borderRight: borderRightStyle
            }}>
            <div
                key={`render-view-title-content-${pageNum}-${MapSection.Id}`}
                style={{ display: 'flex', flexDirection: 'column', height: `100%` }}>
                {!HideTitle && (
                    <ElementReportTitle
                        elementTitle={elementTitle}
                        elementIndexOnPage={elementIndexOnPage}
                        isInGroup={isInGroup}
                        //lastInGroup={lastInGroup || DrawBorder}
                        projectId={projectId}
                        chartId={0}
                        isPrinting={isPrinting}
                        reportId={reportId}
                        drawBorder={DrawBorder}
                        firstInGroup={firstInGroup}
                    />
                )}
                <div style={{ display: 'grid', width: '100%', height: '100%', alignItems: 'center', justifyItems: 'center' }}>
                    <div id={`render-view-section-content-${pageNum}-${MapSection.Id}`}
                        className="mapSectionRenderViewContent"
                        style={{
                            height: `${itemHeight}px`,
                            width: isPrinting ? "100%" : `${itemWidth}px`
                        }}>
                        <GeovisMapSectionMap
                            pageNum={pageNum}
                            key={`ms-map-${itemHeight}-${itemWidth}`}
                            mapSectionInfo={mapSectionInfo}
                            sensorsInfoStorage={sensorsInfoStorage}
                            sensorsLastDataStorage={sensorsLastDataStorage}
                            chainsInfoStorage={chainsInfoStorage}
                            userId={userId}
                            userToken={userToken}
                            chainsLastDataStorage={chainsLastDataStorage}
                            timeSearchDistanceElementRequest={timeSearchDistanceDataRequest}
                        />
                    </div>
                </div>

            </div>
        </div >
    )
}


export default Component;