//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum SemiCircleGroupsOrderMode {
	Amount = 0,
	Value = 1
}

const SemiCircleGroupsOrderModeToName = new Map<number, string>([
	[ 0, 'Amount' ],
	[ 1, 'Value' ],
])

export function getSemiCircleGroupsOrderModeToName(element: SemiCircleGroupsOrderMode) : string {
    return SemiCircleGroupsOrderModeToName.get(element) || '';
}

const SemiCircleGroupsOrderModeByName = new Map<string, number>([
	[ 'Amount', 0 ],
	[ 'Value', 1 ],
]);

export function getSemiCircleGroupsOrderModeByName(name: string): SemiCircleGroupsOrderMode {
    return SemiCircleGroupsOrderModeByName.get(name)!;
}


export const SemiCircleGroupsOrderModeList : Readonly<SemiCircleGroupsOrderMode[]> = [
	SemiCircleGroupsOrderMode.Amount,
	SemiCircleGroupsOrderMode.Value,
];



const SemiCircleGroupsOrderModeToDescription = new Map<number, string>([
	[ 0, 'Amount' ],
	[ 1, 'Value' ],
])

export function getSemiCircleGroupsOrderModeToDescription(element: SemiCircleGroupsOrderMode) : string {
    return SemiCircleGroupsOrderModeToDescription.get(element) || '';
}

