/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 02.11.2023
 * @description The sensor movement icons render (for many icons)
 */

import { Fragment } from "react";
import { ICloseToBorderSensorsIds, IHeightMovementVectorInfo, IMovementIconInfo, IMovementVectorInfo } from "./types";
import { SensorMovementIconText } from "./SensorMovementIconText";
import { SensorInfo } from "../../../server/GEOvis3/Model/SensorInfo";
import { getMandatoryOrientation } from "./tools";
import { SensorCategory } from "../../../server/AVTService/TypeLibrary/Sensors/SensorCategory";
import { Geovis4SensorSymbolSettings } from "../../../server/AVTService/TypeLibrary/Sensors/SymbolSettings/Geovis4SensorSymbolSettings";
import { SensorValueAttribute } from "../../../server/AVTService/TypeLibrary/Common/SensorValueAttribute";
import { SensorMeasurementInfo } from "../../../server/SensorMeasurementInfo";

export interface ISensorMovementIconData {
    vectorInfo: IMovementIconInfo;
    sensorInfo: SensorInfo;
}

/**
 * The method to prepare data for rendering sensor icon text with all required information
 * @param vectorsMovementData 
 * @param barsMovementData 
 */
export const prepareSensorIconMovementData = (
    sensorsInfo: SensorInfo[],
    vectorsMovementData: IMovementVectorInfo[] | false,
    barsMovementData: IHeightMovementVectorInfo[] | false,
    useSensorColor: boolean,
    closeToBorderSensorsIds: ICloseToBorderSensorsIds | undefined,
    getLatLngStartPosition: (sensorInfo: SensorInfo) => L.LatLng): ISensorMovementIconData[] => {

    const result: ISensorMovementIconData[] = [];

    for (const sensorInfo of sensorsInfo) {

        const iconData: ISensorMovementIconData = {
            sensorInfo,
            vectorInfo: {
                angle: 0,
                barDeviationText: undefined,
                color: useSensorColor ? sensorInfo.Color : 'rgb(0,0,0)',
                mandatoryOrientation: getMandatoryOrientation(closeToBorderSensorsIds, sensorInfo),
                sensorFullId: sensorInfo.Id,
                startPosition: getLatLngStartPosition(sensorInfo),
                vectorDeviationText: undefined
            }
        };

        if (vectorsMovementData) {
            const vectorMovementInfo = vectorsMovementData.find(v => v.sensorFullId === sensorInfo.Id);
            if (vectorMovementInfo) {
                iconData.vectorInfo.vectorDeviationText = vectorMovementInfo.value;
                iconData.vectorInfo.angle = vectorMovementInfo.angle;
            }
        }

        if (barsMovementData) {
            const barMovementInfo = barsMovementData.find(v => v.sensorFullId === sensorInfo.Id);
            if (barMovementInfo) {
                iconData.vectorInfo.barDeviationText = barMovementInfo.deviationText;
            }
        }

        result.push(iconData);
    }

    return result;
}

interface ISensorMovementIconsTextRenderProps {
    iconMovementData: ISensorMovementIconData[];

    fontSize: number;
    showSensorName: boolean;
    showVectorDeviation: boolean;
    showBarDeviation: boolean;
    useGeovis4Symbol?: boolean;
    showBorder: boolean;
    noScale: boolean;
    replaceIconMap?: Map<SensorCategory, Geovis4SensorSymbolSettings>;
    additionalProps: SensorValueAttribute[];
    sensorsData: Map<string, SensorMeasurementInfo>;
}


export const SensorMovementIconsTextRender = ({ iconMovementData, ...restProps }: ISensorMovementIconsTextRenderProps) => (
    <Fragment>
        {iconMovementData.map((r, index) => (
            <SensorMovementIconText
                key={`movement_vector_text_${index}`}
                {...r}
                {...restProps}
            />
        ))}
    </Fragment>
)