//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { GeovisChartData } from '../../../TypeLibrary/Computation/GeovisChartData';
import { SemiCircleChartDataModel } from '../../../TypeLibrary/Model/SemiCircleChartDataModel';

export class SemiCircleChartResponseModel extends GeovisChartData
{
	public StartDate: string;
	public EndDate: string;
	public Timeslot: string;
	public Data: SemiCircleChartDataModel[];
	public LastMeasurementTime: string;
}
