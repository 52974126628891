import { deprecate } from "util";
import {
    ProjectStateColorGreen,
    ProjectStateColorLightGray
} from "../components/map/types";
import { AlarmInfo } from "../server/AlarmInfo";
import { AlarmType } from "../server/AVTService/TypeLibrary/Alarming/AlarmType";
import { NotificationsTypes } from "../server/AVTService/TypeLibrary/Notifications/NotificationsTypes";
import { getDigitsAfterDecimalPoint, PhysicalUnit } from "../server/AVTService/TypeLibrary/Sensors/PhysicalUnit";
import { SensorCategory } from "../server/AVTService/TypeLibrary/Sensors/SensorCategory";
import { SensorSymbol } from "../server/AVTService/TypeLibrary/Sensors/SensorSymbol";
import { ChainInfo } from "../server/ChainInfo";
import { SensorInfo } from "../server/GEOvis3/Model/SensorInfo";
import { SensorBase } from "../server/SensorBase";
import AuthService from "../services/AuthService";
import { compareFnOfElementInfoAlarms } from "./SortHelper";
import { ITreeElementObj } from "./types";
import { ImageBackgroundInfo } from "../server/AVTService/TypeLibrary/ImageAsMap/ImageBackgroundInfo";

export const getAliasFromFullId = (fullId: string): string => {
    if (!fullId) {
        return '';
    }

    return fullId.substring(0, fullId.indexOf('.'));
}

export const getAliasesFromFullIds = (fullIds: string[]): string[] => {
    const result: string[] = [];
    fullIds.forEach(fullId => {
        const alias = getAliasFromFullId(fullId);
        if (alias !== '' && !result.includes(alias)) {
            result.push(alias);
        }
    });

    return result;
}

export const getSensorFullId = (info: { Id: string, DatabaseId: string }): string => `${info.DatabaseId}.${info.Id}`;

export function toFixedPhysicalUnitValue(unit: PhysicalUnit, value: number): string {
    let digitsAfterPoint = getDigitsAfterDecimalPoint(unit);
    if (digitsAfterPoint === undefined) {
        digitsAfterPoint = 0;
    }
    return value.toFixed(digitsAfterPoint);
}

export function toFixedSensorValue(value: number, sensor: SensorBase): string {
    if (typeof value !== 'number') {
        return '';
    }
    return value.toFixed(sensor.UnitDecimals);
}

/**
 * Return true if sensor have any alarm
 * @param element 
 */
export const isElementWithAlarms = <TElement extends { CausedAlarms: AlarmInfo[] }>(element: TElement): boolean => {
    return element.CausedAlarms && element.CausedAlarms.length > 0;
}

interface IAlarmInfoObj { CausedAlarms: AlarmInfo[], WatchdogEnabled: boolean, Color: string, UseColorOnMap: boolean }

/**
 * Get sensor color for alarm
 * @param alarmInfoObject of type IAlarmInfoObj
 */
export const getAlarmedSensorColors = <TPoint extends IAlarmInfoObj>({ CausedAlarms, WatchdogEnabled, Color, UseColorOnMap }: TPoint): string[] => {

    if (CausedAlarms && CausedAlarms.length === 1) {
        return [CausedAlarms[0].Color];
    }

    if (CausedAlarms && CausedAlarms.length > 1) {
        const sortedAlarms = CausedAlarms.sort((a, b) => a.SeverityIndex - b.SeverityIndex);
        return sortedAlarms.map(a => a.Color).filter((item, index, self) => self.indexOf(item) === index);
    }

    if (UseColorOnMap) {
        return [Color];
    }

    return WatchdogEnabled ? [ProjectStateColorGreen] : [ProjectStateColorLightGray];
}

/**
 * Get sensors colors for alarms (and icon)
 * @param points 
 * TO DO: cover with test
 */
export const getAlarmedSensorsColors = <TPoint extends IAlarmInfoObj>(points: TPoint[]): string[] => {

    const alarmColors: string[] = [];

    for (const point of points) {
        const pointColors = getAlarmedSensorColors(point);
        alarmColors.push(...pointColors);
    }

    // distinct values
    return alarmColors.reduce((previousValue: string[], currentValue: string, currentIndex: number, array: string[]) => {
        if (array.length === 0) {
            return [currentValue];
        }

        if (previousValue.indexOf(currentValue) > -1) {
            return previousValue;
        }

        return [...previousValue, currentValue];

    }, []);
}

interface ITAlarmObj {
    IsPublic: boolean;
    AGMSAlarmType: NotificationsTypes;
    IsOkay?: boolean;
}

/**
 * Filter out the FA2 functional alarms
 * @param projectId
 * @param alarmsInfo
 */
export const getSensorAlarmsForViewer = <TAlarmObj extends ITAlarmObj>(projectId: number, alarmsInfo: TAlarmObj[]): TAlarmObj[] => {
    const publicAlarms = alarmsInfo.filter(a => a.IsPublic || a.IsOkay);

    if (AuthService.isAllowedShowSensorWarningState(projectId)) {
        return publicAlarms;
    }

    const viewerAlarms = publicAlarms.filter(a => a.AGMSAlarmType !== NotificationsTypes.PrismNotMeasured &&
        a.AGMSAlarmType !== NotificationsTypes.PrismNotAccurate &&
        a.AGMSAlarmType !== NotificationsTypes.InclinometerChainNotMeasured);

    return viewerAlarms
}


export const getProjectRoleAllowedSensors = (projectId: number, sensors: SensorInfo[]) => {
    // return sensors for admin
    if (AuthService.isActualAdminOfProject(projectId)) {
        return sensors;
    }

    // filter out not public sensors and return sensors for viewer
    const publicOnlySensors = sensors.filter(s => s.IsPublic);
    return publicOnlySensors.map(sensor => ({ ...sensor, CausedAlarms: getSensorAlarmsForViewer(projectId, sensor.CausedAlarms) }));
}


/**
 * Get sensor with highest severity, or false if no sensors with alarms
 * @param projectId 
 * @param sensorsInfo 
 */
export const getElementWithHighestAlarm = <TElement extends ITreeElementObj>(projectId: number, sensorsInfo: TElement[]): TElement | false => {

    let sensorsWithAlarms = sensorsInfo.filter(s => s.CausedAlarms && s.CausedAlarms.length > 0);

    // remove out alarms, which not visible for viewer
    if (!AuthService.isActualAdminOfProject(projectId)) {
        sensorsWithAlarms = sensorsWithAlarms
            .map<TElement>(s => ({ ...s, CausedAlarms: getSensorAlarmsForViewer(projectId, s.CausedAlarms) }))
            .filter(s => s.CausedAlarms && s.CausedAlarms.length > 0);
    }

    if (sensorsWithAlarms.length === 0) {
        return false;
    }

    const sortedSensorsWithAlarms = sensorsWithAlarms.sort(compareFnOfElementInfoAlarms);

    return sortedSensorsWithAlarms[sortedSensorsWithAlarms.length - 1]; // take last, because last has highest alarm
}

export const getElementWithAllAlarmStates = <TElement extends ITreeElementObj>(projectId: number, sensorsInfo: TElement[]): TElement | false => {
    let sensorsWithAlarms = sensorsInfo.filter(s => s.CausedAlarms && s.CausedAlarms.length > 0);

    // remove out alarms, which not visible for viewer
    if (!AuthService.isActualAdminOfProject(projectId)) {
        sensorsWithAlarms = sensorsWithAlarms
            .map<TElement>(s => ({ ...s, CausedAlarms: getSensorAlarmsForViewer(projectId, s.CausedAlarms) }))
            .filter(s => s.CausedAlarms && s.CausedAlarms.length > 0);
    }

    if (sensorsWithAlarms.length === 0) {
        return false;
    }

    const uniqueAlarms: AlarmInfo[] = [];
    const isNewAlarmState = (alarm: AlarmInfo) => uniqueAlarms.findIndex(a => a.Color === alarm.Color) === -1;

    sensorsWithAlarms.forEach((sensor) => {
        sensor.CausedAlarms.forEach((alarm) => {
            if (isNewAlarmState(alarm)) {
                uniqueAlarms.push(alarm);
            }
        })
    });

    return { ...sensorsWithAlarms[0], CausedAlarms: [...uniqueAlarms] };
}

/**
 * Get highest alarm
 * @param alarms 
 * @param alarmTypes the filter for alarm types, will be search only on defined alarm types
 */
export const getHighestAlarm = (alarms: AlarmInfo[], alarmTypes?: AlarmType[]): AlarmInfo | false => {
    if (!alarms) {
        return false;
    }

    if (alarmTypes) {
        alarms = alarms.filter(alarm => alarmTypes.indexOf(alarm.AlarmType) > -1);
    }

    if (alarms.length === 0) {
        return false;
    }

    if (alarms.length === 1) {
        return alarms[0];
    }

    const sorted = alarms.sort((a, b) => a.SeverityIndex - b.SeverityIndex);

    return sorted[0];
}

/**
 * Convert ChainInfo to SensorInfo
 * Saves reports, alarms and other properties
 * Output with PhysicalType = SensorCategory.BH_Inclinometer, don't change this
 * it means that current SensorInfo represents the ChainInfo
 * @param chain 
 */
export const getChainAsSensorInfo = deprecate((chain: ChainInfo): SensorInfo => {

    const chainAsSensorInfo: SensorInfo = {
        ...chain,
        // BH_Inclinometer - means that SensorInfo represent a ChainInfo, do not change this logic, it is important
        PhysicalType: SensorCategory.BH_Inclinometer,
        Symbol: SensorSymbol.Circle,
        ShowAbsoluteValue: false,
        ShowRelativeValue: false,
        IsPublic: true,
        DatabaseId: '',
        RelatedDocuments: [],
        SensorLink: '',
        SensorLinkName: '',
        UseColorOnMap: false,
        Selected: true,
        SelectedSensorsCount: 0,
        NativeCoordinates: { ...chain.Coordinates },
        NativeCoordinateSystem: chain.NativeCoordinateSystem,
        ImageInfo: new ImageBackgroundInfo(),
        Param1: "",
        Param2: "",
        Param3: "",
        Param4: "",
        Param5: ""
    };

    return chainAsSensorInfo;
}, 'Old method, not required anymore')