export const TASKS_MANAGER_TASKS_LOADING = "TASKS_MANAGER_TASKS_LOADING";
export const TASKS_MANAGER_TASKS_LOADED  = "TASKS_MANAGER_TASKS_LOADED";

export const TASKS_MANAGER_TASKS_FILTER_LOADING = "TASKS_MANAGER_TASKS_FILTER_LOADING";
export const TASKS_MANAGER_TASKS_FILTER_LOADED  = "TASKS_MANAGER_TASKS_FILTER_LOADED";
export const TASKS_MANAGER_TASKS_FILTER_CHANGED = "TASKS_MANAGER_TASKS_FILTER_CHANGED";

export const TASKS_MANAGER_SHOW_DELETE_DIALOG = "TASKS_MANAGER_SHOW_DELETE_DIALOG";
export const TASKS_MANAGER_HIDE_DELETE_DIALOG = "TASKS_MANAGER_HIDE_DELETE_DIALOG";

export const TASKS_MANAGER_SHOW_DETAILS_DIALOG = "TASKS_MANAGER_SHOW_DETAILS_DIALOG";
export const TASKS_MANAGER_HIDE_DETAILS_DIALOG = "TASKS_MANAGER_HIDE_DETAILS_DIALOG";

export const TASKS_MANAGER_DELETE_TASK = "TASKS_MANAGER_DELETE_TASK";

export const TASKS_MANAGER_ON_PROJECTS_FILTER_CHANGED = "TASKS_MANAGER_ON_PROJECTS_FILTER_CHANGED";