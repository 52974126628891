import { Fragment, useEffect } from "react";
import { IWithGeovisServerProps, withGeovisServer } from "../../../../helpers/GeovisHooks";
import { fetchServerElementsByPost } from "../../../../helpers/ProjectDataHelper";
import { t } from "../../../../i18n";
import { DtsChartModel } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/DtsChartModel";
import { DataActionResponse } from "../../../../server/DataActionResponse";
import { DtsSectionInfoSlim } from "../../../../server/GEOvis3/Model/DtsConfiguration/DtsSectionInfoSlim";
import { DtsChartSensorsInfo } from "../../../../server/GEOvis3/Model/GeovisChart/DtsChartSensorsInfo";
import ServerRoutesGen from "../../../../server/Routes/ServerRoutesGen";
import GeovisReactDateTimePicker from "../../../dateTimePickers/GeovisReactDateTimePicker";
import { GeovisSelectMultiT } from "../../../select/GeovisSelect";
import { IGvOptionType } from "../../../select/GeovisSelect_tools";
import { isChartTemplate } from "../tools";
import {
    defaultChartLabelColumnStyle,
    defaultChartRowSettingsStyle,
    IGeovisChartPropertyChangedProps
} from "../types";
import { ElementSettingAllowCheckbox } from "../../ElementSettingAllowCheckbox";

export interface IGeovisDtsSectionLoadActions {
    onDtsChartSectionLoading: () => void;
    onDtsChartSectionLoaded: (response: DataActionResponse<DtsChartSensorsInfo>) => void;
}

interface IDtsChartMainSettingsProps extends IGeovisChartPropertyChangedProps<DtsChartModel>, IWithGeovisServerProps, IGeovisDtsSectionLoadActions {
    dtsChartSections: DtsSectionInfoSlim[];
}

export const DtsChartMainSettings = withGeovisServer(({
    chart,
    dtsChartSections,
    Server,
    onDtsChartSectionLoaded,
    onDtsChartSectionLoading,
    onChartPropertyChanged
}: IDtsChartMainSettingsProps) => {

    const { StartDateSetting, ProjectId, DtsSectionIds } = chart;

    const labelColumnStyle = defaultChartLabelColumnStyle;
    const settingRowStyle = defaultChartRowSettingsStyle;

    const dtsSectionInfoToOption = (section: DtsSectionInfoSlim): IGvOptionType<string> => ({ label: section.Name, value: section.Id });
    const getDtsSectionOptions = (): Array<IGvOptionType<string>> => {
        if (!dtsChartSections) {
            return [];
        }
        return dtsChartSections.map(s => dtsSectionInfoToOption(s));
    }

    const selectedDtsSectionOptions = dtsChartSections
        .filter(s => DtsSectionIds.includes(s.Id))
        .map(s => dtsSectionInfoToOption(s));

    const onDtsSectionsChanged = async (values: Array<IGvOptionType<string | number>>) => {
        const sectionIds = !values ? [] : values.map(o => o.value);
        onChartPropertyChanged("DtsSectionIds", sectionIds);
        // the main code should change the property and trigger useEffect below
    }

    // loading DTS section info at start (when mounted)
    useEffect(() => {
        (async function loadDtsSectionInfo() {

            onDtsChartSectionLoading();

            const route = ServerRoutesGen.GeovisChart.GetDtsChartSensorsInfoBySectionIds.patch(ProjectId);
            const response = await fetchServerElementsByPost<DtsChartSensorsInfo, string[]>(Server, route, chart.DtsSectionIds);
            onDtsChartSectionLoaded(response);

        })();

    }, [chart.DtsSectionIds]);

    const onChangeDateTimeValue = (value: string) => {
        onChartPropertyChanged("StartDateSetting", { Value: value, customerChangeable: StartDateSetting.customerChangeable });
    }

    const onChangeDateTimeCustomerChangeable = (value: boolean) => {
        onChartPropertyChanged("StartDateSetting", { Value: StartDateSetting.Value, customerChangeable: value });
    }

    return (
        <Fragment>
            <div className="flexRowContainerLine" style={settingRowStyle}>
                <div style={labelColumnStyle}>
                    <label htmlFor="tbDateTime">{t("Date / Time")}:</label>
                </div>
                <div className="flexCellContainer_g1_m0">
                    <GeovisReactDateTimePicker
                        locale="en-GB"
                        value={StartDateSetting.Value}
                        onChange={onChangeDateTimeValue}
                    />
                </div>
                <ElementSettingAllowCheckbox
                    isChecked={StartDateSetting.customerChangeable}
                    onChange={onChangeDateTimeCustomerChangeable}
                    isDisabled={false}
                />
            </div>
            {!isChartTemplate(chart) &&
                <div className="flexRowContainerLine" style={settingRowStyle}>
                    <div style={labelColumnStyle}>
                        <label htmlFor="cmbDtsSections">{t("DTS-Section")}:</label>
                    </div>
                    <div className="flexCellContainer_g1_m0">
                        <GeovisSelectMultiT<string>
                            id="cmbDtsSections"
                            options={getDtsSectionOptions()}
                            value={selectedDtsSectionOptions}
                            onChange={onDtsSectionsChanged}
                            isCompact={true}
                            isMulti={true}
                            spacing='compact' />
                    </div>
                </div>
            }
        </Fragment>
    );
})