/**
 * @author Vyacheslav Skripin <vs@iesk.ru>
 * @created 25.04.2022
 * @description Dts chart X-axis settings
 */

import { ChartType } from '../../../../server/AVTService/TypeLibrary/Common/ChartType';
import { DtsChartModel } from '../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/DtsChartModel';
import { GeovisChartAxisSettings } from '../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartAxisSettings';
import { GeovisChartAxisSettingsControl } from '../GeovisChartAxisSettingsControl';
import { IGeovisChartPropertyChangedProps } from '../types';
import { dtsChartXAxisSensorTypes } from './types';

interface IDtsChartXAxisTabProps extends IGeovisChartPropertyChangedProps<DtsChartModel> {
    chart: DtsChartModel;
}

/**
 * Dts chart X-Axis settings tab
 * @param props IDtsChartXAxisTabProps
 * @returns 
 */
export const DtsChartXAxisTab = ({ chart, onChartPropertyChanged }: IDtsChartXAxisTabProps) => {


    const onAxisPropertyChangedHandler = (propertyName: keyof GeovisChartAxisSettings, value: any) => {
        const changes: Partial<GeovisChartAxisSettings> = { ...chart.XAxisSettings };
        changes[propertyName] = value;

        onChartPropertyChanged("XAxisSettings", changes);
    }

    const onAxisPropertiesChangedHandler = (properties: Partial<GeovisChartAxisSettings>) => {
        onChartPropertyChanged<GeovisChartAxisSettings>("XAxisSettings", { ...chart.XAxisSettings, ...properties });
    }

    return (
        <div className="geovisChartTab" style={{ overflow: 'hidden' }}>
            <div className="flexRowContainerLine" style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                <GeovisChartAxisSettingsControl
                    chartType={ChartType.DtsChart}
                    axisSettings={chart.XAxisSettings}
                    onAxisPropertiesChanged={onAxisPropertiesChangedHandler}
                    onAxisPropertyChanged={onAxisPropertyChangedHandler}
                    sensorTypesToSelect={dtsChartXAxisSensorTypes}
                    isXAxis={true}
                    isRightAxis={false}
                    isDisabled={false}
                />
            </div>
        </div>
    )
}

