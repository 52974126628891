import { HeadType, RowType } from "@atlaskit/dynamic-table/dist/types/types";
import Button from "@atlaskit/button";
import EditorRemoveIcon from '@atlaskit/icon/glyph/editor/remove';
import { t } from "i18next";
import { GeovisUploadFileState } from "../../store/uploading.types";
import { GeovisDynamicTable } from "../GeovisDynamicTable";

interface IComponentProps {
    files: GeovisUploadFileState[];
    onFileDelete: (fileId: string) => void;
}

export const UploadedFilesTable = ({
    files,
    onFileDelete
}: IComponentProps) => {

    const onFileDeleteHandler = (fileId: string) => () => {
        onFileDelete(fileId);
    }

    const getRows = (): RowType[] => {
        return files.map(file => ({
            cells: [{
                key: 'name',
                content: (<span>{file.name}</span>)
            }, {
                key: 'actions',
                content: (
                    <Button
                        onClick={onFileDeleteHandler(file.id)}
                        appearance='subtle'
                        iconBefore={<EditorRemoveIcon label={""} />}
                    />
                )
            }]
        }))
    }

    const getHead = (): HeadType => ({
        cells: [{
            key: 'name',
            content: t("File name"),
            width: 90
        }, {
            key: 'actions',
            width: 10
        }]
    });

    return (
        <GeovisDynamicTable
            rows={getRows()}
            head={getHead()}
            caption={t("Uploaded files")}
        />
    )
}