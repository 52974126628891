import Checkbox from "@atlaskit/checkbox";
import { SyntheticEvent } from "react";
import { t } from "../../i18n";

interface IChartSettingAllowCheckboxProps {
    isChecked: boolean;
    onChange: (value: boolean) => void;

    isDisabled?: boolean;
    labelText?: string;
}

export const ElementSettingAllowCheckbox = ({
    isChecked,
    isDisabled,
    labelText,
    onChange
}: IChartSettingAllowCheckboxProps) => {
    const onChangeHandler = (event: SyntheticEvent<HTMLInputElement>) => {
        onChange(event.currentTarget.checked);
    }

    return (
        <div className="flexCellContainer_g1_m0" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
            <label>{labelText || t("Allow")}</label>
            <Checkbox
                id="allow-checkbox-id"
                isChecked={isChecked}
                isDisabled={isDisabled}
                onChange={onChangeHandler}
                label={undefined} />
        </div>
    )
}