import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
import { WrapperProps } from "@atlaskit/modal-dialog/dist/cjs/components/ModalWrapper";

export type GeovisDialogSize = "normal" | "slim" | "wide";

interface IComponentProps extends Partial<WrapperProps> {
    children: React.ReactElement
    dialogSize?: GeovisDialogSize
}

export const GeovisModalDialog = (props: IComponentProps) => {
    const size = props.dialogSize ?? "normal";
    const getWidth = (dialogSize: GeovisDialogSize): string => {
        switch (dialogSize) {
            case "slim": return "x-large";
            case "wide": return "85%";
            default: return "60%";
        }
    }
    return (
        <ModalTransition>
            <Modal
                height={'100%'}
                width={getWidth(size)}
                autoFocus={false}
                {...props}

            >
                {props.children}
            </Modal>
        </ModalTransition>
    )
}