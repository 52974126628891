/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 27.03.2019
 * @description Redux actions and storage common types
 */

import { ReactText } from 'react';
import { Action } from 'redux';
import { IWithProjectViewerModeProperty } from '../components/abstract/IWithProjectViewerModeProperty';
import { MapProviders } from '../server/AVTService/TypeLibrary/Map/MapProviders';
import { SensorCategory } from '../server/AVTService/TypeLibrary/Sensors/SensorCategory';
import { DataActionResponse } from '../server/DataActionResponse';
import { GeovisFeedbackStatistic } from '../server/GEOvis3/Model/GeovisFeedback/GeovisFeedbackStatistic';
import { ProjectOverviewModel } from '../server/GEOvis3/Model/ProjectsOverview/ProjectOverviewModel';
import { IFlag } from '../services/FlagService';

export const ProjectsViewAsMap = "ProjectsViewAsMap";
export const ProjectsViewAsTable = "ProjectsViewAsTable";

export const ProjectsViewFilterOnlyMy = "ProjectsViewFilterOnlyMy";
export const ProjectsViewFilterAll = "ProjectsViewFilterAll";

// ACTIONS
// ====================================================================
export interface IGeovisAction extends Action<string> {
    isLoading?: boolean;
    isPostInProgress?: boolean;
    errorDescription?: string;
}

export interface IGeovisLoginAction extends Action<string> {
    errorMessage?: string;
    isLoginFailed?: boolean;
    isLoginInProgress?: boolean;
    messageLanguage?: string;
}

export interface IGeovisNavigationAction extends IGeovisAction {
    flag?: IFlag;
    language?: string;
    mapProvider?: MapProviders;
    statistic?: DataActionResponse<GeovisFeedbackStatistic>;
}

export interface IGeovisServiceWorkerAction extends IGeovisAction {
    toastId?: ReactText;
    serviceWorkerRegistration?: ServiceWorkerRegistration;
}

export interface IGeovisSettingsAction extends IGeovisAction {
    booleanProperty?: boolean;
    stringProperty?: string;
}

export interface IGeovisProjectsOverviewAction extends IGeovisAction {
    projects?: DataActionResponse<ProjectOverviewModel[]>;
    view?: string;
    filter?: string;
    projectId?: number;
    showOnlyUserProjects?: boolean;
    showProjectName?: boolean;
    rowsPerPage?: number;
    searchQuery?: string;
    filterObjectId?: string;
    projectToUpdate?: ProjectOverviewModel;
}

// STATES
// ====================================================================

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IFeedbackStatisticStorage extends ISomethingStorageBaseEx<GeovisFeedbackStatistic> {

}

/**
 * Root Geovis navigation state
 * Describes current navigation route
 */
export interface IGeovisNavigationState extends IWithProjectViewerModeProperty {
    flags: IFlag[];
    language: string;
    mapProvider: MapProviders;
    feedbackStatisticStorage: IFeedbackStatisticStorage;
}

export interface IGeovisServiceWorkerState {
    isNewVersionAvailable: boolean;
    toastId: ReactText;
    serviceWorkerRegistration?: ServiceWorkerRegistration;
}

/**
 * Login state, there're all should be clear
 */
export interface IGeovisLoginState {
    type: string;
    isLoginInProgress: boolean;
    isLoginFailed: boolean;
    errorMessage: string;
    messageLanguage: string;
}

/**
 * Projects overview state for page ProjectsOverview
 */
export interface IGeovisProjectsOverviewState extends ISomethingStorageBaseEx<ProjectOverviewModel[]> {
    view: string;
    filter: string;
    showProjectName: boolean;
    rowsPerPage: number;
    searchQuery: string;
    noPermissionProjectId: number;
    reloadingProjects: boolean;
    showNoPermissionsDialog: boolean;
}

/**
 * State of settings
 */
export interface IGeovisSettingsState {
    showPingDialog: boolean;
    pingResult: string;
    pingSending: boolean;
}

/**
 * Base interface for store
 */
export interface ISomethingStoreBase {
    /** means that data is loading */
    isLoading: boolean;
    /** means that data is loaded and not need to reload it again */
    isLoaded: boolean;
    /** data loading is in progress */
    isInProgress: boolean;
    /** means error of data loading */
    isError: boolean;
    /** error description */
    errorDescription: string;
}

export interface ISomethingStorageBaseEx<TData> extends ISomethingStoreBase {
    data: TData;
}

export interface IMapViewSensorsLayerState {
    showSensorNames: boolean;
    fontSize?: number;
    useSensorColor?: boolean;
    useSensorColorForText?: boolean;
    useGeovis4SensorTypeSymbol?: boolean;
    visibleSensorTypes: Map<SensorCategory, boolean>;
    showTooltipContentEvenFaAlarm?: boolean;
}

export interface IMapViewLayersVisibility {
    showSensorsLayer: boolean;
    showGeoJSONLayers: boolean;
    showInclinometerChains: boolean;
}

export interface IMapViewState {
    layersVisibility: IMapViewLayersVisibility;
    sensorsLayerState: IMapViewSensorsLayerState;
    showLegend: boolean;
}

/**
 * Default state of storage
 */
export const defaultSomethingStorageState: ISomethingStoreBase = {
    isError: false,
    isLoaded: false,
    isLoading: true,
    isInProgress: false,
    errorDescription: ''
};

/**
 * Default state of storage
 */
export const inProgressSomethingStorageState: ISomethingStoreBase = {
    isError: false,
    isLoaded: false,
    isLoading: true,
    isInProgress: true,
    errorDescription: ''
};

/**
 * Created something storage state with default value
 * @param defaultData 
 * @returns 
 */
export const getDefaultSomethingStorageStateEx = <TData>(defaultData: TData): ISomethingStorageBaseEx<TData> => ({
    ...defaultSomethingStorageState,
    data: defaultData
})

export const loadedSomethingStorageState: ISomethingStoreBase = {
    errorDescription: '',
    isError: false,
    isLoaded: true,
    isLoading: false,
    isInProgress: false
}

/**
 * Creates success loaded something storage with data
 * @param data 
 * @returns 
 */
export const getLoadedSomethingStorageStateEx = <TData>(data: TData): ISomethingStorageBaseEx<TData> => ({
    ...loadedSomethingStorageState,
    data
})

export const errorSomethingStorageState = (...errors: Array<string | undefined>): ISomethingStoreBase => ({
    errorDescription: errors.length ? errors.join(', ') : "Undefined error",
    isError: true,
    isLoaded: true,
    isLoading: false,
    isInProgress: false
});

export const feedbackStatisticStorageInitialState: IFeedbackStatisticStorage = {
    ...defaultSomethingStorageState,
    data: { ApplicationRecords: 0, UserRecords: 0 }
}

export const geovisNavigationInitialState: IGeovisNavigationState = {
    flags: [],
    language: "en",
    mapProvider: MapProviders.CartoDBPositron,
    viewerModeStamp: '',
    feedbackStatisticStorage: feedbackStatisticStorageInitialState
};

export const geovisServiceWorkerInitialState: IGeovisServiceWorkerState = {
    isNewVersionAvailable: false,
    toastId: "",
    serviceWorkerRegistration: undefined
};

export const loginInitialState: IGeovisLoginState = {
    type: "",
    errorMessage: "",
    isLoginFailed: false,
    isLoginInProgress: false,
    messageLanguage: "german"
}

export const projectsOverviewInitialState: IGeovisProjectsOverviewState = {
    ...defaultSomethingStorageState,
    data: [],
    noPermissionProjectId: 0,
    filter: ProjectsViewFilterOnlyMy,
    rowsPerPage: 25,
    showProjectName: false,
    view: ProjectsViewAsMap,
    isLoading: true,
    searchQuery: "",
    showNoPermissionsDialog: false,
    reloadingProjects: true
}