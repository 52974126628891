import { t } from "i18next";
import Pagination from "@atlaskit/pagination/dist/cjs/components/Pagination";
import Select, { OptionType } from "@atlaskit/select";
import { SyntheticEvent } from "react";

interface IPaginationProps {
    pageNumber: number;
    rowsPerPage: number;
    rowsPerPageOptions: number[];
    itemsCount: number;
    showCountRowsSelect?: boolean;
    onSetPageNumber: (pageNumber: number) => void;
    onSetRowsPerPage: (rowsPerPage: number) => void;
}

export const PaginationControl = ({
    itemsCount,
    onSetPageNumber,
    onSetRowsPerPage,
    pageNumber,
    rowsPerPage,
    rowsPerPageOptions,
    showCountRowsSelect
}: IPaginationProps) => {
    const createPaginationPages = (): number[] => {

        if (itemsCount === 0) {
            return [];
        }

        const allPagesCount = Math.ceil(itemsCount / rowsPerPage);

        const result: number[] = [];
        let current = 1;
        while (current <= allPagesCount) {
            result.push(current);
            current++;
        }

        return result;
    }

    const pages = createPaginationPages();
    const selectedIndex = pageNumber ? pages.indexOf(pageNumber) || 0 : 0;

    const getRowsPerPageSelectOptions = (): OptionType[] => rowsPerPageOptions.map(o => ({ label: o.toString(), value: o }));

    const selectOptions = getRowsPerPageSelectOptions();
    // eslint-disable-next-line eqeqeq
    const defaultOption = selectOptions.filter(o => o.value === rowsPerPage)[0];

    const selectStyle = {
        container(styles: any) {
            return { ...styles, width: '100px' };
        },
    };

    const onPageChangeHandler = (event: SyntheticEvent, page: number) => onSetPageNumber(page);

    const onRecordsPerPageChangedHandler = (option: OptionType) => onSetRowsPerPage(+option.value);

    return (
        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginTop: '5px' }}>
            {pages.length > 1 &&
                <div style={{ alignSelf: 'center' }}>
                    <Pagination
                        pages={pages}
                        selectedIndex={selectedIndex}
                        onChange={onPageChangeHandler}
                    />
                </div>
            }
            {showCountRowsSelect !== false &&
                <div className="flexRowContainer" style={{ alignItems: 'center' }}>
                    <div className="flexCellContainer">
                        <span>{t("chooseNumberOfRowsPerPageLabel")}</span>
                    </div>
                    <div className="flexCellContainer">
                        <Select
                            class="single-select"
                            options={selectOptions}
                            defaultValue={defaultOption}
                            styles={selectStyle}
                            isSearchable={false}
                            onChange={onRecordsPerPageChangedHandler}
                            spacing="compact"
                            menuPlacement="top"
                        />
                    </div>
                </div>
            }
        </div>

    )
}