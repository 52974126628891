/* eslint-disable camelcase */

import { IProjectRouteParams } from "../../../helpers/IRouteParams";

export enum Formulas {
    abs,
    sqrt,
    ln,
    sin,
    cos,
    tan,
    cotan,
    arcsin,
    arccos,
    arctan,
    length,
    length2d,
    lengthLZ,
    lengthTZ,
    deviation,
    deviationX,
    deviationY,
    deviationZ,
    deviationXY,
    deviationAxis,
    deviationAxisX,
    deviationAxis_LZ,
    deviationAxis_TZ,
    prev,
    prevtime,
    translate,
    interpolate,
    meas,
    measDate
}

export const FormulasList: Readonly<Formulas[]> = [
    Formulas.abs,
    Formulas.sqrt,
    Formulas.ln,
    Formulas.sin,
    Formulas.cos,
    Formulas.tan,
    Formulas.cotan,
    Formulas.arcsin,
    Formulas.arccos,
    Formulas.arctan,
    Formulas.length,
    Formulas.length2d,
    Formulas.lengthLZ,
    Formulas.lengthTZ,
    Formulas.deviation,
    Formulas.deviationX,
    Formulas.deviationY,
    Formulas.deviationZ,
    Formulas.deviationXY,
    Formulas.deviationAxis,
    Formulas.deviationAxisX,
    Formulas.deviationAxis_LZ,
    Formulas.deviationAxis_TZ,
    Formulas.prev,
    Formulas.prevtime,
    Formulas.translate,
    Formulas.interpolate,
    Formulas.meas,
    Formulas.measDate
];

const FormulasToName = new Map<Formulas, string>([
    [Formulas.abs, 'Abs'],
    [Formulas.sqrt, 'Sqrt'],
    [Formulas.ln, 'Ln'],
    [Formulas.sin, 'Sin'],
    [Formulas.cos, 'Cos'],
    [Formulas.tan, 'Tan'],
    [Formulas.cotan, 'CoTan'],
    [Formulas.arcsin, 'ArcSin'],
    [Formulas.arccos, 'ArcCos'],
    [Formulas.arctan, 'ArcTan'],
    [Formulas.length, 'Length'],
    [Formulas.length2d, 'Length2d'],
    [Formulas.lengthLZ, 'LengthLZ'],
    [Formulas.lengthTZ, 'LengthTZ'],
    [Formulas.deviation, 'Deviation'],
    [Formulas.deviationX, 'Deviation X'],
    [Formulas.deviationY, 'Deviation Y'],
    [Formulas.deviationZ, 'Deviation Z'],
    [Formulas.deviationXY, 'Deviation XY'],
    [Formulas.deviationAxis, 'Deviation Axis'],
    [Formulas.deviationAxisX, 'Deviation Axis(x)'],
    [Formulas.deviationAxis_LZ, 'DeviationAXIS_LZ'],
    [Formulas.deviationAxis_TZ, 'DeviationAXIS_TZ'],
    [Formulas.prev, 'Prev'],
    [Formulas.prevtime, 'PrevTime'],
    [Formulas.translate, 'Translate'],
    [Formulas.interpolate, 'Interpolate'],
    [Formulas.meas, 'Meas'],
    [Formulas.measDate, 'Meas (Date)']
])

export function getFormulasToName(element: Formulas): string {
    return FormulasToName.get(element) || '';
}

const FormulasToDescription = new Map<Formulas, string>([
    [Formulas.abs, 'Absolute value'],
    [Formulas.sqrt, 'Square root'],
    [Formulas.ln, 'Natural logarithm'],
    [Formulas.sin, 'Sinus'],
    [Formulas.cos, 'Cosine'],
    [Formulas.tan, 'Tangent'],
    [Formulas.cotan, 'Cotangent'],
    [Formulas.arcsin, 'Arcsine'],
    [Formulas.arccos, 'Arccosine'],
    [Formulas.arctan, 'Arctangent'],
    [Formulas.length, 'Vector length functions, returns the absolute value of a vector: (length([id]) = sqrt(id[0]^2 + id[1]^2 + id[2]^2))'],
    [Formulas.length2d, 'Vector length functions for first two components of vector: (length2d([id]) = sqrt(id[0]^2 + id[1]^2))'],
    [Formulas.lengthLZ, 'Vector length functions for longitudinal and height components of axis-vector: (lengthLZ([id]) = sqrt(id[0]^2 + id[2]^2))'],
    [Formulas.lengthTZ, 'Vector length functions for transversal and height components of axis-vector: (lengthTZ([id]) = sqrt(id[1]^2 + id[2]^2))'],
    [Formulas.deviation, 'Deviation from reference value: (deviation(id) = abs(id-id.reference)). For 3D sensors returns deviation as a absolute value: deviation(id) = sqrt((id[0]-id.reference[0])^2 + (id[1]-id.reference[1])^2 + (id[2]-id.reference[2])^2)'],
    [Formulas.deviationX, 'Deviation in particular direction (only works if sensors has three values)'],
    [Formulas.deviationY, 'Deviation in particular direction (only works if sensors has three values)'],
    [Formulas.deviationZ, 'Deviation in particular direction (only works if sensors has three values)'],
    [Formulas.deviationXY, 'Deviation in particular direction (only works if sensors has three values)'],
    [Formulas.deviationAxis, 'Returns deviation as a absolute Axis value: deviationAXIS(id) = sqrt(sum(id.axis[n]-id.axisreference[n])^2), where n = [0, size vector - 1]'],
    [Formulas.deviationAxisX, "deviationAXIS(id)[x] = abs(id.axis[x]-id.axisreference[x]), where x from 0 to 'size vector - 1'"],
    [Formulas.deviationAxis_LZ, 'deviationAXIS_LZ(id) = sqrt((id.axis[0]-id.axisreference[0])^2 + (id.axis[2]-id.axisreference[2])^2)'],
    [Formulas.deviationAxis_TZ, 'deviationAXIS_TZ(id) = sqrt((id.axis[1]-id.axisreference[1])^2 + (id.axis[2]-id.axisreference[2])^2)'],
    [Formulas.prev, 'Returns previous value. The prev(id, 5) is equal to time[-5] value of sensor ‘id’ (index ‘0’ is a time-reference for current value of sensor i.e. the same as ‘id’); this expression can be used for calculating of sensor deviation during time e.g. d = length(id – prev(id,1))'],
    [Formulas.prevtime, "Returns data of previous sensor's timeslot"],
    [Formulas.translate, 'Translate sensor data for id[0] and id[1]: = X - A*T^3 + B*T^2 + C*T + D, (T - value for the transformation ; A, B, C and D are coefficients for sensor).'],
    [Formulas.interpolate, "Interpolate the sensor's data"],
    [Formulas.meas, 'Meas'],
    [Formulas.measDate, 'Meas (Date)'],
])

export function getFormulasToDescription(element: Formulas): string {
    return FormulasToDescription.get(element) || '';
}

const FormulasToLexem = new Map<Formulas, string>([
    [Formulas.abs, 'abs'],
    [Formulas.sqrt, 'sqrt'],
    [Formulas.ln, 'ln'],
    [Formulas.sin, 'sin'],
    [Formulas.cos, 'cos'],
    [Formulas.tan, 'tan'],
    [Formulas.cotan, 'cotan'],
    [Formulas.arcsin, 'arcsin'],
    [Formulas.arccos, 'arccos'],
    [Formulas.arctan, 'arctan'],
    [Formulas.length, 'length'],
    [Formulas.length2d, 'length2d'],
    [Formulas.lengthLZ, 'lengthLZ'],
    [Formulas.lengthTZ, 'lengthTZ'],
    [Formulas.deviation, 'deviation'],
    [Formulas.deviationX, 'deviationX'],
    [Formulas.deviationY, 'deviationY'],
    [Formulas.deviationZ, 'deviationZ'],
    [Formulas.deviationXY, 'deviationXY'],
    [Formulas.deviationAxis, 'deviationAXIS'],
    [Formulas.deviationAxisX, 'deviationAXIS'],
    [Formulas.deviationAxis_LZ, 'deviationAXIS_LZ'],
    [Formulas.deviationAxis_TZ, 'deviationAXIS_TZ'],
    [Formulas.prev, 'prev'],
    [Formulas.prevtime, 'prevtime'],
    [Formulas.translate, 'translate'],
    [Formulas.interpolate, 'interpolate'],
    [Formulas.meas, 'Meas'],
    [Formulas.measDate, 'Meas']
])

export function getFormulasToLexem(element: Formulas): string {
    return FormulasToLexem.get(element) || '';
}

export enum Filters {
    Min = 0,
    Max = 1,
    Mean = 2,
    Median = 3,
    TrimmedMean = 4
}

const FiltersToName = new Map<Filters, string>([
    [Filters.Min, 'Min'],
    [Filters.Max, 'Max'],
    [Filters.Mean, 'Mean'],
    [Filters.Median, 'Median'],
    [Filters.TrimmedMean, 'Trimmed Mean'],
])

export function getFiltersToName(element: Filters): string {
    return FiltersToName.get(element) || '';
}

const FiltersToDescription = new Map<Filters, string>([
    [Filters.Min, 'Minimum value'],
    [Filters.Max, 'Maximum value'],
    [Filters.Mean, 'Mean value'],
    [Filters.Median, 'Median value'],
    [Filters.TrimmedMean, "Special filters: mean value, but ignores the highest and the lowest value. Don't work with arrays and x has to be 3 or bigger"],
])

export function getFiltersToDescription(element: Filters): string {
    return FiltersToDescription.get(element) || '';
}

const FiltersToLexem = new Map<Filters, string>([
    [Filters.Min, 'min'],
    [Filters.Max, 'max'],
    [Filters.Mean, 'mean'],
    [Filters.Median, 'median'],
    [Filters.TrimmedMean, 'trimmedmean'],
])

export function getFiltersToLexem(element: Filters): string {
    return FiltersToLexem.get(element) || '';
}
export const FiltersList: Readonly<Filters[]> = [
    Filters.Min,
    Filters.Max,
    Filters.Mean,
    Filters.Median,
    Filters.TrimmedMean
];

export type FormulaParams =
    "devAxis_X"
    | "prev_x"
    | "prevTime_T"
    | "prevTime_D"
    | "translate_T"
    | "interpolate_T"
    | "interpolate_SD"
    | "interpolate_O"
    | "interpolate_N"
    | "interpolate_TO"
    | "meas_x"
    | "meas_d"
    | "meas_c";

export type FilterParams = "min" | "max" | "mean" | "median" | "trimmedMean";


export enum InterpolateModes {
    Normal = 0,
    Fast = 1,
    NormalMed = 2,
    NormalMedBack = 3,
}

const InterpolateModesToName = new Map<InterpolateModes, string>([
    [InterpolateModes.Normal, 'Normal Mode (average)'],
    [InterpolateModes.Fast, 'Fast Mode'],
    [InterpolateModes.NormalMed, 'Normal Mode (median)'],
    [InterpolateModes.NormalMedBack, 'Normal Mode (median/only backwards)']
])

export function getInterpolateModesToName(element: InterpolateModes): string {
    return InterpolateModesToName.get(element) || '';
}

export const InterpolateModesList: Readonly<InterpolateModes[]> = [
    InterpolateModes.Normal,
    InterpolateModes.Fast,
    InterpolateModes.NormalMed,
    InterpolateModes.NormalMedBack
];

export enum SensorProperties {
    None = 0,
    Index = 1,
    Raw = 2,
    Value = 3,
    Offset = 4,
    Reference = 5,
    Relative = 6,
    Axis = 7,
    AxisOffset = 8,
    AxisReference = 9,
    AxisRelative = 10,
    Param1 = 11,
    Param2 = 12,
    Param3 = 13,
    Param4 = 14,
    Param5 = 15,
    Km = 16,
    Time = 17
}


const SensorPropertiesToName = new Map<SensorProperties, string>([
    [SensorProperties.None, 'None'],
    [SensorProperties.Index, 'Index'],
    [SensorProperties.Raw, 'Raw'],
    [SensorProperties.Value, 'Value'],
    [SensorProperties.Offset, 'Offset'],
    [SensorProperties.Reference, 'Reference'],
    [SensorProperties.Relative, 'Relative'],
    [SensorProperties.Axis, 'Axis'],
    [SensorProperties.AxisOffset, 'AxisOffset'],
    [SensorProperties.AxisReference, 'AxisReference'],
    [SensorProperties.AxisRelative, 'AxisRelative'],
    [SensorProperties.Param1, 'Param 1'],
    [SensorProperties.Param2, 'Param 2'],
    [SensorProperties.Param3, 'Param 3'],
    [SensorProperties.Param4, 'Param 4'],
    [SensorProperties.Param5, 'Param 5'],
    [SensorProperties.Km, 'Km'],
    [SensorProperties.Time, 'Time'],
])

export function getSensorPropertiesToName(element: SensorProperties): string {
    return SensorPropertiesToName.get(element) || '';
}

export const SensorPropertiesList: Readonly<SensorProperties[]> = [
    SensorProperties.None,
    SensorProperties.Index,
    SensorProperties.Raw,
    SensorProperties.Value,
    SensorProperties.Offset,
    SensorProperties.Reference,
    SensorProperties.Relative,
    SensorProperties.Axis,
    SensorProperties.AxisOffset,
    SensorProperties.AxisReference,
    SensorProperties.AxisRelative,
    SensorProperties.Param1,
    SensorProperties.Param2,
    SensorProperties.Param3,
    SensorProperties.Param4,
    SensorProperties.Param5,
    SensorProperties.Km,
    SensorProperties.Time
];

const SensorPropertiesToLexem = new Map<SensorProperties, string>([
    [SensorProperties.None, ''],
    [SensorProperties.Index, ''],
    [SensorProperties.Raw, '.raw'],
    [SensorProperties.Value, '.value'],
    [SensorProperties.Offset, '.offset'],
    [SensorProperties.Reference, '.reference'],
    [SensorProperties.Relative, '.relative'],
    [SensorProperties.Axis, '.axis'],
    [SensorProperties.AxisOffset, '.axisoffset'],
    [SensorProperties.AxisReference, '.axisreference'],
    [SensorProperties.AxisRelative, '.axisrelative'],
    [SensorProperties.Param1, '.param1'],
    [SensorProperties.Param2, '.param2'],
    [SensorProperties.Param3, '.param3'],
    [SensorProperties.Param4, '.param4'],
    [SensorProperties.Param5, '.param5'],
    [SensorProperties.Km, '.km'],
    [SensorProperties.Time, '.time'],
])

export function getSensorPropertiesToLexem(element: SensorProperties): string {
    return SensorPropertiesToLexem.get(element) || '';
}

/**
 * Sensor types enum
 */
export enum ProjectEditSensorsTab {
    /**
     * Default sensors
     */
    Standard = "standard",

    /**
     * Chains
     */
    Chains = "chains",

    /**
     * Vibration sensors
     */
    Vibration = "vibration"
}

export interface ISensorsDialogCommonProps {
    projectId: number;

    /**
     * Confirm dialog action
     * @returns 
     */
    onOk: () => void;

    onCancel: () => void;
}

/**
 * Edit sensor route param
 */
export interface IProjectSensorsRouteParams extends IProjectRouteParams {
    type: ProjectEditSensorsTab | undefined;
}


/**
 * 
 */
export interface IEditProjectSensorsRouteParams extends IProjectSensorsRouteParams {
    fullId: string | undefined;
}