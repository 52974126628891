/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 24.04.2022
 * @description Dts chart Y-axis settings
 */

import { t } from '../../../../i18n';
import { ChartType } from '../../../../server/AVTService/TypeLibrary/Common/ChartType';
import { DtsChartModel } from '../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/DtsChartModel';
import { GeovisChartAxisSettings } from '../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartAxisSettings';
import { DtsSectionInfoSlim } from '../../../../server/GEOvis3/Model/DtsConfiguration/DtsSectionInfoSlim';
import { SensorChangesModel } from '../../../../server/GEOvis3/Model/GeovisChart/SensorChangesModel';
import { ChartAxisSensorsControl } from '../ChartAxisSensorsControl';
import { GeovisChartAxisSettingsControl } from '../GeovisChartAxisSettingsControl';
import { getDtsChartSensorIdsOfType, isChartTemplate } from '../tools';
import {
    IDtsChartSensorsInfoStorage,
    IGeovisChartPropertyChangedProps,
    IGeovisChartSensorsPropertyProps
} from '../types';
import { dtsChartYAxisSensorTypes } from './types';

interface IDtsChartYAxisProps extends IGeovisChartPropertyChangedProps<DtsChartModel>, IGeovisChartSensorsPropertyProps {
    chart: DtsChartModel;
    dtsSections: DtsSectionInfoSlim[];
    dtsChartSensorsInfoStorage: IDtsChartSensorsInfoStorage;
}

/**
 * Dts chart Y-Axis tab
 * @param props IDtsChartYAxisProps
 * @returns 
 */
export const DtsChartYAxisTab = ({ onChartPropertyChanged, chart, dtsSections, dtsChartSensorsInfoStorage, sensorsChanges }: IDtsChartYAxisProps) => {

    const onAxisPropertyChangedHandler = (axisPropertyName: keyof DtsChartModel, axisSettings: GeovisChartAxisSettings) => (propertyName: keyof GeovisChartAxisSettings, value: any) => {

        const changedAxisSettings: Partial<GeovisChartAxisSettings> = { ...axisSettings };
        changedAxisSettings[propertyName] = value;

        if (propertyName === "HeatpulseOption" && changedAxisSettings.HeatpulseOption !== undefined) {
            changedAxisSettings.SensorIds = getDtsChartSensorIdsOfType(dtsChartSensorsInfoStorage.dtsChartSensorInfos, axisSettings.TypeOfSensor, changedAxisSettings.HeatpulseOption);
        }

        onChartPropertyChanged(axisPropertyName, changedAxisSettings);
    }

    const onAxisPropertiesChangedHandlerFunc = (axisPropertyName: keyof DtsChartModel, axisSettings: GeovisChartAxisSettings) => (properties: Partial<GeovisChartAxisSettings>) => {

        const changedAxisSettings: GeovisChartAxisSettings = { ...axisSettings, ...properties };

        if (properties.TypeOfSensor) {
            changedAxisSettings.SensorIds = getDtsChartSensorIdsOfType(dtsChartSensorsInfoStorage.dtsChartSensorInfos, changedAxisSettings.TypeOfSensor, changedAxisSettings.HeatpulseOption);
        }

        onChartPropertyChanged(axisPropertyName, changedAxisSettings);

    }

    const onAxisSensorsChangedFunc = (axisPropertyName: keyof DtsChartModel, axisSettings: GeovisChartAxisSettings) => (sensorIds: string[], customerChangeable: boolean, sensorChanges: SensorChangesModel[]) => {

        const newAxisSettings: GeovisChartAxisSettings = {
            ...axisSettings,
            SensorIds: [...sensorIds],
            IsSensorsCustomerChangeable: customerChangeable
        }

        // onChangeSensorsChanges(sensorChanges);
        onChartPropertyChanged(axisPropertyName, newAxisSettings, sensorChanges);
    }

    const onXyChartSensorsPairsChanged = () => {
        // nothing do there, only for XyChartModel
    }

    const {
        LeftYAxisSettings,
        RightYAxisSettings
    } = chart;

    const isHeatable = dtsSections.filter(s => chart.DtsSectionIds.includes(s.Id) && s.IsHeatable === true).length > 0;
    const isTemplate = isChartTemplate(chart);

    return (
        <div className="geovisChartTab">
            <div className="flexRowContainerLine">
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    <div style={{ textAlign: 'center', fontWeight: 'bold' }}>{t("Left Y-Axis")}</div>
                    <GeovisChartAxisSettingsControl
                        axisSettings={LeftYAxisSettings}
                        chartType={ChartType.DtsChart}
                        isXAxis={false}
                        onAxisPropertiesChanged={onAxisPropertiesChangedHandlerFunc("LeftYAxisSettings", chart.LeftYAxisSettings)}
                        onAxisPropertyChanged={onAxisPropertyChangedHandler("LeftYAxisSettings", chart.LeftYAxisSettings)}
                        sensorTypesToSelect={dtsChartYAxisSensorTypes}
                        isRightAxis={false}
                        isHeatable={isHeatable}
                        isDisabled={false}
                    />

                    {!isTemplate &&
                        <ChartAxisSensorsControl
                            isChain={false}
                            chartType={chart.Type}
                            dtsSectionIds={chart.DtsSectionIds}
                            projectId={chart.ProjectId}
                            ySensorType={LeftYAxisSettings.TypeOfSensor}
                            sensorIds={LeftYAxisSettings.SensorIds}
                            customerChangeable={LeftYAxisSettings.IsSensorsCustomerChangeable}
                            onChange={onAxisSensorsChangedFunc("LeftYAxisSettings", chart.LeftYAxisSettings)}
                            sensorChanges={sensorsChanges}
                            showPairsDialog={false} // pairs dialog for XyChart
                            onChangeXyChartSensorsPairs={onXyChartSensorsPairsChanged}
                            isDisabled={true}
                            hideCustomerChangeable={true}
                            isSingle={false}
                            selectedChainsIds={[]} // in this dialog we do not need those ids
                        />
                    }
                </div>
                <div className="flexCellContainer_g1_m0 separator">
                    {/** */}
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    <div style={{ textAlign: 'center', fontWeight: 'bold' }}>{t("Right Y-Axis")}</div>
                    <GeovisChartAxisSettingsControl
                        axisSettings={RightYAxisSettings}
                        chartType={ChartType.DtsChart}
                        isXAxis={false}
                        onAxisPropertiesChanged={onAxisPropertiesChangedHandlerFunc("RightYAxisSettings", chart.RightYAxisSettings)}
                        onAxisPropertyChanged={onAxisPropertyChangedHandler("RightYAxisSettings", chart.RightYAxisSettings)}
                        sensorTypesToSelect={dtsChartYAxisSensorTypes}
                        isRightAxis={true}
                        isHeatable={isHeatable}
                        isDisabled={false}
                    />

                    {!isTemplate &&
                        <ChartAxisSensorsControl
                            isChain={false}
                            chartType={chart.Type}
                            dtsSectionIds={chart.DtsSectionIds}
                            projectId={chart.ProjectId}
                            ySensorType={RightYAxisSettings.TypeOfSensor}
                            sensorIds={RightYAxisSettings.SensorIds}
                            customerChangeable={RightYAxisSettings.IsSensorsCustomerChangeable}
                            onChange={onAxisSensorsChangedFunc("RightYAxisSettings", chart.RightYAxisSettings)}
                            sensorChanges={sensorsChanges}
                            showPairsDialog={false} // pairs dialog needs for XyChart
                            onChangeXyChartSensorsPairs={onXyChartSensorsPairsChanged}
                            isDisabled={true}
                            hideCustomerChangeable={true}
                            isSingle={false}
                            selectedChainsIds={[]} // in this dialog we do not need those ids
                        />
                    }
                </div>
            </div>
        </div>
    );

}