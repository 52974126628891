//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum SemiCircleGroupType {
	SensorValue = 0,
	SensorWatchdog = 1,
	ChainWatchdog = 2,
	SystemWatchdog = 3
}

const SemiCircleGroupTypeToName = new Map<number, string>([
	[ 0, 'SensorValue' ],
	[ 1, 'SensorWatchdog' ],
	[ 2, 'ChainWatchdog' ],
	[ 3, 'SystemWatchdog' ],
])

export function getSemiCircleGroupTypeToName(element: SemiCircleGroupType) : string {
    return SemiCircleGroupTypeToName.get(element) || '';
}

const SemiCircleGroupTypeByName = new Map<string, number>([
	[ 'SensorValue', 0 ],
	[ 'SensorWatchdog', 1 ],
	[ 'ChainWatchdog', 2 ],
	[ 'SystemWatchdog', 3 ],
]);

export function getSemiCircleGroupTypeByName(name: string): SemiCircleGroupType {
    return SemiCircleGroupTypeByName.get(name)!;
}


export const SemiCircleGroupTypeList : Readonly<SemiCircleGroupType[]> = [
	SemiCircleGroupType.SensorValue,
	SemiCircleGroupType.SensorWatchdog,
	SemiCircleGroupType.ChainWatchdog,
	SemiCircleGroupType.SystemWatchdog,
];



const SemiCircleGroupTypeToDescription = new Map<number, string>([
	[ 0, 'Sensor values' ],
	[ 1, 'Sensors watchdog' ],
	[ 2, 'Chains watchdog' ],
	[ 3, 'System watchdog' ],
])

export function getSemiCircleGroupTypeToDescription(element: SemiCircleGroupType) : string {
    return SemiCircleGroupTypeToDescription.get(element) || '';
}

