/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 15.08.2023
 * @description Geovis image tools
 */

import { TimeSearchDistanceDataRequest } from "../../../../../server/AVTService/Computation/TimeSearchDistance/TimeSearchDistanceDataRequest";
import { MapArea } from "../../../../../server/AVTService/TypeLibrary/Map/MapArea";
import { GeovisImageModel } from "../../../../../server/AVTService/TypeLibrary/Model/GeovisProjectElements/GeovisImageModel";
import { ImageBackgroundViewPort } from "../../../../../server/ImageBackgroundViewPort";
import { IGeovisImageReportData, IGeovisImageReportInfo, IGeovisReportSettings } from "../../../../../store/projectReport.types";

/**
 * Get geovis image report config
 * @param geovisReportSettings 
 * @param pageNum 
 * @param elementId
 * @returns 
 */
export const getGeovisImageInfo = (geovisReportSettings: IGeovisReportSettings, pageNum: number, elementId: number): IGeovisImageReportInfo | false => {

    const page = geovisReportSettings.geovisPages.get(pageNum);
    if (!page) {
        return false;
    }

    return page.geovisImages.get(elementId) || false;
}

/**
 * Get geovis image report data
 * @param geovisReportSettings 
 * @param pageNum 
 * @param elementId
 */
export const getGeovisImageData = (geovisReportSettings: IGeovisReportSettings, pageNum: number, elementId: number): IGeovisImageReportData | false => {
    const page = geovisReportSettings.geovisPagesData.get(pageNum);
    if (!page) {
        return false;
    }

    return page.geovisImageData.get(elementId) || false;
}

/**
 * Get GeovisImage time search distance request
 * @param geovisImageReportInfo
 * @returns 
 */
export const getGeovisImageTimeSearchDistanceElementDataRequest = ({ GeovisImage: { Chains, Sensors, AdditionalAttributes } }: IGeovisImageReportInfo, reportId?: number): TimeSearchDistanceDataRequest => ({
    UseLastMeasurementTime: true,
    Timeslot: "",
    TimeSearchDistance: 0,
    SensorFullIds: Sensors.map(s => s.FullId),
    ChainFullId: Chains.map(c => c.FullId),
    UseCurrentTime: false,
    ReportId: reportId ?? 0,
    RequestedSensorAttributes: AdditionalAttributes
});


export const getMapAreaOfGeovisImage = (geovisImage: GeovisImageModel): MapArea => getMapAreaOfGeovisImageViewPort(geovisImage.ImageInfo.ViewPort);

export const getMapAreaOfGeovisImageViewPort = (viewPort: ImageBackgroundViewPort): MapArea => ({
    East: viewPort.Right,
    North: viewPort.Top,
    South: viewPort.Bottom,
    West: viewPort.Left,
    Height: Math.abs(viewPort.Top - viewPort.Bottom),
    Width: Math.abs(viewPort.Right - viewPort.Left),
    ZoomLevel: 0
});