import Button from '@atlaskit/button';
import Checkbox from '@atlaskit/checkbox';
import { HeadType, RowType } from '@atlaskit/dynamic-table/types';
import ChevronLeftIcon from '@atlaskit/icon/glyph/chevron-left';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close';
import HipchatChevronDoubleDownIcon from '@atlaskit/icon/glyph/hipchat/chevron-double-down';
import HipchatChevronDoubleUpIcon from '@atlaskit/icon/glyph/hipchat/chevron-double-up';
import MediaServicesBrushIcon from '@atlaskit/icon/glyph/media-services/brush';
import SelectClearIcon from '@atlaskit/icon/glyph/select-clear';
import { OptionType } from '@atlaskit/select';
import Textfield from '@atlaskit/textfield';
import Tooltip from '@atlaskit/tooltip';
import { debounce } from "lodash";
import React, { CSSProperties, SyntheticEvent, useEffect, useLayoutEffect, useRef, useState } from "react";
import CircleSensorSymbol from 'src/resources/icons/SensorsSymbols/circle-transparent.png';
import PlusSensorSymbol from 'src/resources/icons/SensorsSymbols/plus-transparent.png';
import RombSensorSymbol from 'src/resources/icons/SensorsSymbols/romb-transparent.png';
import SquareSensorSymbol from 'src/resources/icons/SensorsSymbols/square-transparent.png';
import StarSensorSymbol from 'src/resources/icons/SensorsSymbols/star-transparent.png';
import TriangleDownSensorSymbol from 'src/resources/icons/SensorsSymbols/triangle-down-transparent.png';
import TriangleLeftSensorSymbol from 'src/resources/icons/SensorsSymbols/triangle-left-transparent.png';
import TriangleRightSensorSymbol from 'src/resources/icons/SensorsSymbols/triangle-right-transparent.png';
import TriangleUpSensorSymbol from 'src/resources/icons/SensorsSymbols/triangle-up-transparent.png';
import { t } from '../../../i18n';
import { SensorSymbol } from '../../../server/AVTService/TypeLibrary/Sensors/SensorSymbol';
import { GeovisMstShortInfoModel } from '../../../server/GEOvis3/Model/Database/GeovisMstShortInfoModel';
import { SensorChangesModel } from '../../../server/GEOvis3/Model/GeovisChart/SensorChangesModel';
import { SensorInfoMini } from '../../../server/GEOvis3/Model/Sensors/SensorInfoMini';
import { GeovisTableWithSelectableRows } from '../../DynamicTableWithSelectableRows';
import { LoadingPageSkeleton } from '../../LoadingPageSkeleton';
import { getIGvOptionTypeList, IGvOptionType } from '../../select/GeovisSelect_tools';
import { getMstsOptions, getPredefinedColors, isVisibleSensorType } from './tools';
import { GeovisModalDialog } from '../../editDialogs/GeovisModalDialog';
import { GeovisSelect, GeovisSelectMulti } from '../../select/GeovisSelect';
import { getSensorCategoryName, SensorCategory, SensorCategoryOrdered } from '../../../server/AVTService/TypeLibrary/Sensors/SensorCategory';
import { compareSuffixParts, parseSensorName } from '../../utils';

interface ISelectSensorsDialogProps {
    allItems: SensorInfoMini[];
    selectedItems: SensorInfoMini[];
    heading: string;
    isLoading?: boolean;
    projectMsts: GeovisMstShortInfoModel[];
    sensorChanges: SensorChangesModel[];
    isMultiType: boolean

    selectedDatabaseIds: string[];
    showPublic: boolean | undefined;
    useFilterForSelected: boolean;
    searchQuery: string;
    selectedSensorsTypes: SensorCategory[];

    allItemsFilterFunc: (sensor: SensorInfoMini) => boolean;
    selectedItemsFilterFunc: (sensor: SensorInfoMini) => boolean;

    onSelected: (items: SensorInfoMini[], sensorChanges: SensorChangesModel[]) => void;
    onCanceled: () => void;

    onShowPublicChanged: (showPublic: boolean | undefined, selectedIds: string[]) => void;
    onDatabaseIdsChanged: (databaseIds: string[], selectedIds: string[]) => void;
    onSensorsTypesChanged: (sTypes: SensorCategory[], selectedIds: string[]) => void;
    onSearchTextChanged: (searchText: string, selectedIds: string[]) => void;
    onFilterSelectedChanged: (useFilterForSelectedItems: boolean, selectedIds: string[]) => void;
}

interface ISelectSensorsDialogState {
    selectedItems: SensorInfoMini[];
    availableItemsSelection: string[];
    selectedItemsSelection: string[];
    sensorChanges: SensorChangesModel[];
}

const lineHeight = 48; // 3
const defaultRowsPerPage = 15;

const defaultTableRowStyle: CSSProperties = {
    height: '3em'
};

const defaultTableRowProperties = {
    style: defaultTableRowStyle
};

export const SelectSensorsDialog = ({
    allItems,
    heading,
    onCanceled,
    onSelected,
    projectMsts,
    selectedItems,
    isLoading,
    onShowPublicChanged,
    showPublic,
    onDatabaseIdsChanged,
    selectedDatabaseIds,
    onFilterSelectedChanged,
    onSearchTextChanged,
    searchQuery,
    useFilterForSelected,
    allItemsFilterFunc,
    selectedItemsFilterFunc,
    sensorChanges,
    selectedSensorsTypes,
    onSensorsTypesChanged,
    isMultiType
}: ISelectSensorsDialogProps) => {

    const initState: ISelectSensorsDialogState = {
        selectedItems,
        availableItemsSelection: [],
        selectedItemsSelection: [],
        sensorChanges,
    }

    const [state, setState] = useState<ISelectSensorsDialogState>(initState);
    const [rowsPerPage, setRowsPerPage] = useState<number>(defaultRowsPerPage);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [selectedPageNumber, setSelectedPageNumber] = useState<number>(1);

    const mainDivRef = useRef<HTMLDivElement | null>(null);

    // does it reset when list of selected items changed?
    // but it happens only once and important that whole components tree can be mounted/unmounted
    useEffect(() => {
        setState(initState);
        setPageNumber(1);
        setSelectedPageNumber(1);
    }, [selectedItems])

    useLayoutEffect(() => {
        if (mainDivRef && mainDivRef.current) {

            const height = mainDivRef.current.clientHeight;
            const linesPerPage = Math.trunc(height / lineHeight) - 1;
            setRowsPerPage(linesPerPage);
        }
    }, [selectedItems]);

    const clearSearchQueryRef = useRef<HTMLInputElement>();

    if (isLoading) {
        return (
            <GeovisModalDialog
                heading={heading}>
                <LoadingPageSkeleton />
            </GeovisModalDialog>
        );

    }

    const typeOfSensorOptions = getIGvOptionTypeList(SensorCategoryOrdered.filter(st => isVisibleSensorType(st)), getSensorCategoryName);
    const selectedTypesOfSensorOptions = typeOfSensorOptions.filter(o => selectedSensorsTypes.includes(+o.value));

    const onSearchDebounce = debounce((query: string) => {
        setPageNumber(1);
        if (useFilterForSelected) {
            setSelectedPageNumber(1);
        }
        onSearchTextChanged(query, state.selectedItems.map(s => s.Id));
    }, 500);

    const onSearchWrapped = (event: SyntheticEvent<HTMLInputElement>) => onSearchDebounce(event.currentTarget.value);

    const getPublicOptions = (): ReadonlyArray<OptionType> => [{
        label: t("Public only"), value: 1
    }, {
        label: t("Non public only"), value: 2
    }]

    const getSelectedPublicOption = (): OptionType | undefined => {
        if (showPublic === undefined) {
            return undefined;
        }
        const valueNumber = showPublic ? 1 : 2;
        return getPublicOptions().find(o => o.value === valueNumber);
    }

    const onPublicChanged = (option: OptionType) => {
        onShowPublicChanged(option ? option.value === 1 : undefined, state.selectedItems.map(s => s.Id));
    }

    const onUseFilterForSelectedChanged = () => {
        // setState({ ...state, useFilterForSelected: !state.useFilterForSelected });
        if (searchQuery.length > 0) {
            setSelectedPageNumber(1);
        }
        onFilterSelectedChanged(!useFilterForSelected, state.selectedItems.map(s => s.Id));
    }

    const getSelectedMsts = (): Array<IGvOptionType<string>> => {
        const result: Array<IGvOptionType<string>> = [];

        selectedDatabaseIds.forEach(mstAlias => {
            const mst = projectMsts.find(m => m.Alias === mstAlias);
            if (mst) {
                result.push({ label: mst.Name, value: mst.Alias });
            }
        })

        return result;
    }

    const onMstSelectionChanged = (selection: OptionType[]) => {

        if (selection) {
            onDatabaseIdsChanged(selection.map(o => o.value.toString()), state.selectedItems.map(s => s.Id));
        }
        else {
            onDatabaseIdsChanged([], state.selectedItems.map(s => s.Id));
        }
    }

    const onSensorsTypesChangedHandler = (selection: OptionType[]) => {

        if (selection) {
            onSensorsTypesChanged(selection.map(o => +o.value), state.selectedItems.map(s => s.Id));
        }
        else {
            onSensorsTypesChanged([], state.selectedItems.map(s => s.Id));
        }
    }

    const onDone = () => {
        onSelected(state.selectedItems.filter((item, index, array) => array.indexOf(item) === index), state.sensorChanges);
    }

    const moveAllAvailableToSelected = () => {
        setState({
            ...state,
            selectedItems: [
                ...state.selectedItems,
                ...allItems.filter(si => allItemsFilterFunc(si)).filter(si => state.selectedItems.findIndex(i => i.Id === si.Id) < 0)
            ],
            availableItemsSelection: []
        })
    }

    const moveAllSelectedToAvailable = () => {
        setState({
            ...state,
            selectedItems: state.selectedItems.filter(si => !selectedItemsFilterFunc(si)),
            selectedItemsSelection: []
        })
    }

    const clearAvailableSelection = () => {
        setState({
            ...state,
            availableItemsSelection: []
        })
    }

    const clearSelectedSelection = () => {
        setState({
            ...state,
            selectedItemsSelection: []
        })
    }

    const moveAvailableSelectionToSelected = () => {
        const updSelected = state.selectedItems;

        state.availableItemsSelection.forEach(si => {
            const sensor = allItems.find(s => si === `${s.DatabaseId}.${s.Id}`);
            if (sensor && !updSelected.includes(sensor)) {
                updSelected.push(sensor);
            }
        });

        const pagesBusy = Math.ceil(getAllAvailableItems().length / rowsPerPage);
        if (pagesBusy < pageNumber) {
            setPageNumber(pagesBusy);
        }

        setState({
            ...state,
            selectedItems: updSelected,
            availableItemsSelection: []
        })
    }

    const changeUsePredefinedColors = () => {

        const sChanges: SensorChangesModel[] = [];
        const predefinedColors = getPredefinedColors();

        state.selectedItems.forEach((element, index) => {
            const validNumber = index % predefinedColors.length;
            const overrideColor = predefinedColors[validNumber];
            element.Color = overrideColor;
            sChanges.push({ SensorFullId: element.Id, Color: overrideColor });
        });

        setState({
            ...state,
            selectedItems: state.selectedItems,
            sensorChanges: sChanges
        })
    }

    const moveSelectedSelectionToAvailable = () => {

        const pagesBusy = Math.ceil((getAllSelectedItems().length - state.selectedItemsSelection.length) / rowsPerPage);
        if (pagesBusy < selectedPageNumber) {
            setSelectedPageNumber(pagesBusy);
        }

        setState({
            ...state,
            selectedItems: state.selectedItems.filter(s => !state.selectedItemsSelection.includes(`${s.DatabaseId}.${s.Id}`)),
            selectedItemsSelection: []
        })
    }

    // const filterFunc = (element: SensorInfoMini): boolean => {
    //     if (state.allItemsQuery && !isMatchToSearchString(element.Name, state.allItemsQuery) && !isMatchToSearchString(element.DatabaseName, state.allItemsQuery)) {
    //         return false;
    //     }
    //     if (selectedDatabaseIds.length > 0 && !selectedDatabaseIds.includes(element.DatabaseId)) {
    //         return false;
    //     }

    //     // the Show Public filter moved to server
    //     // if (state.publicFilter) {
    //     //     if (state.publicFilter === 1 && !element.IsPublic || state.publicFilter === 2 && element.IsPublic) {
    //     //         return false;
    //     //     }
    //     // }

    //     return true;
    // }

    const sensorTypeIcon = (element: SensorInfoMini) => {
        const elementChanges = sensorChanges.find(s => s.SensorFullId === element.Id);
        const color = elementChanges === undefined ? element.Color : elementChanges.Color;
        switch (element.Symbol) {
            case SensorSymbol.Star:
                return (
                    <img src={StarSensorSymbol} style={{ backgroundColor: color, width: "24px", height: "24px" }} />
                )
            case SensorSymbol.Plus:
                return (
                    <img src={PlusSensorSymbol} style={{ backgroundColor: color, width: "24px", height: "24px" }} />
                )
            case SensorSymbol.Romb:
                return (
                    <img src={RombSensorSymbol} style={{ backgroundColor: color, width: "24px", height: "24px" }} />
                )
            case SensorSymbol.Square:
                return (
                    <img src={SquareSensorSymbol} style={{ backgroundColor: color, width: "24px", height: "24px" }} />
                )
            case SensorSymbol.TriangleDown:
                return (
                    <img src={TriangleDownSensorSymbol} style={{ backgroundColor: color, width: "24px", height: "24px" }} />
                )
            case SensorSymbol.TriangleLeft:
                return (
                    <img src={TriangleLeftSensorSymbol} style={{ backgroundColor: color, width: "24px", height: "24px" }} />
                )
            case SensorSymbol.TriangleRight:
                return (
                    <img src={TriangleRightSensorSymbol} style={{ backgroundColor: color, width: "24px", height: "24px" }} />
                )
            case SensorSymbol.TriangleUp:
                return (
                    <img src={TriangleUpSensorSymbol} style={{ backgroundColor: color, width: "24px", height: "24px" }} />
                )
            default:
                return (
                    <img src={CircleSensorSymbol} style={{ backgroundColor: color, width: "24px", height: "24px" }} />
                )
        }

    }

    //#region Common

    const getIdFunc = (item: SensorInfoMini): string => {
        return `${item.DatabaseId}.${item.Id}`;
    }

    const getHead = (): HeadType => {
        return {
            cells: [{
                key: 'name',
                content: t("Sensor name")
            }, {
                key: 'actions'
            }]
        }
    }

    const sortingFunc = (itemA: SensorInfoMini, itemB: SensorInfoMini, sortKey: string, sortOrder: 'ASC' | 'DESC'): number => {
        const { prefix: prefixA, suffixParts: suffixPartsA } = parseSensorName(itemA.Id);
        const { prefix: prefixB, suffixParts: suffixPartsB } = parseSensorName(itemB.Id);

        if (prefixA !== prefixB) {
            return sortOrder === 'ASC' ? prefixA.localeCompare(prefixB) : prefixB.localeCompare(prefixA);
        }

        const suffixComparison = compareSuffixParts(suffixPartsA, suffixPartsB);
        return sortOrder === 'ASC' ? suffixComparison : -suffixComparison;
    }

    //#endregion

    //#region Selectable

    const getAllAvailableItems = (): SensorInfoMini[] => {
        return allItems
            .filter(si => !state.selectedItems.includes(si))
            .filter(allItemsFilterFunc)
            .sort((itemA, itemB) => sortingFunc(itemA, itemB, 'name', 'ASC'));
    };

    const availableItemToRowFunc = (item: SensorInfoMini): RowType => {
        const onAddItem = (itemToAdd: SensorInfoMini) => (event: React.MouseEvent) => {
            if (event.isDefaultPrevented()) {
                return;
            }

            const updSelection = state.selectedItems;
            if (!updSelection.includes(itemToAdd)) {
                updSelection.push(itemToAdd);
            }

            const pagesBusy = Math.ceil(getAllAvailableItems().length / rowsPerPage);
            if (pagesBusy < pageNumber) {
                setPageNumber(pagesBusy);
            }

            setState({
                ...state,
                availableItemsSelection: state.availableItemsSelection.filter(si => si !== getIdFunc(itemToAdd)),
                selectedItems: updSelection
            });

            event.preventDefault();
        }

        return ({
            ...defaultTableRowProperties,
            key: `row-${getIdFunc(item)}`,
            cells: [{
                key: 'nameCol',
                content: (
                    <span style={{ userSelect: 'none', msUserSelect: 'none' }}>{`${item.DatabaseName}.${item.Name}`}</span>
                )
            }, {
                key: 'actionCol',
                content: (
                    <div>
                        <Button
                            spacing='compact'
                            onClick={onAddItem(item)}
                            iconBefore={<EditorAddIcon label='addIcon' size='small' />}
                            appearance='subtle'
                        />
                    </div>
                )
            }]
        })
    }

    const availableSelectionChanged = (selected: SensorInfoMini[]) => {
        setState({
            ...state,
            availableItemsSelection: selected.map(s => getIdFunc(s))
        })
    }

    const getAvailableSelection = (): SensorInfoMini[] => {
        return getAllAvailableItems().filter(si => state.availableItemsSelection.includes(getIdFunc(si)))
    }

    const onSelectDeselectAllAvailable = (allSelected: boolean) => {
        setState({
            ...state,
            availableItemsSelection: allSelected ? getAllAvailableItems().map(s => getIdFunc(s)) : []
        })
    }

    //#endregion

    //#region Selected

    const getAllSelectedItems = (): SensorInfoMini[] => {
        return allItems
            .filter(si => state.selectedItems.includes(si))
            .filter(selectedItemsFilterFunc)
            .sort((itemA, itemB) => sortingFunc(itemA, itemB, 'name', 'ASC'));
    };

    const getSelectedItemToRowFunc = (item: SensorInfoMini): RowType => {
        const onRemoveItem = (itemToRemove: SensorInfoMini) => (event: React.MouseEvent<HTMLElement>) => {

            if (event.isDefaultPrevented()) {
                return;
            }

            const updSelection = state.selectedItems.filter(si => !(si.Id === itemToRemove.Id && si.DatabaseId === itemToRemove.DatabaseId));

            const pagesBusy = Math.ceil((getAllSelectedItems().length - 1) / rowsPerPage);
            if (pagesBusy < selectedPageNumber) {
                setSelectedPageNumber(pagesBusy);
            }

            setState({
                ...state,
                selectedItemsSelection: state.selectedItemsSelection.filter(si => si !== getIdFunc(item)),
                selectedItems: updSelection,
            })

            event.preventDefault();
        }

        return ({
            ...defaultTableRowProperties,
            key: `selected-row-${item.Id}`,
            cells: [{
                key: "nameCol",
                content: (
                    <span style={{ userSelect: 'none', msUserSelect: 'none' }}>{`${item.DatabaseName}.${item.Name}`}</span>
                )
            }, {
                key: "colorCol",
                content: (
                    <div style={{ textAlign: 'center' }}>
                        {sensorTypeIcon(item)}
                    </div>
                )
            }, {
                key: "actionsCol",
                content: (
                    <div>
                        <Button
                            iconAfter={<EditorCloseIcon label="remove" size='small' />}
                            spacing='compact'
                            onClick={onRemoveItem(item)}
                            appearance='subtle'
                        />
                    </div>
                )
            }]
        })
    }

    const selectedSelectionChanged = (selected: SensorInfoMini[]) => {
        setState({
            ...state,
            selectedItemsSelection: selected.map(s => getIdFunc(s))
        })
    }

    const getSelectedSelection = (): SensorInfoMini[] => {
        return getAllSelectedItems().filter(si => state.selectedItemsSelection.includes(getIdFunc(si)))
    }

    const onSelectDeselectAllSelected = (allSelected: boolean) => {
        setState({
            ...state,
            selectedItemsSelection: allSelected ? getAllSelectedItems().map(s => getIdFunc(s)) : []
        })
    }

    //#endregion   


    const onClearSearchField = () => {
        if (clearSearchQueryRef !== undefined && clearSearchQueryRef.current) {
            clearSearchQueryRef.current.value = "";
        }
        onSearchTextChanged('', state.selectedItems.map(s => s.Id));
    }

    return (
        <GeovisModalDialog
            heading={heading}
            actions={[
                { text: t("Done"), onClick: onDone, isDisabled: isLoading },
                { text: t("Cancel"), onClick: onCanceled }
            ]}
        >
            <div ref={mainDivRef} style={{ height: '100%', display: 'flex', flexDirection: 'column', gap: "5px" }}>
                {/* header */}
                <div className="flexRowContainerLine" style={{ gap: '0', gridGap: '0' }}>
                    <div className="flexCellContainer" style={{ width: '280px' }}>
                        <Textfield
                            ref={clearSearchQueryRef}
                            placeholder={t("Search")}
                            defaultValue={searchQuery}
                            onChange={onSearchWrapped}
                            isCompact={true}
                            elemAfterInput={
                                <div style={{ cursor: 'default' }} onClick={onClearSearchField}>
                                    <SelectClearIcon label="clear" size="small" primaryColor="gray" />
                                </div>
                            }
                        />
                    </div>
                    {isMultiType &&
                        <div className="flexCellContainer_g1">
                            <GeovisSelectMulti
                                placeholder={t("Sensors types")}
                                id="cmbTypesOfSensors"
                                searchable={true}
                                isClearable={true}
                                options={typeOfSensorOptions}
                                value={selectedTypesOfSensorOptions}
                                spacing='compact'
                                onChange={onSensorsTypesChangedHandler}
                            />

                        </div>
                    }
                    <div className="flexCellContainer_g1">
                        <GeovisSelectMulti
                            placeholder={t("Database")}
                            options={getMstsOptions(projectMsts)}
                            searchable={true}
                            isClearable={true}
                            spacing='compact'
                            isMulti={true}
                            value={getSelectedMsts()}
                            onChange={onMstSelectionChanged} />

                    </div>
                    <div className="flexCellContainer" style={{ width: '170px' }}>
                        <GeovisSelect
                            placeholder={t("Public")}
                            options={getPublicOptions()}
                            isClearable={true}
                            spacing='compact'
                            value={getSelectedPublicOption()}
                            onChange={onPublicChanged} />

                    </div>
                    <div className="flexCellContainer" style={{ width: '120px' }}>
                        <Checkbox
                            label={t("Filter selected")}
                            isChecked={useFilterForSelected}
                            onChange={onUseFilterForSelectedChanged}
                        />
                    </div>
                </div>
                {/* panels below */}
                <div className='flexRowMiddleContainer' style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                    {/* left panel */}
                    <div style={{ width: '100%', height: '100%', display: "flex", flexDirection: 'column', gap: "5px" }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                            <Tooltip content={t("Add selected")} >
                                <Button
                                    // isDisabled={state.availableItemsSelection.length === 0}
                                    onClick={moveAvailableSelectionToSelected}
                                    iconBefore={<ChevronRightIcon label='moveSelected' />}
                                />
                            </Tooltip>
                            <Tooltip content={t("Add all")}>
                                <Button
                                    style={{ transform: 'rotate(90deg)' }}
                                    onClick={moveAllAvailableToSelected}
                                    iconBefore={<HipchatChevronDoubleUpIcon label='moveAll' />}
                                />
                            </Tooltip>
                            <Tooltip content={t("Clear selection")}>
                                <Button
                                    // isDisabled={state.availableItemsSelection.length === 0}
                                    onClick={clearAvailableSelection}
                                    iconBefore={<CrossIcon label='clearSelected' />}
                                />
                            </Tooltip>
                        </div>
                        <div>
                            <span style={{ fontSize: 'large' }}>{t("Sensors to select")}</span>
                        </div>

                        <GeovisTableWithSelectableRows
                            allItems={getAllAvailableItems()}
                            head={getHead()}
                            getIdFunc={getIdFunc}
                            isLoading={false}
                            itemToRowFunc={availableItemToRowFunc}
                            needSelectAll={true}
                            onSelectionChanged={availableSelectionChanged}
                            selectedItems={getAvailableSelection()}
                            sortingFunc={sortingFunc}
                            onSelectDeselectAll={onSelectDeselectAllAvailable}
                            rowsPerPage={rowsPerPage}
                            onChangePageCallback={setPageNumber}
                            pageNumber={pageNumber}
                            showPagination={true}
                            showCountRowsSelect={false}
                        />

                        {/* <DynamicTable
                                    rows={getSelectableRows()}
                                    rowsPerPage={rowsPerPage}
                                    defaultPage={1}
                                /> */}


                    </div>
                    <div className="separator">
                        {/** separator */}
                    </div>
                    {/* right panel */}
                    <div style={{ width: '100%', height: '100%', display: "flex", flexDirection: 'column', gap: "5px" }}>
                        <div className='flexRowContainer'>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Tooltip content={t("Set predefined colors to selected sensors")} >
                                    <Button
                                        onClick={changeUsePredefinedColors}
                                        iconBefore={<MediaServicesBrushIcon label='useDefinedColors' />}
                                    />
                                </Tooltip>
                                <Tooltip content={t("Remove selected")}>
                                    <Button
                                        // isDisabled={state.selectedItemsSelection.length === 0}
                                        onClick={moveSelectedSelectionToAvailable}
                                        iconBefore={<ChevronLeftIcon label='removeSelected' />}
                                    />
                                </Tooltip>
                                <Tooltip content={t("Remove all")}>
                                    <Button
                                        style={{ transform: 'rotate(90deg)' }}
                                        onClick={moveAllSelectedToAvailable}
                                        iconBefore={<HipchatChevronDoubleDownIcon label='removeAll' />}
                                    />
                                </Tooltip>
                                <Tooltip content={t("Clear selection")}>
                                    <Button
                                        onClick={clearSelectedSelection}
                                        iconBefore={<CrossIcon label='clearSelected' />}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                        <div className='flexRowContainer'>
                            <div>
                                <span style={{ fontSize: 'large' }}>{t("Selected sensors")}</span>
                            </div>
                        </div>
                        <div className='flexRowMiddleContainer'>
                            <GeovisTableWithSelectableRows
                                head={getHead()}
                                allItems={getAllSelectedItems()}
                                getIdFunc={getIdFunc}
                                isLoading={false}
                                itemToRowFunc={getSelectedItemToRowFunc}
                                needSelectAll={true}
                                onSelectionChanged={selectedSelectionChanged}
                                selectedItems={getSelectedSelection()}
                                onSelectDeselectAll={onSelectDeselectAllSelected}
                                rowsPerPage={rowsPerPage}
                                sortingFunc={sortingFunc}
                                onChangePageCallback={setSelectedPageNumber}
                                pageNumber={selectedPageNumber}
                                showPagination={true}
                                showCountRowsSelect={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </GeovisModalDialog>
    );
}