import { t } from "i18next";
import { OptionType } from "@atlaskit/select";
import { CSSProperties } from "react";
import { getSensorValueAttributeToName, SensorValueAttribute } from "../../../../server/AVTService/TypeLibrary/Common/SensorValueAttribute";
import { ChartSettingBool } from "../../../../server/AVTService/TypeLibrary/Model/ChartSettingBool";
import { SemiCircleChartModel } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/SemiCircleChartModel";
import { SemiCircleValuesSettings } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/SemiCircleValuesSettings";
import { getSensorCategoryName, SensorCategory, SensorCategoryOrdered } from "../../../../server/AVTService/TypeLibrary/Sensors/SensorCategory";
import { GeovisSelect, GeovisSelectMulti } from "../../../select/GeovisSelect";
import { getIGvOptionType, getIGvOptionTypeList, IGvOptionType } from "../../../select/GeovisSelect_tools";
import AxisScaleLimitControl from "../AxisScaleLimitControl";
import { ChartBooleanSettingProps, ChartTextSetting, CustomerChangeableChartBooleanSetting } from "../ChartTab_tools";
import { getPhysicalUnitsOptionsOfCategory, isChartTemplate, isVisibleSensorType, onChangeTextPropertyDebounced } from "../tools";
import { availableValueTypesForRelativeValues, defaultChartRowSettingsStyle, IGeovisChartPropertyChangedProps } from "../types";
import { HeatmapColorTemperatureSchemeControl } from "../heatmap/HeatmapColorTemperatureSchemeControl";
import { ChartAxisSensorsControl } from "../ChartAxisSensorsControl";
import { SemiCircleType } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/SemiCircleType";
import { getSemiCircleGroupTypeToDescription, SemiCircleGroupType, SemiCircleGroupTypeList } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/SemiCircleGroupType";
import { getSemiCircleWdFilterTypeToDescription, SemiCircleWdFilterType, SemiCircleWdFilterTypeList } from "../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/SemiCircleWdFilterType";
import { GeovisMstModel } from "../../../../server/GEOvis3/Model/Database/GeovisMstModel";
import { LongTimeSpanEditControl } from "../../../editControls/LongTimeSpanEditControl";
import { GeovisUnitSelect } from "../../../select/GeovisUnitSelect";
import { getDigitsAfterDecimalPoint, PhysicalUnit } from "../../../../server/AVTService/TypeLibrary/Sensors/PhysicalUnit";

interface ISemiCircleValueSettingsProps extends IGeovisChartPropertyChangedProps<SemiCircleChartModel> {
    aliases: GeovisMstModel[];
}

const possibleSensorAttributes: ReadonlyArray<SensorValueAttribute> = [
    SensorValueAttribute.Value1,
    SensorValueAttribute.Value2,
    SensorValueAttribute.Value3,
    SensorValueAttribute.AxisDeviationLZ,
    SensorValueAttribute.AxisDeviationTZ,
    SensorValueAttribute.AxisValue1,
    SensorValueAttribute.AxisValue2,
    SensorValueAttribute.AxisValue3,
    SensorValueAttribute.Deviation,
    SensorValueAttribute.DeviationXY,
];

export const SemiCircleChartValueSettings = ({ chart, onChartPropertyChanged, aliases }: ISemiCircleValueSettingsProps) => {
    const { ValueSettings } = chart;

    const labelColumnStyle: CSSProperties = { width: '30%' };
    const settingsRowStyle = defaultChartRowSettingsStyle;

    const typeOfSensorOptions = getIGvOptionTypeList(SensorCategoryOrdered.filter(st => isVisibleSensorType(st)), getSensorCategoryName);
    const selectedTypeOfSensorOption = typeOfSensorOptions.find(o => o.value === ValueSettings.TypeOfSensor);

    const groupByOptions = getIGvOptionTypeList(SemiCircleGroupTypeList, getSemiCircleGroupTypeToDescription);
    const selectedGroupByOption = groupByOptions.find(o => o.value === ValueSettings.GroupBy);

    const groupFilterOptions = getIGvOptionTypeList(SemiCircleWdFilterTypeList.filter(o => ValueSettings.GroupBy !== SemiCircleGroupType.SensorValue || o !== SemiCircleWdFilterType.AllInProject), getSemiCircleWdFilterTypeToDescription);
    const selectedGroupFilterOption = groupFilterOptions.find(o => o.value === ValueSettings.GroupingFilter.FilterType);

    const onValuePropertyChangedFunc = (propertyName: keyof SemiCircleValuesSettings) => (value: any) => {

        const changes: Partial<SemiCircleValuesSettings> = {};
        changes[propertyName] = value;

        if (propertyName === "Value" && !availableValueTypesForRelativeValues.includes(value)) {
            changes.ShowAsRelative = {
                value: false,
                customerChangeable: ValueSettings.ShowAsRelative.customerChangeable
            }
        }

        if (propertyName === 'GroupBy') {
            const groupByValue: SemiCircleGroupType = value;
            if (groupByValue === SemiCircleGroupType.SensorValue) {
                changes["GroupingFilter"] = {
                    ...chart.ValueSettings.GroupingFilter, FilterType: SemiCircleWdFilterType.Filter
                }
            }
            else {
                changes["GroupingFilter"] = {
                    ...chart.ValueSettings.GroupingFilter, FilterType: SemiCircleWdFilterType.AllInProject
                }
            }
        }

        onChartPropertyChanged<SemiCircleValuesSettings>("ValueSettings", { ...ValueSettings, ...changes });
    }

    const onSelectedPropertyChanged = (propertyName: keyof SemiCircleValuesSettings) => (option: IGvOptionType<number>) => {
        onValuePropertyChangedFunc(propertyName)(option.value);
    }

    const onChangeStringProperty = (propertyName: keyof SemiCircleValuesSettings) => (v1: string) => {
        onChangeTextPropertyDebounced(v1, (v2) => {
            onValuePropertyChangedFunc(propertyName)(v2);
        });
    }

    const onChangeNumberProperty = (propertyName: keyof SemiCircleValuesSettings) => (v1: string) => {
        onChangeTextPropertyDebounced(v1, (v2) => {
            onValuePropertyChangedFunc(propertyName)(+v2);
        });
    }

    const onSensorsChanged = (sensorIds: string[], isSensorsCustomerChangeable: boolean) => {
        onChartPropertyChanged<SemiCircleValuesSettings>("ValueSettings", { ...ValueSettings, SensorIds: sensorIds, IsSensorsCustomerChangeable: isSensorsCustomerChangeable });
    }

    const onChainsChanged = (chainsIds: string[], isSensorsCustomerChangeable: boolean) => {
        onChartPropertyChanged<SemiCircleValuesSettings>("ValueSettings", { ...ValueSettings, ChainsIds: chainsIds, IsSensorsCustomerChangeable: isSensorsCustomerChangeable });
    }

    const onChangeUnits = (unit: PhysicalUnit) => {
        onChartPropertyChanged<SemiCircleValuesSettings>("ValueSettings", { ...ValueSettings, Unit: unit, Decimals: getDigitsAfterDecimalPoint(unit) });
    }

    const getBooleanSetting = (setting: ChartSettingBool): ChartSettingBool => {
        return setting || { value: false, customerChangeable: false };
    }

    const onFilterSensorsTypesSelectionChanged = (selection: OptionType[]) => {
        if (selection) {
            onChartPropertyChanged<SemiCircleValuesSettings>("ValueSettings", { ...ValueSettings, GroupingFilter: { ...ValueSettings.GroupingFilter, SensorTypes: selection.map(s => +s.value) } });
        }
        else {
            onChartPropertyChanged<SemiCircleValuesSettings>("ValueSettings", { ...ValueSettings, GroupingFilter: { ...ValueSettings.GroupingFilter, SensorTypes: [] } });
        }
    }

    const onFilterDatabasesSelectionChanged = (selection: OptionType[]) => {
        if (selection) {
            onChartPropertyChanged<SemiCircleValuesSettings>("ValueSettings", { ...ValueSettings, GroupingFilter: { ...ValueSettings.GroupingFilter, Databases: selection.map(s => s.value.toString()) } });
        }
        else {
            onChartPropertyChanged<SemiCircleValuesSettings>("ValueSettings", { ...ValueSettings, GroupingFilter: { ...ValueSettings.GroupingFilter, Databases: [] } });
        }
    }

    const onFilterTypeChanged = (value: OptionType) => {
        onChartPropertyChanged<SemiCircleValuesSettings>("ValueSettings", { ...ValueSettings, GroupingFilter: { ...ValueSettings.GroupingFilter, FilterType: +value.value } });
    }

    const onChangeStringFilter = (v1: string) => {
        onChangeTextPropertyDebounced(v1, () => {
            onChartPropertyChanged<SemiCircleValuesSettings>("ValueSettings", { ...ValueSettings, GroupingFilter: { ...ValueSettings.GroupingFilter, NameFilter: v1 } });
        });
    }

    const getDatabasesFilterOptions = (): OptionType[] => {
        return aliases.map(alias => ({ value: alias.Id, label: alias.Name }));
    }

    const getSelectedDatabases = (): OptionType[] => {
        const results: OptionType[] = [];

        getDatabasesFilterOptions().forEach(op => {
            if (chart.ValueSettings.GroupingFilter.Databases.includes(op.value.toString())) {
                results.push(op);
            }
        })

        return results;
    }


    const selectedTypeOfSensorFilterOption = typeOfSensorOptions.filter(o => ValueSettings.GroupingFilter.SensorTypes.includes(o.value));

    const isTemplate = isChartTemplate(chart);
    const isSensorValues = chart.SemiCircleType === SemiCircleType.SensorValues;
    const isGrouping = chart.SemiCircleType === SemiCircleType.Grouping;
    const isSuccessMeas = chart.SemiCircleType === SemiCircleType.SuccessfulMeasurementRate;

    return (
        <div className='geovisChartTab'>
            {isGrouping &&
                <div className="flexRowContainerLine" style={settingsRowStyle}>
                    <div style={labelColumnStyle}>
                        <label htmlFor="cmbGroupBy" >{t("Grouping by")}:</label>
                    </div>
                    <div className="flexCellContainer_g1_m0">
                        <GeovisSelect
                            id="cmbGroupBy"
                            options={groupByOptions}
                            value={selectedGroupByOption}
                            spacing='compact'
                            onChange={onSelectedPropertyChanged("GroupBy")}
                        />
                    </div>
                </div>
            }

            {(isSensorValues || isSuccessMeas) &&
                <div className="flexRowContainerLine" style={settingsRowStyle}>
                    <div style={labelColumnStyle}>
                        <label htmlFor="cmbTypeOfSensors" >{t("Type of Sensor")}:</label>
                    </div>
                    <div className="flexCellContainer_g1_m0">
                        <GeovisSelect
                            id="cmbTypeOfSensors"
                            options={typeOfSensorOptions}
                            value={selectedTypeOfSensorOption}
                            spacing='compact'
                            onChange={onSelectedPropertyChanged("TypeOfSensor")}
                        />
                    </div>
                </div>
            }

            {(isSensorValues || isGrouping && chart.ValueSettings.GroupBy === SemiCircleGroupType.SensorValue) &&
                <div className="flexRowContainerLine" style={settingsRowStyle}>
                    <div style={labelColumnStyle}>
                        <label htmlFor="cmbValues">{t("Value")}:</label>
                    </div>
                    <div className="flexCellContainer_g1_m0">
                        <GeovisSelect
                            id="cmbValues"
                            value={getIGvOptionType(ValueSettings.Value, getSensorValueAttributeToName)}
                            options={getIGvOptionTypeList(possibleSensorAttributes, getSensorValueAttributeToName)}
                            onChange={onSelectedPropertyChanged("Value")}
                        />
                    </div>
                </div>
            }

            {isSensorValues &&
                <div className="flexRowContainerLine" style={settingsRowStyle}>
                    <div style={labelColumnStyle}>
                        <label htmlFor="cmbUnits">{t("Unit")}:</label>
                    </div>
                    <div className="flexCellContainer_g1_m0">
                        <GeovisUnitSelect
                            id="cmbUnits"
                            value={ValueSettings.Unit}
                            options={getPhysicalUnitsOptionsOfCategory(ValueSettings.TypeOfSensor)}
                            spacing='compact'
                            onChange={onChangeUnits}
                            customEditorStyle={{ width: '100%' }}
                        />
                    </div>
                </div>
            }

            {isSensorValues &&
                <ChartTextSetting
                    labelColumnStyle={labelColumnStyle}
                    settingsStyle={settingsRowStyle}
                    labelText={t("Decimals")}
                    value={ValueSettings.Decimals}
                    type="number"
                    htmlName="tbDecimals"
                    onChangeTextField={onChangeNumberProperty("Decimals")} />
            }

            {isSuccessMeas &&
                <div className="flexRowContainerLine" style={settingsRowStyle}>
                    <div style={labelColumnStyle}>
                        <label htmlFor="interval">{t("Sensor interval")}:</label>
                    </div>
                    <div className="flexCellContainer_g1_m0">
                        <LongTimeSpanEditControl
                            onChange={onValuePropertyChangedFunc("SensorInterval")}
                            value={ValueSettings.SensorInterval}
                            showDays={false}
                            showSeconds={true}
                        />
                    </div>
                </div>
            }

            {isSensorValues &&
                <CustomerChangeableChartBooleanSetting
                    setting={getBooleanSetting(ValueSettings.ShowAsRelative)}
                    settingLabel={t("Show as relative")}
                    onChange={onValuePropertyChangedFunc("ShowAsRelative")}
                    isDisabled={!availableValueTypesForRelativeValues.includes(ValueSettings.Value)}
                />
            }

            {isSensorValues &&
                <AxisScaleLimitControl
                    scaleLimit={ValueSettings.MinValue}
                    tbLabel={t("Min value")}
                    hideAuto={false}
                    hideExact={true}
                    isDisabled={ValueSettings.TemperatureColorSettings.EnableCustomColorScheme}
                    onChange={onValuePropertyChangedFunc("MinValue")} />
            }

            {isSensorValues &&
                <AxisScaleLimitControl
                    scaleLimit={ValueSettings.MaxValue}
                    tbLabel={t("Max value")}
                    hideAuto={false}
                    hideExact={true}
                    isDisabled={ValueSettings.TemperatureColorSettings.EnableCustomColorScheme}
                    onChange={onValuePropertyChangedFunc("MaxValue")} />
            }

            {isSensorValues &&
                <HeatmapColorTemperatureSchemeControl
                    colorSettings={chart.ValueSettings.TemperatureColorSettings}
                    maxValue={chart.ValueSettings.MaxValue.value}
                    minValue={chart.ValueSettings.MinValue.value}
                    unit={chart.ValueSettings.Unit}
                    onPropertyChanged={onValuePropertyChangedFunc("TemperatureColorSettings")}
                />
            }

            {isSensorValues &&
                <ChartBooleanSettingProps
                    label={t("Show color bar scheme")}
                    isChecked={ValueSettings.ShowColorBarScheme}
                    onChange={onValuePropertyChangedFunc("ShowColorBarScheme")}
                    isDisabled={false}
                />
            }

            {isSensorValues &&
                <ChartBooleanSettingProps
                    label={t("Show axis labels")}
                    isChecked={ValueSettings.ShowAxisLabel}
                    onChange={onValuePropertyChangedFunc("ShowAxisLabel")}
                    isDisabled={false}
                />
            }

            {isSensorValues &&
                <ChartBooleanSettingProps
                    label={t("Show unit in labels")}
                    isChecked={ValueSettings.ShowUnitInAxisLabel}
                    onChange={onValuePropertyChangedFunc("ShowUnitInAxisLabel")}
                    isDisabled={false}
                />
            }

            {isSensorValues &&
                <ChartTextSetting
                    labelColumnStyle={labelColumnStyle}
                    settingsStyle={settingsRowStyle}
                    labelText={t("Axis label")}
                    value={ValueSettings.AxisLabel}
                    htmlName="tbAxisLabel"
                    onChangeTextField={onChangeStringProperty("AxisLabel")} />
            }

            {(isSensorValues || isGrouping && chart.ValueSettings.GroupBy !== SemiCircleGroupType.SystemWatchdog) &&
                <div className="flexRowContainerLine" style={settingsRowStyle}>
                    <div style={labelColumnStyle}>
                        <label htmlFor="cmbFilterType" >{t("Filter type")}:</label>
                    </div>
                    <div className="flexCellContainer_g1_m0">
                        <GeovisSelect
                            id="cmbFilterType"
                            options={groupFilterOptions}
                            value={selectedGroupFilterOption}
                            spacing='compact'
                            onChange={onFilterTypeChanged}
                        />
                    </div>
                </div>
            }

            {isGrouping && chart.ValueSettings.GroupBy !== SemiCircleGroupType.SystemWatchdog && ValueSettings.GroupingFilter.FilterType === SemiCircleWdFilterType.Filter &&
                <div className="flexRowContainerLine" style={settingsRowStyle}>
                    <div style={labelColumnStyle}>
                        <label htmlFor="cmbTypeOfSensors" >{t("Types of Sensor")}:</label>
                    </div>
                    <div className="flexCellContainer_g1_m0">
                        <GeovisSelectMulti
                            id="cmbTypesOfSensors"
                            options={typeOfSensorOptions}
                            value={selectedTypeOfSensorFilterOption}
                            spacing='compact'
                            onChange={onFilterSensorsTypesSelectionChanged}
                        />
                    </div>
                </div>
            }

            {(isSensorValues || isGrouping
                && chart.ValueSettings.GroupBy !== SemiCircleGroupType.SystemWatchdog
                && !isTemplate)
                && ValueSettings.GroupingFilter.FilterType === SemiCircleWdFilterType.Filter &&
                <div className="flexRowContainerLine" style={settingsRowStyle}>
                    <div style={labelColumnStyle}>
                        <label htmlFor="cmbDbFilter">{t("Databases filter")}:</label>
                    </div>
                    <div className="flexCellContainer_g1_m0">
                        <GeovisSelectMulti
                            id="cmbDbFilter"
                            options={getDatabasesFilterOptions()}
                            value={getSelectedDatabases()}
                            isMulti={true}
                            onChange={onFilterDatabasesSelectionChanged}
                            isCompact={true}
                            spacing='compact'
                        />
                    </div>
                </div>
            }

            {(isSensorValues || isGrouping
                && chart.ValueSettings.GroupBy !== SemiCircleGroupType.SystemWatchdog) && ValueSettings.GroupingFilter.FilterType === SemiCircleWdFilterType.Filter &&
                <ChartTextSetting
                    labelColumnStyle={labelColumnStyle}
                    settingsStyle={settingsRowStyle}
                    labelText={t("Sensor name filter")}
                    value={ValueSettings.GroupingFilter.NameFilter}
                    htmlName="tbNAmeFilter"
                    onChangeTextField={onChangeStringFilter} />
            }




            {(isGrouping && (chart.ValueSettings.GroupBy === SemiCircleGroupType.SensorValue || chart.ValueSettings.GroupBy === SemiCircleGroupType.SensorWatchdog) && ValueSettings.GroupingFilter.FilterType === SemiCircleWdFilterType.Custom)
                && !isTemplate &&
                <ChartAxisSensorsControl
                    isChain={false}
                    chartType={chart.Type}
                    dtsSectionIds={[]}
                    projectId={chart.ProjectId}
                    ySensorType={SensorCategory.Unknown}
                    sensorIds={chart.ValueSettings.SensorIds}
                    customerChangeable={chart.ValueSettings.IsSensorsCustomerChangeable}
                    onChange={onSensorsChanged}
                    sensorChanges={[]}
                    showPairsDialog={false}
                    selectedChainsIds={[]}
                    isMultiType={true}
                    isSingle={false}
                    hideCustomerChangeable={false}
                />
            }

            {isSuccessMeas &&
                <ChartAxisSensorsControl
                    isChain={false}
                    chartType={chart.Type}
                    dtsSectionIds={[]}
                    projectId={chart.ProjectId}
                    ySensorType={chart.ValueSettings.TypeOfSensor}
                    sensorIds={chart.ValueSettings.SensorIds}
                    customerChangeable={chart.ValueSettings.IsSensorsCustomerChangeable}
                    onChange={onSensorsChanged}
                    sensorChanges={[]}
                    showPairsDialog={false}
                    selectedChainsIds={[]}
                    isSingle={true}
                    hideCustomerChangeable={true}
                />
            }

            {isSensorValues && chart.ValueSettings.GroupingFilter.FilterType === SemiCircleWdFilterType.Custom && !isTemplate &&
                <ChartAxisSensorsControl
                    isChain={false}
                    chartType={chart.Type}
                    dtsSectionIds={[]}
                    projectId={chart.ProjectId}
                    ySensorType={chart.ValueSettings.TypeOfSensor}
                    sensorIds={chart.ValueSettings.SensorIds}
                    customerChangeable={chart.ValueSettings.IsSensorsCustomerChangeable}
                    onChange={onSensorsChanged}
                    sensorChanges={[]}
                    showPairsDialog={false}
                    selectedChainsIds={[]}
                    isSingle={false}
                    hideCustomerChangeable={false}
                />
            }

            {isGrouping && chart.ValueSettings.GroupBy === SemiCircleGroupType.ChainWatchdog && ValueSettings.GroupingFilter.FilterType === SemiCircleWdFilterType.Custom && !isTemplate &&
                <ChartAxisSensorsControl
                    isChain={true}
                    chartType={chart.Type}
                    dtsSectionIds={[]}
                    projectId={chart.ProjectId}
                    ySensorType={SensorCategory.Inclinometer}
                    sensorIds={chart.ValueSettings.ChainsIds}
                    customerChangeable={chart.ValueSettings.IsSensorsCustomerChangeable}
                    onChange={onChainsChanged}
                    sensorChanges={[]}
                    showPairsDialog={false}
                    selectedChainsIds={chart.ValueSettings.ChainsIds}
                    isSingle={false}
                    hideCustomerChangeable={false}
                />
            }
        </div>
    )
}