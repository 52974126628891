//     This code was generated by a Reinforced.Typings tool.
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum ChartType {
	TimeValue = 0,
	XYDiagram = 1,
	Inclinometer = 2,
	VibrationSensorFraquencyAnalyzer = 3,
	VibrationDiagramm = 4,
	XYVibrationEventDiagram = 5,
	DtsChart = 6,
	HeatMap = 7,
	SemiCircle = 8
}

const ChartTypeToName = new Map<number, string>([
	[ 0, 'TimeValue' ],
	[ 1, 'XYDiagram' ],
	[ 2, 'Inclinometer' ],
	[ 3, 'VibrationSensorFraquencyAnalyzer' ],
	[ 4, 'VibrationDiagramm' ],
	[ 5, 'XYVibrationEventDiagram' ],
	[ 6, 'DtsChart' ],
	[ 7, 'HeatMap' ],
	[ 8, 'SemiCircle' ],
])

export function getChartTypeToName(element: ChartType) : string {
    return ChartTypeToName.get(element) || '';
}

const ChartTypeByName = new Map<string, number>([
	[ 'TimeValue', 0 ],
	[ 'XYDiagram', 1 ],
	[ 'Inclinometer', 2 ],
	[ 'VibrationSensorFraquencyAnalyzer', 3 ],
	[ 'VibrationDiagramm', 4 ],
	[ 'XYVibrationEventDiagram', 5 ],
	[ 'DtsChart', 6 ],
	[ 'HeatMap', 7 ],
	[ 'SemiCircle', 8 ],
]);

export function getChartTypeByName(name: string): ChartType {
    return ChartTypeByName.get(name)!;
}


export const ChartTypeList : Readonly<ChartType[]> = [
	ChartType.TimeValue,
	ChartType.XYDiagram,
	ChartType.Inclinometer,
	ChartType.VibrationSensorFraquencyAnalyzer,
	ChartType.VibrationDiagramm,
	ChartType.XYVibrationEventDiagram,
	ChartType.DtsChart,
	ChartType.HeatMap,
	ChartType.SemiCircle,
];



const ChartTypeToDescription = new Map<number, string>([
	[ 0, 'Time-Value chart' ],
	[ 1, 'XY charts' ],
	[ 2, 'Inclinometer chart' ],
	[ 3, 'VibrationSensorFraquencyAnalyzer' ],
	[ 4, 'Vibration chart' ],
	[ 5, 'XY Vibration chart' ],
	[ 6, 'DTS chart' ],
	[ 7, 'HeatMap' ],
	[ 8, 'Donut chart' ],
])

export function getChartTypeToDescription(element: ChartType) : string {
    return ChartTypeToDescription.get(element) || '';
}

