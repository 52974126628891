import AddIcon from '@atlaskit/icon/glyph/add';
import ArrowDownIcon from '@atlaskit/icon/glyph/arrow-down';
import EditFilledIcon from '@atlaskit/icon/glyph/edit-filled';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import WatchIcon from '@atlaskit/icon/glyph/watch';
import Button from "@atlaskit/button";
import Tooltip from "@atlaskit/tooltip";
import { HeadType, RowType } from "@atlaskit/dynamic-table/dist/types/types";
import { OptionType } from "@atlaskit/select";
import { t } from "i18next";
import { useState, useRef, useEffect } from 'react';
import { Guid } from 'guid-typescript';
import { BoolEditField, MultiSelectEditField, SingleSelectEditField, TextEditField } from "../../../../components/editDialogs/EditDialogTools";
import { getSensorValueAttributeToDescription, SensorValueAttribute } from "../../../../server/AVTService/TypeLibrary/Common/SensorValueAttribute";
import { getPhysicalUnitShortName, PhysicalUnit } from "../../../../server/AVTService/TypeLibrary/Sensors/PhysicalUnit";
import { getSensorCategoryName, getSensorCategorySupportedUnitsValue, SensorCategory, SensorCategoryOrdered } from "../../../../server/AVTService/TypeLibrary/Sensors/SensorCategory";
import { GeovisAlarmModel } from "../../../../server/GEOvis3/Model/Alarms/GeovisAlarmModel";
import { GeovisDynamicTable } from '../../../../components/GeovisDynamicTable';
import { AlarmCondition } from '../../../../server/AVTService/TypeLibrary/Alarming/AlarmCondition';
import { ChartAlarmLineType } from '../../../../server/AVTService/TypeLibrary/Common/ChartAlarmLineType';
import { AlarmConditionEditDialog } from './AlarmConditionEditDialog';
import { AlarmConditionRemoveDialog } from './AlarmConditionRemoveDialog';
import LabelEditView from '../../../../components/projectOverview/labels/LabelEditView';
import LabelDeleteView from '../../../../components/projectOverview/labels/LabelDeleteView';
import LabelListView from '../../../../components/projectOverview/labels/LabelListView';
import { IGeovisProjectLabelStorage } from '../../../../store/data.types';
import { mapToListOfElements } from '../../../../helpers/StorageHelper';
import { AlarmActionUIType } from '../../../../server/AVTService/TypeLibrary/Alarming/AlarmActionUIType';
import { AlarmOffsetInfoModal } from './AlarmOffsetInfoModal';
import { VibrationFrequencyBand } from '../../../../server/AVTService/TypeLibrary/Alarming/VibrationFrequencyBand';
import { GeovisUnitSelect, getPhysicalUnitOption, IPhysicalUnitOption } from '../../../../components/select/GeovisUnitSelect';

interface IComponentProps {
    alarm: GeovisAlarmModel;
    onAlarmChanged: (updAlarm: GeovisAlarmModel) => void;
    isReadonly: boolean;
    labelStorage: IGeovisProjectLabelStorage;
    onShowLabelsListView: () => void;
    isTemplate: boolean
}

interface IComponentState {
    showEditConditionDialog: boolean;
    condition: AlarmCondition;
    conditionIndex: number;
    showRemoveConditionDialog: boolean;
    showOffsetInfoDialog: boolean;
}

export const AlarmEditDialogMainSettingsTab = ({
    alarm,
    isReadonly,
    onAlarmChanged,
    labelStorage,
    onShowLabelsListView,
    isTemplate
}: IComponentProps) => {

    const [state, setState] = useState<IComponentState>({
        condition: new AlarmCondition(),
        showEditConditionDialog: false,
        conditionIndex: -1,
        showRemoveConditionDialog: false,
        showOffsetInfoDialog: false
    })

    const updateAlarmProperty = (value: any, propertyName: keyof GeovisAlarmModel) => {
        const update: Partial<GeovisAlarmModel> = {};
        update[propertyName] = value;
        onAlarmChanged({ ...alarm, ...update })
    }

    const updateAlarmProperties = (properties: Map<keyof GeovisAlarmModel, any>) => {
        const update: Partial<GeovisAlarmModel> = {};
        properties.forEach((val, key) => {
            update[key] = val;
        })
        onAlarmChanged({ ...alarm, ...update });
    }

    const nameRef = useRef<HTMLInputElement>(null);
    const triggerLimitRef = useRef<HTMLInputElement>(null);
    const offsetRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (nameRef.current && nameRef.current.value !== alarm.Name) {
            nameRef.current.value = alarm.Name;
        }
        if (triggerLimitRef.current && triggerLimitRef.current.value !== alarm.TriggeredLimit.toString()) {
            triggerLimitRef.current.value = alarm.TriggeredLimit.toString();
        }
        if (offsetRef.current && offsetRef.current.value !== alarm.Offset.toString()) {
            offsetRef.current.value = alarm.Offset.toString();
        }
    }, [alarm]);

    // const onTextPropertyChangedDebounced = debounce((value: string | number, propertyName: keyof GeovisAlarmModel) => {
    //     updateAlarmProperty(value, propertyName);
    // }, 400);

    const onTextPropertyChanged = (propertyName: keyof GeovisAlarmModel) => (value: string | number) => {
        switch (propertyName) {
            case "Comment":
            case "DescriptionConfirmed":
            case "DescriptionRaised":
            case "Name": updateAlarmProperty(value, propertyName); break;
            case "Offset":
            case "TriggeredLimit": {
                if (!isNaN(+value)) {
                    updateAlarmProperty(value, propertyName);
                }
                break;
            }
        }
    }

    const getSensorTypesOptions = (): OptionType[] => {
        return SensorCategoryOrdered.filter(c => c !== SensorCategory.MST && c !== SensorCategory.SB).map(c => ({ value: c, label: getSensorCategoryName(c) }))
    }

    const getSelectedSensorCategory = (): OptionType => {
        const category = getSensorTypesOptions().find(o => o.value === alarm.SensorType);
        if (!category) {
            return {
                label: getSensorCategoryName(SensorCategory.Unknown),
                value: SensorCategory.Unknown
            }
        }
        return category;
    }

    const getUnitsOptions = (): IPhysicalUnitOption[] => {
        if (alarm.SensorFunction === SensorValueAttribute.InclinometerChainPosition1 || alarm.SensorFunction === SensorValueAttribute.InclinometerChainPosition2) {
            return [
                getPhysicalUnitOption(PhysicalUnit.Meter),
                getPhysicalUnitOption(PhysicalUnit.Decimeter),
                getPhysicalUnitOption(PhysicalUnit.Centimeter),
                getPhysicalUnitOption(PhysicalUnit.Millimeter),
            ];
        }
        const unitsValues = getSensorCategorySupportedUnitsValue(alarm.SensorType);
        return unitsValues.map(u => getPhysicalUnitOption(u));
    };

    const isUnitDisabled = () => {
        return alarm.SensorType === SensorCategory.Unknown;
    }

    const getAllowedSensorsFunctions = (isVibration: boolean): ReadonlyArray<SensorValueAttribute> => isVibration
        ? [
            SensorValueAttribute.PeakX,
            SensorValueAttribute.PeakY,
            SensorValueAttribute.PeakZ,
            SensorValueAttribute.PeakVector,
            SensorValueAttribute.VSum,
        ]
        : [
            SensorValueAttribute.AxisDeviationLZ,
            SensorValueAttribute.AxisDeviationTZ,
            SensorValueAttribute.AxisValue1,
            SensorValueAttribute.AxisValue2,
            SensorValueAttribute.AxisValue3,
            SensorValueAttribute.Value1,
            SensorValueAttribute.Value2,
            SensorValueAttribute.Value3,
            SensorValueAttribute.Deviation,
            SensorValueAttribute.DeviationX,
            SensorValueAttribute.DeviationY,
            SensorValueAttribute.DeviationZ,
            SensorValueAttribute.DeviationXY,
            SensorValueAttribute.InclinometerChainPosition1,
            SensorValueAttribute.InclinometerChainPosition2
        ];

    const getSensorValueOptions = (isVibration: boolean): OptionType[] => {
        return getAllowedSensorsFunctions(isVibration).map(s => ({ value: s, label: getSensorValueAttributeToDescription(s) }));
    }

    const getSelectedSensorValueOption = (isVibration: boolean): OptionType => {
        const defaultFunc = isVibration ? SensorValueAttribute.PeakX : SensorValueAttribute.Value1;
        return getSensorValueOptions(isVibration).find(s => +s.value === alarm.SensorFunction) ?? { value: defaultFunc, label: getSensorValueAttributeToDescription(defaultFunc) };
    }

    const getRelevantFrequencyValues = (): SensorValueAttribute[] => [
        SensorValueAttribute.DomFreqX,
        SensorValueAttribute.DomFreqY,
        SensorValueAttribute.DomFreqZ,
        SensorValueAttribute.DomFreqVector,
        SensorValueAttribute.FreqMaxVcomponent,
    ]

    const getRelevantFrequencyOptions = (): OptionType[] => {
        return getRelevantFrequencyValues().map(o => ({ value: o, label: getSensorValueAttributeToDescription(o) }));
    }

    const getSelectedRelevantOption = (): OptionType => {
        return getRelevantFrequencyOptions().find(s => +s.value === alarm.RelevantFrequency) ?? { value: SensorValueAttribute.PeakX, label: getSensorValueAttributeToDescription(SensorValueAttribute.PeakX) };
    }

    const onSelectedOptionChanged = (propertyName: keyof GeovisAlarmModel) => (selected: OptionType) => {
        if (propertyName === 'SensorFunction' && (selected.value === SensorValueAttribute.InclinometerChainPosition1 || selected.value === SensorValueAttribute.InclinometerChainPosition2)) {
            const properties: Map<keyof GeovisAlarmModel, any> = new Map<keyof GeovisAlarmModel, any>();
            properties.set("Unit", PhysicalUnit.Meter);
            properties.set("UseRelative", false);
            properties.set(propertyName, +selected.value);
            updateAlarmProperties(properties);
        }
        else if (propertyName === 'SensorFunction' && (alarm.SensorFunction === SensorValueAttribute.InclinometerChainPosition1 || alarm.SensorFunction === SensorValueAttribute.InclinometerChainPosition2)) {
            const properties: Map<keyof GeovisAlarmModel, any> = new Map<keyof GeovisAlarmModel, any>();
            properties.set("Unit", PhysicalUnit.Undefined);
            properties.set(propertyName, +selected.value);
            updateAlarmProperties(properties);
        }
        else if (propertyName === 'SensorType') {
            const selectedSensorType = +selected.value;
            // sensor type switched to "Vibration Sensor"
            if (selectedSensorType === SensorCategory.VibrationSensor && alarm.SensorType !== SensorCategory.VibrationSensor) {
                const properties: Map<keyof GeovisAlarmModel, any> = new Map<keyof GeovisAlarmModel, any>();
                properties.set("Unit", PhysicalUnit.MillimeterPerSecond);
                properties.set(propertyName, selectedSensorType);
                properties.set("AutoConfirm", true);
                properties.set("Retrigger", true);
                properties.set("Conditions", []);
                properties.set("SensorFunction", SensorValueAttribute.PeakX);
                updateAlarmProperties(properties);
            }
            // sensor type switched from "Vibration Sensor"
            else if (selectedSensorType !== SensorCategory.VibrationSensor && alarm.SensorType === SensorCategory.VibrationSensor) {
                const properties: Map<keyof GeovisAlarmModel, any> = new Map<keyof GeovisAlarmModel, any>();
                properties.set("Unit", PhysicalUnit.Undefined);
                properties.set(propertyName, selectedSensorType);
                properties.set("Conditions", []);
                properties.set("SensorFunction", SensorValueAttribute.Value1);
                updateAlarmProperties(properties);
            }
            else {
                const properties: Map<keyof GeovisAlarmModel, any> = new Map<keyof GeovisAlarmModel, any>();
                properties.set("Unit", PhysicalUnit.Undefined);
                properties.set(propertyName, selectedSensorType);
                updateAlarmProperties(properties);
            }
        }
        else {
            updateAlarmProperty(+selected.value, propertyName);
        }
    }

    const onUnitChange = (value: PhysicalUnit) => {
        const properties: Map<keyof GeovisAlarmModel, any> = new Map<keyof GeovisAlarmModel, any>();
        properties.set("Unit", value);
        updateAlarmProperties(properties);
    };

    const onBooleanPropertyChanged = (propertyName: keyof GeovisAlarmModel) => (value: boolean) => {
        updateAlarmProperty(value, propertyName);
    }

    const getLabelsOptions = (): OptionType[] => {
        return mapToListOfElements(labelStorage.labels).map(l => ({ value: l.id, label: l.name }))
    }

    const getSelectedLabels = (): OptionType[] => {
        const result: OptionType[] = [];

        alarm.LabelsIds.forEach(lId => {

            const labelOption = getLabelsOptions().find(l => l.value.toString() === lId);
            if (labelOption) {
                result.push(labelOption);
            }

        })

        return result;
    }

    const onSelectedLabelsChanged = (selection: OptionType[] | undefined) => {
        if (!selection) {
            updateAlarmProperty([], "LabelsIds")
        }
        else {
            updateAlarmProperty(selection.map(o => o.value.toString()), "LabelsIds");
        }
    }

    const getConditionValue = (value: number, isEnabled: boolean): string => {
        return isEnabled ? value.toString() : "∞"
    }

    const getConditionsPriority = (index: number, collectionLength: number): JSX.Element => {
        if (index === 0) {
            return (
                <div>
                    <span>{t("highest")}</span>
                </div>
            )
        }
        if (index === collectionLength - 1) {
            return (
                <div>
                    <span>{t("lowest")}</span>
                </div>
            )
        }

        return (
            <div>
                <ArrowDownIcon label='less' />
            </div>
        )
    }

    const getColorDisplayControl = (color: string): JSX.Element => {
        return (
            <div style={{
                padding: '4px',
                background: '#fff',
                borderRadius: '1px',
                width: '100%',
                border: 'solid #dadada 1px',
                height: '15px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <div style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '2px',
                    background: color,
                    border: 'solid #dadada 1px'
                }} />
            </div>
        )
    }

    const getConditionBandFreqLimits = (condition: AlarmCondition): string => {
        return `[${condition.frequencyBands.map(b => (`${b.StartFrequency}-${b.EndFrequency}`)).join(' / ')}]`;
    }

    const getConditionBandFreqValues = (condition: AlarmCondition): string => {
        return `[${condition.frequencyBands.map(b => (b.Value.toString())).join(' / ')}]`;
    }

    const getConditionsRows = (triggeredConditions: AlarmCondition[], isVibration: boolean): RowType[] => {
        return alarm.Conditions.map((condition, index, collection) => {
            const isConditionTriggered = triggeredConditions.find(c => c.Id === condition.Id) !== undefined;
            return ({
                key: `condition-${condition.severityName}`,
                //style: { background: condition.color },
                cells: [{
                    key: 'name',
                    content: (
                        <span style={{ marginLeft: '5px' }}>{condition.severityName}</span>
                    )
                }, {
                    key: 'low',
                    content: (
                        <span>{isVibration ? getConditionBandFreqLimits(condition) : getConditionValue(condition.min, condition.minEnabled)}</span>
                    )
                }, {
                    key: 'high',
                    content: (
                        <span>{isVibration ? getConditionBandFreqValues(condition) : getConditionValue(condition.max, condition.maxEnabled)}</span>
                    )
                }, {
                    key: 'priority',
                    content: getConditionsPriority(index, collection.length)
                }, {
                    key: 'color',
                    content: getColorDisplayControl(condition.color)
                }, {
                    key: 'actions',
                    content: (
                        <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: '5px', paddingRight: '5px' }}>
                            <div style={{ marginRight: '3px' }}>
                                {!isReadonly &&
                                    <Tooltip content={t("Edit condition")}>
                                        <Button
                                            iconBefore={<EditFilledIcon label='edit' />}
                                            appearance='subtle-link'
                                            onClick={onShowConditionEditDialog(isVibration, condition)}
                                        />
                                    </Tooltip>
                                }
                                {isReadonly &&
                                    <Tooltip content={t("Watch condition")}>
                                        <Button
                                            iconBefore={<WatchIcon label='watch' />}
                                            appearance='subtle-link'
                                            onClick={onShowConditionEditDialog(isVibration, condition)}
                                        />
                                    </Tooltip>
                                }
                            </div>
                            <div>
                                {!isReadonly && !isConditionTriggered &&
                                    <Tooltip content={t("Remove condition")}>
                                        <Button
                                            iconBefore={<TrashIcon label='remove' />}
                                            appearance='subtle-link'
                                            isDisabled={isReadonly}
                                            onClick={onShowRemoveCOnditionDialog(condition)}
                                        />
                                    </Tooltip>
                                }
                                {isReadonly &&
                                    <Tooltip content={t("Condition can not be removed when alarm is active")}>
                                        <Button
                                            iconBefore={<TrashIcon label='remove' primaryColor="#a5adba" />}
                                            appearance='subtle-link'
                                        />
                                    </Tooltip>
                                }
                                {!isReadonly && isConditionTriggered &&
                                    <Tooltip content={t("Condition can not be removed when there are some sensors which have alarm raised in this condition")}>
                                        <Button
                                            iconBefore={<TrashIcon label='remove' primaryColor="#a5adba" />}
                                            appearance='subtle-link'
                                        />
                                    </Tooltip>
                                }

                            </div>
                        </div>
                    )
                }]
            })
        })
    }

    const getConditionsHead = (): HeadType => {
        return ({
            cells: [{
                key: 'name',
                content: t("Severity name"),
                width: 10
            }, {
                key: 'low',
                content: isVibrationMode ? `${t("Frequency Bands")} [${getPhysicalUnitShortName(PhysicalUnit.Herz)}]` : `${t("Low limit")} [${getPhysicalUnitShortName(alarm.Unit)}]`,
                width: 4
            }, {
                key: 'high',
                content: isVibrationMode ? `${t("Alarm Value")} [${getPhysicalUnitShortName(PhysicalUnit.MillimeterPerSecond)}]` : `${t("High limit")} [${getPhysicalUnitShortName(alarm.Unit)}]`,
                width: 4
            }, {
                key: 'priority',
                content: t("Priority"),
                width: 4
            }, {
                key: 'color',
                content: t("Color"),
                width: 4
            }, {
                key: 'actions',
                width: 1
            }]
        })
    }

    const onShowConditionEditDialog = (isVibration: boolean, condition: AlarmCondition | undefined) => () => {

        const conditionIndex = condition ? alarm.Conditions.indexOf(condition) : -1;

        const previousCondition: AlarmCondition | undefined = isVibration && alarm.Conditions.length > 0 ? alarm.Conditions[alarm.Conditions.length - 1] : undefined;

        setState({
            ...state,
            condition: condition ?? getInitCondition(isVibration, previousCondition),
            showEditConditionDialog: true,
            conditionIndex
        })
    }

    const getInitCondition = (isVibration: boolean, previousCondition?: AlarmCondition): AlarmCondition => {
        const defaultBands: VibrationFrequencyBand[] = !isVibration ? []
            : !previousCondition
                ? [{ Id: Guid.create().toString(), StartFrequency: 0, EndFrequency: 100, Value: 20 }]
                : previousCondition.frequencyBands.map(b => ({ ...b, Id: Guid.create().toString() }));
        return ({
            color: 'black',
            lineEnabled: true,
            lineType: ChartAlarmLineType.Solid,
            lineWeight: 1,
            max: 0,
            maxEnabled: true,
            min: 0,
            minEnabled: true,
            severityName: "",
            Id: Guid.create().toString(),
            fillEnabled: false,
            frequencyBands: defaultBands,
            isVibration
        })
    };

    const onHideConditionsDialogs = () => {
        setState({
            ...state,
            condition: getInitCondition(false),
            showEditConditionDialog: false,
            conditionIndex: -1
        })
    }

    const onHideRemoveConditionDialog = () => {
        setState({
            ...state,
            showRemoveConditionDialog: false,
            conditionIndex: -1,
            condition: getInitCondition(false)
        })
    }

    const onSaveCondition = (condition: AlarmCondition) => {
        const updConditions = [...alarm.Conditions];
        if (state.conditionIndex < 0) {
            updConditions.push(condition);
            alarm.Actions.forEach(action => {
                if (action.ActionType === AlarmActionUIType.Message) {
                    action.EmailNotificationsStrategy.forEach(s => {
                        s.ConditionsIds.push(condition.Id)
                    })
                }
                else if (action.ActionType === AlarmActionUIType.AgmsDigitalOutput) {
                    action.ConditionsIds.push(condition.Id);
                }
            });
        }
        else {
            updConditions.splice(state.conditionIndex, 1, condition);
        }

        const sortedConditions = updConditions.sort((a, b) => onSortConditions(a, b));

        updateAlarmProperty(sortedConditions, "Conditions");
        //updateAlarmProperty(alarm.Actions, "Actions");
        setState({
            ...state,
            condition: getInitCondition(false),
            showEditConditionDialog: false,
            conditionIndex: -1
        })
    }

    const onSortConditions = (condA: AlarmCondition, condB: AlarmCondition): number => {
        if (!condA.isVibration && !condB.isVibration) {
            if ((!condA.minEnabled && !condA.maxEnabled) || (!condB.maxEnabled && !condB.minEnabled)) {
                return 0;
            }
            if (condA.minEnabled && condB.minEnabled) {
                return condA.min > condB.min ? 1 : -1;
            }

            if (condA.maxEnabled && condB.maxEnabled) {
                return condA.max > condB.max ? -1 : 1;
            }
        }
        else {
            return condA.frequencyBands[0].Value > condB.frequencyBands[0].Value ? -1 : 1;
        }

        return 0;
    }

    const onRemoveCondition = () => {
        const updConditions = [...alarm.Conditions];
        const condition = updConditions[state.conditionIndex];

        alarm.Actions.forEach(action => {
            if (action.ActionType === AlarmActionUIType.Message) {
                action.EmailNotificationsStrategy.forEach(s => {
                    s.ConditionsIds = s.ConditionsIds.filter(c => c !== condition.Id);
                })
            }
            else if (action.ActionType === AlarmActionUIType.AgmsDigitalOutput) {
                action.ConditionsIds = action.ConditionsIds.filter(c => c !== condition.Id);
            }
        });

        updConditions.splice(state.conditionIndex, 1);
        updateAlarmProperty(updConditions, "Conditions");

        setState({
            ...state,
            condition: getInitCondition(false),
            showRemoveConditionDialog: false,
            conditionIndex: -1
        })
    }

    const onShowRemoveCOnditionDialog = (condition: AlarmCondition) => () => {
        const conditionIndex = alarm.Conditions.indexOf(condition);

        setState({
            ...state,
            showRemoveConditionDialog: true,
            conditionIndex
        })
    }

    const onResetTriggerClick = () => {
        updateAlarmProperty(0, "MaxTriggerCount");
    }

    const getTriggeredConditions = (): AlarmCondition[] => {
        if (alarm.MaxTriggerCount === 0) {
            return [];
        }

        const result: AlarmCondition[] = [];

        alarm.Sensors.filter(s => s.TriggeredCount > 0).forEach(sensor => {
            const triggeredCondition = alarm.Conditions.find(c => c.Id === sensor.ConditionId);
            if (triggeredCondition !== undefined) {
                const addedCond = result.find(m => m.Id === triggeredCondition.Id);
                if (!addedCond) {
                    result.push(triggeredCondition);
                }
            }
        });

        return result;
    }

    const onShowAlarmOffsetInfoModalClick = () => {
        setState({ ...state, showOffsetInfoDialog: true });
    }

    const onHideAlarmOffsetInfoModalClick = () => {
        setState({ ...state, showOffsetInfoDialog: false });
    }

    const triggeredConditions = getTriggeredConditions();

    const noRelativeAttributes: SensorValueAttribute[] = [
        SensorValueAttribute.Deviation,
        SensorValueAttribute.DeviationX,
        SensorValueAttribute.DeviationY,
        SensorValueAttribute.DeviationZ,
        SensorValueAttribute.DeviationXY,
        SensorValueAttribute.InclinometerChainPosition1,
        SensorValueAttribute.InclinometerChainPosition2,
        SensorValueAttribute.AxisDeviationLZ,
        SensorValueAttribute.AxisDeviationTZ
    ];

    const isVibrationMode = alarm.SensorType === SensorCategory.VibrationSensor;

    const isAlarmState = triggeredConditions.length > 0;
    const tooltipText = isAlarmState ? t("Before adding new conditions you have to confirm all triggered sensors for this alarmplan") : undefined;

    if (nameRef.current !== null && nameRef.current.value !== alarm.Name) {
        nameRef.current.value = alarm.Name;
    }

    return (
        <div style={{ width: '100%', padding: '5px' }}>
            <TextEditField
                htmlName="tfName"
                labelText={t("Name")}
                onChangeTextField={onTextPropertyChanged("Name")}
                value={alarm.Name}
                isDisabled={isReadonly}
                reference={nameRef}
                labelColumnStyle={{ width: '100px' }}
            />

            <div className="flexRowContainerLine" style={{ alignItems: 'center' }}>
                <div className="flexCellContainer" style={{ width: '100px' }}>
                    <span>{isTemplate ? t("Trigger limit") : t("Trigger state (cur/max)")}:</span>
                </div>

                <div className="flexCellContainer_g1" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                    {!isTemplate &&
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', flexGrow: 2 }}>
                            <span style={{ marginRight: '3px' }}>{alarm.MaxTriggerCount}</span>
                            <span>{"/"}</span>
                        </div>
                    }
                    <div style={{ width: '100%' }}>
                        <TextEditField
                            htmlName="tfMaxTrigger"
                            onChangeTextField={onTextPropertyChanged("TriggeredLimit")}
                            value={alarm.TriggeredLimit}
                            isDisabled={isReadonly}
                            hideLabel={true}
                            reference={triggerLimitRef}
                        />
                    </div>
                    <div>
                        <Button
                            // spacing="compact"
                            isDisabled={isReadonly}
                            appearance="default"
                            onClick={onResetTriggerClick}
                        >
                            {t("Reset")}
                        </Button>
                    </div>
                </div>
            </div>

            <SingleSelectEditField
                options={getSensorTypesOptions()}
                label={t("Type of Sensor")}
                onValueChanged={onSelectedOptionChanged("SensorType")}
                value={getSelectedSensorCategory()}
                labelColumnStyle={{ width: '100px' }}
                isDisabled={isReadonly}
            />

            <div className="flexRowContainerLine" style={{ alignItems: 'center', gap: '5px', gridGap: '5px' }}>
                <div className="flexCellContainer" style={{ width: '100px' }}>
                    <label htmlFor="cmbValuesSingleEditor" >{"Unit"}:</label>
                </div>
                <div className="flexCellContainer_g1">
                    <GeovisUnitSelect
                        options={getUnitsOptions()}
                        onChange={onUnitChange}
                        value={alarm.Unit}
                        spacing='compact'
                        customEditorStyle={{ flexGrow: 1 }}
                        isDisabled={isUnitDisabled() || isReadonly}
                    />
                </div>
            </div>

            <SingleSelectEditField
                options={getSensorValueOptions(isVibrationMode)}
                label={t("Value")}
                onValueChanged={onSelectedOptionChanged("SensorFunction")}
                value={getSelectedSensorValueOption(isVibrationMode)}
                labelColumnStyle={{ width: '100px' }}
                isDisabled={isReadonly}
            />

            {isVibrationMode &&
                <SingleSelectEditField
                    options={getRelevantFrequencyOptions()}
                    label={t("Relevant frequency")}
                    onValueChanged={onSelectedOptionChanged("RelevantFrequency")}
                    value={getSelectedRelevantOption()}
                    labelColumnStyle={{ width: '100px' }}
                    isDisabled={isReadonly}
                />
            }

            <div className='flexRowContainerLine'>
                <div style={{ width: '100px' }}>
                    {/*  */}
                </div>
                <BoolEditField
                    isChecked={alarm.UseRelative}
                    isDisabled={isReadonly || noRelativeAttributes.includes(alarm.SensorFunction) || isVibrationMode}
                    label={t("Use relative")}
                    onChange={onBooleanPropertyChanged("UseRelative")}
                />
            </div>

            {!isTemplate &&
                <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', alignItems: 'center' }}>
                    <MultiSelectEditField
                        options={getLabelsOptions()}
                        label={t("Labels")}
                        onValueChanged={onSelectedLabelsChanged}
                        value={getSelectedLabels()}
                        labelColumnStyle={{ width: '100px' }}
                        isCheckbox={true}
                        isDisabled={isReadonly}
                    />
                    <div style={{ marginRight: '5px' }}>
                        <Button
                            onClick={onShowLabelsListView}
                        >
                            {t("Manage labels")}
                        </Button>
                    </div>
                </div>
            }

            <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                <TextEditField
                    htmlName="tfOffset"
                    labelText={t("Offset")}
                    onChangeTextField={onTextPropertyChanged("Offset")}
                    value={alarm.Offset}
                    isDisabled={isReadonly || !alarm.AutoConfirm}
                    labelColumnStyle={{ width: '100px' }}
                    onInfoButtonClick={onShowAlarmOffsetInfoModalClick}
                    settingsStyle={{ width: '350px' }}
                    reference={offsetRef}
                />
                <BoolEditField
                    isChecked={alarm.AutoConfirm}
                    isDisabled={isReadonly}
                    label={t("Auto confirm")}
                    onChange={onBooleanPropertyChanged("AutoConfirm")}
                />
                <BoolEditField
                    isChecked={alarm.Retrigger}
                    isDisabled={isReadonly}
                    label={t("Retrigger")}
                    onChange={onBooleanPropertyChanged("Retrigger")}
                />

                <BoolEditField
                    isChecked={alarm.IsPublic}
                    isDisabled={isReadonly}
                    label={t("Is public")}
                    onChange={onBooleanPropertyChanged("IsPublic")}
                />
            </div>

            <div style={{ margin: '5px' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <span style={{ fontWeight: 'bold' }}>{t("Conditions")}:</span>
                    <div style={{ flexGrow: 3, display: 'flex', justifyContent: 'flex-end' }}>
                        {isAlarmState ? (
                            <Tooltip content={tooltipText}>
                                <Button
                                    isDisabled={true}
                                    appearance={isReadonly ? 'default' : 'primary'}
                                    iconBefore={<AddIcon label={'addCond'} />}
                                    onClick={onShowConditionEditDialog(isVibrationMode, undefined)}
                                >
                                    {t("Add condition")}
                                </Button>
                            </Tooltip>
                        ) : (
                            <Button
                                isDisabled={isReadonly}
                                appearance={isReadonly ? 'default' : 'primary'}
                                iconBefore={<AddIcon label={'addCond'} />}
                                onClick={onShowConditionEditDialog(isVibrationMode, undefined)}
                            >
                                {t("Add condition")}
                            </Button>
                        )}
                    </div>
                </div>
                <GeovisDynamicTable
                    rows={getConditionsRows(triggeredConditions, isVibrationMode)}
                    head={getConditionsHead()}
                />
            </div>



            <TextEditField
                htmlName="taComment"
                labelText={t("Comment")}
                isMultiLine={true}
                onChangeTextField={onTextPropertyChanged("Comment")}
                value={alarm.Comment}
                isDisabled={isReadonly}
                labelColumnStyle={{ width: '100px' }}
            />

            {state.showEditConditionDialog &&
                <AlarmConditionEditDialog
                    condition={state.condition}
                    hasTriggeredSensors={alarm.Sensors.some(s => s.TriggeredCount > 0)}
                    isNewCondition={state.conditionIndex < 0}
                    onClose={onHideConditionsDialogs}
                    onSaveCondition={onSaveCondition}
                    isReadonly={isReadonly}
                    isVibration={isVibrationMode}
                />
            }

            {state.showRemoveConditionDialog &&
                <AlarmConditionRemoveDialog
                    onClose={onHideRemoveConditionDialog}
                    onConfirm={onRemoveCondition}
                />
            }

            {state.showOffsetInfoDialog &&
                <AlarmOffsetInfoModal
                    onClose={onHideAlarmOffsetInfoModalClick}
                />
            }

            <LabelEditView />
            <LabelDeleteView />
            <LabelListView />
        </div>
    )
}