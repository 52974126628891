/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 25.10.2019
 * @description The document shape to render on the map
 */

import L, { DragEndEvent } from 'leaflet';
import { Marker } from 'react-leaflet';
import document24 from 'src/resources/icons/document_24px.png';
import document48 from 'src/resources/icons/document_48px.png';
import { locationToMapCoordinates } from '../../../helpers/MapHelper';
import { LocalMapObjectDocument } from '../../../server/LocalMapObjectDocument';
import { ILocalMapShapeOptions } from '../ILocalMapShapeOptions';
import { LocalMapObjectViewMode } from '../types';
import { LocalMapObjectPopup, LocalMapObjectTooltip } from './LocalMapObjectMapElements';
import { LocalMapObjectProperties } from '../../../server/GEOvis3/Model/LocalMapObjects/LocalMapObjectProperties';

interface ILocalMapObjectDocumentShapeProps {
    element: LocalMapObjectDocument;
    viewMode: LocalMapObjectViewMode;
    isEditMode: boolean;
    editState?: LocalMapObjectProperties;

    onEdit: (id: string) => void;
    onRemove: (id: string) => void;
    onOpenFile: (localMapObjectId: string) => void;
    onChangeLocation?: (elementId: string, latLng: L.LatLng) => void;
}

export const LocalMapObjectDocumentShape = ({
    element,
    editState,
    onEdit,
    onRemove,
    viewMode,
    onOpenFile: onDownloadFile,
    isEditMode,
    onChangeLocation,
}: ILocalMapObjectDocumentShapeProps) => {

    const elementDocument = element as LocalMapObjectDocument;
    const { Location } = elementDocument;
    const location = editState && editState.coordinates !== undefined ? editState.coordinates : Location;

    const documentIcon = new L.Icon({
        iconUrl: document24,
        iconRetinaUrl: document48,
        iconAnchor: [0, 0],
        popupAnchor: [12, 0],
        iconSize: [24, 24]
    });

    const onClickHandler = (event: L.LeafletMouseEvent) => {
        if (viewMode === LocalMapObjectViewMode.Edit && !event.originalEvent.ctrlKey) {
            return;
        }

        onDownloadFile(element.Id);
    };

    const documentOptions: ILocalMapShapeOptions = {
        localMapObjectId: element.Id,
        localMapObjectType: element.ObjectType
    };

    const getChildren = (): JSX.Element[] => {
        const result: JSX.Element[] = [];
        if (viewMode !== LocalMapObjectViewMode.MapSection) {
            result.push(<LocalMapObjectPopup key={`lmo-${element.Id}-popup-key`} element={element} onEdit={onEdit} onRemove={onRemove} editMode={viewMode === LocalMapObjectViewMode.Edit} dragMode={isEditMode} />)
        }
        result.push(<LocalMapObjectTooltip key={`lmo-${element.Id}-tooltip-key`} element={element} />);

        return result;
    }

    const onDragEnd = (ev: DragEndEvent) => {
        if (onChangeLocation) {
            const latLng = ev.target.getLatLng();
            onChangeLocation(element.Id, latLng);
        }
    }

    return (
        <Marker
            key={`local_map_document_${element.Id}_marker`}
            position={locationToMapCoordinates(location)}
            icon={documentIcon}
            eventHandlers={viewMode === LocalMapObjectViewMode.MapSection ? undefined : {
                click: onClickHandler,
                dragend: onDragEnd
            }}
            draggable={isEditMode}
            {...documentOptions}>
            {getChildren()}
        </Marker>
    )
}
