/**
 * @author Vyacheslav Skripin <vs@ieskr.ru>
 * @created 29.06.2020
 * @description Sensors and chains tooltip elements implementation
 */

import { CustomThemeButton } from '@atlaskit/button';
import Icon from '@atlaskit/icon';
import { Direction } from 'leaflet';
import React from 'react';
import { Tooltip } from 'react-leaflet';
import { compareFnOfElementInfoAlarms } from '../../../helpers/SortHelper';
import { t } from '../../../i18n';
import { ChainInfo } from '../../../server/ChainInfo';
import { ChainType } from '../../../server/ChainType';
import { SensorInfo } from '../../../server/GEOvis3/Model/SensorInfo';
import { getChainTooltipOffset, getSensorTooltipOffset } from '../../map/IconActions';
import { createSensorIconOfSvg } from '../../map/IconFactory';
import ChainLastMeasurement, { ICustomChainTooltipContentProps } from './ChainLastMeasurement';
import {
    KmPositionDescription,
    MapItemPopupHeaderAsLink
} from './PopupContentControls';
import { sensorLinkThemeFunc } from './tools';
import { SensorTooltipAlarmContent } from './TooltipContentControls';

import './geovis.tooltip';
import { ISensorsLastDataStorage } from '../../../store/data.types';
import { SensorLastMeasurementData } from './SensorLastMeasurement';


interface IStackedMapItemsTooltipProps {
    chainsProps: ISingleChainTooltipProps[];
    sensorsProps: ISingleSensorTooltipContentProps[];
    dataStorage: ISensorsLastDataStorage;
}

export const StackedMapItemsTooltipContent = ({ chainsProps, sensorsProps, dataStorage }: IStackedMapItemsTooltipProps) => (
    <div>
        {/* sensors */}
        {sensorsProps
            .sort((a, b) => compareFnOfElementInfoAlarms(a.sensor, b.sensor))
            .map(({ sensor, showLastMeasurementTime }, index) => {

                if (index === 3) {
                    return (
                        <span key={`more_span_${index}`}>{sensorsProps.length - 3}&nbsp;{t("more sensors...")}</span>
                    )
                }

                if (index > 3) {
                    return null;
                }

                return (
                    <React.Fragment key={`stacked_items_of_${index}`}>
                        <SingleSensorTooltipContent
                            key={`single_sensor_tooltip_${index}`}
                            showLastMeasurementTime={showLastMeasurementTime}
                            sensor={sensor}
                            dataStorage={dataStorage}
                        />
                        {index < sensorsProps.length - 1 && (<hr />)}
                    </React.Fragment>
                )
            })}

        {/* divider between sensors and chains */}
        {chainsProps.length > 0 && sensorsProps.length > 0 && (
            <hr />
        )}

        {chainsProps
            .sort((a, b) => compareFnOfElementInfoAlarms(a.chain, b.chain))
            .map(({ chain }, index) => {

                if (index === 3) {
                    // render text "X sensors more..."
                    return (
                        <span key={`mode_chains_${index}`}>{chainsProps.length - 3}&nbsp;{t("more chains...")}</span>
                    )
                }

                if (index > 3) {
                    // don't render anymore for tooltip
                    return null;
                }

                if (chain) {
                    return (
                        <React.Fragment key={`stacked_items_of_${index}`}>
                            <SingleChainTooltipContent chain={chain} />
                            {index < chainsProps.length - 1 && (<hr />)}
                        </React.Fragment>
                    );
                }

                return null;
            })}
    </div>
)

export interface ISingleChainTooltipProps extends ICustomChainTooltipContentProps {
    chain: ChainInfo;
    useGeovis4SensorTypeSymbol?: boolean;
}

/**
 * Single chain tooltip
 * @param props of type ISingleChainTooltipProps
 */
export const SingleChainTooltip = (props: ISingleChainTooltipProps) => (
    <Tooltip
        key={`chain_${props.chain.Id}_tooltip`}
        direction="top"
        className="MapItemTooltip"
        offset={getChainTooltipOffset({
            sensor: props.chain,
            useGeovis4SensorTypeSymbol: props.useGeovis4SensorTypeSymbol
        })}>
        <SingleChainTooltipContent {...props} />
    </Tooltip>
);

/**
 * Single chain tooltip content
 * @param props of type ISIngleChainTooltipProps
 */
const SingleChainTooltipContent = ({ chain, customChainsLastDataStorage }: ISingleChainTooltipProps) => (
    <div>
        {/* tooltip header content */}
        <span>
            <CustomThemeButton
                appearance="link"
                spacing="compact"
                iconBefore={
                    <Icon
                        label=""
                        dangerouslySetGlyph={createSensorIconOfSvg(chain)}
                    />}
                theme={sensorLinkThemeFunc}>
                {chain.Name}
            </CustomThemeButton>

            {chain.ChainType === ChainType.SlopeControl && Math.abs(chain.Km) > 0 && (
                <b><KmPositionDescription {...chain} /></b>
            )}

        </span>
        {/* <MapItemPopupHeaderAsLink/> */}
        <ChainLastMeasurement chain={chain} customChainsLastDataStorage={customChainsLastDataStorage} />
        {/* draw caused alarms */}
        <SensorTooltipAlarmContent {...chain} />
    </div>
)

export interface ISingleSensorTooltipContentProps {
    sensor: SensorInfo;
    dataStorage: ISensorsLastDataStorage;
    direction?: Direction;
    useGeovis4SensorTypeSymbol?: boolean;
    useSensorColor?: boolean;
    useSmartPosition?: boolean;
    showEvenNoDataAlarm?: boolean;
    showLastMeasurementTime?: boolean;
}

/**
 * Single sensor tooltip
 * @param props of ISingleSensorTooltipContentProps
 */
export const SingleSensorTooltip = (props: ISingleSensorTooltipContentProps) => (
    <Tooltip
        key={`sensor_${props.sensor.Id}_tooltip`}
        direction={props.direction || "top"}
        useSmartPosition={props.useSmartPosition}
        className="MapItemTooltip"
        offset={getSensorTooltipOffset({ sensor: props.sensor, useGeovis4SensorTypeSymbol: props.useGeovis4SensorTypeSymbol })}>
        <SingleSensorTooltipContent {...props} />
    </Tooltip>
);

/**
 * Single sensor tooltip content
 * @param props of type ISingleSensorTooltipProps
 */
const SingleSensorTooltipContent = ({ sensor, showEvenNoDataAlarm, showLastMeasurementTime, dataStorage, useGeovis4SensorTypeSymbol, useSensorColor }: ISingleSensorTooltipContentProps) => (
    <div>
        {/* <SensorPopupHeader {...sensor} /> */}
        <MapItemPopupHeaderAsLink
            key="MapItemPopupHeaderAsLink "
            sensor={sensor}
            sensorIconOptions={{ useGeovis4SensorTypeSymbol, useSensorColor }}
        />

        <SensorLastMeasurementData
            key="SensorLastMeasurement"
            sensor={sensor}
            showEvenNoDataAlarm={showEvenNoDataAlarm}
            isPopup={false}
            showMeasurementAt={showLastMeasurementTime}
            dataStorage={dataStorage}
        />

        <SensorTooltipAlarmContent
            key="SensorTooltipAlarmContent "
            {...sensor} />
    </div>
)