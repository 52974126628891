import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighChartAnnotations from 'highcharts/modules/annotations';
import HighchartsBoost from 'highcharts/modules/boost';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { t } from "i18next";
import React, { useLayoutEffect, useRef, useState } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { cloneDeep } from "lodash";
// eslint-disable-next-line @atlaskit/design-system/no-deprecated-imports
import { withNavigationUIController } from "@atlaskit/navigation-next";
import { GeovisChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/GeovisChartModel";
import { SemiCircleChartModel } from "../../../../../../server/AVTService/TypeLibrary/Model/GeovisCharts/SemiCircleChartModel";
import Logger from "../../../../../../services/Logger";
import { LoadingPageErrorSkeleton } from "../../../../../LoadingPageErrorSkeleton";
import { IReportElementRenderOwnProps } from "../../types";
import { getGeovisChartConfigFromStorage } from "../options/tools";
import { IChartRenderStateToProps } from "../types";
import { ChartLoadingSkeleton } from "./ChartLoadingComponent";
import { LoadingContainerSkeleton } from "../../../../../LoadingContainerSkeleton";
import { IGeovisChartReportData, IGeovisReportPageData } from "../../../../../../store/projectReport.types";
import { IGeovisStoreState } from "../../../../../../store/store.types";
import { IGeovisAction } from "../../../../../../store/types";
import { setDirtyGeovisChartSettings } from "../../../../../../store/creators/projectReportCreators";
import { getSemiCircleChartRenderOptions } from "../options/semiCircleChartRenderOptions";
import { SemiCircleChartResponseModel } from "../../../../../../server/AVTService/Computation/ChartComputation/ResponseModel/SemiCircleChartResponseModel";
import { TimePeriod } from "../../../../../../server/AVTService/TypeLibrary/Common/TimePeriod";
import { SemiCircleChartLegend } from "./extraComponents/SemiCircleChartLegend";
import IUINavigationControllerProps from "../../../../../atlaskit/UIControllerInterface";

type IStateToProps = IChartRenderStateToProps<SemiCircleChartResponseModel>

interface IDispatchToProps {
    setDirtyChartConfig: (pageNum: number, config: GeovisChartModel) => void;
}

interface IOwnProps extends IReportElementRenderOwnProps {
    chartId: number;
}

interface IComponentProps extends IStateToProps, IDispatchToProps, IOwnProps, IUINavigationControllerProps {

}

interface IComponentState {
    containerHeight: number;
    calculated: boolean;
    isNavigationCollapsed: boolean;
}

const SemiCircleChartRender = ({
    chartData,
    chartId,
    isPrinting,
    pageNum,
    navigationUIController
}: IComponentProps) => {
    if (!chartData) {
        return (<ChartLoadingSkeleton text={t("Initializing semi circle chart data")} />)
    }

    const { data, errorDescription, isError, isLoading, isLoaded } = chartData;

    if (isError) {
        return (<LoadingPageErrorSkeleton errorText={t("Error load semi circle chart data")} errorDescription={errorDescription} />);
    }

    if (!isLoaded) {
        return (<ChartLoadingSkeleton text={t("Loading semi circle chart data...")} />)
    }

    Logger.render('SemiCircleChartRender');

    // getting changed chart config directly from storage, do not subscribe on UPDATE event
    const chart = getGeovisChartConfigFromStorage<SemiCircleChartModel>(pageNum, chartId);
    if (!chart) {
        return null;
    }

    const containerRef = useRef<HTMLDivElement>(null);

    const [state, setState] = useState<IComponentState>({
        calculated: false,
        containerHeight: 300,
        isNavigationCollapsed: false
    })

    useLayoutEffect(() => {
        if (containerRef.current !== null && !state.calculated) {
            setState({
                ...state,
                containerHeight: containerRef.current.clientHeight / 2,
                calculated: true
            });
        }
        if (navigationUIController && state.isNavigationCollapsed !== navigationUIController.state.isCollapsed) {
            setTimeout(() => {
                setState({
                    ...state,
                    isNavigationCollapsed: navigationUIController.state.isCollapsed
                })
            }, 200);
        }
    });

    const renderOptions = getSemiCircleChartRenderOptions(pageNum, cloneDeep(chart), data, false);

    NoDataToDisplay(Highcharts);
    HighChartAnnotations(Highcharts);

    // disable boost if chart is in Printing mode
    if (!isPrinting) {
        HighchartsBoost(Highcharts);
    }

    return (
        <React.Fragment>
            <div className="report-chart-render" ref={containerRef} style={{ flexGrow: 1, display: 'flex', flexWrap: 'nowrap', height: '100%', position: 'relative' }}>

                {isLoading && (
                    <LoadingContainerSkeleton loadingText={t("Loading donut chart data...")} />
                )}

                {isLoaded && chart.ValueSettings.ShowColorBarScheme &&
                    null
                    // <div style={{ width: '200px', flexShrink: 0 }}>
                    //     {/*  */}
                    // </div>
                }

                {isLoaded &&
                    <div style={{ width: '100%', height: '100%' }}>
                        <HighchartsReact
                            key={`highcharts-${chart.Id}-0-${chartData.Timestamp}-${containerRef.current?.clientWidth}-${state.isNavigationCollapsed}`}
                            highcharts={Highcharts}
                            options={renderOptions}
                            constructorType={'chart'}
                            containerProps={{ style: { flexGrow: 2, height: `100%` } }}
                        />
                    </div>
                }
                {isLoaded &&
                    <SemiCircleChartLegend
                        containerHeight={state.containerHeight}
                        data={data.Data}
                        valueSettings={chart.ValueSettings}
                    />
                }
            </div>
        </React.Fragment>
    )
}

const getChartData = (chartId: number, pageNum: number, pagesData: Map<number, IGeovisReportPageData>): IGeovisChartReportData<SemiCircleChartResponseModel> => {
    const resultChartData: IGeovisChartReportData<SemiCircleChartResponseModel> = {
        data: {
            ... new SemiCircleChartResponseModel(),
            Data: [],
            EndDate: '',
            LinkedAlarmLines: [],
            SlimChartConfig: {
                EndDate: '',
                Id: 0,
                Period: TimePeriod.Day1,
                StartDate: '',
                Title: ''
            },
            StartDate: '',
            startMeasurementTime: '',
            Timeslot: '',
            TimeSlotFrom: '',
            TimeSlotTo: '',
            VibrationAlarmsBands: [],
            visualSettings: {
                chartAxisLabelFontSize: '12px',
                chartLineWidth: '10px',
                movementVectorColor: 'red',
                negativeHeightBarColor: 'red',
                positiveHeightBarColor: 'red'
            }
        },
        dtsSectionInfos: [],
        errorDescription: '',
        isError: false,
        isInProgress: false,
        isLoaded: false,
        isLoading: true,
        Timestamp: 0,
    }
    const chartsOnPageData = pagesData.get(pageNum);
    if (chartsOnPageData) {
        const chartData = chartsOnPageData.geovisChartsData.get(chartId);
        if (chartData) {
            return chartData as IGeovisChartReportData<SemiCircleChartResponseModel>;
        }
    }
    return resultChartData;
}

const mapStateToProps = ({ projectReport }: IGeovisStoreState, { chartId, pageNum }: IOwnProps): IStateToProps => ({
    chartData: getChartData(chartId, pageNum, projectReport.geovisReportSettings.geovisPagesData)
});

const mapDispatchToProps = (dispatch: Dispatch<IGeovisAction>): IDispatchToProps => ({
    setDirtyChartConfig: (pageNumber, config) => dispatch(setDirtyGeovisChartSettings(pageNumber, config))
});

export default connect<IStateToProps, IDispatchToProps, IOwnProps>(mapStateToProps, mapDispatchToProps)(withNavigationUIController(SemiCircleChartRender));